exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo-logoColor-1n1kN {\n\tcolor: var(--btn-primary-bg);\n\tcolor: var(--btn-primary-bg);\n}\n\n.Logo-whiteColor-2YsHA {\n\tcolor: #fff;\n}\n\n.Logo-logoTextTabViewWidth-3al28 {\n\t-o-text-overflow: ellipsis;\n\t   text-overflow: ellipsis;\n\toverflow: hidden;\n\tdisplay: block;\n\twidth: 100%;\n\tmax-width: 180px;\n\twhite-space: nowrap;\n}\n\n@media screen and (max-width:1299px) {\n\t.Logo-logoTextTabViewWidth-3al28 {\n\t\tmax-width: 145px;\n\t}\n}\n\n@media screen and (max-width: 1199px) {\n\t.Logo-logoTextTabViewWidth-3al28 {\n\t\tmax-width: 180px;\n\t}\n}\n\n@media screen and (min-width: 768px) and (max-width: 991px) {\n\t.Logo-logoTextTabViewWidth-3al28 {\n\t\tmax-width: 110px;\n\t}\n}\n\n@media screen and (max-width: 767px) {\n\t.Logo-logoTextTabViewWidth-3al28 {\n\t\tmax-width: 200px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"logoColor": "Logo-logoColor-1n1kN",
	"whiteColor": "Logo-whiteColor-2YsHA",
	"logoTextTabViewWidth": "Logo-logoTextTabViewWidth-3al28"
};