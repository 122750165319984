exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Contact-root-3ydpW {\n  padding-left: 20px;\n  padding-right: 20px;\n  position: relative;\n  width: 100%;\n  z-index: 1;\n  height: auto;\n}\n\n.Contact-container-2rVw2 {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-container-width);\n  max-width: var(--max-container-width);\n}\n\n@media screen and (max-width:1200px) {\n  .Contact-container-2rVw2 {\n    padding: 40px 0 40px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "Contact-root-3ydpW",
	"container": "Contact-container-2rVw2"
};