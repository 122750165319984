exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DropDownMenu-menuBg-2Wfid {\n    width: 78px;\n    height: 44px;\n    background: var(--menu-background-color);\n    background: var(--menu-background-color);\n    border-radius: 22px;\n    display: grid;\n    grid-template-columns: 50% 50%;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    padding: 0 10px 0 12px;\n    cursor: pointer;\n}\n\n.DropDownMenu-icon-25BRV {\n    height: 50px\n}\n\n.DropDownMenu-userAvatar-3V6pZ {\n    border-radius: 50%;\n}\n\n.DropDownMenu-notification-ATATL {\n    fill: var(--btn-primary-bg);\n    fill: var(--btn-primary-bg);\n    height: 10px;\n    width: 10px;\n    position: absolute;\n    top: 1px;\n    right: 10px;\n}\n\n@media screen and (max-width: 767px) {\n    .DropDownMenu-notification-ATATL {\n        top: -3px;\n    }\n}", ""]);

// exports
exports.locals = {
	"menuBg": "DropDownMenu-menuBg-2Wfid",
	"icon": "DropDownMenu-icon-25BRV",
	"userAvatar": "DropDownMenu-userAvatar-3V6pZ",
	"notification": "DropDownMenu-notification-ATATL"
};