import React from "react";
import PropTypes from "prop-types";
import { flowRight as compose } from "lodash";

// Redux
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";

import cookie from "react-cookies";

import cloud from "./cloud.svg";
import fly from "./fly.svg";
import egypt from "./Egypt.png";
import dubia from "./Dubia.png";
import bookIcon from "./images-svgs/book.svg";
import shareIcon from "./images-svgs/share.svg";
import swapIcon from "./images-svgs/swap.svg";

// Translation
import { FormattedMessage, injectIntl } from "react-intl";

import withStyles from "isomorphic-style-loader/lib/withStyles";

import { Button, Grid, Row, Col, FormControl } from "react-bootstrap";
import cx from "classnames";
import * as FontAwesome from "react-icons/lib/fa";
// Components
import DateRange from "../DateRange";
import PlaceGeoSuggest from "../PlaceGeoSuggest";
import MobileDateRange from "../MobileDateRange";

// Redux Action
import { getSpecificSettings } from "../../../actions/getSpecificSettings";
import { setPersonalizedValues } from "../../../actions/personalized";

// Helper
import detectMobileBrowsers from "../../../helpers/detectMobileBrowsers";
// History
import history from "../../../core/history";
// Locale
import messages from "../../../locale/messages";

//Imaage
import searchIcon from "/public/SiteIcons/searchIconHome.svg";

import bt from "../../commonStyle.css";
import s from "./SearchForm.css";

class SearchForm extends React.Component {
  static propTypes = {
    inHeader: PropTypes.bool,
    setPersonalizedValues: PropTypes.any.isRequired,
    getSpecificSettings: PropTypes.any.isRequired,
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      chosen: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      personCapacity: PropTypes.number,
      formatMessage: PropTypes.any,
      isOneTotalToggle: PropTypes.bool,
    }),
    settingsData: PropTypes.shape({
      listSettings: PropTypes.array.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    listingFields: [],
  };

  static defaultProps = {
    inHeader: false,
    personalized: {
      location: null,
      lat: null,
      lng: null,
      startDate: null,
      endDate: null,
      personCapacity: null,
      chosen: null,
    },
    settingsData: {
      listSettings: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileDevice: false,
      personCapacity: [],
      isLoad: false,
      smallDevice: false,
      verySmallDevice: false,
      isOneTotalToggle: false,
      isCardVisible: false,
      adults: 1, // Default values
      children: 0,
      infants: 0,
      pets: 0,
      myCurrency: cookie.load("currency"),
      selectedActions: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.cardRef = React.createRef();
    this.toggleCardVisibility = this.toggleCardVisibility.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updatePersonCapacity = this.updatePersonCapacity.bind(this); // Bind the method
    this.handleIconClick = this.handleIconClick.bind(this);
  }

  updatePersonCapacity = () => {
    const totalGuests = (this.state.adults || 0) + (this.state.children || 0);

    this.props.setPersonalizedValues({
      name: "personCapacity",
      value: totalGuests, // Sum of adults and children
    });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({ isLoad: false });
    let isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }

    const loadedCurrency = cookie.load("currency");
    this.setState({ myCurrency: loadedCurrency });
    console.log("Loaded currency:", loadedCurrency);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields, personalized } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
    if (personalized?.isOneTotalToggle)
      this.setState({ isOneTotalToggle: personalized?.isOneTotalToggle });
  }

  UNSAFE_componentWillMount() {
    const { getSpecificSettings, listingFields, personalized } = this.props;
    this.setState({ isLoad: true });
    if (detectMobileBrowsers.isMobile() === true) {
      this.setState({ mobileDevice: true });
    }
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
    if (personalized?.isOneTotalToggle)
      this.setState({ isOneTotalToggle: personalized?.isOneTotalToggle });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    let isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.removeEventListener("resize", this.handleResize);
    }
  }

  handleClickOutside(event) {
    if (this.cardRef.current && !this.cardRef.current.contains(event.target)) {
      this.setState({ isCardVisible: false });
    }
  }

  toggleCardVisibility() {
    this.setState((prevState) => ({ isCardVisible: !prevState.isCardVisible }));
  }

  handleResize(e) {
    let isBrowser = typeof window !== "undefined";
    let smallDevice = isBrowser
      ? window.matchMedia("(max-width: 767px)").matches
      : true;
    let verySmallDevice = isBrowser
      ? window.matchMedia("(max-width: 480px)").matches
      : false;

    this.setState({
      smallDevice,
      verySmallDevice,
    });
  }

  handleIconClick(action) {
    this.setState((prevState) => {
      const { selectedActions } = prevState;
      if (selectedActions.includes(action)) {
        // Remove the action if already selected
        return {
          selectedActions: selectedActions.filter((a) => a !== action),
        };
      } else {
        // Add the action if not already selected
        return {
          selectedActions: [...selectedActions, action],
        };
      }
    });
  }

  handleClick() {
    const { personalized, setPersonalizedValues, change } = this.props;
    const { selectedActions } = this.state;

    let updatedURI,
      uri = "/s?";

    if (personalized?.chosen) {
      uri =
        uri +
        "&address=" +
        personalized?.location +
        "&chosen=" +
        personalized?.chosen;
    } else {
      if (personalized?.location) {
        uri = uri + "&address=" + personalized?.location;
      }
    }

    if (personalized?.startDate && personalized?.endDate) {
      setPersonalizedValues({ name: "isOneTotalToggle", value: true });
      change("SearchForm", "isOneTotalToggle", true);
      uri =
        uri +
        "&startdate=" +
        personalized?.startDate +
        "&enddate=" +
        personalized?.endDate;
    }

    if (personalized?.personCapacity && !isNaN(personalized?.personCapacity)) {
      uri = uri + "&guests=" + personalized?.personCapacity;
    }

    // Add selected actions to the query string
    if (selectedActions.length > 0) {
      selectedActions.forEach((action) => {
        uri = uri + `&${action.toLowerCase()}=true`;
      });
    }

    updatedURI = encodeURI(uri);
    history.push(updatedURI);
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
      </div>
    );
  };

  renderGuestRow(title, subtitle, key) {
    return (
      <div className={cx(s.guestRow)}>
        <div className={cx(s.guestInfo)}>
          <span className={cx(s.guestIcon)}>
            {title === "Adults" ? (
              <FontAwesome.FaUser />
            ) : title === "Children" ? (
              <FontAwesome.FaChild />
            ) : title === "Infants" ? (
              <FontAwesome.FaSmileO />
            ) : (
              <FontAwesome.FaPaw />
            )}
          </span>
          <div>
            <p className={cx(s.guestTitle)}>{title}</p>
            <span className={cx(s.guestSubtitle)}>{subtitle}</span>
          </div>
        </div>
        <div className={cx(s.counterGroup)}>
          <button
            className={cx(s.counterButton)}
            onClick={() => {
              this.setState(
                (prevState) => ({
                  [key]: Math.max(
                    key === "adults"
                      ? (prevState[key] || 1) - 1
                      : (prevState[key] || 0) - 1,
                    key === "adults" ? 1 : 0
                  ),
                }),
                this.updatePersonCapacity
              );
            }}
            disabled={
              key === "adults" ? this.state[key] <= 1 : this.state[key] <= 0
            }
          >
            -
          </button>
          <span className={cx(s.guestCount)}>
            {this.state[key] || (key === "adults" ? 1 : 0)}
          </span>
          <button
            className={cx(s.counterButton)}
            onClick={() => {
              this.setState(
                (prevState) => ({
                  [key]: (prevState[key] || 0) + 1,
                }),
                this.updatePersonCapacity
              );
            }}
          >
            +
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      location,
      dates,
      settingsData,
      setPersonalizedValues,
      personalized,
      layout5Css,

      guests,
      inHeader,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { personCapacity, smallDevice, isLoad, myCurrency, selectedActions } =
      this.state;
    let rows = [],
      startValue,
      endValue;
    const isBrowser = typeof window !== "undefined";

    const totalGuests = (this.state.adults || 0) + (this.state.children || 0);

    const imageSrc =
      myCurrency === "EGP" ? egypt : myCurrency === "AED" ? dubia : fly; // Default to 'fly' if currency is not set

    if (personCapacity?.[0] && personCapacity[0]?.startValue) {
      for (
        let i = personCapacity[0]?.startValue;
        i <= personCapacity[0]?.endValue;
        i++
      ) {
        rows.push(
          <option value={i} key={i}>
            {i}{" "}
            {i > 1
              ? personCapacity[0]?.otherItemName
              : personCapacity[0]?.itemName}
          </option>
        );
        startValue = personCapacity[0]?.startValue;
        endValue = personCapacity[0]?.endValue;
      }
    }
    // const smallDevice = isBrowser ? window.matchMedia('(max-width: 640px)').matches : undefined;

    return (
      <div className={"layout5Css"}>
        <div
          className={cx(
            s.searchFormInputs,
            "homeSearchForm",
            "verticalsearchform",
            "layout5Searchform"
          )}
          style={{
            position: "relative",
          }}
        >
          <div className={cx(s.iconsFilters)}>
            <div
              className={cx(s.iconContainer, {
                [s.active]: selectedActions.includes("book"),
              })}
              onClick={() => this.handleIconClick("book")}
              role="button"
              tabIndex="0"
            >
              <div>
                <img src={bookIcon} alt="Book Icon" />
              </div>
              <span>Book</span>
            </div>
            <div
              className={cx(s.iconContainer, {
                [s.active]: selectedActions.includes("share"),
              })}
              onClick={() => this.handleIconClick("share")}
              role="button"
              tabIndex="0"
            >
              <div>
                <img src={shareIcon} alt="Share Icon" />
              </div>
              <span>Share</span>
            </div>
          </div>

          {!inHeader && (
            <>
              <img
                src={cloud}
                alt="onvacaday"
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "-25px",
                }}
              />

              {/* change fly depended on currency */}
              <img
                src={imageSrc}
                alt="onvacaday"
                style={{
                  position: "absolute",
                  top: "-210px",
                  right: "-110px",
                  width: "290px",
                  height: "270px",
                  objectFit: "cover",
                }}
              />
            </>
          )}

          <div
            className={cx(s.searchForm, "layout5SearchformRadiaus")}
            style={{
              position: "relative",
              background: "white",
            }}
          >
            <div className={cx(s.table)}>
              <div className={cx(s.tableRow)}>
                <div
                  className={cx(
                    s.tableCell,
                    s.location,
                    "tableCellRTL",
                    "layout5SearchformLocation",
                    "layout5DateWidth",
                    "layout5AllPadding",
                    "tableCellRemoveBorder"
                  )}
                >
                  <label className={s.label}>
                    <span>
                      {" "}
                      <FormattedMessage {...messages.where} />
                    </span>
                  </label>
                  {!isLoad && (
                    <PlaceGeoSuggest
                      label={formatMessage(messages.homeWhere)}
                      className={cx(s.formControlInput, s.input)}
                      containerClassName={s.geoSuggestContainer}
                    />
                  )}
                  {isLoad && (
                    <Field
                      component={this.renderFormControl}
                      label={formatMessage(messages.homeWhere)}
                      className={cx(s.formControlInput, s.input)}
                      name="location"
                    />
                  )}
                </div>

                <div
                  className={cx(
                    s.tableCell,
                    s.dates,
                    "layout5SearchformLocationTwo",
                    "layout5DateWidth",
                    "layout5AllPadding"
                  )}
                >
                  <label className={s.label}>
                    <span>
                      {" "}
                      <FormattedMessage {...messages.when} />
                    </span>
                  </label>
                  <span
                    className={cx(
                      "homeDate",
                      s.formControlInput,
                      s.input,
                      "homeDateAR",
                      "homeSerachDate"
                    )}
                  >
                    {!smallDevice && (
                      <DateRange formName={"SearchForm"} numberOfMonths={2} />
                    )}

                    {smallDevice && (
                      <MobileDateRange
                        formName={"SearchForm"}
                        numberOfMonths={1}
                      />
                    )}
                  </span>
                </div>

                <div
                  className={cx(
                    s.tableCell,
                    s.guests,
                    s.guestPadding,
                    s.mobilePadding,
                    "tableCellLeftRTL",
                    "layOut5SearchFormGuest",
                    "layout5AllPadding"
                  )}
                >
                  <label className={cx(s.selectPadding, s.label, "inputSpace")}>
                    <span>
                      <FormattedMessage {...messages.guest} />
                    </span>
                  </label>
                  {/* <FormControl
                    componentClass="select"
                    className={cx(
                      s.formControlSelect,
                      s.input,
                      s.inputPadding,
                      "inputPaddingAR",
                      "inputSpace",
                      "inputPaddingRTL"
                    )}
                    onChange={(e) =>
                      setPersonalizedValues({
                        name: "personCapacity",
                        value: Number(e.target.value),
                      })
                    }
                    defaultValue={personalized.personCapacity}
                    value={personalized.personCapacity}
                  >
                    {rows}
                  </FormControl> */}
                  <div
                    className={cx(
                      s.formControlInput,
                      s.input,
                      s.inputPadding,
                      "inputPaddingAR",
                      "inputSpace",
                      "inputPaddingRTL"
                    )}
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "3px",
                    }}
                    onClick={this.toggleCardVisibility}
                  >
                    {totalGuests} {totalGuests === 1 ? "guest" : "guests"}
                  </div>
                  {this.state.isCardVisible && (
                    <div className={cx(s.card)} ref={this.cardRef}>
                      <div className={cx(s.guestPickerContainer)}>
                        <div className={cx(s.guestPickerPopup)}>
                          {/* Adults Row */}
                          {this.renderGuestRow(
                            "Adults",
                            "Ages 13 or above",
                            "adults"
                          )}

                          {/* Divider */}
                          <div className={cx(s.divider)}></div>

                          {/* Children Row */}
                          {this.renderGuestRow(
                            "Children",
                            "Ages 2 to 12",
                            "children"
                          )}

                          {/* Divider */}
                          <div className={cx(s.divider)}></div>

                          {/* Infants Row */}
                          {this.renderGuestRow(
                            "Infants",
                            "Under 2 years old",
                            "infants"
                          )}

                          {/* Divider */}
                          <div className={cx(s.divider)}></div>

                          {/* Pets Row */}
                          {this.renderGuestRow(
                            "Pets",
                            "Bringing a service animal?",
                            "pets"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={cx(
                    s.tableCell,
                    s.search,
                    s.noBroderRight,
                    "layOut5SearchBtnBottom",
                    "noBroderRightRTL"
                  )}
                >
                  <Button
                    className={cx(
                      bt.btnPrimary,
                      s.btnBlock,
                      s.searchButton,
                      "layOut5SearchBtn"
                    )}
                    onClick={this.handleClick}
                  >
                    <span>
                      <img
                        src={searchIcon}
                        className={cx(
                          s.searchIconCommon,
                          "searchIconCommonRTL"
                        )}
                      />
                    </span>
                    <FormattedMessage {...messages.search} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchForm = reduxForm({
  form: "HomeSearchForm", // a unique name for this form
})(SearchForm);

const mapState = (state) => ({
  personalized: state.personalized,
  settingsData: state.viewListing.settingsData,
  listingFields: state.listingFields.data,
  isOneTotalToggle: state.personalized.isOneTotalToggle,
});

const mapDispatch = {
  getSpecificSettings,
  setPersonalizedValues,
  change,
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch)
)(SearchForm);
