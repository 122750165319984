import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from "react-bootstrap/lib/FormControl";
import InputGroup from 'react-bootstrap/lib/InputGroup';
import { compose } from 'react-apollo';

import Link from '../../Link/Link';
import Loader from '../../Loader';
import CountryList from '../../CountryList';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';

import { sendVerificationSms } from '../../../actions/SmsVerification/sendVerificationSms';
import { openSmsVerificationModal } from '../../../actions/SmsVerification/modalActions';

import messages from '../../../locale/messages';
import showToaster from '../../../helpers/showToaster';

import s from './AddPhoneNumberForm.css';
import bt from '../../../components/commonStyle.css';

class AddPhoneNumberForm extends Component {

  static propTypes = {
    fieldType: PropTypes.string,
    formatMessage: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      countryCode: 'US',
      country: '+1',
      phoneNumber: '',
      submitting: false,
      error: null,
      isAgreePhoneNumberVerification: false
    }
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitForm = async () => {
    const { sendVerificationSms } = this.props;
    const { country, phoneNumber, countryCode, isAgreePhoneNumberVerification } = this.state;
    let error = null, submitting = false;
    if (!phoneNumber) {
      error = { phoneNumber: 'required' };
    } else if (isNaN(phoneNumber)) {
      error = { phoneNumber: 'required' };
    }

    if (!isAgreePhoneNumberVerification && error === null) {
      showToaster({ messageId: 'isAgreePhoneNumberError', toasterType: 'error' });
      return;
    }
    submitting = (error === null && isAgreePhoneNumberVerification) ? true : false;

    this.setState({ submitting, error });

    if (error === null && submitting) {
      const { status, errorMessage } = await sendVerificationSms(country, phoneNumber, countryCode);

      if (status != '200') {
        if (errorMessage == 'Authenticate') {
          error = { phoneNumber: 'twilioAuthErr' };
        } else {
          error = { phoneNumber: errorMessage ? errorMessage : 'Sorry, something went wrong. Please try again' };
        }
      }
    }
    if (this.refs.addPhoneNumberForm) {
      this.setState({ submitting: false, error });
    }
  }

  handleCountryChange = (e, selectedData) => {
    this.setState({
      country: selectedData.dialCode,
      countryCode: selectedData.countryCode
    });
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { country, phoneNumber, submitting, error, countryCode, isAgreePhoneNumberVerification } = this.state;
    const { siteName } = this.props;

    return (
      <div className={s.formContainer} ref="addPhoneNumberForm">
        <FormGroup className={s.formGroup}>
          <label className={cx(s.labelText, 'textWhite')} >
            <FormattedMessage {...messages.chooseACountry} />
          </label>
          <CountryList
            input={
              {
                name: 'countryCode',
                onChange: this.handleChange,
                value: countryCode
              }
            }
            className={cx(bt.commonControlSelect)}
            dialCode={false}
            getSelected={this.handleCountryChange}
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <label className={cx(s.labelText, 'textWhite')} >
            <FormattedMessage {...messages.addAPhoneNumber} />
          </label>
          <InputGroup>
            <InputGroup.Addon className={s.inputGroupAddon}>{country}</InputGroup.Addon>
            <FormControl
              name={'phoneNumber'}
              value={phoneNumber}
              placeholder={''}
              type={'text'}
              className={cx(bt.commonControlInput)}
              onChange={this.handleChange} />
          </InputGroup>
          {error && error.phoneNumber && (
            <span className={s.errorMessage}>
              {messages[error.phoneNumber]
                ? formatMessage(messages[error.phoneNumber])
                : error.phoneNumber
              }
            </span>
          )}
        </FormGroup>


        <div className={cx(s.phoneNumberCheckBox, bt.space3)}>
          <CustomCheckbox
            className={'icheckbox_square-green flexNoShrink'}
            name={'isAgreePhoneNumberVerification'}
            checked={isAgreePhoneNumberVerification}
            onChange={e => { this.setState({ isAgreePhoneNumberVerification: e }) }}
          />
          <p className={s.indicationTxt}>
            {formatMessage(messages.phoneNumberVerificationInfo1)}{" "}{siteName}{" "}{formatMessage(messages.phoneNumberVerificationInfo2)}
            {" "}  <a href="/privacy" target="_blank">
              {formatMessage(messages.termsAndPrivacy)}
            </a>
          </p>
        </div>

        <FormGroup className={cx(s.formGroup, 'textAlignEnd', bt.noMargin)}>
          <Loader
            type={"button"}
            buttonType={"button"}
            className={cx(s.button, bt.btnPrimary, bt.btnLarge, 'arButtonLoader')}
            disabled={submitting}
            show={submitting}
            label={formatMessage(messages.verifyViaSms)}
            handleClick={this.submitForm}
          />
        </FormGroup>
      </div>
    )
  }

}

const mapState = (state) => ({
  profileId: state.account.data.profileId,
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
  sendVerificationSms,
  openSmsVerificationModal
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch)
)(AddPhoneNumberForm);