exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n\n.PriceDetailsModal-logInModalContainer-zS8Qh {\n    max-width: 780px;\n    width: 100%;\n}\n\n.PriceDetailsModal-logInModalBody-3DQxn {\n    padding: 0px;\n}\n\n.PriceDetailsModal-panelHeader-wOraj {\n    font-size: 16px;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    border-bottom: 1px solid var(--border-secondary-color);\n    border-bottom: 1px solid var(--border-secondary-color);\n    background: transparent;\n    text-align: center;\n}\n\n@media (max-width:991px) and (min-width:768px) {\n    .PriceDetailsModal-logInModalContainer-zS8Qh {\n        width: auto;\n        display: block;\n        margin: 30px 15px;\n        max-width: 100%;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .PriceDetailsModal-logInModalContainer-zS8Qh {\n        display: block;\n        width: auto;\n        max-width: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "PriceDetailsModal-logInModalContainer-zS8Qh",
	"logInModalBody": "PriceDetailsModal-logInModalBody-3DQxn",
	"panelHeader": "PriceDetailsModal-panelHeader-wOraj"
};