import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./CreateShare.css";

// Component
import CreateShareList from "../../components/CreateShareList/CreateShareList";
import { bookingProcess } from "../../actions/booking/bookingProcess";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class CreateShare extends Component {
  static propTypes = {
    listId: PropTypes.string.isRequired,
    isHost: PropTypes.bool,
    totalAmount: PropTypes.number,
    basePrice: PropTypes.number,
    cleaningPrice: PropTypes.number,
    tax: PropTypes.number,
    currency: PropTypes.string,
    monthlyDiscount: PropTypes.number,
    weeklyDiscount: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    bookingProcess: PropTypes.func.isRequired,
    base: PropTypes.any,
    rates: PropTypes.any,
    serviceFees: PropTypes.any,
  };

  render() {
    const {
      listId,
      isHost,
      totalAmount,
      basePrice,
      cleaningPrice,
      tax,
      currency,
      base,
      rates,
      monthlyDiscount,
      weeklyDiscount,
      startDate,
      endDate,
      bookingProcess,
      serviceFees,
    } = this.props;
    
    return (
      <div className={cx(s.container, s.overFlowHiddenMobile)}>
        <CreateShareList
          listId={listId}
          isHost={isHost}
          totalAmount={totalAmount}
          basePrice={basePrice}
          cleaningPrice={cleaningPrice}
          tax={tax}
          taxRate={0}
          serviceFees={serviceFees}
          currency={currency}
          base={base}
          rates={rates}
          monthlyDiscount={monthlyDiscount}
          weeklyDiscount={weeklyDiscount}
          startDate={startDate}
          endDate={endDate}
          bookingProcess={bookingProcess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  base: state.currency.base,
  rates: state.currency.rates,
});

const mapDispatchToProps = {
  bookingProcess,
};

export default injectIntl(
  withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(CreateShare))
);
