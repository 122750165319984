import React from "react";
import PropTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import { formValueSelector } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import cx from "classnames";
import * as FontAwesome from "react-icons/lib/fa";
import Scroll from "react-scroll";

import Photos from "../../components/ViewListing/Photos";
import ListingIntro from "../../components/ViewListing/ListingIntro";
import Calendar from "../../components/ViewListing/Calendar";
import ListingDetails from "../../components/ViewListing/ListingDetails";
import Reviews from "../../components/ViewListing/Reviews";
import HostDetail from "../../components/ViewListing/HostDetail";
import LocationMap from "../../components/ViewListing/LocationMap";
import Loader from "../../components/Loader";
import NotFound from "../notFound/NotFound";
import AvailabilityCalendar from "../../components/ViewListing/AvailabilityCalendar";
import StarRating from "../../components/StarRating";
import CurrencyConverter from "../../components/CurrencyConverter";
import BookingModal from "../../components/ViewListing/BookingModal";
import SimilarListings from "../../components/ViewListing/SimilarListings";
import HostDetails from "../../components/ViewListing/HostDetails";

import BlockedDatesQuery from "./BlockedDates.graphql";
import ListingDataQuery from "./getListingData.graphql";
import MoreReviewsQuery from "./MoreReviews.graphql";
import SimilarListsQuery from "./getSimilarListing.graphql";
import AutoAffix from "react-overlays/lib/AutoAffix";

import messages from "../../locale/messages";
import { openBookingModal } from "../../actions/BookingModal/modalActions";

import s from "./ViewListing.css";
import bt from "../../components/commonStyle.css";
import ShareTable from "../../components/shareTable/ShareTable";
import ListingIntroNew from "../../components/ViewListing/ListingIntro/ListingIntroNew";

import { bookingProcess } from "../../actions/booking/bookingProcess";
import { Modal } from "react-bootstrap";

// Or Access Link,Element,etc as follows
let Link = Scroll.Link;
let Element = Scroll.Element;
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
var durationFn = function (deltaTop) {
  return deltaTop;
};
class ViewListing extends React.Component {
  //

  constructor(props) {
    super(props);

    // Initialize state with default tab
    const isBrowser = typeof window !== "undefined";
    const defaultTab = "book";

    let activeTab = defaultTab;
    if (isBrowser) {
      const params = new URLSearchParams(window.location.search);
      const activeParam = params.get("active");
      if (activeParam === "book" || activeParam === "share") {
        activeTab = activeParam;
      }
    }

    this.state = {
      activeTab: activeTab,
      showModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  //
  static propTypes = {
    title: PropTypes.string.isRequired,
    listId: PropTypes.number.isRequired,
    preview: PropTypes.bool,
    isAdmin: PropTypes.bool,
    account: PropTypes.shape({
      userId: PropTypes.string,
      userBanStatus: PropTypes.number,
    }),
    ListingBlockedDates: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.shape({
        blockedDates: PropTypes.array,
      }),
    }),
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object,
    }),
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    similarListsData: PropTypes.shape({
      loading: PropTypes.bool,
      getSimilarListing: PropTypes.array,
    }),
    openBookingModal: PropTypes.func,
  };
  static defaultProps = {
    getListingData: {
      loading: false,
      UserListing: {
        userId: null,
      },
    },
    ListingBlockedDates: {
      loading: true,
      UserListing: {
        blockedDates: [],
      },
    },
    account: {
      userId: null,
      userBanStatus: 0,
    },
    isAdmin: false,
  };

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  render() {
    const {
      listId,
      title,
      getListingData: { loading, UserListing },
      preview,
      averageBasePrice,
      bookingProcess,
    } = this.props;
    const { ListingBlockedDates } = this.props;
    const { listReviewsData } = this.props;
    const { openBookingModal, baseCurrency } = this.props;

    const { showModal } = this.state;

    const {
      account: { userId, userBanStatus },
      isAdmin,
    } = this.props;
    //
    const { activeTab } = this.state;
    //
    const {
      lat,
      lng,
      startDate,
      endDate,
      guests,
      similarListsData,
      similarListsData: { getSimilarListing },
    } = this.props;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser
      ? window.matchMedia("(max-width: 640px)").matches
      : undefined;

    let basePriceValue =
      UserListing &&
      UserListing.listingData &&
      UserListing.listingData.basePrice
        ? UserListing.listingData.basePrice
        : 0;
    let currencyValue =
      UserListing && UserListing.listingData && UserListing.listingData.currency
        ? UserListing.listingData.currency
        : "USD";

    if (loading && !UserListing) {
      return (
        <div className={cx(s.loaderMobileView, s.viewListingLoder)}>
          <Loader type="text" />
        </div>
      );
    }
    let isHost = false;
    if (UserListing) {
      if (userId && userId === UserListing.userId) {
        isHost = true;
      } else if (isAdmin) {
        isHost = true;
      }
    }
    if (preview && !isHost) {
      return <NotFound title={title} />;
    }
    if (!loading && !UserListing) {
      return <NotFound title={title} />;
    }
    let reviewsCount = UserListing.reviewsCount;
    let reviewsStarRating = UserListing.reviewsStarRating;
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Math.round(reviewsStarRating / reviewsCount);
    }

    return (
      <div className={s.root}>
        <div className={s.container}>
          {UserListing && (
            <div className={s.pageContainer}>
              {/* TODO: Section 1 --> Host Details */}
              <div className={s.pageContent}></div>

              {/* TODO: Section 2 --> Photos */}
              <div
                className={s.pageContent}
                style={{
                  padding: "25px 0px 30px 0px",
                }}
              >
                <Photos data={UserListing} loading={loading} />
              </div>

              {/* TODO: Section 3 --> Listing Details */}
              <div className={cx(s.horizontalLineThrough)}>
                <Row className={cx(s.pageContent, s.gridTop, s.safariTop)}>
                  <Col xs={12} sm={12} md={8} lg={8}>
                    <Row>
                      <div className={s.stickyContainer}>
                        <AutoAffix
                          viewportOffsetTop={0}
                          container={this}
                          affixClassName={s.showAffix}
                        >
                          <div
                            className={cx(s.zPanel, s.panelDefault, "bgBlack")}
                          >
                            <div className={cx("stickHeader", s.viewHeader)}>
                              <div className={s.headerNav}>
                                <div
                                  className={s.textColor}
                                  style={{ height: "100%" }}
                                >
                                  <ul
                                    className={cx("list-inline", s.stickyMenu)}
                                  >
                                    <li>
                                      <Link
                                        className={cx(s.textList)}
                                        activeClass={cx(s.active, "textWhite")}
                                        to="test1"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={800}
                                        onSetActive={this.handleSetActive}
                                      >
                                        <FormattedMessage
                                          {...messages.overview}
                                        />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className={cx(s.textList)}
                                        activeClass={cx(s.active, "textWhite")}
                                        to="test2"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={800}
                                        onSetActive={this.handleSetActive}
                                      >
                                        <FormattedMessage
                                          {...messages.reviews}
                                        />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className={cx(s.textList)}
                                        activeClass={cx(s.active, "textWhite")}
                                        to="test3"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        onSetActive={this.handleSetActive}
                                      >
                                        <FormattedMessage
                                          {...messages.theHost}
                                        />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className={cx(s.textList)}
                                        activeClass={cx(s.active, "textWhite")}
                                        to="test4"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        onSetActive={this.handleSetActive}
                                      >
                                        <FormattedMessage
                                          {...messages.location}
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AutoAffix>
                      </div>
                      <Element name="test1" className="element">
                        <div className={s.listingIntroSection}>
                          <HostDetails
                            data={UserListing}
                            reviewsCount={UserListing.reviewsCount}
                            reviewsStarRating={UserListing.reviewsStarRating}
                            loading={loading}
                          />
                        </div>

                        <ListingDetails
                          data={UserListing}
                          isHost={isHost}
                          userBanStatus={userBanStatus}
                          urlParameters={{
                            listTitle: UserListing.title,
                            startDate,
                            endDate,
                            guests,
                          }}
                        />
                      </Element>
                      <div className={"availabilityMobile"}>
                        <AvailabilityCalendar
                          listId={listId}
                          smallDevice={smallDevice}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null
                              ? ListingBlockedDates.UserListing.blockedDates
                              : undefined
                          }
                          listingData={UserListing.listingData || undefined}
                          country={UserListing.country}
                          queryStartDate={startDate}
                          queryEndDate={endDate}
                        />
                      </div>

                      {/* ToDo: Task #1 */}
                      <Element name="test4" className="element">
                        <ShareTable
                          listId={listId}
                          bookingProcess={bookingProcess}
                        />
                      </Element>
                      {/* ToDo: Task #1 */}

                      <Element name="test2" className="element">
                        <Reviews
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          data={listReviewsData}
                          listId={listId}
                        />
                      </Element>
                      <Element name="test4" className="element">
                        <LocationMap data={UserListing} />
                      </Element>
                      <Element name="test3" className="element">
                        <HostDetail
                          id={UserListing.id}
                          userId={UserListing.userId}
                          hostEmail={UserListing.user.email}
                          personCapacity={UserListing.personCapacity}
                          city={UserListing.city}
                          listingData={UserListing.listingData || undefined}
                          profile={UserListing.user.profile || undefined}
                          blockedDates={
                            ListingBlockedDates.UserListing != null
                              ? ListingBlockedDates.UserListing.blockedDates
                              : undefined
                          }
                          isHost={isHost}
                          userBanStatus={userBanStatus}
                          country={UserListing.country}
                          urlParameters={{
                            listTitle: UserListing.title,
                            startDate,
                            endDate,
                            guests,
                          }}
                        />
                      </Element>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className={s.positionSticky}
                  >
                    {!smallDevice && (
                      <div
                        className={cx(
                          s.flexJustifyCenter,
                          "hidden-xs hidden-sm"
                        )}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <Button
                          className={`${s.btn} ${
                            activeTab === "book"
                              ? bt.btnPrimary
                              : bt.btnPrimaryBorder
                          }`}
                          onClick={() => this.handleTabChange("book")}
                        >
                          <svg
                            width="16"
                            height="21"
                            viewBox="0 0 16 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.9144 18.2491C11.6554 18.8321 11.5258 19.2855 11.2667 19.8037C11.2019 19.998 10.9428 20.1923 10.8133 20.1923C10.619 20.1923 10.3599 19.998 10.2951 19.8037C9.51779 17.7309 8.7405 15.6581 7.9632 13.5853C7.89843 13.391 7.89843 13.1319 8.02798 13.0023C8.15753 12.8728 8.41662 12.8728 8.61095 12.9376C10.7485 13.7149 12.8213 14.4922 14.9589 15.3342C15.1532 15.399 15.2827 15.6581 15.2827 15.7876C15.2827 15.9172 15.0884 16.1763 14.9589 16.2411C14.5054 16.5002 13.9872 16.6297 13.4043 16.8888C13.9872 17.4718 14.5702 18.0548 15.1532 18.6377C15.6066 19.0912 15.6066 19.2207 15.1532 19.6741C14.052 20.7753 14.2463 20.7753 13.1452 19.6741C12.7565 19.0912 12.3679 18.7025 11.9144 18.2491Z"
                              fill={
                                activeTab === "book" ? "#ffffff" : "#48cddc"
                              }
                            />
                            <path
                              d="M6.47368 13.3254C6.27935 12.8072 6.34413 12.0946 6.79755 11.6412C7.25097 11.1878 8.02827 11.123 8.48169 11.3174C9.97151 11.9003 11.5261 12.4185 13.0159 13.0015C13.858 11.6412 14.5057 10.0866 14.5705 8.4025C14.5705 8.27295 14.5705 7.6252 14.5705 7.43088C14.5705 3.60918 11.4613 0.5 7.63962 0.5C3.81792 0.5 0.70874 3.60918 0.70874 7.49565C0.70874 7.6252 0.70874 8.27295 0.70874 8.46727C0.903064 12.8072 4.98386 16.4993 6.02026 17.5357C6.73278 18.2482 7.4453 18.2482 7.63962 18.2482C7.70439 18.2482 7.96349 18.2482 8.28737 18.1187C7.70439 16.6289 7.12142 15.0743 6.47368 13.3254ZM4.72476 6.84791C4.72476 5.22854 6.02026 3.86828 7.70439 3.86828C9.38853 3.86828 10.684 5.16377 10.684 6.84791C10.684 8.53205 9.38853 9.82754 7.70439 9.82754C6.02026 9.82754 4.72476 8.46727 4.72476 6.84791Z"
                              fill={
                                activeTab === "book" ? "#ffffff" : "#48cddc"
                              }
                            />
                          </svg>

                          <FormattedMessage {...messages.book} />
                        </Button>
                        <Button
                          className={`${s.btn} ${
                            activeTab === "share"
                              ? bt.btnPrimary
                              : bt.btnPrimaryBorder
                          }`}
                          onClick={() => this.handleTabChange("share")}
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1604_3130)">
                              <path
                                d="M12.5706 23.1667C11.8934 23.1667 11.2726 23.1384 10.6518 23.0538C10.4825 23.0256 10.3697 22.8563 10.3979 22.687C10.4261 22.5177 10.5954 22.4048 10.7647 22.433C11.3572 22.5177 11.978 22.5741 12.5706 22.5459C12.7399 22.5459 12.9092 22.687 12.9092 22.8563C12.8809 22.9974 12.7399 23.1667 12.5706 23.1667Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M14.4334 22.9119C14.2923 22.9119 14.1512 22.8273 14.123 22.658C14.0948 22.4887 14.1795 22.3194 14.3488 22.263C14.9413 22.1219 15.5056 21.9244 16.07 21.6704C16.2393 21.5858 16.4086 21.6704 16.4932 21.8397C16.5779 22.009 16.4932 22.1783 16.3239 22.263C15.7314 22.5451 15.1106 22.7426 14.4898 22.8837C14.4898 22.9119 14.4616 22.9119 14.4334 22.9119Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M2.77919 12.3596C2.60989 12.3314 2.4688 12.1903 2.49702 12.021V11.9928C2.58167 11.372 2.72276 10.723 2.94849 10.1305C3.00492 9.96116 3.20244 9.8765 3.34352 9.93294C3.51282 9.98937 3.59747 10.1587 3.54104 10.328C3.34352 10.8923 3.20244 11.4849 3.11779 12.0774C3.11779 12.2467 2.94849 12.3878 2.77919 12.3596Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M21.5714 11.4C21.4303 11.4 21.2892 11.3153 21.261 11.146C21.1199 10.5535 20.9224 9.98916 20.6685 9.45304C20.5838 9.28374 20.6685 9.11444 20.8378 9.02979C21.0071 8.94514 21.1764 9.02979 21.261 9.19909C21.5432 9.79164 21.7407 10.3842 21.91 11.0332C21.9382 11.2025 21.8536 11.3718 21.6843 11.4282C21.5996 11.4 21.5996 11.4 21.5714 11.4Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M3.96399 8.83167C3.93578 8.83167 3.87934 8.80345 3.85113 8.80345C3.71004 8.7188 3.65361 8.52128 3.73826 8.3802C4.04864 7.81587 4.44368 7.30796 4.86693 6.80006C4.97979 6.65898 5.17731 6.65898 5.31839 6.77185C5.45948 6.88471 5.45948 7.08223 5.34661 7.22331C4.95158 7.67478 4.58476 8.18268 4.27438 8.69058C4.21794 8.80345 4.10508 8.85988 3.96399 8.83167Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M19.9921 8.01362C19.8792 8.01362 19.7946 7.9854 19.7099 7.87253C19.3431 7.39285 18.9481 6.94138 18.4966 6.51813C18.3555 6.40527 18.3555 6.20775 18.4684 6.06667C18.5812 5.92558 18.7788 5.92558 18.9198 6.03845C19.3995 6.48992 19.8228 6.9696 20.2178 7.4775C20.3307 7.61858 20.3025 7.8161 20.1332 7.92897C20.1332 8.01362 20.0485 8.01362 19.9921 8.01362Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M12.0068 0C9.04403 0 6.67383 2.39842 6.67383 5.33296C6.67383 5.47404 6.67383 5.95372 6.67383 6.06659C6.81491 9.36795 9.97518 12.1896 10.7653 13.0079C11.3014 13.5722 11.8375 13.5722 12.0068 13.5722C12.1479 13.5722 12.684 13.5722 13.2483 13.0079C14.0384 12.1896 17.1987 9.36795 17.3397 6.06659C17.3397 5.95372 17.3397 5.47404 17.3397 5.33296C17.3397 2.39842 14.9413 0 12.0068 0ZM12.0068 7.08239C10.7653 7.08239 9.74945 6.06659 9.74945 4.82506C9.74945 3.58352 10.7653 2.56772 12.0068 2.56772C13.2483 2.56772 14.2641 3.58352 14.2641 4.82506C14.2641 6.09481 13.2483 7.08239 12.0068 7.08239Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M9.69254 17.3823C9.66433 17.2694 9.55146 16.8744 9.49503 16.7897C8.70496 14.3631 6.10902 13.0369 3.68239 13.8269C1.25575 14.5888 -0.0704367 17.1847 0.747848 19.6114C0.776065 19.7242 0.917148 20.1193 0.945365 20.2039C1.96117 22.8563 5.29074 24.3518 6.16546 24.8032C6.75801 25.1136 7.20947 24.9725 7.32234 24.9443C7.43521 24.9161 7.88668 24.775 8.16884 24.1543C8.62031 23.2795 10.4544 20.1193 9.69254 17.3823ZM6.39119 22.0098C6.08081 22.1227 5.74221 22.1791 5.4036 22.1791C4.16207 22.1791 2.94875 21.389 2.5255 20.4297C2.41263 20.1757 2.5255 19.8653 2.80767 19.7525C3.06162 19.6396 3.372 19.7525 3.48487 20.0064C3.82347 20.7683 5.03679 21.389 5.99616 21.0222C6.67336 20.7683 7.51986 20.2039 7.26591 18.3981C7.23769 18.1159 7.43521 17.8619 7.71737 17.8055C7.99954 17.7773 8.25349 17.9748 8.30993 18.257C8.64853 20.6272 7.43521 21.6148 6.39119 22.0098Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M21.3461 13.0632C19.1735 12.7246 17.1419 14.1919 16.8033 16.3646C16.775 16.4492 16.7468 16.816 16.7186 16.9007C16.4364 19.3273 18.4116 21.754 18.9195 22.4594C19.2581 22.9391 19.6531 22.9955 19.766 23.0237C19.8789 23.0519 20.2739 23.1084 20.7254 22.7415C21.4026 22.2336 24.0549 20.5406 24.5346 18.1422C24.5628 18.0576 24.6193 17.6908 24.6193 17.6061C24.9861 15.4052 23.5188 13.4018 21.3461 13.0632ZM23.152 18.5373C22.5595 20.2585 21.459 20.6817 20.5279 20.6817C20.415 20.6817 20.3303 20.6817 20.2175 20.6535C18.8066 20.5124 17.7908 19.1016 17.8755 17.9729C17.9037 17.6908 18.1294 17.465 18.4116 17.4932C18.6938 17.5215 18.9195 17.7472 18.9195 18.0294C18.8913 18.6783 19.5403 19.5531 20.3303 19.6095C20.9511 19.6659 21.713 19.5531 22.1644 18.1987C22.2491 17.9165 22.5595 17.7754 22.8134 17.8601C23.0956 17.9447 23.2649 18.2551 23.152 18.5373Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1604_3130">
                                <rect
                                  width="24.1817"
                                  height="25"
                                  fill={
                                    activeTab === "share"
                                      ? "#ffffff"
                                      : "#48cddc"
                                  }
                                  transform="translate(0.493408)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <FormattedMessage {...messages.share} />
                        </Button>
                      </div>
                    )}

                    {!smallDevice && !loading && (
                      <div
                        className={cx(
                          s.responsiveNopadding,
                          "hidden-xs hidden-sm"
                        )}
                      >
                        <Calendar
                          id={UserListing.id}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null
                              ? ListingBlockedDates.UserListing.blockedDates
                              : undefined
                          }
                          personCapacity={UserListing.personCapacity}
                          listingData={UserListing.listingData || undefined}
                          isHost={isHost}
                          bookingType={UserListing.bookingType}
                          userBanStatus={userBanStatus}
                          startDate={startDate}
                          endDate={endDate}
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          guests={guests}
                          country={UserListing.country}
                          isShare={activeTab === "share" ? true : false}
                        />
                      </div>
                    )}
                    {!smallDevice && loading && (
                      <div
                        className={cx(
                          s.webDevice,
                          s.responsiveNopadding,
                          "hidden-xs hidden-sm"
                        )}
                      >
                        <Calendar
                          id={UserListing.id}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null
                              ? ListingBlockedDates.UserListing.blockedDates
                              : undefined
                          }
                          personCapacity={UserListing.personCapacity}
                          listingData={UserListing.listingData || undefined}
                          isHost={isHost}
                          bookingType={UserListing.bookingType}
                          userBanStatus={userBanStatus}
                          startDate={startDate}
                          endDate={endDate}
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          guests={guests}
                          country={UserListing.country}
                          isShare={activeTab === "share" ? true : false}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                {similarListsData &&
                  similarListsData.getSimilarListing &&
                  similarListsData.getSimilarListing.length > 0 && (
                    <div
                      className={cx(
                        s.space2,
                        s.noPaddingMobile,
                        s.pageContent,
                        s.similarPadding
                      )}
                    >
                      <div
                        className={cx(
                          s.sliderMargin,
                          "similarListing",
                          s.similarPadding
                        )}
                      >
                        <div className={cx(s.similarPaddingText)}>
                          <h2 className={cx(s.sectionTitleText)}>
                            <FormattedMessage {...messages.similarListings} />
                          </h2>
                        </div>
                        <SimilarListings
                          data={similarListsData.getSimilarListing}
                          hideHeading={true}
                          viewListingSimilarItem={"viewListingSimilarItem"}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}

          {/* TODO: Section 4 -->  */}
          <div className={cx(s.stickyBookButton, "visible-sm visible-xs")}>
            <div className={cx(s.filtersFooter)}>
              <div className={cx(s.filtersContainer, "bgBlackTwo")}>
                <Row className={s.footerContainer}>
                  <div className={cx(s.displayTable, s.displayTableMobile)}>
                    <div
                      className={cx(
                        s.smSpace,
                        s.displayTableCell,
                        s.displayBlockMobile
                      )}
                    >
                      <div className={s.bookItPriceAmount}>
                        <div className={s.bookItPrice}>
                          <CurrencyConverter
                            from={currencyValue || baseCurrency}
                            amount={
                              averageBasePrice
                                ? averageBasePrice
                                : basePriceValue || 0
                            }
                            className={s.bookItPrice}
                          />
                          <span className={s.bookItNight}>
                            {" "}
                            / <FormattedMessage {...messages.perNight} />
                          </span>
                          {UserListing &&
                            UserListing.bookingType === "instant" && (
                              <span>
                                <FontAwesome.FaBolt className={s.instantIcon} />
                              </span>
                            )}
                        </div>
                      </div>
                      {starRatingValue > 0 && (
                        <div>
                          <div
                            className={cx(s.reviewSection, "reviewSectionRTL")}
                          >
                            <StarRating
                              name={"review"}
                              value={starRatingValue}
                            />
                          </div>
                          <div>
                            <span>{starRatingValue}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Popup */}
                    <div
                      className={cx(s.displayTableCell, s.displayBlockMobile)}
                    >
                      <BookingModal
                        id={UserListing.id}
                        loading={ListingBlockedDates.loading}
                        blockedDates={
                          ListingBlockedDates.UserListing != null
                            ? ListingBlockedDates.UserListing.blockedDates
                            : undefined
                        }
                        personCapacity={UserListing.personCapacity}
                        listingData={UserListing.listingData || undefined}
                        isHost={isHost}
                        bookingType={UserListing.bookingType}
                        reviewsCount={UserListing.reviewsCount}
                        reviewsStarRating={UserListing.reviewsStarRating}
                        country={UserListing.country}
                        startDate={startDate}
                        endDate={endDate}
                      />
                      {/* <Button
                        className={cx(s.btn, bt.btnPrimary, s.fullWidth)}
                        onClick={openBookingModal}
                      >
                        <FormattedMessage {...messages.bookNow} />
                      </Button> */}

                      <div className={cx(s.flexJustifyCenter)}>
                        <Button
                          className={`${s.btn} ${
                            activeTab === "book"
                              ? bt.btnPrimary
                              : bt.btnPrimaryBorder
                          }`}
                          onClick={() => {
                            this.handleTabChange("book");
                            this.toggleModal();
                          }}
                        >
                          <svg
                            width="16"
                            height="21"
                            viewBox="0 0 16 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.9144 18.2491C11.6554 18.8321 11.5258 19.2855 11.2667 19.8037C11.2019 19.998 10.9428 20.1923 10.8133 20.1923C10.619 20.1923 10.3599 19.998 10.2951 19.8037C9.51779 17.7309 8.7405 15.6581 7.9632 13.5853C7.89843 13.391 7.89843 13.1319 8.02798 13.0023C8.15753 12.8728 8.41662 12.8728 8.61095 12.9376C10.7485 13.7149 12.8213 14.4922 14.9589 15.3342C15.1532 15.399 15.2827 15.6581 15.2827 15.7876C15.2827 15.9172 15.0884 16.1763 14.9589 16.2411C14.5054 16.5002 13.9872 16.6297 13.4043 16.8888C13.9872 17.4718 14.5702 18.0548 15.1532 18.6377C15.6066 19.0912 15.6066 19.2207 15.1532 19.6741C14.052 20.7753 14.2463 20.7753 13.1452 19.6741C12.7565 19.0912 12.3679 18.7025 11.9144 18.2491Z"
                              fill={
                                activeTab === "book" ? "#ffffff" : "#48cddc"
                              }
                            />
                            <path
                              d="M6.47368 13.3254C6.27935 12.8072 6.34413 12.0946 6.79755 11.6412C7.25097 11.1878 8.02827 11.123 8.48169 11.3174C9.97151 11.9003 11.5261 12.4185 13.0159 13.0015C13.858 11.6412 14.5057 10.0866 14.5705 8.4025C14.5705 8.27295 14.5705 7.6252 14.5705 7.43088C14.5705 3.60918 11.4613 0.5 7.63962 0.5C3.81792 0.5 0.70874 3.60918 0.70874 7.49565C0.70874 7.6252 0.70874 8.27295 0.70874 8.46727C0.903064 12.8072 4.98386 16.4993 6.02026 17.5357C6.73278 18.2482 7.4453 18.2482 7.63962 18.2482C7.70439 18.2482 7.96349 18.2482 8.28737 18.1187C7.70439 16.6289 7.12142 15.0743 6.47368 13.3254ZM4.72476 6.84791C4.72476 5.22854 6.02026 3.86828 7.70439 3.86828C9.38853 3.86828 10.684 5.16377 10.684 6.84791C10.684 8.53205 9.38853 9.82754 7.70439 9.82754C6.02026 9.82754 4.72476 8.46727 4.72476 6.84791Z"
                              fill={
                                activeTab === "book" ? "#ffffff" : "#48cddc"
                              }
                            />
                          </svg>

                          <FormattedMessage {...messages.book} />
                        </Button>
                        <Button
                          className={`${s.btn} ${
                            activeTab === "share"
                              ? bt.btnPrimary
                              : bt.btnPrimaryBorder
                          }`}
                          onClick={() => {
                            this.handleTabChange("share");
                            this.toggleModal();
                          }}
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1604_3130)">
                              <path
                                d="M12.5706 23.1667C11.8934 23.1667 11.2726 23.1384 10.6518 23.0538C10.4825 23.0256 10.3697 22.8563 10.3979 22.687C10.4261 22.5177 10.5954 22.4048 10.7647 22.433C11.3572 22.5177 11.978 22.5741 12.5706 22.5459C12.7399 22.5459 12.9092 22.687 12.9092 22.8563C12.8809 22.9974 12.7399 23.1667 12.5706 23.1667Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M14.4334 22.9119C14.2923 22.9119 14.1512 22.8273 14.123 22.658C14.0948 22.4887 14.1795 22.3194 14.3488 22.263C14.9413 22.1219 15.5056 21.9244 16.07 21.6704C16.2393 21.5858 16.4086 21.6704 16.4932 21.8397C16.5779 22.009 16.4932 22.1783 16.3239 22.263C15.7314 22.5451 15.1106 22.7426 14.4898 22.8837C14.4898 22.9119 14.4616 22.9119 14.4334 22.9119Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M2.77919 12.3596C2.60989 12.3314 2.4688 12.1903 2.49702 12.021V11.9928C2.58167 11.372 2.72276 10.723 2.94849 10.1305C3.00492 9.96116 3.20244 9.8765 3.34352 9.93294C3.51282 9.98937 3.59747 10.1587 3.54104 10.328C3.34352 10.8923 3.20244 11.4849 3.11779 12.0774C3.11779 12.2467 2.94849 12.3878 2.77919 12.3596Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M21.5714 11.4C21.4303 11.4 21.2892 11.3153 21.261 11.146C21.1199 10.5535 20.9224 9.98916 20.6685 9.45304C20.5838 9.28374 20.6685 9.11444 20.8378 9.02979C21.0071 8.94514 21.1764 9.02979 21.261 9.19909C21.5432 9.79164 21.7407 10.3842 21.91 11.0332C21.9382 11.2025 21.8536 11.3718 21.6843 11.4282C21.5996 11.4 21.5996 11.4 21.5714 11.4Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M3.96399 8.83167C3.93578 8.83167 3.87934 8.80345 3.85113 8.80345C3.71004 8.7188 3.65361 8.52128 3.73826 8.3802C4.04864 7.81587 4.44368 7.30796 4.86693 6.80006C4.97979 6.65898 5.17731 6.65898 5.31839 6.77185C5.45948 6.88471 5.45948 7.08223 5.34661 7.22331C4.95158 7.67478 4.58476 8.18268 4.27438 8.69058C4.21794 8.80345 4.10508 8.85988 3.96399 8.83167Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M19.9921 8.01362C19.8792 8.01362 19.7946 7.9854 19.7099 7.87253C19.3431 7.39285 18.9481 6.94138 18.4966 6.51813C18.3555 6.40527 18.3555 6.20775 18.4684 6.06667C18.5812 5.92558 18.7788 5.92558 18.9198 6.03845C19.3995 6.48992 19.8228 6.9696 20.2178 7.4775C20.3307 7.61858 20.3025 7.8161 20.1332 7.92897C20.1332 8.01362 20.0485 8.01362 19.9921 8.01362Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M12.0068 0C9.04403 0 6.67383 2.39842 6.67383 5.33296C6.67383 5.47404 6.67383 5.95372 6.67383 6.06659C6.81491 9.36795 9.97518 12.1896 10.7653 13.0079C11.3014 13.5722 11.8375 13.5722 12.0068 13.5722C12.1479 13.5722 12.684 13.5722 13.2483 13.0079C14.0384 12.1896 17.1987 9.36795 17.3397 6.06659C17.3397 5.95372 17.3397 5.47404 17.3397 5.33296C17.3397 2.39842 14.9413 0 12.0068 0ZM12.0068 7.08239C10.7653 7.08239 9.74945 6.06659 9.74945 4.82506C9.74945 3.58352 10.7653 2.56772 12.0068 2.56772C13.2483 2.56772 14.2641 3.58352 14.2641 4.82506C14.2641 6.09481 13.2483 7.08239 12.0068 7.08239Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M9.69254 17.3823C9.66433 17.2694 9.55146 16.8744 9.49503 16.7897C8.70496 14.3631 6.10902 13.0369 3.68239 13.8269C1.25575 14.5888 -0.0704367 17.1847 0.747848 19.6114C0.776065 19.7242 0.917148 20.1193 0.945365 20.2039C1.96117 22.8563 5.29074 24.3518 6.16546 24.8032C6.75801 25.1136 7.20947 24.9725 7.32234 24.9443C7.43521 24.9161 7.88668 24.775 8.16884 24.1543C8.62031 23.2795 10.4544 20.1193 9.69254 17.3823ZM6.39119 22.0098C6.08081 22.1227 5.74221 22.1791 5.4036 22.1791C4.16207 22.1791 2.94875 21.389 2.5255 20.4297C2.41263 20.1757 2.5255 19.8653 2.80767 19.7525C3.06162 19.6396 3.372 19.7525 3.48487 20.0064C3.82347 20.7683 5.03679 21.389 5.99616 21.0222C6.67336 20.7683 7.51986 20.2039 7.26591 18.3981C7.23769 18.1159 7.43521 17.8619 7.71737 17.8055C7.99954 17.7773 8.25349 17.9748 8.30993 18.257C8.64853 20.6272 7.43521 21.6148 6.39119 22.0098Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                              <path
                                d="M21.3461 13.0632C19.1735 12.7246 17.1419 14.1919 16.8033 16.3646C16.775 16.4492 16.7468 16.816 16.7186 16.9007C16.4364 19.3273 18.4116 21.754 18.9195 22.4594C19.2581 22.9391 19.6531 22.9955 19.766 23.0237C19.8789 23.0519 20.2739 23.1084 20.7254 22.7415C21.4026 22.2336 24.0549 20.5406 24.5346 18.1422C24.5628 18.0576 24.6193 17.6908 24.6193 17.6061C24.9861 15.4052 23.5188 13.4018 21.3461 13.0632ZM23.152 18.5373C22.5595 20.2585 21.459 20.6817 20.5279 20.6817C20.415 20.6817 20.3303 20.6817 20.2175 20.6535C18.8066 20.5124 17.7908 19.1016 17.8755 17.9729C17.9037 17.6908 18.1294 17.465 18.4116 17.4932C18.6938 17.5215 18.9195 17.7472 18.9195 18.0294C18.8913 18.6783 19.5403 19.5531 20.3303 19.6095C20.9511 19.6659 21.713 19.5531 22.1644 18.1987C22.2491 17.9165 22.5595 17.7754 22.8134 17.8601C23.0956 17.9447 23.2649 18.2551 23.152 18.5373Z"
                                fill={
                                  activeTab === "share" ? "#ffffff" : "#48cddc"
                                }
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1604_3130">
                                <rect
                                  width="24.1817"
                                  height="25"
                                  fill={
                                    activeTab === "share"
                                      ? "#ffffff"
                                      : "#48cddc"
                                  }
                                  transform="translate(0.493408)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <FormattedMessage {...messages.share} />
                        </Button>
                      </div>

                      <Modal
                        show={showModal}
                        onHide={this.toggleModal}
                        animation={false}
                        className="modal-xl"
                      >
                        <Modal.Header>
                          <p className={cx(s.modalTitleText)}>
                            {activeTab === "book" ? (
                              <FormattedMessage {...messages.book} />
                            ) : (
                              <FormattedMessage {...messages.share} />
                            )}
                          </p>
                        </Modal.Header>

                        <Modal.Body>
                          <Calendar
                            id={UserListing.id}
                            loading={ListingBlockedDates.loading}
                            blockedDates={
                              ListingBlockedDates.UserListing != null
                                ? ListingBlockedDates.UserListing.blockedDates
                                : undefined
                            }
                            personCapacity={UserListing.personCapacity}
                            listingData={UserListing.listingData || undefined}
                            isHost={isHost}
                            bookingType={UserListing.bookingType}
                            userBanStatus={userBanStatus}
                            startDate={startDate}
                            endDate={endDate}
                            reviewsCount={UserListing.reviewsCount}
                            reviewsStarRating={UserListing.reviewsStarRating}
                            guests={guests}
                            country={UserListing.country}
                            isShare={activeTab === "share" ? true : false}
                          />
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const selector = formValueSelector("BookingForm");

const mapState = (state) => ({
  account: state.account.data,
  isAdmin: state.runtime.isAdminAuthenticated,
  averageBasePrice: selector(state, "averageBasePrice"),
});
const mapDispatch = {
  openBookingModal,
  bookingProcess,
};
export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(ListingDataQuery, {
    name: "getListingData",
    options: (props) => ({
      variables: {
        listId: props.listId,
        preview: props.preview,
      },
      fetchPolicy: "network-only",
      ssr: true,
    }),
  }),
  graphql(BlockedDatesQuery, {
    name: "ListingBlockedDates",
    options: (props) => ({
      variables: {
        listId: props.listId,
        preview: props.preview,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  }),
  graphql(MoreReviewsQuery, {
    name: "listReviewsData",
    options: (props) => ({
      variables: {
        listId: props.listId,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  }),
  graphql(SimilarListsQuery, {
    name: "similarListsData",
    options: (props) => ({
      variables: {
        listId: props.listId,
        lat: props.lat,
        lng: props.lng,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  })
)(ViewListing);
