exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Payment-root-2vJ5C {\n  padding-left: 24px;\n  padding-right: 24px;\n}\n\n.Payment-container-1TVvv {\n  margin: 5px auto;\n  padding: 0 0 40px;\n  margin-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}\n\n@media screen and (max-width:991px) {\n  .Payment-container-1TVvv {\n    margin-top: 100px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "Payment-root-2vJ5C",
	"container": "Payment-container-1TVvv"
};