exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n.PaymentContent-container-3gBJF {\n\twidth: 100%;\n\tmax-width: var(--max-container-width);\n\tmax-width: var(--max-container-width);\n\tmargin: 0 auto;\n\tpadding: 0;\n}\n.PaymentContent-flex-2JH99 {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n\tmargin-top: 35px;\n}\n.PaymentContent-name-3T0bN {\n\tfont-size: 18px;\n\tfont-weight: 600;\n\tpadding-left: 18px;\n}\n.PaymentContent-content-1uYLa {\n\tfont-size: 16px;\n\tcolor: #707070;\n\tmargin-top: 23px;\n\tfont-style: italic;\n}\n.PaymentContent-imgCss-1Hx84 {\n\twidth: 48px;\n\theight: 48px;\n\tborder-radius: 50%;\n}\n.PaymentContent-outline-24SZv {\n\tborder: 1px solid #E8E8E8;\n\tborder-radius: 6px;\n\tpadding: 42px 30px;\n}\n.PaymentContent-iconCss-1_4sD {\n\twidth: 51px;\n\theight: 39px;\n}\n.PaymentContent-mainhedding-3J4sK h1 {\n\tfont-size: 46px;\n\ttext-align: center;\n\tmargin: 0 0 60px;\n\tfont-weight: 600;\n}\n.PaymentContent-displayInline-Cw2AP {\n\tdisplay: inline-block;\n\tcursor: pointer;\n}\n.PaymentContent-arrowCenter-1iWYU {\n\ttext-align: center;\n\tmargin-top: 38px;\n}\n.PaymentContent-displayInline-Cw2AP img {\n\twidth: 50px;\n}\n@media screen and (max-width: 991px) {\n\t.PaymentContent-mainhedding-3J4sK h1 {\n\t\tfont-size: 36px;\n\t}\n}\n@media screen and (max-width: 767px) {\n\t.PaymentContent-mainhedding-3J4sK h1 {\n\t\tfont-size: 24px;\n\t\ttext-align: left;\n\t\tmargin: 0 0 45px;\n\t}\n\n\t.PaymentContent-container-3gBJF {\n\t\tpadding: 0 15px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"container": "PaymentContent-container-3gBJF",
	"flex": "PaymentContent-flex-2JH99",
	"name": "PaymentContent-name-3T0bN",
	"content": "PaymentContent-content-1uYLa",
	"imgCss": "PaymentContent-imgCss-1Hx84",
	"outline": "PaymentContent-outline-24SZv",
	"iconCss": "PaymentContent-iconCss-1_4sD",
	"mainhedding": "PaymentContent-mainhedding-3J4sK",
	"displayInline": "PaymentContent-displayInline-Cw2AP",
	"arrowCenter": "PaymentContent-arrowCenter-1iWYU"
};