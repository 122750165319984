exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PhotosList-photoListBgImage-2Pq5d {\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    width: 100%;\n    height: 100%;\n    padding-top: 70%;\n    border-radius: 4px;\n}\n\n.PhotosList-displayGrid-1xXsR {\n    display: grid;\n    grid-template-columns: 31% 31% 31%;\n    grid-gap: 20px;\n    margin-top: 30px;\n}\n\n.PhotosList-positionRelative-3Mxfo {\n    position: relative;\n}\n\n.PhotosList-coverPhotoSection-3gHQa {\n    color: var(--common-text-color);\n    color: var(--common-text-color);\n    font-size: 12px;\n    background-color: #fff;\n    display: inline-block;\n    position: absolute;\n    top: 8px;\n    left: 8px;\n    padding: 4px 6px;\n    border-radius: 4px;\n    height: 25px;\n    text-align: center;\n    line-height: 18px;\n}\n\n.PhotosList-tickIcon-3mrI5 {\n    width: 11px;\n    height: 8px;\n    margin-right: 5px;\n}\n\n@media screen and (min-width:768px) and (max-width:1024px) {\n    .PhotosList-displayGrid-1xXsR {\n        grid-template-columns: 100%;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .PhotosList-displayGrid-1xXsR {\n        grid-template-columns: repeat(2, 1fr);\n    }\n}", ""]);

// exports
exports.locals = {
	"photoListBgImage": "PhotosList-photoListBgImage-2Pq5d",
	"displayGrid": "PhotosList-displayGrid-1xXsR",
	"positionRelative": "PhotosList-positionRelative-3Mxfo",
	"coverPhotoSection": "PhotosList-coverPhotoSection-3gHQa",
	"tickIcon": "PhotosList-tickIcon-3mrI5"
};