exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PanelWrapper-titleText-2p9hw {\n    margin: 0px;\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.PanelWrapper-panelBorder-3nNFE {\n    border-radius: 8px;\n    border: 1px solid var(--border-color);\n    border: 1px solid var(--border-color);\n    padding: 22px 25px;\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 767px) {\n    .PanelWrapper-panelBorder-3nNFE {\n        margin-bottom: 0px;\n    }\n}", ""]);

// exports
exports.locals = {
	"titleText": "PanelWrapper-titleText-2p9hw",
	"panelBorder": "PanelWrapper-panelBorder-3nNFE"
};