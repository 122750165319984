var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TripsContainer-container-2dkE2 {\n  margin: 0px auto;\n  padding-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}\n\n.TripsContainer-panelHeader-2nKI3 {\n  border-radius: 0;\n  width: 100%;\n  display: block;\n  font-size: 16px;\n  border-bottom: 1px solid var(--border-secondary-color);\n  border-bottom: 1px solid var(--border-secondary-color);\n  background-color: #f5f5f5;\n  padding-bottom: 0px;\n  margin-bottom: 0px;\n}\n\n.TripsContainer-listFlex-1_c7G {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 30px 0;\n}\n\n.TripsContainer-formControlInput-169kp {\n  width: 382px;\n  height: 45px;\n  border: 1px solid var(--border-color);\n  border: 1px solid var(--border-color);\n  border-radius: 23px;\n}\n\n.TripsContainer-locationBgIcon-H7Ol_ {\n  background: url(" + escape(require("../../../public/SiteIcons/listSeaechIcon.svg")) + ");\n  background-repeat: no-repeat;\n  background-position: 4% 51%;\n  padding-left: 35px;\n  background-size: 15px;\n}\n\n.TripsContainer-noMargin-1zv_X {\n  margin: 0;\n}\n\n@media screen and (max-width: 768px) {\n  .TripsContainer-smPadding-2RhU3 {\n    margin-top: 20px;\n  }\n}\n\n.TripsContainer-hideInMobile-1xdH5 {\n  display: none;\n}\n\n@media screen and (max-width: 767px) {\n  .TripsContainer-formControlInput-169kp {\n    width: 100%;\n  }\n\n  .TripsContainer-mobileWidth-35vYl {\n    width: 100%;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"container": "TripsContainer-container-2dkE2",
	"panelHeader": "TripsContainer-panelHeader-2nKI3",
	"listFlex": "TripsContainer-listFlex-1_c7G",
	"formControlInput": "TripsContainer-formControlInput-169kp",
	"locationBgIcon": "TripsContainer-locationBgIcon-H7Ol_",
	"noMargin": "TripsContainer-noMargin-1zv_X",
	"smPadding": "TripsContainer-smPadding-2RhU3",
	"hideInMobile": "TripsContainer-hideInMobile-1xdH5",
	"mobileWidth": "TripsContainer-mobileWidth-35vYl"
};