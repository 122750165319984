import React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmailSignature from "./EmailSignature";
import EmptySpace from "../modules/EmptySpace";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class BanStatusServiceStatusUnBanned extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      userMail: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "normal",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const linkText = {
      color: PRIMARYCOLOR,
      fontSize: "16px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };
    const {
      content: { userName, userMail, adminMail, logo, siteName },
    } = this.props;
    let mailTo = "mailto:" + adminMail;

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <Body textStyle={textStyle}>
          <div>Dear {userName},</div>
          <EmptySpace height={20} />
          <div>
            Your account is now active again! You can log in with your
            credentials. Please remember that it was previously disabled due to
            a policy violation.
          </div>
          <EmptySpace height={20} />
          <div>
            If you need any help, feel free to reach out to{" "}
            <a href={mailTo}>us</a>
          </div>
          <EmptySpace height={40} />
          <EmailSignature siteName={siteName} />
        </Body>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BanStatusServiceStatusUnBanned;
