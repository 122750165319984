import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { cancel } from "../../actions/Reservation/cancelReservation";
import { connect } from "react-redux";
import { refundGuestGeneral } from "../../actions/Reservation/refundGuest";
import showToaster from "../../helpers/showToaster";

const styles = {
  container: {
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    maxWidth: "1240px",
    padding: "20px",
    margin: "20px auto",
  },
  tableWrapper: {
    overflow: "auto",
    width: "100%",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    minWidth: "650px", // Ensures table doesn't get too squished
  },
  tableHeader: {
    backgroundColor: "#33bacf",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    padding: "12px 8px",
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "5px",
    verticalAlign: "middle",
  },
  mobileCard: {
    display: "none",
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginBottom: "16px",
    padding: "16px",
    backgroundColor: "white",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  mobileCardLabel: {
    fontWeight: "bold",
    color: "#666",
    marginBottom: "4px",
  },
  mobileCardContent: {
    marginBottom: "12px",
  },
  combinedCell: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  avatar: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
  },
  nameEmailContainer: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "0",
  },
  email: {
    fontSize: "14px",
    color: "#666",
    margin: "0",
  },
  buttonContainer: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
  payButton: {
    backgroundColor: "#33bacf",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  approveButton: {
    backgroundColor: "#4dc74d",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  declineButton: {
    backgroundColor: "red",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  copyButton: {
    backgroundColor: "#33bacf",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  toggleButton: {
    backgroundColor: "#33bacf",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "3px",
    cursor: "pointer",
    marginBottom: "16px",
  },
  "@media (max-width: 768px)": {
    table: {
      display: "none",
    },
    mobileCard: {
      display: "block",
    },
  },
};

class TableShare extends Component {
  static propTypes = {
    reservationId: PropTypes.number.isRequired,
    bookingProcess: PropTypes.any.isRequired,
    cancel: PropTypes.func.isRequired,
    refundGuestGeneral: PropTypes.func.isRequired,
    account: PropTypes.any,
    allowPublic: PropTypes.bool,
  };

  state = {
    data: [],
    loading: false,
    localAllowPublic: this.props.allowPublic || false,
  };

  componentDidMount() {
    this.fetchListData(this.props.reservationId);
  }

  fetchListData = async (id) => {
    const query = `
      query GetSlotsReservation($reservationId: Int!) {
        getSlotsReservation(reservationId: $reservationId) {
          id
          userId
          userData {
            email
            firstName
            id
            lastName
            picture
          }
          transaction { 
            transactionId
          }
          reservationData {
            listId
            guestId
            guests
            checkIn
            checkOut
            currency
            threadId
            allowPublic
            isMainGuest
          }
          price
          paymentStatus
          reservationId
          
          bedroom {
            bedCount
            id
            bedTypes {
              id
              type
            }
          }
        }
      }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables: { reservationId: id },
        }),
        credentials: "include",
      });

      const { data, errors } = await response.json();

      if (errors) {
        throw new Error(errors.map((error) => error.message).join(", "));
      }

      this.setState({
        data: data.getSlotsReservation,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
      });
      console.error("Error fetching listing data:", error);
    }
  };

  /**
   * Mutation function to update a slot's status (or other fields).
   */
  updateSlot = async (slotId, { paymentStatus, email, userId, bedroomId }) => {
    const mutation = `
      mutation UpdateSlot($id: Int!, $input: UpdateSlotInput!) {
        updateSlot(id: $id, input: $input) {
          status
          message
        }
      }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: mutation,
          variables: {
            id: slotId,
            input: {
              paymentStatus,
              email,
              userId,
              bedroomId,
            },
          },
        }),
        credentials: "include",
      });

      const { data, errors } = await response.json();
      if (errors) {
        throw new Error(errors.map((err) => err.message).join(", "));
      }

      await this.fetchListData(this.props.reservationId);

      return data.updateSlot;
    } catch (error) {
      console.error("Error updating slot:", error);
      // Handle error appropriately
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * Handle user actions: "approve", "decline", "pay", etc.
   */
  handleAction = async (id, action, slot) => {
    const { bookingProcess, cancel, refundGuestGeneral } = this.props;

    console.log(`Action: ${action} performed on ID: ${id}`);
    if (action === "approve") {
      // Approve -> set paymentStatus to "completed"
      await this.updateSlot(id, {
        paymentStatus: "completed",
        email: slot?.userData?.email ?? null,
        userId: slot?.userData?.id ?? null,
        bedroomId: slot?.bedroom?.id ?? null,
      });
    } else if (action === "decline") {
      cancel({
        reservationId: slot.reservationId,
        cancellationPolicy: "Flexible",
        refundToGuest: slot.price,
        payoutToHost: 0,
        guestServiceFee: 0,
        hostServiceFee: 0,
        total: slot.price,
        currency: slot.reservationData.currency,
        threadId: slot.reservationData.threadId,
        cancelledBy: "guest",
        message: `guest ${slot?.userData?.email} canceled by main guest`,
        checkIn: slot.reservationData.checkIn,
        checkOut: slot.reservationData.checkOut,
        guests: slot.reservationData.guests,
        userType: null,
        isTaxRefunded: 0,
        slotId: id,
      });

      refundGuestGeneral(
        slot.reservationId,
        null,
        null,
        slot?.userData?.email,
        slot?.userData?.id,
        slot.price,
        slot.reservationData.currency,
        slot.reservationData.currency,
        2,
        slot.transaction.transactionId,
        null,
        id
      );

      await this.updateSlot(id, {
        paymentStatus: "pending",
        email: null,
        userId: null,
        bedroomId: slot?.bedroom?.id ?? null,
      });
    } else if (action === "pay") {
      if (id) {
        bookingProcess(
          slot.reservationData.listId,
          slot.reservationData.guests,
          slot.reservationData.checkIn,
          slot.reservationData.checkOut,
          null,
          null,
          null,
          slot.reservationId,
          id
        );
      }
    }
  };

  updateReservationStates = async (reservationId, allowPublic) => {
    const mutation = `
      mutation updateReservationStates($reservationId: Int!, $allowPublic: Boolean!) {
        updateReservationStates(reservationId: $reservationId, allowPublic: $allowPublic) {
          id
          allowPublic
          status
        }
      }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          query: mutation,
          variables: {
            reservationId,
            allowPublic,
          },
        }),
      });

      const { data, errors } = await response.json();
      if (errors) {
        throw new Error(errors.map((err) => err.message).join(", "));
      }

      // Update local state to reflect new allowPublic value
      this.setState({
        localAllowPublic: data.updateReservationStates.allowPublic,
      });

      return data.updateReservationStates;
    } catch (error) {
      console.error("Error updating reservation states:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * Copies the booking link to the clipboard and shows a toaster message.
   */
  handleCopyLink = async (reservationId, slotId, slot) => {
    const action = {
      action: "booking",
      reservationId: reservationId,
      slotId: slotId,
      listId: slot.reservationData.listId,
      guests: slot.reservationData.guests,
      checkIn: slot.reservationData.checkIn,
      checkOut: slot.reservationData.checkOut,
    };

    const actionString = `${btoa("acavno")}.${btoa(
      btoa(JSON.stringify(action))
    )}.${btoa("onvaca")}`;

    const link = `${window?.location?.origin}/start-process/${actionString}`;

    try {
      await navigator.clipboard.writeText(link);
      showToaster({ messageId: "linkCopied", toasterType: "success" });
    } catch (err) {
      console.error("Failed to copy link:", err);
    }
  };

  render() {
    const { data, localAllowPublic } = this.state;
    const { account } = this.props;

    if (!data || data.length === 0) {
      return null;
    }

    const renderActionButtons = (row) => (
      <div style={styles.buttonContainer}>
        {row.paymentStatus === "pending" &&
          row.reservationData.guestId !== row.userId && (
            <>
              <button
                style={styles.payButton}
                onClick={() => this.handleAction(row.id, "pay", row)}
              >
                Pay
              </button>
              <button
                style={styles.copyButton}
                onClick={() =>
                  this.handleCopyLink(row.reservationId, row.id, row)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                  <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
                </svg>
              </button>
            </>
          )}

        {row.paymentStatus === "waiting_for_approval" &&
          row.reservationData.guestId === account.userId && (
            <>
              <button
                style={styles.approveButton}
                onClick={() => this.handleAction(row.id, "approve", row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </button>
              <button
                style={styles.declineButton}
                onClick={() => this.handleAction(row.id, "decline", row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </>
          )}
      </div>
    );

    return (
      <div style={styles.container}>
        {data[0]?.reservationData?.isMainGuest && (
          <button
            style={{
              ...styles.toggleButton,
              backgroundColor: localAllowPublic ? "red" : "green",
            }}
            onClick={() =>
              this.updateReservationStates(
                this.props.reservationId,
                !localAllowPublic
              )
            }
          >
            {localAllowPublic ? "Make it private" : "Make it public"}
          </button>
        )}

        {/* Desktop Table View */}
        <div style={styles.tableWrapper}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>User Info</th>
                <th style={styles.tableHeader}>Bedroom Number</th>
                <th style={styles.tableHeader}>Status</th>
                <th style={styles.tableHeader}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={row.id}>
                  <td style={styles.tableCell}>
                    <div style={styles.combinedCell}>
                      <img
                        src={
                          row.userData?.picture ??
                          "/assets/public/SiteImages/defaultPic.jpg"
                        }
                        alt="avatar"
                        style={styles.avatar}
                      />
                      <div style={styles.nameEmailContainer}>
                        <p style={styles.name}>
                          {row.userData?.firstName} {row.userData?.lastName}
                        </p>
                        <p style={styles.email}>{row.userData?.email}</p>
                      </div>
                    </div>
                  </td>
                  <td style={styles.tableCell}>Bedroom {index + 1}</td>
                  <td style={styles.tableCell}>{row.paymentStatus}</td>
                  <td style={styles.tableCell}>{renderActionButtons(row)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        {data.map((row, index) => (
          <div key={row.id} style={styles.mobileCard}>
            <div style={styles.combinedCell}>
              <img
                src={
                  row.userData?.picture ??
                  "/assets/public/SiteImages/defaultPic.jpg"
                }
                alt="avatar"
                style={styles.avatar}
              />
              <div style={styles.nameEmailContainer}>
                <p style={styles.name}>
                  {row.userData?.firstName} {row.userData?.lastName}
                </p>
                <p style={styles.email}>{row.userData?.email}</p>
              </div>
            </div>
            <div style={styles.mobileCardContent}>
              <p style={styles.mobileCardLabel}>Bedroom Number</p>
              <p>Bedroom {index + 1}</p>
            </div>
            <div style={styles.mobileCardContent}>
              <p style={styles.mobileCardLabel}>Status</p>
              <p>{row.paymentStatus}</p>
            </div>
            <div style={styles.mobileCardContent}>
              <p style={styles.mobileCardLabel}>Actions</p>
              {renderActionButtons(row)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const mapState = (state) => ({
  account: state?.account?.data,
});

const mapDispatch = {
  cancel,
  refundGuestGeneral,
};

export default injectIntl(connect(mapState, mapDispatch)(TableShare));
