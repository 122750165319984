import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  change,
} from "redux-form";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { FormGroup, ControlLabel, Modal, Button } from "react-bootstrap";

import cookie from 'react-cookies'

import IncrementButton from "../IncrementButton";
import IncrementBtn from "./IncrementBtn";
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";
import * as FontAwesome from "react-icons/lib/fa";

import update from "./update";
import messages from "../../locale/messages";
import SelectFieldComponent from "../Common/FormField/SelectFieldComponent";

import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

import uploadImageIcon from "../../../public/SiteIcons/upload_image.svg";

class Page3 extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    change: PropTypes.func,
    bedTypes: PropTypes.array,
    listingFields: PropTypes.object,
    beds: PropTypes.number,
    bathrooms: PropTypes.number,
    isExistingList: PropTypes.bool,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    formPage: PropTypes.number,
    step: PropTypes.number,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    // Local state
    this.state = {
      // Preloaded listingField data
      bedType: [],
      beds: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      bedrooms: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      personCapacity: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      bathrooms: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      bathroomType: [],

      loading: false, // Add loading state
      uploadError: null, // Add error state

      // Modal control
      showBedroomModal: false,
      activeBedroomIndex: null,
      fileInputKey: 0,
    };
    this.fileInputRef = React.createRef();
  }

  //-----------------------------------------
  // Lifecycle
  //-----------------------------------------
  UNSAFE_componentWillMount() {
    const { listingFields, bedrooms, change, bedroomDetails } = this.props;
  
    if (listingFields) {
      this.setState({
        bedType: listingFields.bedType,
        beds: listingFields.beds && listingFields.beds[0],
        bedrooms: listingFields.bedrooms && listingFields.bedrooms[0],
        personCapacity:
          listingFields.personCapacity && listingFields.personCapacity[0],
        bathrooms: listingFields.bathrooms && listingFields.bathrooms[0],
        bathroomType: listingFields.bathroomType,
      });
    }
  
    // Initialize bedroomDetails array in Redux Form only if it doesn't exist
    if (bedrooms && !bedroomDetails) {
      let bedroomsArray = [];
      Array(bedrooms)
        .fill()
        .forEach(() => {
          bedroomsArray.push({
            bedTypes: [
              {
                bedType: listingFields?.bedType?.[0]?.id || null,
              },
            ],
            images: [],
          });
        });
      change("bedroomDetails", bedroomsArray);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields, bedrooms, change, bedroomDetails } = nextProps;
  
    // Handle listingFields updates
    if (nextProps.listingFields !== this.props.listingFields) {
      if (listingFields) {
        this.setState({
          bedType: listingFields.bedType,
          beds: listingFields.beds && listingFields.beds[0],
          bedrooms: listingFields.bedrooms && listingFields.bedrooms[0],
          personCapacity:
            listingFields.personCapacity && listingFields.personCapacity[0],
          bathrooms: listingFields.bathrooms && listingFields.bathrooms[0],
          bathroomType: listingFields.bathroomType,
        });
      }
    }
  
    // Update bedroomDetails when bedroom count changes
    if (nextProps.bedrooms !== this.props.bedrooms) {
      const currentDetails = bedroomDetails || [];
      const newCount = nextProps.bedrooms || 0;
      const currentCount = currentDetails.length;
      
      if (newCount > currentCount) {
        // Add new bedrooms
        const newBedrooms = Array(newCount - currentCount)
          .fill()
          .map(() => ({
            bedTypes: [
              {
                bedType: listingFields?.bedType?.[0]?.id || null,
              },
            ],
            images: [],
          }));
        
        change("bedroomDetails", [...currentDetails, ...newBedrooms]);
      } else if (newCount < currentCount) {
        // Remove excess bedrooms
        change("bedroomDetails", currentDetails.slice(0, newCount));
      }
    }
  }

  //-----------------------------------------
  // Modal
  //-----------------------------------------
  /**
   * Open the modal for a specific bed index
   */
  openBedModal = (index) => {
    this.setState({
      activeBedroomIndex: index,
      showBedroomModal: true,
    });
  };

  /**
   * Close the bed modal
   */
  closeBedModal = () => {
    this.setState({
      activeBedroomIndex: null,
      showBedroomModal: false,
    });
  };

  //-----------------------------------------
  // Rendering the Modal
  //-----------------------------------------
  /**
   * The content inside the modal shows a single bed’s "bedType" field
   * plus an array of images (title, description, file).
   */
  renderBedroomModal() {
    const { showBedroomModal, activeBedroomIndex, loading, uploadError } = this.state;
  
    return (
      <Modal show={showBedroomModal} onHide={this.closeBedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Bedroom #{activeBedroomIndex + 1}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Loader Overlay */}
          {loading && (
            <div className={cx(s.loaderOverlay)}>
              <div className={cx(s.loader)}>Uploading...</div>
            </div>
          )}
  
          {/* Error Alert */}
          {uploadError && (
            <div className={cx(s.alert, s.alertDanger)}>
              {uploadError}
            </div>
          )}
  
          {/* Existing Modal Content */}
          <div className={cx(s.sectionContainer)}>
            <FieldArray
              name={`bedroomDetails[${activeBedroomIndex}].bedTypes`}
              component={this.renderBedTypesArray}
            />
          </div>
  
          <hr className={cx(s.sectionDivider)} />
  
          <div className={cx(s.sectionContainer)}>
            <div className={cx(s.uploadButtonContainer)}>
              <input
                type="file"
                id={`imageUpload-${activeBedroomIndex}`}
                multiple
                style={{ display: "none" }}
                onChange={(e) =>
                  this.handleImageUpload(e.target.files, activeBedroomIndex)
                }
                accept="image/jpeg,image/png,image/gif"
                key={this.state.fileInputKey}
              />
              <label
                htmlFor={`imageUpload-${activeBedroomIndex}`}
                className={cx(s.btnDefault, s.btnPrimary)}
              >
                <img
                  src={uploadImageIcon}
                  alt="Upload"
                  className={s.uploadIcon}
                />
                <span>Upload Images</span>
              </label>
            </div>
  
            <FieldArray
              name={`bedroomDetails[${activeBedroomIndex}].images`}
              component={this.renderBedroomImagesArray}
              key={this.state.fileInputKey}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //-----------------------------------------
  // Helpers
  //-----------------------------------------
  getBedTypeSelectOptions = () => {
    // Build array of { value, label } objects
    const { bedType } = this.state;
    let options = [];
    bedType &&
      bedType.forEach((item) => {
        if (item.isEnable) {
          options.push({
            value: item.id,
            label: item.itemName,
          });
        }
      });
    return options;
  };

  handleImageUpload = async (files, activeBedIndex) => {
    const { change, bedroomDetails } = this.props;
  
    if (!files || files.length === 0) return;
  
    try {
      // Set loading state
      this.setState({ loading: true, uploadError: null });
  
      // Create FormData for the upload
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
  
      // Upload images to the server
      const response = await fetch("/uploadBedroomsImages", {
        method: "POST",
        body: formData,
        headers: {
          cookie: `id_token=${cookie.load('id_token')}`, // Add auth token if needed
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to upload images");
      }
  
      const responseData = await response.json();
  
      // Get current bedroom details
      const currentBedroom = bedroomDetails?.[activeBedIndex] || {};
      const currentImages = currentBedroom.images || [];
  
      // Add uploaded images to Redux Form
      const newImages = responseData.data.map((image) => ({
        id: image.id, // Use the ID returned from the server
        filename: image.sizes.x_medium, // Use the original filename
        title: image.sizes.x_medium,
        description: "",
      }));
  
      change(`bedroomDetails[${activeBedIndex}].images`, [
        ...currentImages,
        ...newImages,
      ]);
  
      // Reset the file input
      this.setState((prevState) => ({
        fileInputKey: prevState.fileInputKey + 1,
        loading: false,
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
      this.setState({
        loading: false,
        uploadError: "Failed to upload images. Please try again.",
      });
    }
  };

  // Add new method to handle image removal
  handleImageRemove = (index, activeBedIndex) => {
    const { change, bedroomDetails } = this.props;
  
    // Safely get current images
    const currentBedroom = bedroomDetails?.[activeBedIndex] || {};
    const currentImages = [...(currentBedroom.images || [])];
  
    // Remove the image at the specified index
    currentImages.splice(index, 1);
  
    // Update Redux Form
    change(`bedroomDetails[${activeBedIndex}].images`, currentImages);
  
    // Force re-render by updating state
    this.setState((prevState) => ({
      fileInputKey: prevState.fileInputKey + 1,
    }));
  };

  //-----------------------------------------
  // Standard Render Methods
  //-----------------------------------------
  renderBedroomsArray = ({ fields }) => {
    return (
      <div className={cx(s.customSmallCardContainer)}>
        {fields.map((bedroom, index) => (
          <button
            key={index}
            className={cx(s.customSmallCard)}
            onClick={() => this.openBedModal(index)}
          >
            Edit Bedroom #{index + 1}
          </button>
        ))}
      </div>
    );
  };

  renderBedTypesArray = ({ fields }) => {
    return (
      <div>
        <div className={cx(s.flexBetween)}>
          <h4>Bed Types</h4>
          <button
            className={cx(s.btnDefault, s.btnPrimary)}
            onClick={() =>
              fields.push({
                bedType: this.state.bedType?.[0]?.id || null,
              })
            }
          >
            Add Bed Type
          </button>
        </div>

        {fields.map((bed, index) => (
          <div key={index}>
            <div className={cx(s.flexBetween)} style={{ margin: 0 }}>
              <h5 className={cx(s.squareBorder)}>#{index + 1}</h5>

              <FormGroup style={{ flexGrow: 1 }}>
                <Field
                  name={`${bed}.bedType`}
                  component={SelectFieldComponent}
                  options={this.getBedTypeSelectOptions()}
                  style={{ margin: 0 }}
                />
              </FormGroup>

              <button
                className={cx(s.btnDefault, s.btnDanger)}
                onClick={() => fields.remove(index)}
              >
                <svg
                  fill="#ffffff"
                  version="1.1"
                  id="Capa_1"
                  width="16px"
                  height="16px"
                  viewBox="0 0 900.5 900.5"
                >
                  <g>
                    <path d="M176.415,880.5c0,11.046,8.954,20,20,20h507.67c11.046,0,20-8.954,20-20V232.487h-547.67V880.5L176.415,880.5z    M562.75,342.766h75v436.029h-75V342.766z M412.75,342.766h75v436.029h-75V342.766z M262.75,342.766h75v436.029h-75V342.766z" />
                    <path d="M618.825,91.911V20c0-11.046-8.954-20-20-20h-297.15c-11.046,0-20,8.954-20,20v71.911v12.5v12.5H141.874   c-11.046,0-20,8.954-20,20v50.576c0,11.045,8.954,20,20,20h34.541h547.67h34.541c11.046,0,20-8.955,20-20v-50.576   c0-11.046-8.954-20-20-20H618.825v-12.5V91.911z M543.825,112.799h-187.15v-8.389v-12.5V75h187.15v16.911v12.5V112.799z" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderBedroomImagesArray = ({ fields }) => {
    const { isRTL } = this.props;
    const { activeBedroomIndex } = this.state;

    console.log("fields", fields.get(0))
  
    if (!fields || !fields.length) {
      return (
        <div>
          <div className={cx(s.flexBetween)} style={{ marginBottom: "5px" }}>
            No images uploaded yet
          </div>
        </div>
      );
    }
  
    return (
      <div key={fields.length}> {/* Add a key prop to force re-render */}
        <div className={cx(s.flexBetween)} style={{ marginBottom: "5px" }}>
          <h4>Bedroom Images</h4>
        </div>
  
        <div className={cx(s.imageGrid)}>
          {fields.map((image, index) => (
            <div key={fields.get(index).id} className={cx(s.imageCard)}>
              {/* Remove Button - Updated to use handleImageRemove */}
              <button
                type="button" // Add this to prevent form submission
                className={cx(
                  s.removeButton,
                  isRTL ? s.removeButtonRTL : s.removeButtonLTR
                )}
                onClick={() =>
                  this.handleImageRemove(index, activeBedroomIndex)
                }
              >
                <svg
                  fill="#ffffff"
                  version="1.1"
                  id="Capa_1"
                  width="16px"
                  height="16px"
                  viewBox="0 0 900.5 900.5"
                >
                  <g>
                    <path d="M176.415,880.5c0,11.046,8.954,20,20,20h507.67c11.046,0,20-8.954,20-20V232.487h-547.67V880.5L176.415,880.5z    M562.75,342.766h75v436.029h-75V342.766z M412.75,342.766h75v436.029h-75V342.766z M262.75,342.766h75v436.029h-75V342.766z" />
                    <path d="M618.825,91.911V20c0-11.046-8.954-20-20-20h-297.15c-11.046,0-20,8.954-20,20v71.911v12.5v12.5H141.874   c-11.046,0-20,8.954-20,20v50.576c0,11.045,8.954,20,20,20h34.541h547.67h34.541c11.046,0,20-8.955,20-20v-50.576   c0-11.046-8.954-20-20-20H618.825v-12.5V91.911z M543.825,112.799h-187.15v-8.389v-12.5V75h187.15v16.911v12.5V112.799z" />
                  </g>
                </svg>
              </button>
  
              {/* Image Preview */}
              <div className={cx(s.imagePreview)}>
                <img
                  src={`/images/upload/${fields.get(index)?.filename}`}
                  alt={fields.get(index)?.filename}
                />
              </div>
  
              {/* Title and Description */}
              <div className={cx(s.imageDetails)}>
                <FormGroup>
                  <Field
                    name={`${image}.title`}
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder="Title"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name={`${image}.description`}
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder="Description"
                  />
                </FormGroup>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderIncrementButton = (field) => {
    return <IncrementButton {...field} />;
  };

  render() {
    const {
      handleSubmit,
      previousPage,
      nextPage,
      isExistingList,
      formPage,
      step,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { beds, bedrooms, personCapacity, bathrooms, bathroomType } =
      this.state;

    // Build bathroom type options
    let bathroomTypeValue = [];
    if (bathroomType) {
      bathroomType.forEach((item) => {
        if (item.isEnable) {
          bathroomTypeValue.push({
            value: item.id,
            label: item.itemName,
          });
        }
      });
    }

    const nextPath = isExistingList ? "map" : "location";

    return (
      <div className={cx(s.grid, "arrowPosition")}>
        <SidePanel
          title={formatMessage(messages.stepOneCommonHeading)}
          landingContent={formatMessage(messages.howManyGuests)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            {/* Total Guests */}
            <FormGroup className={s.space5}>
              <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                <FormattedMessage {...messages.totalGuests} />
              </ControlLabel>
              <Field
                name="personCapacity"
                type="text"
                component={this.renderIncrementButton}
                labelSingluar={personCapacity.itemName}
                labelPlural={personCapacity.otherItemName}
                maxValue={personCapacity.endValue}
                minValue={personCapacity.startValue}
                incrementBy={1}
              />
            </FormGroup>

            {/* Bedrooms */}
            <FormGroup className={s.space5}>
              <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                <FormattedMessage {...messages.howManyBedrooms} />
              </ControlLabel>
              <Field
                name="bedrooms"
                type="text"
                component={this.renderIncrementButton}
                labelSingluar={bedrooms.itemName}
                labelPlural={bedrooms.otherItemName}
                minValue={bedrooms.startValue}
                incrementBy={1}
                maxValue={bedrooms.endValue}
              />
            </FormGroup>

            {/* The clickable list of beds; each opens a modal */}
            <FieldArray
              name="bedroomDetails"
              component={this.renderBedroomsArray}
            />

            {/* Bathrooms */}
            <FormGroup className={s.space5}>
              <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                <FormattedMessage {...messages.howManyBathrooms} />
              </ControlLabel>
              <Field
                name="bathrooms"
                type="text"
                component={this.renderIncrementButton}
                labelSingluar={bathrooms.itemName}
                labelPlural={bathrooms.otherItemName}
                maxValue={bathrooms.endValue}
                minValue={bathrooms.startValue}
                incrementBy={0.5}
              />
            </FormGroup>

            {/* Bathroom Type */}
            <FormGroup className={s.formGroup}>
              <Field
                name="bathroomType"
                component={SelectFieldComponent}
                inputClass={cx(
                  s.formControlSelect,
                  s.jumboSelect,
                  s.jumboSelectPadding,
                  s.listCommonJumboSelect,
                  "listCommonJumboSelectRTL"
                )}
                options={bathroomTypeValue}
              />
            </FormGroup>
          </div>

          {/* Footer Nav */}
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={nextPath}
            previousPagePath="room"
            formPage={formPage}
            step={step}
          />
        </form>

        {/* Render our single bed modal (with bedType + images) */}
        {this.renderBedroomModal()}
      </div>
    );
  }
}

Page3 = reduxForm({
  form: "ListPlaceStep1",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
})(Page3);

const selector = formValueSelector("ListPlaceStep1");

const mapState = (state) => ({
  isExistingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  bedrooms: selector(state, "bedrooms"),
  bedroomDetails: selector(state, "bedroomDetails"),
});

const mapDispatch = {
  change,
};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(Page3))
);