exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout5-bannerLayoutContainer-Xsoct {\n  min-height: auto;\n  height: auto;\n  position: relative;\n  background: var(--background-common-color);\n  background: var(--background-common-color);\n}\n\n.Layout5-bannerBackgroundImage-GaTtV {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  -o-object-fit: cover !important;\n     object-fit: cover !important;\n  -o-object-fit: contain !important;\n     object-fit: contain !important;\n  z-index: 0;\n  background-color: #ffffff;\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-attachment: scroll;\n}\n\n.Layout5-searchFormContainer-3zJLF {\n  position: relative;\n  height: 100%;\n  margin: 0 auto;\n  max-width: 1250px;\n  padding: 400px 15px 0px 15px;\n}\n\n.Layout5-bannerCaptionText-1Z_nH {\n  font-size: 60px;\n  line-height: 65px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  color: var(--white-color);\n  color: var(--white-color);\n  margin: 30px 0 45px;\n  font-weight: 600;\n  text-shadow: 0 2px 2em #282828;\n  text-align: center;\n}\n\n.Layout5-bannerCaptionHighlight-23a6U {\n  font-weight: 700;\n  color: var(--btn-primary-bg);\n  color: var(--btn-primary-bg);\n}\n\n.Layout5-imageCss-PDZDW {\n  width: 100%;\n  border-radius: 15px;\n  margin-top: 35px;\n}\n\n.Layout5-positionRelative-31ZXq {\n  position: relative;\n}\n\n@media screen and (max-width: 1200px) {\n  .Layout5-bannerCaptionText-1Z_nH {\n    padding-top: 50px;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .Layout5-searchFormWrap-3_a3z {\n    padding: 20px;\n  }\n\n  .Layout5-bannerCaptionText-1Z_nH {\n    font-size: 32px;\n    line-height: 36px;\n    bottom: -115px;\n  }\n\n  .Layout5-searchFormContainer-3zJLF {\n    padding: 135x 15px 100px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"bannerLayoutContainer": "Layout5-bannerLayoutContainer-Xsoct",
	"bannerBackgroundImage": "Layout5-bannerBackgroundImage-GaTtV",
	"searchFormContainer": "Layout5-searchFormContainer-3zJLF",
	"bannerCaptionText": "Layout5-bannerCaptionText-1Z_nH",
	"bannerCaptionHighlight": "Layout5-bannerCaptionHighlight-23a6U",
	"imageCss": "Layout5-imageCss-PDZDW",
	"positionRelative": "Layout5-positionRelative-31ZXq",
	"searchFormWrap": "Layout5-searchFormWrap-3_a3z"
};