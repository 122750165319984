exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StartProcessContainer-actionsContainer-2YYOf {\nbackground-color: #f9f9f9; /* Light background */\npadding: 20px;\nmargin-top: 20px;\nborder-radius: 4px;\n}\n\n.StartProcessContainer-bgLight-3ykdd {\nbackground-color: #fff !important; /* Light panel background */\ncolor: #33bacf !important;         /* Primary aqua text */\npadding: 15px;\nborder-radius: 4px;\n}\n\n.StartProcessContainer-dashBoardContainer-3lDHh {\npadding: 20px;\n}\n\n.StartProcessContainer-dashBoardListingGrid-3SCf- {\nmargin: 0 auto;\nmax-width: 1200px; /* Adjust as needed */\n}\n\n.StartProcessContainer-commonListingBg-2L_Db {\nbackground-color: #f9f9f9;\npadding: 20px;\n}\n\n.StartProcessContainer-loaderText-FnsoX {\ncolor: #33bacf;\n}\n  ", ""]);

// exports
exports.locals = {
	"actionsContainer": "StartProcessContainer-actionsContainer-2YYOf",
	"bgLight": "StartProcessContainer-bgLight-3ykdd",
	"dashBoardContainer": "StartProcessContainer-dashBoardContainer-3lDHh",
	"dashBoardListingGrid": "StartProcessContainer-dashBoardListingGrid-3SCf-",
	"commonListingBg": "StartProcessContainer-commonListingBg-2L_Db",
	"loaderText": "StartProcessContainer-loaderText-FnsoX"
};