import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Collapse from "react-bootstrap/lib/Collapse";
import cx from "classnames";
import * as FontAwesome from "react-icons/lib/fa";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import ListItem from "./ListItem";
import Link from "../../Link";
import Loader from "../../Loader";
import ListBedTypes from "./ListBedTypes";

import messages from "../../../locale/messages";
import { getSpecificSettings } from "../../../actions/getSpecificSettings";
import { contactHostOpen } from "../../../actions/message/contactHostModal";
import { checkIn, checkValue } from "./helper";

import roomTypeIcon from "/public/SiteIcons/listingRoomTypeIcon.svg";
import guestIcon from "/public/SiteIcons/listingGuestIcon.svg";
import bedIcon from "/public/SiteIcons/listingBedIcon.svg";
import bathIcon from "/public/SiteIcons/listingBathIcon.svg";
import checkInIcon from "/public/SiteIcons/listingCheckIcon.svg";
import rightArrow from "/public/SiteIcons/viewReceiptRight.svg";
import totalRoom from "/public/SiteIcons/totalRooms.svg";
import bathroomIcon from "/public/SiteIcons/bathrooms.svg";
import s from "./ListingDetails.css";

class ListingDetails extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      listingData: PropTypes.shape({
        cancellation: PropTypes.shape({
          policyName: PropTypes.string.isRequired,
          policyContent: PropTypes.string.isRequired,
        }),
      }),
    }),
    getSpecificSettings: PropTypes.any,
    settingsData: PropTypes.object,
    isHost: PropTypes.bool.isRequired,
    formatMessage: PropTypes.any,
    userBanStatus: PropTypes.number,
    urlParameters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      guests: PropTypes.string,
      listTitle: PropTypes.string,
    }),
  };
  static defaultProps = {
    isHost: false,
    description: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoad: false });
  }

  UNSAFE_componentWillMount() {
    this.setState({ isLoad: true });
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { data } = this.props;
    const { formatMessage } = this.props.intl;
    const { open, isLoad } = this.state;

    let checkInStart, checkInEnd, roomType, buildingSize, bathroomType;
    let userAmenities = [],
      userSafetyAmenities = [];
    let sharedSpaces = [],
      houseRules = [],
      listBedTypes = [];
    let description,
      personCapacity,
      bathrooms,
      bedrooms,
      bedroomsData,
      bedCounts = 0;
    let count = 150,
      firstArray,
      restArray,
      dotString = false;
    let isAmenities,
      isSharedSpaces,
      bedResult = [];

    if (data.listingData != undefined) {
      checkInStart = checkValue(data?.listingData?.checkInStart, "");
      checkInEnd = checkValue(data?.listingData?.checkInEnd, "");
    }
    if (data.settingsData != undefined && data?.settingsData?.length > 0) {
      roomType = checkValue(data?.settingsData[0]?.listsettings?.itemName, "");
      buildingSize = checkValue(
        data?.settingsData[2]?.listsettings?.itemName,
        ""
      );
      bathroomType = checkValue(
        data?.settingsData[3]?.listsettings?.itemName,
        ""
      );
    }
    sharedSpaces = checkValue(data?.userSpaces, []);
    houseRules = checkValue(data?.houseRules, []);
    userAmenities = checkValue(data?.userAmenities, []);
    userSafetyAmenities = checkValue(data?.userSafetyAmenities, []);
    description = checkValue(data?.description, "");
    personCapacity = checkValue(data?.personCapacity, 0);
    bathrooms = checkValue(data?.bathrooms, 0);
    bedrooms = checkValue(data?.bedrooms.length, 0);
    bedroomsData = checkValue(data?.bedrooms, []);
    listBedTypes = checkValue(data?.listBedTypes, []);

    if (bedroomsData && bedroomsData?.length > 0) {
      // Initialize result object for counting by listsettings itemName
      let bedResult = {};

      // Iterate through all bedrooms
      bedroomsData.forEach((bedroom) => {
        if (bedroom.bedTypes && bedroom.bedTypes.length > 0) {
          // Process each bedType in the current bedroom
          bedroom.bedTypes.forEach((bedType) => {
            // If listsettings exists, use itemName as key, otherwise use type
            const key = bedType.listsettings?.itemName || bedType.type;
            bedResult[key] = (bedResult[key] || 0) + 1;
          });
        }
      });

      console.log("bedResult", bedResult);

      // Calculate total count
      const bedCounts = Object.values(bedResult).reduce((a, b) => a + b, 0);
      console.log("Total bedCounts:", bedCounts);

      // bedResult = bedroomsData?.bedTypes?.reduce(
      //   (acc, o) => (
      //     (acc[o.listsettings && o.listsettings.itemName] =
      //       (acc[o.listsettings && o.listsettings.itemName] || 0) + 1),
      //     acc
      //   ),
      //   {}
      // );
      // console.log("bedResult", bedResult)
      // bedCounts = Object.values(bedResult);
      // bedCounts = bedCounts.reduce((a, b) => a + b, 0);
    }

    if (description) {
      firstArray = description?.slice(0, count);
      restArray = description?.slice(count, description?.length);
    }
    if (restArray && restArray?.length > 0) {
      dotString = true;
    }

    userAmenities?.map((item) => {
      if (item?.listsettings?.isEnable == "1") {
        isAmenities = true;
      }
    });

    sharedSpaces?.map((item) => {
      if (item?.listsettings?.isEnable == "1") {
        isSharedSpaces = true;
      }
    });

    return (
      <Row className={cx(s.pageContent)}>
        <div className={cx(s.horizontalLineThrough)}>
          <h2 className={cx(s.sectionTitleText)}>
            {" "}
            <FormattedMessage {...messages.aboutListing} />
          </h2>
          <div>
            <p className={cx(s.listingFontSize)}>
              {!this.state.open && count >= 150 && (
                <span className={cx(s.subText, s.lineBreak)}>
                  {" "}
                  {firstArray} {dotString === true && <span>...</span>}
                </span>
              )}
              {restArray && restArray?.length > 0 && (
                <span>
                  <Collapse in={open}>
                    <div>
                      {" "}
                      <span className={cx(s.subText, s.lineBreak)}>
                        {this.state.open && firstArray} {restArray}
                      </span>
                    </div>
                  </Collapse>
                  {dotString && (
                    <div className={s.btnContainer}>
                      <div className={s.showHidePadding}>
                        <Button
                          bsStyle="link"
                          className={cx(
                            s.button,
                            s.noPadding,
                            s.btnLInk,
                            s.showHideBtn,
                            "bgTransparent"
                          )}
                          onClick={() => this.handleClick()}
                        >
                          {this.state.open ? (
                            <FormattedMessage {...messages.closeAll} />
                          ) : (
                            <FormattedMessage {...messages.showDescription} />
                          )}

                          {this.state.open && (
                            <FontAwesome.FaAngleUp
                              className={s.navigationIcon}
                            />
                          )}
                          {!this.state.open && (
                            <FontAwesome.FaAngleDown
                              className={s.navigationIcon}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                </span>
              )}
            </p>
          </div>
        </div>
        <div className={s.listingLine}></div>
        <div className={cx(s.horizontalLineThrough)}>
          <div className={cx(s.space1)}>
            <p className={s.sectionTitleText}>
              <FormattedMessage {...messages.theSpace} />
            </p>
          </div>
          {!isLoad && (
            <div className={s.grid}>
              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={roomTypeIcon} />
                </div>
                <div className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.roomType} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>{roomType}</div>
                </div>
              </p>
              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={guestIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.guest} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {personCapacity}{" "}
                    {personCapacity > 1 ? (
                      <FormattedMessage {...messages.guests} />
                    ) : (
                      <FormattedMessage {...messages.guest} />
                    )}
                  </div>
                </span>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={bedIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.bedrooms} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {bedrooms}{" "}
                    {bedrooms > 1 ? (
                      <FormattedMessage {...messages.bedrooms} />
                    ) : (
                      <FormattedMessage {...messages.bedroom} />
                    )}
                  </div>
                </span>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={totalRoom} />
                </div>
                <div className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.totalRooms} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {buildingSize}
                  </div>
                </div>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={bedIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.bedsCapital} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {bedCounts}{" "}
                    {bedCounts > 1 ? (
                      <FormattedMessage {...messages.beds} />
                    ) : (
                      <FormattedMessage {...messages.bed} />
                    )}
                  </div>
                </span>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={bathroomIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.bathrooms} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {bathrooms}{" "}
                    {bathrooms > 1 ? (
                      <FormattedMessage {...messages.bathrooms} />
                    ) : (
                      <FormattedMessage {...messages.bathroom} />
                    )}
                  </div>
                </span>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={checkInIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.checkIn} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {checkIn(checkInStart, checkInEnd)}
                  </div>
                </span>
              </p>

              <p className={s.flex}>
                <div className={cx(s.svgImg, "svgImg")}>
                  <img src={bathIcon} />
                </div>
                <span className={cx(s.textCss)}>
                  <div>
                    <FormattedMessage {...messages.bathroomType} />
                  </div>
                  <div className={cx(s.smallText, "textWhite")}>
                    {bathroomType}
                  </div>
                </span>
              </p>
            </div>
          )}
          {isLoad && <Loader type={"text"} />}
        </div>
        <div className={cx(s.listingLine, s.listingLineTwo)}></div>
        {listBedTypes?.length > 0 && listBedTypes[0]?.bedType && (
          <div>
            {" "}
            <ListBedTypes
              itemList={listBedTypes}
              label={formatMessage(messages.bedsCapital)}
            />
            <div className={cx(s.listingLine, s.listingLineTwo)}></div>
          </div>
        )}

        {userAmenities?.length > 0 && isAmenities && (
          <div>
            {" "}
            <ListItem
              itemList={userAmenities}
              label={formatMessage(messages.aminities)}
              showLabel={formatMessage(messages.showAmenities)}
              hideLabel={formatMessage(messages.closeAmenities)}
              icon={true}
            />
            <div className={s.listingLine}></div>
          </div>
        )}
        {userSafetyAmenities?.length > 0 && (
          <div>
            <ListItem
              itemList={userSafetyAmenities}
              label={formatMessage(messages.safetyFeatures)}
              showLabel={formatMessage(messages.showAllSafetyFeatures)}
              hideLabel={formatMessage(messages.closeSafetyFeatures)}
              icon={true}
            />
            <div className={s.listingLine}></div>
          </div>
        )}
        {sharedSpaces?.length > 0 && isSharedSpaces && (
          <div>
            {" "}
            <ListItem
              itemList={sharedSpaces}
              label={formatMessage(messages.sharedSpaces)}
              showLabel={formatMessage(messages.showAllSharedSpaces)}
              hideLabel={formatMessage(messages.closeAllSharedSpaces)}
              icon={true}
            />
            <div className={s.listingLine}></div>
          </div>
        )}

        {houseRules?.length > 0 && (
          <div>
            {" "}
            <ListItem
              itemList={houseRules}
              label={formatMessage(messages.houseRules)}
              showLabel={formatMessage(messages.showAllHouseRules)}
              hideLabel={formatMessage(messages.closeHouseRules)}
              icon={false}
              houseRulesIcon={true}
            />
            <div className={s.listingLine}></div>
          </div>
        )}

        {data?.listingData?.cancellation && (
          <div className={cx(s.horizontalLineThrough)}>
            <div className={cx(s.space1)}>
              <p className={s.sectionTitleText}>
                <FormattedMessage {...messages.cancellations} />
              </p>
            </div>
            <div className={cx(s.spaceTop1)}>
              <p className={s.listingFontSize}>
                <span className={cx(s.text)}>
                  <strong>{data?.listingData?.cancellation?.policyName}</strong>
                </span>
              </p>
              <p className={s.listingFontSize}>
                <span className={cx(s.text)}>
                  {data?.listingData?.cancellation?.policyContent}
                </span>
              </p>
              <div className={cx(s.listingFontSize, s.showHideMargin)}>
                <Link
                  to={
                    "/cancellation-policies/" +
                    data?.listingData?.cancellation?.policyName
                  }
                  className={cx(s.sectionCaptionLink, s.viewFlex)}
                >
                  <FormattedMessage {...messages.viewDetails} />{" "}
                  <img src={rightArrow} className={"detailsArrowRTL"} />
                </Link>
              </div>
            </div>
            <div className={s.listingLine}></div>
          </div>
        )}
      </Row>
    );
  }
}
const mapState = (state) => ({
  settingsData: state?.viewListing?.settingsData,
});
const mapDispatch = {
  getSpecificSettings,
  contactHostOpen,
};
export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(ListingDetails))
);
