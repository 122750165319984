exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n.ImageBanner-container-1BRKB {\n\twidth: 100%;\n\tmax-width: var(--max-container-width);\n\tmax-width: var(--max-container-width);\n\tmargin: 0 auto;\n\tpadding: 0;\n}\n.ImageBanner-bgImage-nvyu9 {\n\tmin-height: 376px;\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\ttext-align: center;\n\tborder-radius: 10px;\n\tmargin: 100px 0;\n\tcolor: #fff;\n\tfont-size: 46px;\n}\n.ImageBanner-bgImgeCenter-2hz_5 {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n\t-webkit-box-pack: center;\n\t    -ms-flex-pack: center;\n\t        justify-content: center;\n\tpadding: 20px;\n}\n.ImageBanner-btn-3LMn_ {\n\tdisplay: inline-block;\n\tbackground-color: #fff;\n\tcolor: var(--btn-primary-bg) !important;\n\tcolor: var(--btn-primary-bg) !important;\n\tborder-radius: 23px;\n\tpadding: 10px 20px;\n\ttext-align: center;\n\tfont-size: 16px;\n\ttext-decoration: none !important;\n}\n.ImageBanner-titleText-241H9 {\n\tfont-size: 46px;\n\tfont-weight: 600;\n\ttext-shadow: 0 6px 32px var(--common-text-color);\n\ttext-shadow: 0 6px 32px var(--common-text-color);\n}\n@media screen and (max-width: 767px) {\n\t.ImageBanner-bgImage-nvyu9 {\n\t\tmargin: 55px 0;\n\t}\n}", ""]);

// exports
exports.locals = {
	"container": "ImageBanner-container-1BRKB",
	"bgImage": "ImageBanner-bgImage-nvyu9",
	"bgImgeCenter": "ImageBanner-bgImgeCenter-2hz_5",
	"btn": "ImageBanner-btn-3LMn_",
	"titleText": "ImageBanner-titleText-241H9"
};