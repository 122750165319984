import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { mainUrl } from "../../../config";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class NewInquiry extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkout: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const btnCenter = {
      textAlign: "center",
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "bold",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const {
      content: {
        receiverName,
        type,
        senderName,
        message,
        threadId,
        logo,
        siteName,
      },
    } = this.props;
    const {
      content: { checkIn, checkOut, personCapacity },
    } = this.props;
    let messageURL = mainUrl + "/message/" + threadId + "/guest";
    if (type === "host") {
      messageURL = mainUrl + "/message/" + threadId + "/host";
    }
    let checkInDate =
      checkIn != null ? moment(checkIn).format("ddd, Do MMM, YYYY") : "";
    let checkOutDate =
      checkOut != null ? moment(checkOut).format("ddd, Do MMM, YYYY") : "";

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {receiverName},</div>
                  <EmptySpace height={20} />
                  <div>Good news! {senderName} has sent an inquiry.</div>
                  <EmptySpace height={20} />
                  <div>
                    <span style={textBold}>Details:</span>
                    <br />
                    Check-in: {checkInDate}
                    <br />
                    Check-out: {checkOutDate}
                    <br />
                    Guests: {personCapacity}
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    <span style={textBold}>Message:</span>
                  </div>
                  <EmptySpace height={10} />
                  <div>
                    {message &&
                      message
                        .trim()
                        .split("\n")
                        .map(function (item, index) {
                          return (
                            <span key={index}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                  </div>
                  <EmptySpace height={40} />
                  <div style={btnCenter}>
                    <a href={messageURL} style={buttonStyle}>
                      Reply to {senderName}
                    </a>
                  </div>
                  <EmptySpace height={40} />
                  <EmailSignature siteName={siteName} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default NewInquiry;
