exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalFilter-root-2QUrm {\n  padding: 25px;\n}\n\n.ModalFilter-cancelBtn-Udcdd {\n  display: inline-block;\n  color: var(--btn-secondary-bg) !important;\n  color: var(--btn-secondary-bg) !important;\n  text-decoration: none;\n  cursor: pointer;\n  margin-right: 30px;\n  font-size: 16px;\n}", ""]);

// exports
exports.locals = {
	"root": "ModalFilter-root-2QUrm",
	"cancelBtn": "ModalFilter-cancelBtn-Udcdd"
};