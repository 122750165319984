import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { googleMapLoader } from '../../helpers/googleMapLoader';
import { setPersonalizedValues } from '../../actions/personalized';
import { getLocationData } from '../../actions/getLocation';
import s from '!isomorphic-style-loader/!css-loader!react-geosuggest/module/geosuggest.css';
class PlaceSuggest extends Component {

  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    setPersonalizedValues: PropTypes.any,
    googleMaps: PropTypes.object,
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      geography: PropTypes.string
    })
  };

  static defaultProps = {
    personalized: {
      location: null
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      googleMapsApiLoaded: false,
    };
  }

  async componentDidMount() {
    const autocompleteService = await googleMapLoader();
    this.setState({ googleMapsApiLoaded: true });
    this.autocompleteService = autocompleteService;
  }

  onSuggestSelect = (data) => {
    return data ? this.props.getLocationData(data.description) : '';
  }

  render() {
    const { label, className, containerClassName, personalized } = this.props;
    const { googleMapsApiLoaded } = this.state;

    return (
      <div>
        {
          googleMapsApiLoaded ?
            <Geosuggest
              ref={el => this._geoSuggest = el}
              placeholder={label}
              inputClassName={className}
              className={containerClassName}
              initialValue={personalized.location}
              onSuggestSelect={this.onSuggestSelect}
              onKeyPress={e => { if (e.key === 'Enter') e.preventDefault(); }
              }
              autoComplete={'off'}
            />
            : <></>
        }
      </div>
    )
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  setPersonalizedValues,
  getLocationData
};

export default withStyles(s)(connect(mapState, mapDispatch)(PlaceSuggest));