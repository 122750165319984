// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import Grid from "react-bootstrap/lib/Grid";
import Button from "react-bootstrap/lib/Button";

import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

import Avatar from "../Avatar";
import update from "./update";

import { money, hear, check, secure } from "./listIntroIcons";

import messages from "../../locale/messages";

import BgImage from "/public/SiteImages/createListBg.svg";
import ListImage from "/public/SiteImages/createListImage.svg";
import darkImage from "/public/SiteImages/darkIntro.svg";
import waveIcon from "/public/SiteIcons/waving-hand.png";

// Component to render SVG safely using dangerouslySetInnerHTML
class SvgComponent extends Component {
  static propTypes = {
    svgString: PropTypes.string.isRequired,
  };

  render() {
    const { svgString } = this.props;
    return (
      <div
        dangerouslySetInnerHTML={{ __html: svgString }}
        className={s.inlineBlock}
      />
    );
  }
}

// Card component to display individual benefits
class BenefitsCard extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  render() {
    const { icon, title, description } = this.props;
    return (
      <div
        className={`${s.bgWhite} ${s.roundedLg} ${s.p6} ${s.hFull} ${s.transitionTransform} ${s.hoverScale105} ${s.shadowMd} ${s.border} ${s.borderGray200}`}
      >
        <div className={`${s.flexCol} ${s.itemsCenter} ${s.textCenter}`}>
          <div className={`${s.mb4} ${s.p3} ${s.roundedFull}`}>
            <SvgComponent svgString={icon} />
          </div>
          <h3 className={`${s.textLg} ${s.fontBold} ${s.mb2}`}>{title}</h3>
          <p className={`${s.textGray600} ${s.textSm}`}>{description}</p>
        </div>
      </div>
    );
  }
}

class ListIntro extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    hostDisplayName: PropTypes.string.isRequired,
    guestDisplayName: PropTypes.string,
    nextPage: PropTypes.any,
    hostPicture: PropTypes.string,
    guestPicture: PropTypes.string,
    userData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    userData: {
      firstName: "",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      roomType: [],
      personCapacity: [],
      benefits: [
        {
          icon: money,
          title: {
            en: "Earn as Host",
            ar: "كسب كمضيف",
          },
          description: {
            en: "Easily list your property and start earning. We offer a seamless setup process to get your property in front of travelers worldwide.",
            ar: "قم بتسجيل عقارك بسهولة وابدأ في تحقيق الأرباح. نحن نوفر عملية تثبيت سلسة للحصول على عقارك في مقدمة السائحين حول العالم.",
          },
        },
        {
          icon: hear,
          title: {
            en: "Step-by-Step Guidance",
            ar: "الإرشاد الخطوة بخطوة",
          },
          description: {
            en: "Our team provides personalized support to help you optimize your listing and maximize your earnings. We've got you covered.",
            ar: "يقدم فريقنا دعمًا شخصيًا لمساعدتك في تحسين قائمتك وزيادة أرباحك. لقد غطيناك.",
          },
        },
        {
          icon: secure,
          title: {
            en: "Insurance",
            ar: "التأمين",
          },
          description: {
            en: "Comprehensive coverage to safeguard you and your belongings, ensuring you receive compensation if any issues arise. Host with confidence.",
            ar: "تغطية شاملة لحمايتك وممتلكاتك ، مما يضمن لك تلقي تعويض إذا حدثت أي مشاكل. استضف بثقة.",
          },
        },
        {
          icon: check,
          title: {
            en: "Guest Verification",
            ar: "التحقق من صحة المسافر",
          },
          description: {
            en: "All guests will be documented to protect you and your property, ensuring a safe and secure hosting experience.",
            ar: "سيتم تأهيل جميع المسافرين لحمايتك وممتلكاتك ، مما يضمن تجربة استضافة آمنة وآمنة.",
          },
        },
      ],
    };
  }

  componentDidMount() {
    // This ensures that the document is accessed only on the client side
    const locals = document.documentElement.lang;
    this.setState({
      locals: locals,
    });
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    const locals = document.documentElement.lang;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  render() {
    const {
      nextPage,
      userData,
      hostPicture,
      hostDisplayName,
      guestDisplayName,
    } = this.props;

    const { benefits, locals } = this.state;

    return (
      <div className={s.listIntroBgSection}>
        <Grid fluid className={s.listIntroContainer}>
          <div className={s.userRight}>
            <Avatar
              isUser
              title={guestDisplayName}
              className={s.profileImage}
            />
          </div>
          <h3 className={s.listIntroTitle}>
            <img src={waveIcon} className={s.waveCss} />{" "}
            <FormattedMessage {...messages.hi} />,{" "}
            <span className={s.userNameColor}>{userData.firstName}!</span>
          </h3>
        </Grid>

        {/* New Cards Will be here */}
        <div className={s.spaceAround}>
          <div className={`${s.gridParent} ${s.gap6} ${s.mb20}`}>
            {benefits.map((benefit, index) => (
              <BenefitsCard
                key={index}
                icon={benefit.icon}
                title={locals === "ar" ? benefit.title.ar : benefit.title.en}
                description={
                  locals === "ar"
                    ? benefit.description.ar
                    : benefit.description.en
                }
              />
            ))}
          </div>

          <div
            style={{
              display: "flex",
              gap: "15px",
              position: "fixed",
              bottom: "0",
              left: "0",
              width: "100vw",
              padding: "10px",
            }}
          >
            <Button
              className={cx(
                s.button,
                s.secondaryButton,
                bt.btnLarge,
                s.spaceTop2,
                s.textBold,
                s.fullWidth
              )}
              disabled
            >
              <FormattedMessage {...messages.back} />
            </Button>
            <Button
              className={cx(
                s.button,
                bt.btnPrimary,
                bt.btnLarge,
                s.spaceTop2,
                s.textBold,
                s.fullWidth
              )}
              onClick={() => nextPage("room")}
            >
              <FormattedMessage {...messages.becomeAHost} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ListIntro = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
})(ListIntro);

const mapState = (state) => ({
  userData: state.account.data,
  listingFields: state.listingFields.data,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(ListIntro))
);
