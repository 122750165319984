import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./CreateShare.css";

// Component
import { bookingProcess } from "../../actions/booking/bookingProcess";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import TableShare from "../../components/TableShare/TableShare";

class ManageShare extends Component {
  static propTypes = {
    reservationId: PropTypes.string.isRequired,
    bookingProcess: PropTypes.func,
  };

  render() {
    const { reservationId, bookingProcess } = this.props;

    return (
      <div className={cx(s.container, s.overFlowHiddenMobile)}>
        <TableShare
          reservationId={reservationId}
          bookingProcess={bookingProcess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  bookingProcess,
};

export default injectIntl(
  withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(ManageShare))
);
