exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShowOneTotalPrice-labelText-1eQtE {\n  display: inline-block;\n  font-weight: 500;\n  margin: 0px;\n  font-size: 14px;\n}\n\n.ShowOneTotalPrice-mapFlex-2uuR6 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-column-gap: 8px;\n     -moz-column-gap: 8px;\n          column-gap: 8px;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.ShowOneTotalPrice-ShowPricecurvedbox-2c_is {\n  width: 100%;\n  max-width: 457px;\n  margin: 0;\n  border: 1px solid var(--border-color);\n  border: 1px solid var(--border-color);\n  border-radius: 27px;\n  padding: 12px 12px 12px 24px;\n  gap: 3px;\n}\n\n.ShowOneTotalPrice-totalDescriptiontext-A9ime {\n  color: var(--common-text-Secondry-color);\n  color: var(--common-text-Secondry-color);\n}\n", ""]);

// exports
exports.locals = {
	"labelText": "ShowOneTotalPrice-labelText-1eQtE",
	"mapFlex": "ShowOneTotalPrice-mapFlex-2uuR6",
	"ShowPricecurvedbox": "ShowOneTotalPrice-ShowPricecurvedbox-2c_is",
	"totalDescriptiontext": "ShowOneTotalPrice-totalDescriptiontext-A9ime"
};