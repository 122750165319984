exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AdminFooter-footerContainer-3s0he {\n    margin-left: 270px;\n    margin-top: 50px;\n    position: fixed;\n    bottom: 0;\n    padding: 15px;\n    background: #f5f5f5;\n    width: calc(100% - 270px);\n    text-align: center;\n    border-top: 1px solid var(--border-secondary-color);\n    border-top: 1px solid var(--border-secondary-color);\n    z-index: 8;\n}\n\n@media screen and (max-width: 1023px) {\n    .AdminFooter-footerContainer-3s0he {\n        width: 100%;\n        margin-left: 0;\n    }\n}", ""]);

// exports
exports.locals = {
	"footerContainer": "AdminFooter-footerContainer-3s0he"
};