import React from "react";
import { Table, TBody, TR, TD, Img } from "oy-vey";

const Footer = (props) => {
  const style = {
    backgroundColor: "#f7f7f7",
  };

  const spaceStyle = {
    paddingBottom: "25px",
    paddingLeft: "5px",
    color: "#9ca299",
    fontSize: "14px",
    textAlign: "center",
  };

  return (
    <Table width="100%" style={style}>
      <TBody>
        <TR>
          <TD>
            <div style={spaceStyle}>
              Sent with{" "}
              <Img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDkuNDIgNDEzLjUiPg0KICA8ZGVmcz4NCiAgICA8c3R5bGU+DQogICAgICAuY2xzLTEgew0KICAgICAgICBmaWxsOiAjMzNiYWNmOw0KICAgICAgfQ0KICAgIDwvc3R5bGU+DQogIDwvZGVmcz4NCiAgPGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+DQogICAgPGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj4NCiAgICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMTE4LjM1Yy40Ny0yNy42MSw2LjI2LTUzLjU4LDI0LjI5LTc1LjVDNDIuNzEsMjAuNDUsNjYuNDksNy4wNiw5NSwyLjA1YzQwLjQtNy4xLDc2LDQuNzIsMTA4LjY4LDI3Ljg4YTE0NC4zMSwxNDQuMzEsMCwwLDEsMTcuNjIsMTQuNzNjMi42NCwyLjU5LDQuMzYsMi4zOSw2Ljk1LS4wN0ExNzEuNzksMTcxLjc5LDAsMCwxLDI3NS4xLDEyLjczYzQ2LjY5LTIxLjYsMTAwLjQyLTE1LjMzLDEzNywxNi41LDIzLjIsMjAuMiwzNC4yOCw0Ni4xOSwzNi43NSw3Ni4yMywzLjIzLDM5LjIxLTcuNTIsNzUuNTYtMjMuNzUsMTEwLjU5LTE4LjA5LDM5LjA3LTQ0LjE4LDcyLjI4LTc1LjQ0LDEwMS42Ny0zMS44NCwyOS45NS02NS4zNiw1Ny44MS0xMDEuNzgsODIuMDYtNi41Miw0LjM0LTEzLjM0LDguMi0xOS44NCwxMi41NS0yLjQ4LDEuNjYtNC4yMSwxLjQ3LTYuNjgsMC0yNS42My0xNS4zNi00OS42Ny0zMy03Mi44LTUxLjgxLTI0LjI5LTE5LjgtNDcuOTQtNDAuMzYtNjkuMi02My40MkM0MC43NCwyNTUuMjEsMTMuOTQsMjA3LDIuOSwxNTAuNjZBMTY2LjU0LDE2Ni41NCwwLDAsMSwwLDExOC4zNVoiIC8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4=' 
              style={{ width: '15px'}}/>
              {" "}
              from {props.siteName}
            </div>
          </TD>
        </TR>
      </TBody>
    </Table>
  );
};

export default Footer;
