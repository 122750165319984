
import React from "react";
import Layout from "../../components/Layout";
import NotFound from "../notFound/NotFound";
import StartProcessContainer from "./StartProcessContainer";

// Example decode helper
function doubleBase64Decode(encoded) {
  try {
    return atob(atob(encoded));
  } catch (err) {
    console.error("Decoding error:", err);
    return null;
  }
}

export default async function action({ store, params, intl }) {
  const title = intl?.formatMessage
    ? intl.formatMessage({ id: "startProcessTitle", defaultMessage: "Start Process" })
    : "Start Process";

  // 1. Check if we have the actionString from params
  const actionString = params?.action;
  if (!actionString) {
    return {
      title,
      component: (
        <Layout>
          <NotFound title="Invalid Link" />
        </Layout>
      ),
      status: 404,
    };
  }

  // 2. Split the actionString
  const parts = actionString.split(".");
  if (parts.length !== 3) {
    return {
      title,
      component: (
        <Layout>
          <NotFound title="Invalid Link" />
        </Layout>
      ),
      status: 404,
    };
  }

  const [prefix, encodedAction, suffix] = parts;
  const decoded = doubleBase64Decode(encodedAction);

  if (!decoded) {
    return {
      title,
      component: (
        <Layout>
          <NotFound title="Invalid Link" />
        </Layout>
      ),
      status: 404,
    };
  }

  let actionObj;
  try {
    actionObj = JSON.parse(decoded);
  } catch (err) {
    console.error("JSON parse error:", err);
    return {
      title,
      component: (
        <Layout>
          <NotFound title="Invalid Link" />
        </Layout>
      ),
      status: 404,
    };
  }

  // 3. Extract fields from actionObj
  const { action, reservationId, slotId, listId, guests, checkIn, checkOut } = actionObj;

  // If the action isn't recognized, show NotFound
  if (action !== "booking") {
    return {
      title,
      component: (
        <Layout>
          <NotFound title="Invalid Link" />
        </Layout>
      ),
      status: 404,
    };
  }

  // 4. Check if user is authenticated (on the server). 
  //    If not, do a server-side redirect to /login immediately
  const state = store.getState();
  const isAuthenticated = state.runtime.isAuthenticated;
  if (!isAuthenticated) {
    return {
      redirect: `/login?refer=${encodeURIComponent(`/start-process/${actionString}`)}`,
    };
  }

  // 5. [Optional] Dispatch bookingProcess on the server side 
  //    (If your bookingProcess is a thunk that returns a promise):
  // 
  // try {
  //   await store.dispatch(
  //     bookingProcess(listId, guests, checkIn, checkOut, null, null, null, reservationId, slotId)
  //   );
  // } catch (err) {
  //   console.error("Booking process error:", err);
  //   // Show NotFound or any error component
  //   return {
  //     title,
  //     component: (
  //       <Layout>
  //         <NotFound title="Booking Error" />
  //       </Layout>
  //     ),
  //     status: 500,
  //   };
  // }

  // If you'd prefer to let the client do the final dispatch, skip the above.

  // 6. If everything is valid, return the container. 
  //    Pass the already validated data so the client doesn’t need to decode again.
  return {
    title,
    component: (
      <Layout>
        <StartProcessContainer
          // Instead of sending actionString, we can send the actual fields
          actionData={{ listId, guests, checkIn, checkOut, reservationId, slotId }}
          actionString={actionString} // if you still need it for something
        />
      </Layout>
    ),
  };
}

// import React from "react";
// import Layout from "../../components/Layout";
// import NotFound from "../notFound/NotFound";
// // Import the new container
// import StartProcessContainer from "./StartProcessContainer";

// export default async function action({ store, params, intl }) {
//   // Localize the title if desired
//   const title = intl?.formatMessage
//     ? intl.formatMessage({ id: "startProcessTitle", defaultMessage: "Start Process" })
//     : "Start Process";

//   // 1. Check if we have the actionString from params
//   const actionString = params?.action;

//   // If no actionString, show NotFound
//   if (!actionString) {
//     return {
//       title,
//       component: (
//         <Layout>
//           <NotFound title="Invalid Link" />
//         </Layout>
//       ),
//       status: 404,
//     };
//   }

//   // 2. Return the container for client-side logic
//   //    We pass `actionString` as a prop so the container can decode it.
//   return {
//     title,
//     component: (
//       <Layout>
//         <StartProcessContainer actionString={actionString} />
//       </Layout>
//     ),
//   };
// }
