exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n.FaqSection-container-U75Dh {\n\twidth: 100%;\n\tmax-width: var(--max-container-width);\n\tmax-width: var(--max-container-width);\n\tmargin: 0 auto;\n\tpadding: 0;\n}\n.FaqSection-seperator-2LP2N {\n\tbackground-color: var(--common-text-color);\n\tbackground-color: var(--common-text-color);\n\twidth: 64px;\n\tdisplay: block;\n\theight: 1.5px;\n\tmargin: 0 auto;\n\tmargin-bottom: 27px;\n}\n.FaqSection-boxseperator-1oPRV {\n\theight: 2.5px;\n\tborder: 1px solid var(--common-text-color);\n\tborder: 1px solid var(--common-text-color);\n\tbackground-color: transparent;\n}\n.FaqSection-mainhedding-3sQ65 h1 {\n\tfont-size: 46px;\n\ttext-align: center;\n\tfont-weight: bold;\n\tmargin: 100px 0 60px;\n}\nh4.FaqSection-common-11Hlh {\n\tfont-size: 24px;\n\tfont-weight: 800;\n\tmargin-bottom: 20px;\n}\np.FaqSection-common-11Hlh {\n\tfont-size: 17px;\n\tline-height: 1.7;\n\tfont-weight: 400;\n}\n.FaqSection-faqsection-3_0Rn {\n\tmargin-bottom: 75px;\n}\n.FaqSection-faqsection-3_0Rn p {\n\tfont-size: 16px;\n}\n@media screen and (max-width: 991px) {\n\th4.FaqSection-common-11Hlh {\n\t\tfont-size: 23px;\n\t\tfont-weight: 600;\n\t}\n\n\t.FaqSection-mainhedding-3sQ65 h1 {\n\t\tfont-size: 36px;\n\t\tline-height: 51px;\n\t\tmargin-bottom: 56px;\n\t}\n}\n@media screen and (max-width: 767px) {\n\t.FaqSection-mainhedding-3sQ65 h1 {\n\t\tfont-size: 24px;\n\t\tline-height: 30px;\n\t\tmargin-bottom: 20px;\n\t\ttext-align: left;\n\t\tpadding-right: 15px;\n\t\tpadding-left: 15px;\n\t\tmargin: 50px 0;\n\t}\n\n\th4.FaqSection-common-11Hlh {\n\t\tfont-size: 26px;\n\t}\n\n\t.FaqSection-faqsection-3_0Rn {\n\t\tmargin-bottom: 10px;\n\t}\n\n\t.FaqSection-faqsection-3_0Rn {\n\t\tmargin-bottom: 10px;\n\t}\n\n\t.FaqSection-faqsection-3_0Rn p {\n\t\tpadding-right: 10px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"container": "FaqSection-container-U75Dh",
	"seperator": "FaqSection-seperator-2LP2N",
	"boxseperator": "FaqSection-boxseperator-1oPRV",
	"mainhedding": "FaqSection-mainhedding-3sQ65",
	"common": "FaqSection-common-11Hlh",
	"faqsection": "FaqSection-faqsection-3_0Rn"
};