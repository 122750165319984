exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** Common - Start **/\n.BannerCaption-noPadding-5h7xB {\n    padding: 0px;\n}\n.BannerCaption-noMargin-2vmV3 {\n    margin: 0px;\n}\n/** Common - End **/\n/** Banner caption - Start **/\n.BannerCaption-bannerCaptionContainer-1nHJQ {\n    position: relative;\n}\n.BannerCaption-bannerCaptionText-1m66z {\n    font-size: 60px;\n    line-height: 66px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n    margin: 0px;\n    font-weight: 600;\n    text-align: center;\n}\n.BannerCaption-bannerCaptionHighlight-dA2QW {\n    font-weight: 700;\n    color: var(--btn-primary-bg);\n    color: var(--btn-primary-bg);\n}\n@media screen and (max-width: 1200px) {\n    .BannerCaption-bannerCaptionText-1m66z {\n        padding-top: 110px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .BannerCaption-bannerCaptionText-1m66z {\n        font-size: 25px;\n        line-height: 30px;\n    }\n}\n/** Banner caption - End **/", ""]);

// exports
exports.locals = {
	"noPadding": "BannerCaption-noPadding-5h7xB",
	"noMargin": "BannerCaption-noMargin-2vmV3",
	"bannerCaptionContainer": "BannerCaption-bannerCaptionContainer-1nHJQ",
	"bannerCaptionText": "BannerCaption-bannerCaptionText-1m66z",
	"bannerCaptionHighlight": "BannerCaption-bannerCaptionHighlight-dA2QW"
};