exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Transaction-secondContainer-173ty {\n    width: 100%;\n    max-width: 783px;\n    overflow-x: auto;\n    overflow-y: hidden;\n}\n\n@media screen and (min-width:1281px) {\n    .Transaction-transactionGrid-3Lhos {\n        grid-template-columns: 267px 783px;\n    }\n}", ""]);

// exports
exports.locals = {
	"secondContainer": "Transaction-secondContainer-173ty",
	"transactionGrid": "Transaction-transactionGrid-3Lhos"
};