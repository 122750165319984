import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Geosuggest from 'react-geosuggest';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { setPersonalizedValues } from '../../../actions/personalized';
import { googleMapLoader } from '../../../helpers/googleMapLoader';
import s from '!isomorphic-style-loader/!css-loader!react-geosuggest/module/geosuggest.css';

class PlaceGeoSuggest extends Component {

	static propTypes = {
		label: PropTypes.string,
		className: PropTypes.string,
		containerClassName: PropTypes.string,
		setPersonalizedValues: PropTypes.any,
		googleMaps: PropTypes.object,
		personalized: PropTypes.shape({
			location: PropTypes.string,
			lat: PropTypes.number,
			lng: PropTypes.number,
			geography: PropTypes.string
		})
	};

	static defaultProps = {
		personalized: {
			location: null
		}
	}

	constructor(props) {
		super(props);
		this.state = {
			googleMapsApiLoaded: false,
		};
	}

	async componentDidMount() {
		document.addEventListener('wheel', this.handleScroll);
		document.addEventListener('mousedown', this.handleBlur);
		const autocompleteService = await googleMapLoader();
		this.setState({ googleMapsApiLoaded: true });
		this.autocompleteService = autocompleteService;
	}

	componentWillUnmount() {
		document.removeEventListener('wheel', this.handleScroll);
		document.removeEventListener('mousedown', this.handleBlur);
	}

	handleScroll = () => {
		var element = document.getElementById("geosuggest__input");
		if (element) {
			element.classList.remove("addTextOverflow");
			element.classList.add("removeTextOverflow");
		}
	}

	handleBlur = () => {
		var element = document.getElementById("geosuggest__input");
		if (element) {
			element.classList.remove("removeTextOverflow");
			element.classList.add("addTextOverflow");
		}
	}

	onSuggestSelect = (data) => {
		const { setPersonalizedValues } = this.props;
		let locationData = {};
		if (data && data.gmaps) {
			data.gmaps.address_components.map((item, key) => {
				if (item.types[0] == "administrative_area_level_1") {
					locationData["administrative_area_level_1_short"] = item.short_name;
					locationData["administrative_area_level_1_long"] = item.long_name;
				} else if (item.types[0] == "country") {
					locationData[item.types[0]] = item.short_name;
				} else {
					locationData[item.types[0]] = item.long_name;
				}
			});
			setPersonalizedValues({ name: 'geography', value: JSON.stringify(locationData) });
			setPersonalizedValues({ name: 'location', value: data.label });
			setPersonalizedValues({ name: 'lat', value: data.location.lat });
			setPersonalizedValues({ name: 'lng', value: data.location.lng });
			setPersonalizedValues({ name: 'chosen', value: 1 });
			setPersonalizedValues({ name: 'searchLocationStatus', value: false });
		}
	}

	onChange = async (value) => {
		const { setPersonalizedValues, change } = this.props;

		if (value == '') {
			setPersonalizedValues({ name: 'location', value: null });
			setPersonalizedValues({ name: 'chosen', value: null });
			setPersonalizedValues({ name: 'geography', value: null });
			setPersonalizedValues({ name: 'searchLocationStatus', value: true });
			await change('SearchForm', 'location', null);
			await change('SearchForm', 'lat', null);
			await change('SearchForm', 'lng', null);
			await change('SearchForm', 'geography', null);
			await change('SearchForm', 'sw_lat', null);
			await change('SearchForm', 'sw_lng', null);
			await change('SearchForm', 'ne_lat', null);
			await change('SearchForm', 'ne_lng', null);
			await change('SearchForm', 'geoType', null);
			await change('SearchForm', 'currentPage', 1);
		}
	}

	render() {
		const { label, className, containerClassName, personalized } = this.props;
		const { googleMapsApiLoaded } = this.state;
		return (
			<div>
				{
					googleMapsApiLoaded ?
						<Geosuggest
							ref={el => this._geoSuggest = el}
							placeholder={label}
							inputClassName={className}
							className={containerClassName}
							initialValue={personalized.location}
							onChange={this.onChange}
							onSuggestSelect={this.onSuggestSelect}
							onKeyPress={e => {
								if (e.key === 'Enter') e.preventDefault();
							}
							}
							autoComplete={'off'}
						/> : <></>
				}
			</div>
		)
	}
}

const mapState = (state) => ({
	personalized: state?.personalized
});

const mapDispatch = {
	setPersonalizedValues,
	change
};

export default withStyles(s)(connect(mapState, mapDispatch)(PlaceGeoSuggest));