import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Calendar.css";
import bt from "../../../components/commonStyle.css";
import { Button, FormGroup, Modal } from "react-bootstrap";
import cx from "classnames";
// Component
import Loader from "../../Loader";
import history from "../../../core/history";

// Locale
import messages from "../../../locale/messages";
import CreateShareList from "../../CreateShareList/CreateShareList";
import { getCustomRedirectURL } from "../../../helpers/formatURL";
import showToaster from "../../../helpers/showToaster";
import { loadAccount } from "../../../actions/account";

class BookingButton extends Component {
  static propTypes = {
    availability: PropTypes.bool.isRequired,
    isDateChosen: PropTypes.bool.isRequired,
    basePrice: PropTypes.number.isRequired,
    isHost: PropTypes.bool.isRequired,
    isShare: PropTypes.bool.isRequired,
    bookingProcess: PropTypes.any.isRequired,
    listId: PropTypes.number.isRequired,
    guests: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    bookingType: PropTypes.string.isRequired,
    bookingLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    maximumStay: PropTypes.bool,
    userBanStatus: PropTypes.number,
    hostId: PropTypes.string,
  };

  static defaultProps = {
    availability: true,
    isDateChosen: false,
    bookingLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleClick() {
    const { account, hostId } = this.props;

    if (account?.userId === hostId) {
      showToaster({messageId: "canNotBookYourList", toasterType: "error"});
      return;
    }

    const { bookingProcess, listId, guests, startDate, endDate } = this.props;
    bookingProcess(listId, guests, startDate, endDate, null);
  }

  handleChange() {
    history.push("/s");
  }

  getHostingIdAndQueryParams() {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const queryString = window.location.search;
      const parts = path.split("/");
  
      const hostingId = parts[2];
  
      const params = new URLSearchParams(queryString);
      const queryParams = {};
      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }
  
      return { hostingId, queryParams };
    }
    return { hostingId: null, queryParams: {} };
  }

  toggleModal() {
    const { isAuthenticated, account, hostId } = this.props;

    if (!isAuthenticated) {
      const { hostingId, queryParams } = this.getHostingIdAndQueryParams();

      let redirect = getCustomRedirectURL(hostingId, {...queryParams, active: 'share'});
      history.push(redirect);
    }

    if (account.userId === hostId) {
      showToaster({messageId: "canNotBookYourList", toasterType: "error"});
      return;
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  render() {
    const {
      basePrice,
      cleaningPrice,
      tax,
      currency,
      monthlyDiscount,
      weeklyDiscount,
      startDate,
      endDate,
      serviceFees,
      base,
      rates,
      taxRate,
      userBanStatus,
      isDateChosen,
      bookButton,
      availability,
      isHost,
      isShare,
      bookingType,
      listId,
      bookingLoading,
      bookingProcess,
    } = this.props;

    const { formatMessage } = this.props.intl;
    const { maximumStay, minimumStay } = this.props;
    const { showModal } = this.state;

    let disabled, buttonLabel;
    if (
      !isDateChosen ||
      basePrice < 1 ||
      isHost ||
      maximumStay ||
      userBanStatus ||
      minimumStay
    ) {
      disabled = true;
    } else {
      disabled = false;
    }

    if (bookingType === "instant") {
      buttonLabel = messages.book;
    } else {
      buttonLabel = messages.requestToBook;
    }

    if (!availability && isDateChosen) {
      return (
        <div>
          <FormGroup className={s.formGroup}>
            <Button
              className={cx(
                s.btn,
                s.btnBlock,
                bt.btnLarge,
                bt.btnPrimaryBorder
              )}
              onClick={this.handleChange}
            >
              <FormattedMessage {...messages.viewOtherListings} />
            </Button>
          </FormGroup>
        </div>
      );
    } else if (bookButton) {
      return isShare ? (
        <>
          <Button
            className={cx(
              s.btn,
              s.btnBlock,
              bt.btnLarge,
              bt.btnPrimary,
              bt.space2,
              bt.padding1
            )}
            onClick={this.toggleModal}
          >
            <FormattedMessage {...messages.createShareListing} />
          </Button>

          <Modal
            show={showModal}
            onHide={this.toggleModal}
            animation={false}
            className="modal-xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Share Listing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateShareList
                listId={listId}
                isHost={isHost}
                totalAmount={basePrice}
                basePrice={basePrice}
                cleaningPrice={cleaningPrice}
                tax={tax}
                currency={currency}
                monthlyDiscount={monthlyDiscount}
                weeklyDiscount={weeklyDiscount}
                startDate={startDate}
                endDate={endDate}
                serviceFees={serviceFees}
                base={base}
                rates={rates}
                taxRate={taxRate}
                bookingProcess={bookingProcess}
              />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <FormGroup className={s.formGroup}>
          <Loader
            type={"button"}
            className={cx(
              s.btn,
              s.btnBlock,
              bt.btnLarge,
              bt.btnPrimary,
              "arButtonLoader"
            )}
            handleClick={this.handleClick}
            show={bookingLoading}
            label={formatMessage(buttonLabel)}
          />
        </FormGroup>
      );
    } else {
      return <></>;
    }
  }
}

const mapState = (state) => ({
  isAuthenticated: state?.runtime?.isAuthenticated,
	account: state.account.data,
});

const mapDispatch = {
	loadAccount
};

export default connect(mapState,mapDispatch)(injectIntl(withStyles(s, bt)(BookingButton)));
