exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedoSearch-redoContainer-1ZWmy {\n  position: absolute;\n  top: 32px;\n  left: 33px;\n  z-index: 1;\n  -webkit-box-shadow: 0px 5px 12px #00000033;\n          box-shadow: 0px 5px 12px #00000033;\n  border-radius: 6px;\n  width: 100%;\n  min-width: 240px;\n  max-width: 240px;\n  margin: 0 auto;\n}\n\n.RedoSearch-redoContent-1CpWX {\n  padding: 9px 12px 9px 12px;\n  background-color: var(--white-color);\n  background-color: var(--white-color);\n  border-radius: 6px;\n  -webkit-box-shadow: 0px 5px 12px #00000033;\n          box-shadow: 0px 5px 12px #00000033;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 10px;\n}\n\n.RedoSearch-redoText-10hvV {\n  word-wrap: break-word;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.2px;\n}", ""]);

// exports
exports.locals = {
	"redoContainer": "RedoSearch-redoContainer-1ZWmy",
	"redoContent": "RedoSearch-redoContent-1CpWX",
	"redoText": "RedoSearch-redoText-10hvV"
};