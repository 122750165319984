exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishLists-root-1hIfd {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.WishLists-container-1RfzC {\n  margin: 0px auto;\n  padding-top: 30px;\n  max-width: var(--max-container-width);\n  max-width: var(--max-container-width);\n  padding-bottom: 50px;\n}\n\n@media screen and (max-width: 1200px) {\n  .WishLists-mainSection-7cvGg {\n    margin-bottom: 60px;\n    margin-top: 30px;\n  }\n\n  .WishLists-container-1RfzC {\n    padding-top: 75px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "WishLists-root-1hIfd",
	"container": "WishLists-container-1RfzC",
	"mainSection": "WishLists-mainSection-7cvGg"
};