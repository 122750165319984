exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loader-saving-3C62- {\n\tfont-size: 18px;\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n}\n\n.Loader-savingDots-4O7Jw {\n\tfont-size: 48px;\n}\n\n.Loader-saving-3C62- span {\n\t-webkit-animation-name: Loader-blink-3ZQyr;\n\t        animation-name: Loader-blink-3ZQyr;\n\t-webkit-animation-duration: 1.4s;\n\t        animation-duration: 1.4s;\n\t-webkit-animation-iteration-count: infinite;\n\t        animation-iteration-count: infinite;\n\t-webkit-animation-fill-mode: both;\n\t        animation-fill-mode: both;\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n}\n\n.Loader-saving-3C62- span:nth-child(2) {\n\t-webkit-animation-delay: .2s;\n\t        animation-delay: .2s;\n}\n\n.Loader-saving-3C62- span:nth-child(3) {\n\t-webkit-animation-delay: .4s;\n\t        animation-delay: .4s;\n}\n\n@-webkit-keyframes Loader-blink-3ZQyr {\n\t0% {\n\t\topacity: .2;\n\t}\n\n\t20% {\n\t\topacity: 1;\n\t}\n\n\t100% {\n\t\topacity: .2;\n\t}\n}\n\n@keyframes Loader-blink-3ZQyr {\n\t0% {\n\t\topacity: .2;\n\t}\n\n\t20% {\n\t\topacity: 1;\n\t}\n\n\t100% {\n\t\topacity: .2;\n\t}\n}\n\n.Loader-textCenter-2D2w9 {\n\ttext-align: center;\n}\n\n.Loader-iconColor-2bInx {\n\tcolor: var(--btn-primary-bg);\n\tcolor: var(--btn-primary-bg);\n\tfont-size: 48px;\n}", ""]);

// exports
exports.locals = {
	"saving": "Loader-saving-3C62-",
	"savingDots": "Loader-savingDots-4O7Jw",
	"blink": "Loader-blink-3ZQyr",
	"textCenter": "Loader-textCenter-2D2w9",
	"iconColor": "Loader-iconColor-2bInx"
};