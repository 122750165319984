import { defineMessages } from "react-intl";
const messages = defineMessages({
  home: {
    id: "app.home",
    defaultMessage: "Home",
    description: "Menu item",
  },
  becomeAHost: {
    id: "app.becomeAHost",
    defaultMessage: "Become a host",
    description: "Menu item",
  },
  help: {
    id: "app.help",
    defaultMessage: "Help",
    description: "Menu item",
  },
  login: {
    id: "app.login",
    defaultMessage: "Login",
    description: "Menu item",
  },
  signup: {
    id: "app.signup",
    defaultMessage: "Signup",
    description: "Menu item",
  },
  host: {
    id: "app.host",
    defaultMessage: "Host",
    description: "Menu item",
  },
  manageListing: {
    id: "app.manageListing",
    defaultMessage: "Manage listings",
    description: "Menu item",
  },
  transactionHistory: {
    id: "app.transactionHistory",
    defaultMessage: "Transaction history",
    description: "Menu item",
  },
  profile: {
    id: "app.profile",
    defaultMessage: "Profile",
    description: "Menu item",
  },
  accountSettings: {
    id: "app.accountSettings",
    defaultMessage: "Account settings",
    description: "Menu item",
  },
  trips: {
    id: "app.trips",
    defaultMessage: "Trips",
    description: "Menu item",
  },
  messages: {
    id: "app.messages",
    defaultMessage: "Messages",
    description: "Menu item",
  },
  dashboard: {
    id: "app.dashboard",
    defaultMessage: "Dashboard",
    description: "Menu item",
  },
  editProfile: {
    id: "app.editProfile",
    defaultMessage: "Edit profile",
    description: "Menu item",
  },
  logout: {
    id: "app.logout",
    defaultMessage: "Logout",
    description: "Menu item",
  },
  about: {
    id: "app.about",
    defaultMessage: "About",
    description: "Menu item",
  },
  discover: {
    id: "app.discover",
    defaultMessage: "Discover",
    description: "Menu item",
  },
  trustSafety: {
    id: "app.trustSafety",
    defaultMessage: "Trust & Safety",
    description: "Menu item",
  },
  travelCredit: {
    id: "app.travelCredit",
    defaultMessage: "Travel Credit",
    description: "Menu item",
  },
  hosting: {
    id: "app.hosting",
    defaultMessage: "Hosting",
    description: "Menu item",
  },
  whyHost: {
    id: "app.whyHost",
    defaultMessage: "Why Host",
    description: "Menu item",
  },
  termsPrivacy: {
    id: "app.termsPrivacy",
    defaultMessage: "Terms & Privacy",
    description: "Menu item",
  },
  appsAvailableOn: {
    id: "app.appsAvailableOn",
    defaultMessage: "Apps available on",
    description: "Apps available on",
  },
  or: {
    id: "app.or",
    defaultMessage: "or continue with",
    description: "or continue with",
  },
  signUp: {
    id: "app.signUp",
    defaultMessage: "Sign Up",
    description: "Sign Up Button",
  },
  noAccount: {
    id: "app.noAccount",
    defaultMessage: "Don’t have an account?",
    description: "Don’t have an account?",
  },
  email: {
    id: "app.email",
    defaultMessage: "Email",
    description: "Your Email Address to Login",
  },
  emailInvalid: {
    id: "app.emailInValid",
    defaultMessage: "Invalid email address",
    description: "Invalid Email Address",
  },
  emailNotExists: {
    id: "app.emailNotExists",
    defaultMessage:
      "No account exists for this email. Make sure it's typed in correctly, or “sign up” instead",
    description: "Email Address is not exist",
  },
  urlInvalid: {
    id: "app.urlInvalid",
    defaultMessage: "Invalid URL",
    description: "URL invalid",
  },
  password: {
    id: "app.password",
    defaultMessage: "Password",
    description: "Your Password to Login",
  },
  passwordInvalid: {
    id: "app.passwordInValid",
    defaultMessage:
      "Your password must be at least 8 characters. Please try again",
    description: "Invalid Password",
  },
  passwordWrong: {
    id: "app.passwordWrong",
    defaultMessage: "Wrong password. Please try again",
    description: "Wrong Password",
  },
  userBannedTitle: {
    id: "app.userBannedTitle",
    defaultMessage: "We're sorry!",
    description: "Not Found",
  },
  userBannedSubTitle: {
    id: "app.userBannedSubTitle",
    defaultMessage:
      " It looks like your account is blocked for some reason. If you need further information, please ",
    description: "Not Found",
  },
  somethingWentWrong: {
    id: "app.somethingWentWrong",
    defaultMessage: "Sorry, something went wrong. Please try again",
    description: "Wrong went wrong",
  },
  loggedIn: {
    id: "app.loggedIn",
    defaultMessage: "You are already logged in!",
    description: "You are already Logged-in",
  },
  cantLogin: {
    id: "app.cantLogin",
    defaultMessage: "Forgot password?",
    description: "Forgot password?",
  },
  signupWithEmail: {
    id: "app.signupWithEmail",
    defaultMessage: "Signup with Email",
    description: "Login Modal title",
  },
  facebook: {
    id: "app.facebook",
    defaultMessage: "Facebook",
    description: "sign up with facebook",
  },
  twitter: {
    id: "app.twitter",
    defaultMessage: "Twitter",
    description: "sign up with twitter",
  },
  google: {
    id: "app.google",
    defaultMessage: "Google",
    description: "Signup with Google",
  },
  alreadyHaveAccount: {
    id: "app.alreadyHaveAccount",
    defaultMessage: "Already have an account?",
    description: "alreadyHaveAccount",
  },
  firstName: {
    id: "app.firstName",
    defaultMessage: "First name",
    description: "Your First name to Register",
  },
  lastName: {
    id: "app.lastName",
    defaultMessage: "Last name",
    description: "Your Last name to Register",
  },
  dateOfBirth: {
    id: "app.dateOfBirth",
    defaultMessage: "Birthday",
    description: "Your Birthday to Register",
  },
  month: {
    id: "app.month",
    defaultMessage: "Month",
    description: "Month of birthday",
  },
  year: {
    id: "app.year",
    defaultMessage: "Year",
    description: "Year of birthday",
  },
  WrongDayChosen: {
    id: "app.WrongDayChosen",
    defaultMessage: "Wrong day chosen for the month",
    description: "Wrong day chosen for the month",
  },
  birthDay: {
    id: "app.birthDay",
    defaultMessage: "Birthday",
    description: "Birthday",
  },
  forgotPassword: {
    id: "app.forgotPassword",
    defaultMessage: "Forgot password",
    description: "Forgot Password",
  },
  forgotPasswordInfo: {
    id: "app.forgotPasswordInfo",
    defaultMessage:
      "Enter the email address associated with your account, and we’ll email you a link to reset your password.",
    description: "Forgot password info",
  },
  backToLogin: {
    id: "app.backToLogin",
    defaultMessage: "Back to login",
    description: "Back to login",
  },
  sendLink: {
    id: "app.sendLink",
    defaultMessage: "Send reset link",
    description: "Send reset link",
  },
  inbox: {
    id: "app.inbox",
    defaultMessage: "Inbox",
    description: "Inbox",
  },
  yourListings: {
    id: "app.yourListings",
    defaultMessage: "Your listings",
    description: "Your Listings",
  },
  account: {
    id: "app.account",
    defaultMessage: "Account",
    description: "Account",
  },
  addListing: {
    id: "app.addListing",
    defaultMessage: "Add new",
    description: " Add New Listings Button",
  },
  profilePhoto: {
    id: "app.profilePhoto",
    defaultMessage: "Profile photo",
    description: "Profile Photo",
  },
  trustAndVerification: {
    id: "app.trustAndVerification",
    defaultMessage: "Trust and verification",
    description: "Trust and Verification",
  },
  reviews: {
    id: "app.reviews",
    defaultMessage: "Reviews",
    description: "Reviews",
  },
  viewProfile: {
    id: "app.viewProfile",
    defaultMessage: "View profile",
    description: "View Profile",
  },
  payoutPreferences: {
    id: "app.payoutPreferences",
    defaultMessage: "Payments & payouts",
    description: "Payments & payouts",
  },
  security: {
    id: "app.security",
    defaultMessage: "Security",
    description: "Security",
  },
  where: {
    id: "app.where",
    defaultMessage: "Where",
    description: "Where",
  },
  when: {
    id: "app.when",
    defaultMessage: "When",
    description: "When",
  },
  guest: {
    id: "app.guest",
    defaultMessage: "Guest",
    description: "Guests",
  },
  search: {
    id: "app.search",
    defaultMessage: "Search",
    description: "Search",
  },
  homeWhere: {
    id: "app.homeWhere",
    defaultMessage: "Destination, city, address",
    description: "Destination, city, address",
  },
  checkIn: {
    id: "app.checkIn",
    defaultMessage: "Check-in",
    description: "Check In",
  },
  checkOut: {
    id: "app.checkOut",
    defaultMessage: "Checkout",
    description: "Check Out",
  },
  checkOutShare: {
    id: "app.checkOutShare",
    defaultMessage: "Check out",
    description: "Check Out",
  },
  recommended: {
    id: "app.recommended",
    defaultMessage: "Recommended",
    description: "Recommended Home page Slider title",
  },
  mostViewed: {
    id: "app.mostViewed",
    defaultMessage: "Most viewed",
    description: "Most Viewed Home page Slider title",
  },
  popularLocation: {
    id: "app.popularLocation",
    defaultMessage: "Popular locations",
    description: "Popular Locations Home page Slider title",
  },
  location: {
    id: "app.location",
    defaultMessage: "Location",
    description: "Location",
  },
  dates: {
    id: "app.dates",
    defaultMessage: "Dates",
    description: "Dates",
  },
  selectDates: {
    id: "app.selectDates",
    defaultMessage: "Select dates",
    description: "Select dates",
  },
  roomType: {
    id: "app.roomType",
    defaultMessage: "Room type",
    description: "Type of Room",
  },
  priceRange: {
    id: "app.priceRange",
    defaultMessage: "Price range",
    description: "Price range",
  },
  priceTextForTotalPrice: {
    id: "app.priceTextForTotalPrice",
    defaultMessage:
      "This applies exclusively to the base price before fees and taxes",
    description:
      "This applies exclusively to the base price before fees and taxes",
  },
  instantBook: {
    id: "app.instantBook",
    defaultMessage: "Instant book",
    description: "Instant book",
  },
  instantBookInfo: {
    id: "app.instantBookInfo",
    defaultMessage: "Listings you can book without waiting for host approval.",
    description: "instant Book Information",
  },
  size: {
    id: "app.size",
    defaultMessage: "Size",
    description: "Size",
  },
  anyBed: {
    id: "app.anyBed",
    defaultMessage: "Any bedrooms",
    description: "Any bedrooms",
  },
  anyBath: {
    id: "app.anyBath",
    defaultMessage: "Any bathrooms",
    description: "Any bathrooms",
  },
  anyBeds: {
    id: "app.anyBeds",
    defaultMessage: "Any beds",
    description: "Any beds",
  },
  filter: {
    id: "app.filter",
    defaultMessage: "Filter",
    description: "Filter button",
  },
  seeHomes: {
    id: "app.seeHomes",
    defaultMessage: "See Homes",
    description: "See Homes",
  },
  rentals: {
    id: "app.rentals",
    defaultMessage: "Rentals",
    description: "Rentals",
  },
  aminities: {
    id: "app.aminities",
    defaultMessage: "Amenities",
    description: "Amenities",
  },
  facilities: {
    id: "app.facilities",
    defaultMessage: "Facilities",
    description: "Facilities",
  },
  houseRules: {
    id: "app.houseRules",
    defaultMessage: "House rules",
    description: "House Rules",
  },
  cancel: {
    id: "app.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  applyFilters: {
    id: "app.applyFilters",
    defaultMessage: "Apply",
    description: "Apply button",
  },
  filters: {
    id: "app.filters",
    defaultMessage: "Filters",
    description: "Filters button for mobile",
  },
  noResultsTitle: {
    id: "app.noResultsTitle",
    defaultMessage: "No result",
    description: "No results message for search listing",
  },
  noResultsSubTitle: {
    id: "app.noResultsSubTitle",
    defaultMessage: "Try adjusting your search. Here are some idea",
    description: "No results message for search listing",
  },
  noResultsTerms1: {
    id: "app.noResultsTerms1",
    defaultMessage:
      "Change your filters or dates. Search for a specific city, address.",
    description: "No results message for search listing",
  },
  aboutListing: {
    id: "app.aboutListing",
    defaultMessage: "About this listing",
    description: "View listing description",
  },
  contactHost: {
    id: "app.contactHost",
    defaultMessage: "Contact host",
    description: "Contact host button and link",
  },
  theSpace: {
    id: "app.theSpace",
    defaultMessage: "The space",
    description: "The space",
  },
  bedrooms: {
    id: "app.bedrooms",
    defaultMessage: "Bedrooms",
    description: "The space bedrooms",
  },
  bathrooms: {
    id: "app.bathrooms",
    defaultMessage: "Bathrooms",
    description: "The space bathrooms",
  },
  bed: {
    id: "app.bed",
    defaultMessage: "Bed",
    description: "Bed",
  },
  beds: {
    id: "app.beds",
    defaultMessage: "Beds",
    description: "The space beds",
  },
  sharedSpaces: {
    id: "app.sharedSpaces",
    defaultMessage: "Shared spaces",
    description: "View listing shared spaces",
  },
  cancellations: {
    id: "app.cancellations",
    defaultMessage: "Cancelation policy",
    description: "View listing cancelations",
  },
  viewDetails: {
    id: "app.viewDetails",
    defaultMessage: "View details",
    description: "Cancelations view details",
  },
  safetyFeatures: {
    id: "app.safetyFeatures",
    defaultMessage: "Safety amenities",
    description: "View listing",
  },
  availability: {
    id: "app.availability",
    defaultMessage: "Availability",
    description: "View listing availability",
  },
  in: {
    id: "app.in",
    defaultMessage: "in",
    description: "in",
  },
  guests: {
    id: "app.guests",
    defaultMessage: "Guests",
    description: "Guests",
  },
  bedroom: {
    id: "app.bedroom",
    defaultMessage: "Bedroom",
    description: "Bedroom",
  },
  noReviewTitle: {
    id: "app.noReviewTitle",
    defaultMessage: "This place would love your review",
    description: "View listing no review title",
  },
  noReviewInfo: {
    id: "app.noReviewInfo",
    defaultMessage:
      "When you book this place, here’s where your review will show up!",
    description: "View listing no review info",
  },
  loadMore: {
    id: "app.loadMore",
    defaultMessage: "Load more",
    description: "Load more button",
  },
  viewCountInfo: {
    id: "app.viewCountInfo",
    defaultMessage: "This home is on people minds.",
    description: "View listing view count details",
  },
  viewCountTotal1: {
    id: "app.viewCountTotal1",
    defaultMessage: "It’s been viewed",
    description: "View listing view count details",
  },
  viewCountTotal2: {
    id: "app.viewCountTotal2",
    defaultMessage: "times in the past week.",
    description: "View listing view count details",
  },
  neighborhood: {
    id: "app.neighborhood",
    defaultMessage: "The neighborhood",
    description: "View listing neighborhood",
  },
  neighborhoodInfo: {
    id: "app.neighborhoodInfo",
    defaultMessage:
      "Exact location information is provided after a booking is confirmed.",
    description: "View listing neighborhoodInfo",
  },
  joinedIn: {
    id: "app.joinedIn",
    defaultMessage: "Joined in",
    description: "View listing host joining date",
  },
  contactHostinfo1: {
    id: "app.contactHostinfo1",
    defaultMessage: "Make sure you share the following",
    description: "Contact host instructions",
  },
  contactHostinfo2: {
    id: "app.contactHostinfo2",
    defaultMessage: "Tell",
    description: "Contact host instructions",
  },
  contactHostinfo3: {
    id: "app.contactHostinfo3",
    defaultMessage: "a little about yourself",
    description: "Contact host instructions",
  },
  contactHostinfo4: {
    id: "app.contactHostinfo4",
    defaultMessage: "What brings you to",
    description: "Contact host instructions",
  },
  contactHostinfo5: {
    id: "app.contactHostinfo5",
    defaultMessage: "Who’s joining with you?",
    description: "Contact host instructions",
  },
  contactHostinfo6: {
    id: "app.contactHostinfo6",
    defaultMessage: "What do you love about this listing? Mention it",
    description: "Contact host instructions",
  },
  contactHostDate: {
    id: "app.contactHostDate",
    defaultMessage: "When are you travelling?",
    description: "Contact host dates title",
  },
  hostErrorMessage1: {
    id: "app.hostErrorMessage1",
    defaultMessage: "Please specify check-in and checkout",
    description: "Contact host error message",
  },
  hostErrorMessage2: {
    id: "app.hostErrorMessage2",
    defaultMessage: "Those dates are not available",
    description: "Contact host error message",
  },
  hostErrorMessage3: {
    id: "app.hostErrorMessage3",
    defaultMessage: "You can contact the host now",
    description: "Contact host error message",
  },
  chooseGuests: {
    id: "app.chooseGuests",
    defaultMessage: "Choose guests",
    description: "Contact host choose guests dropdown",
  },
  textBoxMessage: {
    id: "app.textBoxMessage",
    defaultMessage: "Enter your message...",
    description: "Contact host text box start your message",
  },
  sendMessage: {
    id: "app.sendMessage",
    defaultMessage: "Send message",
    description: "Contact host button",
  },
  emptySpace: {
    id: "app.emptySpace",
    defaultMessage: "Invalid input ",
    description: "emptySpace",
  },
  maximumCharcterLeft: {
    id: "app.maximumCharcterLeft",
    defaultMessage: "characters left.",
    description: "maximumCharcterLeft",
  },
  serviceFee: {
    id: "app.serviceFee",
    defaultMessage: "Service fee",
    description: "Bill details",
  },
  cleaningFee: {
    id: "app.cleaningFee",
    defaultMessage: "Cleaning fee",
    description: "Bill details",
  },
  total: {
    id: "app.total",
    defaultMessage: "Total",
    description: "Bill details",
  },
  slotTotal: {
    id: "app.slotTotal",
    defaultMessage: "Slot Total",
    description: "Slot Bill details",
  },
  nights: {
    id: "app.nights",
    defaultMessage: "nights",
    description: "Bill details",
  },
  viewOtherListings: {
    id: "app.viewOtherListings",
    defaultMessage: "View other listings",
    description: "Booking button",
  },
  book: {
    id: "app.book",
    defaultMessage: "Book",
    description: "Booking button",
  },
  requestToBook: {
    id: "app.requestToBook",
    defaultMessage: "Request to Book",
    description: "Booking button",
  },
  maximumStay: {
    id: "app.maximumStay",
    defaultMessage: "Maximum stay is",
    description: "Maximum day error",
  },
  perNight: {
    id: "app.perNight",
    defaultMessage: "Night",
    description: "Per Night",
  },
  bookingInfo: {
    id: "app.bookingInfo",
    defaultMessage: "You won’t be charged yet",
    description: "You won’t be charged yet",
  },
  cancellationPolicies: {
    id: "app.cancellationPolicies",
    defaultMessage: "Cancelation policies",
    description: "Cancelation policies",
  },
  flexible: {
    id: "app.flexible",
    defaultMessage: "Flexible",
    description: "Flexible",
  },
  moderate: {
    id: "app.moderate",
    defaultMessage: "Moderate",
    description: "Moderate",
  },
  strict: {
    id: "app.strict",
    defaultMessage: "Strict",
    description: "Strict",
  },
  addProfilePhoto: {
    id: "app.addProfilePhoto",
    defaultMessage: "Add your profile photo",
    description: "Avatar upload title",
  },
  uploadInfo: {
    id: "app.uploadInfo",
    defaultMessage:
      "This helps your host recognize you when you meet, so make sure it shows your face.",
    description: "Avatar upload info",
  },
  continue: {
    id: "app.continue",
    defaultMessage: "Continue",
    description: "Continue button",
  },
  dropzoneUpload: {
    id: "app.dropzoneUpload",
    defaultMessage: "Upload a file from your computer",
    description: "Dropzone upload info",
  },
  meetupTitle: {
    id: "app.meetupTitle",
    defaultMessage: "Get ready to book with",
    description: "Get ready to book with",
  },
  meetupInfo1: {
    id: "app.meetupInfo1",
    defaultMessage:
      "We ask everyone to confirm a few things before travelling or hosting.",
    description: "Meetup information",
  },
  meetupInfo2: {
    id: "app.meetupInfo2",
    defaultMessage: "You’ll only have to do this once.",
    description: "Meetup information",
  },
  next: {
    id: "app.next",
    defaultMessage: "Next",
    description: "Next button",
  },
  checkEmail: {
    id: "app.checkEmail",
    defaultMessage: "Check your email",
    description: "Email verification title",
  },
  verificationInfo1: {
    id: "app.verificationInfo1",
    defaultMessage: "Tap the link in the email we sent you.",
    description: "Verification info",
  },
  verificationInfo2: {
    id: "app.verificationInfo2",
    defaultMessage:
      "Confirming your email address helps us send you trip information.",
    description: "Verification info",
  },
  resendEmail: {
    id: "app.resendEmail",
    defaultMessage: "Resend email",
    description: "Resend email button",
  },
  aboutYourTrip: {
    id: "app.aboutYourTrip",
    defaultMessage: "Tell your host about your trip",
    description: "Payment About Your Trip",
  },
  sayHello: {
    id: "app.sayHello",
    defaultMessage: "Say hello to your host and tell them why you’re coming",
    description: "Payment page info",
  },
  descriptionInfo: {
    id: "app.descriptionInfo",
    defaultMessage:
      "Let the host know why you're travelling and when you'll check-in.",
    description: "Payment description information",
  },
  payment: {
    id: "app.payment",
    defaultMessage: "Payment",
    description: "Payment",
  },
  paymentInfo: {
    id: "app.paymentInfo",
    defaultMessage:
      "You’ll only be charged if your request is accepted by the host.They’ll have 24 hours to accept or decline.",
    description: "Payment information",
  },
  paymentCurrency: {
    id: "app.paymentCurrency",
    defaultMessage: "Payment currency",
    description: "Payment currency",
  },
  chooseCurrency: {
    id: "app.chooseCurrency",
    defaultMessage: "Choose currency",
    description: "Choose Currency",
  },
  paymentType: {
    id: "app.paymentType",
    defaultMessage: "Payment type",
    description: "Payment Type",
  },
  paypal: {
    id: "app.paypal",
    defaultMessage: "PayPal",
    description: "PayPal",
  },
  loginInfo: {
    id: "app.loginInfo",
    defaultMessage:
      "Log into PayPal. You will be able to review your request before it is final.",
    description: "Login information",
  },
  hostedBy: {
    id: "app.hostedBy",
    defaultMessage: "Hosted by",
    description: "Hosted by",
  },
  cancellationPolicy: {
    id: "app.cancellationPolicy",
    defaultMessage: "Cancelation policy",
    description: "Cancelation policy",
  },
  deleteListing: {
    id: "app.deleteListing",
    defaultMessage: "Delete this listing",
    description: "Manage listing delete this listing",
  },
  deleteListingInfo: {
    id: "app.deleteListingInfo",
    defaultMessage:
      "Are you sure you want to delete this listing? You cannot reverse this action.",
    description: "Manage listing delete listing information",
  },
  delete: {
    id: "app.delete",
    defaultMessage: "Delete",
    description: "Manage listing Delete button",
  },
  goBack: {
    id: "app.goBack",
    defaultMessage: "Go Back",
    description: "Manage listing Go back button",
  },
  progressBarText1: {
    id: "app.progressBarText1",
    defaultMessage: "You’re",
    description: "Manage listing Progress bar text",
  },
  progressBarText2: {
    id: "app.progressBarText2",
    defaultMessage: "done with your listing",
    description: "Manage listing Progress bar text",
  },
  listingUpdateInfo: {
    id: "app.listingUpdateInfo",
    defaultMessage: "Last updated on",
    description: "Manage listing update info",
  },
  editListing: {
    id: "app.editListing",
    defaultMessage: "Edit listing",
    description: "Manage listing button",
  },
  finishListing: {
    id: "app.finishListing",
    defaultMessage: "Finish the listings",
    description: "Manage listing button",
  },
  preview: {
    id: "app.preview",
    defaultMessage: "Preview",
    description: "Manage listing button",
  },
  listed: {
    id: "app.listed",
    defaultMessage: "Listed",
    description: "Manage listing dropdown",
  },
  unListed: {
    id: "app.unListed",
    defaultMessage: "Unlisted",
    description: "Manage listing dropdown",
  },
  status: {
    id: "app.status",
    defaultMessage: "Status",
    description: "Status",
  },
  details: {
    id: "app.details",
    defaultMessage: "Details",
    description: "Details",
  },
  noList: {
    id: "app.noList",
    defaultMessage: "No list found",
    description: "No List Found",
  },
  notexist: {
    id: "app.notexist",
    defaultMessage: "Guest profile doesn't exist",
    description: "Guest profile not exist",
  },
  messageHistroy: {
    id: "app.messageHistroy",
    defaultMessage: "Message history",
    description: "Message History",
  },
  contactSupport: {
    id: "app.contactSupport",
    defaultMessage: "Contact Support",
    description: "Contact Support",
  },
  viewItinerary: {
    id: "app.viewItinerary",
    defaultMessage: "View itinerary",
    description: "View itinerary",
  },
  viewReceipt: {
    id: "app.viewReceipt",
    defaultMessage: "View Receipt",
    description: "View receipt",
  },
  approve: {
    id: "app.approve",
    defaultMessage: "Approve",
    description: "Approve",
  },
  decline: {
    id: "app.decline",
    defaultMessage: "Decline",
    description: "Decline",
  },
  emailAlreadyExists: {
    id: "app.emailAlreadyExists",
    defaultMessage:
      "That email address is already in use. Please choose different one!",
    description: "Email Address is already exist",
  },
  gender: {
    id: "app.gender",
    defaultMessage: "Gender",
    description: "Gender Field",
  },
  genderMale: {
    id: "app.genderMale",
    defaultMessage: "Male",
    description: "Gender Male",
  },
  genderFemale: {
    id: "app.genderFemale",
    defaultMessage: "Female",
    description: "Gender Female",
  },
  genderOther: {
    id: "app.genderOther",
    defaultMessage: "Other",
    description: "Gender Other",
  },
  preferredLanguage: {
    id: "app.preferredLanguage",
    defaultMessage: "Preferred language",
    description: "Preferred language",
  },
  preferredCurrency: {
    id: "app.preferredCurrency",
    defaultMessage: "Preferred currency",
    description: "Preferred currency",
  },
  phoneNumber: {
    id: "app.phoneNumber",
    defaultMessage: "Phone number",
    description: "Phone number",
  },
  phoneNumberInvalid: {
    id: "app.phoneNumberInvalid",
    defaultMessage: "Invalid Phone number",
    description: "Phone number is Invalid",
  },
  phoneNumberLengthInvalid: {
    id: "app.phoneNumberLengthInvalid",
    defaultMessage: "Mobile Number must be 30 digits or less",
    description: "Mobile Number must be 30 digits or less",
  },
  liveLocation: {
    id: "app.liveLocation",
    defaultMessage: "Location",
    description: "location",
  },
  info: {
    id: "app.info",
    defaultMessage: "Describe yourself",
    description: "Describe yourself",
  },
  notLoggedIn: {
    id: "app.notLoggedIn",
    defaultMessage: "You are not logged In. Please login to continue!",
    description: "Wrong went wrong",
  },
  save: {
    id: "app.save",
    defaultMessage: "Save",
    description: "Save Button",
  },
  birthDayRequired: {
    id: "app.birthDayRequired",
    defaultMessage: "Select your birth date to continue",
    description: "birthDayRequired",
  },
  Required: {
    id: "app.Required",
    defaultMessage: "*Required",
    description: "Required Heading",
  },
  editProfileInfo: {
    id: "app.editProfileInfo",
    defaultMessage: "Edit your personal info",
    description: "Edit your personal info",
  },
  lastNameInfo: {
    id: "app.lastNameInfo",
    defaultMessage:
      "This will be considered while showing the booking informations.",
    description: "lastNameInfo",
  },
  learnMore: {
    id: "app.learnMore",
    defaultMessage: "Learn more",
    description: "learnMore",
  },
  phoneNumberInfo: {
    id: "app.phoneNumberInfo",
    defaultMessage:
      "This is only shared once you have a confirmed booking with another user. This is how we can all get in touch.",
    description: "phoneNumberInfo",
  },
  verifiedInfo: {
    id: "app.verifiedInfo",
    defaultMessage: "Verified Info",
    description: "Verified Info",
  },
  verifiedEmail: {
    id: "app.verifiedEmail",
    defaultMessage: "You have verified your email address",
    description: "You have verified your email address",
  },
  disconnect: {
    id: "app.disconnect",
    defaultMessage: "Disconnect",
    description: "Disconnect button",
  },
  googleInfo: {
    id: "app.googleInfo",
    defaultMessage:
      "Connect your account to the Google account for simplicity and ease.",
    description: "Google information",
  },
  notVerifiedDetails: {
    id: "app.notVerifiedDetails",
    defaultMessage: "You haven't verified any of the below items",
    description: "Not verified information",
  },
  notVerifiedInfo: {
    id: "app.notVerifiedInfo",
    defaultMessage: "Not Verified Info",
    description: "Not Verified Info",
  },
  pleaseVerify: {
    id: "app.pleaseVerify",
    defaultMessage: "Please verify your email address.",
    description: "Verify your email address.",
  },
  connect: {
    id: "app.connect",
    defaultMessage: "Connect",
    description: "Connect button",
  },
  reviewTitle: {
    id: "app.reviewTitle",
    defaultMessage: "Thank you for reviewing",
    description: "Review",
  },
  reviewTitle2: {
    id: "app.reviewTitle2",
    defaultMessage:
      "Thank you for reviewing your guest. Your review will be visible on their profile.",
    description: "Review",
  },
  finish: {
    id: "app.finish",
    defaultMessage: "Finish",
    description: "Finish button",
  },
  reviewPageTitle: {
    id: "app.reviewPageTitle",
    defaultMessage: "Describe your experience",
    description: "Review",
  },
  reviewPageTitle1: {
    id: "app.reviewPageTitle1",
    defaultMessage: "* Your review will be public on your guest profile",
    description: "Review",
  },
  reviewRating: {
    id: "app.reviewRating",
    defaultMessage: "Overall Rating",
    description: "Review",
  },
  submit: {
    id: "app.submit",
    defaultMessage: "Submit",
    description: "Submit button",
  },
  reviewError1: {
    id: "app.reviewError1",
    defaultMessage: "Please write your review!",
    description: "Review",
  },
  reviewError2: {
    id: "app.reviewError2",
    defaultMessage: "Please rate your review!",
    description: "Review",
  },
  response: {
    id: "app.response",
    defaultMessage: "Response",
    description: "Review",
  },
  reviewPanelTitle2: {
    id: "app.reviewPanelTitle2",
    defaultMessage: "Reviews by you",
    description: "Review",
  },
  submitReviewFor: {
    id: "app.submitReviewFor",
    defaultMessage: "Write a review for",
    description: "Review",
  },
  writeReview: {
    id: "app.writeReview",
    defaultMessage: "Write a review",
    description: "Review",
  },
  viewLitneray: {
    id: "app.viewLitneray",
    defaultMessage: "View itinerary",
    description: "Review",
  },
  hey: {
    id: "app.hey",
    defaultMessage: "Hey I’m",
    description: "View profile",
  },
  reportUser: {
    id: "app.reportUser",
    defaultMessage: "Report this user",
    description: "View profile",
  },
  noVerifications: {
    id: "app.noVerifications",
    defaultMessage: "No verifications yet",
    description: "View profile",
  },
  addVerifications: {
    id: "app.addVerifications",
    defaultMessage: "Add verifications",
    description: "View profile",
  },
  emailConfirmed: {
    id: "app.emailConfirmed",
    defaultMessage: "Email confirmed",
    description: "View profile",
  },
  googleConnected: {
    id: "app.googleConnected",
    defaultMessage: "Google connected",
    description: "View profile",
  },
  moreVerifications: {
    id: "app.moreVerifications",
    defaultMessage: "Add more verifications",
    description: "View profile",
  },
  addPayout: {
    id: "app.addPayout",
    defaultMessage: "Add payout method",
    description: "Payout",
  },
  address1: {
    id: "app.address1",
    defaultMessage: "Address Line 1",
    description: "Payout Form",
  },
  address2: {
    id: "app.address2",
    defaultMessage: "Address Line 2",
    description: "Payout Form",
  },
  city: {
    id: "app.city",
    defaultMessage: "City",
    description: "Payout Form",
  },
  state: {
    id: "app.state",
    defaultMessage: "State / Province",
    description: "Payout Form",
  },
  zipCode: {
    id: "app.zipCode",
    defaultMessage: "ZIP code / Postal code",
    description: "Payout Form",
  },
  payoutError5: {
    id: "app.payoutError5",
    defaultMessage: "Enter valid email address",
    description: "Payout Form",
  },
  country: {
    id: "app.country",
    defaultMessage: "Country",
    description: "Payout Form",
  },
  chooseCountry: {
    id: "app.chooseCountry",
    defaultMessage: "Choose country",
    description: "Payout Form",
  },
  back: {
    id: "app.back",
    defaultMessage: "Back",
    description: "Back button",
  },
  paypalIntro1: {
    id: "app.paypalIntro1",
    defaultMessage:
      "PayPal is an online payment processing service that allows you to receive payments from",
    description: "Paypal intro",
  },
  paypalIntro2: {
    id: "app.paypalIntro2",
    defaultMessage: "To use PayPal with",
    description: "Paypal intro",
  },
  paypalIntro3: {
    id: "app.paypalIntro3",
    defaultMessage: "you must have an existing account with PayPal.",
    description: "Paypal intro",
  },
  paypalIntro4: {
    id: "app.paypalIntro4",
    defaultMessage: "Learn more about PayPal",
    description: "Paypal intro",
  },
  paypalEmail: {
    id: "app.paypalEmail",
    defaultMessage:
      "Enter the email address associated with your existing PayPal account.",
    description: "Paypal email",
  },
  paypalCurrency: {
    id: "app.paypalCurrency",
    defaultMessage: "In what currency would you like to be paid?",
    description: "Paypal currency",
  },
  payoutIntro1: {
    id: "app.payoutIntro1",
    defaultMessage:
      "Payouts for reservations are released to you the day after your guest checks in, and it takes some additional time for the money to arrive depending on your payout method.",
    description: "Payout intro",
  },
  payoutIntro2: {
    id: "app.payoutIntro2",
    defaultMessage:
      "We can send money to people in United States with these payout methods. Which do you prefer?",
    description: "Payout intro",
  },
  payoutTitle: {
    id: "app.payoutTitle",
    defaultMessage: "Methods",
    description: "Payout header",
  },
  payoutTitle1: {
    id: "app.payoutTitle1",
    defaultMessage: "Processed in",
    description: "Payout header",
  },
  payoutTitle2: {
    id: "app.payoutTitle2",
    defaultMessage: "Fees",
    description: "Payout header",
  },
  payoutTitle3: {
    id: "app.payoutTitle3",
    defaultMessage: "Currency",
    description: "Payout header",
  },
  payoutTitle4: {
    id: "app.payoutTitle4",
    defaultMessage: "Details",
    description: "Payout header",
  },
  noPaymentFound: {
    id: "app.noPaymentFound",
    defaultMessage: "No payment method found",
    description: "No payment",
  },
  payoutMethod: {
    id: "app.payoutMethod",
    defaultMessage: "Payout methods",
    description: "Payout method",
  },
  addPayoutMethod: {
    id: "app.addPayoutMethod",
    defaultMessage: "To get paid, you need to set up a payout method",
    description: "Payout Method",
  },
  paymentReleaseInfo1: {
    id: "app.paymentReleaseInfo1",
    defaultMessage:
      "releases payouts about 24 hours after a guest’s scheduled check-in time. The time it takes for the funds to appear in your account depends on your payout method.",
    description: "Payout Method",
  },
  payoutTitleBlock1: {
    id: "app.payoutTitleBlock1",
    defaultMessage:
      "When you receive a payment for a reservation, we call that payment to you a “payout”. Our secure payment system supports several payout methods, which can be setup and edited here. Your available payout options and currencies differ by country.",
    description: "Payout Method",
  },
  options: {
    id: "app.options",
    defaultMessage: "Options",
    description: "Payout Method",
  },
  default: {
    id: "app.default",
    defaultMessage: "Default",
    description: "Payout Method",
  },
  ready: {
    id: "app.ready",
    defaultMessage: "Ready",
    description: "Payout Method",
  },
  setDefault: {
    id: "app.setDefault",
    defaultMessage: "Set Default",
    description: "Payout Method",
  },
  remove: {
    id: "app.remove",
    defaultMessage: "Remove",
    description: "Payout Method",
  },
  directDeposit: {
    id: "app.directDeposit",
    defaultMessage: "Direct Deposit, PayPal, etc..",
    description: "Payout Method",
  },
  transactionPayout: {
    id: "app.transactionPayout",
    defaultMessage: "Payout",
    description: "Transaction history",
  },
  transferTo: {
    id: "app.transferTo",
    defaultMessage: "Transfer to",
    description: "Transaction history",
  },
  transferDate: {
    id: "app.transferDate",
    defaultMessage: "Date",
    description: "Date",
  },
  transferType: {
    id: "app.transferType",
    defaultMessage: "Type",
    description: "Type",
  },
  transferAmount: {
    id: "app.transferAmount",
    defaultMessage: "Amount",
    description: "Amount",
  },
  paidOut: {
    id: "app.paidOut",
    defaultMessage: "Paid Out",
    description: "Paid Out",
  },
  reservation: {
    id: "app.reservation",
    defaultMessage: "Reservation",
    description: "Reservation",
  },
  payTo: {
    id: "app.payTo",
    defaultMessage: "Pay to",
    description: "Pay to",
  },
  grossEarnings: {
    id: "app.grossEarnings",
    defaultMessage: "Gross earnings",
    description: "Gross earnings",
  },
  allListings: {
    id: "app.allListings",
    defaultMessage: "All listings",
    description: "All listings",
  },
  transactionsAddPayout: {
    id: "app.transactionsAddPayout",
    defaultMessage: "Add Payout",
    description: "Transaction payout",
  },
  completedTransactions: {
    id: "app.completedTransactions",
    defaultMessage: "Completed",
    description: "Completed",
  },
  futureTransactions: {
    id: "app.futureTransactions",
    defaultMessage: "Future transactions",
    description: "Completed transactions",
  },
  exportCSV: {
    id: "app.exportCSV",
    defaultMessage: "Export to CSV",
    description: "Export to CSV",
  },
  changePassword: {
    id: "app.changePassword",
    defaultMessage: "Change your password",
    description: "Change Your Password",
  },
  newPassword: {
    id: "app.newPassword",
    defaultMessage: "New password",
    description: "New password",
  },
  confirmPassword: {
    id: "app.confirmPassword",
    defaultMessage: "Confirm new password",
    description: "Confirm new password",
  },
  updatePassword: {
    id: "app.updatePassword",
    defaultMessage: "Update password",
    description: "Update password",
  },
  passwordError3: {
    id: "app.passwordError3",
    defaultMessage: "New Password must be minimum 8 characters",
    description: "Password Error",
  },
  passwordError5: {
    id: "app.passwordError5",
    defaultMessage: "Confirm Password must be minimum 8 characters",
    description: "Password Error",
  },
  noMessagesTitle: {
    id: "app.noMessagesTitle",
    defaultMessage: "No Messages yet.",
    description: "Inbox",
  },
  noMessagesTitle1: {
    id: "app.noMessagesTitle1",
    defaultMessage:
      "When hosts respond to your inquiries or booking requests, you'll see their messages here.",
    description: "Inbox",
  },
  noMessagesTitle2: {
    id: "app.noMessagesTitle2",
    defaultMessage:
      "When guests respond to your inquiries or booking requests, you'll see their messages here.",
    description: "Inbox",
  },
  traveling: {
    id: "app.traveling",
    defaultMessage: "Travelling",
    description: "Traveling",
  },
  receipt: {
    id: "app.receipt",
    defaultMessage: "Receipt",
    description: "Receipt",
  },
  customerReceipt: {
    id: "app.customerReceipt",
    defaultMessage: "Customer receipt",
    description: "Customer Receipt",
  },
  confirmationCode: {
    id: "app.confirmationCode",
    defaultMessage: "Confirmation code",
    description: "Confirmation Code",
  },
  name: {
    id: "app.name",
    defaultMessage: "Name",
    description: "Name",
  },
  duration: {
    id: "app.duration",
    defaultMessage: "Duration",
    description: "Duration",
  },
  accommodationType: {
    id: "app.accommodationType",
    defaultMessage: "Accommodation type",
    description: "Accommodation Type",
  },
  accommodationAddress: {
    id: "app.accommodationAddress",
    defaultMessage: "Accommodation address",
    description: "Accommodation Address",
  },
  reservationCharges: {
    id: "app.reservationCharges",
    defaultMessage: "Reservation charges",
    description: "Reservation Charges",
  },
  paymentReceived: {
    id: "app.paymentReceived",
    defaultMessage: "Payment Received",
    description: "Payment Received:",
  },
  receiptInfo1: {
    id: "app.receiptInfo1",
    defaultMessage:
      "is authorized to accept accomodation fees on behalf of the Host as their limited agent.",
    description: "Receipt information",
  },
  receiptInfo2: {
    id: "app.receiptInfo2",
    defaultMessage:
      "This means that your payment obligation to the Host is satisfied by your payment to",
    description: "Receipt information",
  },
  receiptInfo3: {
    id: "app.receiptInfo3",
    defaultMessage:
      "Any of the Host's disagreements by the Host regarding that payment must be settled between the Host and",
    description: "Receipt information",
  },
  itinerayTitle: {
    id: "app.itinerayTitle",
    defaultMessage: "You’re going to",
    description: "Itinerary",
  },
  address: {
    id: "app.address",
    defaultMessage: "Address",
    description: "Itinerary",
  },
  viewListing: {
    id: "app.viewListing",
    defaultMessage: "View listing",
    description: "View Listing",
  },
  messageHost: {
    id: "app.messageHost",
    defaultMessage: "Message Host",
    description: "Itinerary",
  },
  billing: {
    id: "app.billing",
    defaultMessage: "Billing",
    description: "Itinerary",
  },
  messageAction1: {
    id: "app.messageAction1",
    defaultMessage: "You messaged",
    description: "View message",
  },
  messageAction2: {
    id: "app.messageAction2",
    defaultMessage: "about their listing",
    description: "View message",
  },
  messageActionInfo: {
    id: "app.messageActionInfo",
    defaultMessage:
      "Most hosts respond within 24 hours. If this listing is your top choice, enter your payment information to officially request a reservation.",
    description: "View message",
  },
  RequestToBook: {
    id: "app.RequestToBook",
    defaultMessage: "Request to book",
    description: "Request to book",
  },
  messageAction3: {
    id: "app.messageAction3",
    defaultMessage: "Your booking request sent to",
    description: "View message",
  },
  messageAction4: {
    id: "app.messageAction4",
    defaultMessage: "for their listing",
    description: "View message",
  },
  requestDeclined: {
    id: "app.requestDeclined",
    defaultMessage: "Request declined",
    description: "Request declined",
  },
  guestDeclinedInfo: {
    id: "app.guestDeclinedInfo",
    defaultMessage:
      "Your request is declined by host. You can choose different dates or search for other listing.",
    description: "Request declined",
  },
  requestApprovedAction1: {
    id: "app.requestApprovedAction1",
    defaultMessage: "Your booking request approved by",
    description: "Request approved",
  },
  requestTimeInfo1: {
    id: "app.requestTimeInfo1",
    defaultMessage: "You have to book within",
    description: "Request approved",
  },
  requestTimeInfo2: {
    id: "app.requestTimeInfo2",
    defaultMessage: "or it will expire.",
    description: "Request approved",
  },
  bookingConfirmedInfo1: {
    id: "app.bookingConfirmedInfo1",
    defaultMessage: "Your booking is confirmed with",
    description: "Booking confirmed",
  },
  bookingCanceledInfo: {
    id: "app.bookingCanceledInfo",
    defaultMessage:
      "You can contact your host and do necessary arrangements or you can cancel your reservation.",
    description: "Booking canceld",
  },
  bookingExpiredTitle: {
    id: "app.bookingExpiredTitle",
    defaultMessage: "Your booking request has expired",
    description: "Booking expired",
  },
  bookingExpiredInfo: {
    id: "app.bookingExpiredInfo",
    defaultMessage:
      "Try sending request again or send an inquiry to get in touch with the host",
    description: "Booking expired",
  },
  gotoListing: {
    id: "app.gotoListing",
    defaultMessage: "Go to listing",
    description: "Go to Listing",
  },
  bookingRequestCancel1: {
    id: "app.bookingRequestCancel",
    defaultMessage: "Booking request canceled",
    description: "Booking request canceled",
  },
  bookingRequestCancel2: {
    id: "app.bookingRequestCance2",
    defaultMessage: "Your booking request has been canceled.",
    description: "Your booking request has been canceled.",
  },
  hostAction1: {
    id: "app.hostAction1",
    defaultMessage: "Invite",
    description: "Host Action Block.",
  },
  hostAction2: {
    id: "app.hostAction2",
    defaultMessage: "to book by pre-approving this trip",
    description: "Host Action Block.",
  },
  hostAction3: {
    id: "app.hostAction3",
    defaultMessage: "Let",
    description: "Host Action Block.",
  },
  hostAction4: {
    id: "app.hostAction4",
    defaultMessage: "know these dates are available by pre-approving them.",
    description: "Host Action Block.",
  },
  hostResponseTime1: {
    id: "app.hostResponseTime1",
    defaultMessage: "Respond within",
    description: "Host Action Block.",
  },
  hostResponseTime2: {
    id: "app.hostResponseTime2",
    defaultMessage: "to maintain your response rate.",
    description: "Host Action Block.",
  },
  preApprove: {
    id: "app.preApprove",
    defaultMessage: "Pre-approve",
    description: "Pre-approve",
  },
  guestRequest: {
    id: "app.guestRequest",
    defaultMessage: "has sent a booking request for your listing.",
    description: "Guest Request",
  },
  requestApproved: {
    id: "app.requestApproved",
    defaultMessage: "Request approved",
    description: "Request Approved",
  },
  requestBookExpire: {
    id: "app.requestBookExpire",
    defaultMessage: "Booking request has been expired.",
    description: "Time To Expire",
  },
  timeToExpire: {
    id: "app.timeToExpire",
    defaultMessage:
      "Your guest have 24 hours to respond to this or it will get expired",
    description: "Time To Expire",
  },
  declinedInfo: {
    id: "app.declinedInfo",
    defaultMessage:
      "Make sure your calendar is up-to-date and that your listing page accurately reflects your house rules and requirements.",
    description: "Declined Info",
  },
  bookingIsConfirmed: {
    id: "app.bookingIsConfirmed",
    defaultMessage: "Booking is confirmed",
    description: "Booking is confirmed",
  },
  contactGuest: {
    id: "app.contactGuest",
    defaultMessage:
      "You can contact your guest and do necessary arrangements or you can cancel.",
    description: "Contact Guest",
  },
  bookingIsExpired: {
    id: "app.bookingIsExpired",
    defaultMessage: "Booking request is expired",
    description: "Booking request is expired",
  },
  bookingRequestCancel3: {
    id: "app.bookingRequestCance3",
    defaultMessage: "booking request is canceled.",
    description: "booking request is canceled.",
  },
  writeMessage: {
    id: "app.writeMessage",
    defaultMessage: "Write your message",
    description: "Write your message",
  },
  loadMoreMsg: {
    id: "app.loadMoreMsg",
    defaultMessage: "Load more messages",
    description: "Load More Messages",
  },
  noItmesFound: {
    id: "app.noItmesFound",
    defaultMessage: "No items found",
    description: "No items found",
  },
  messageStatus1: {
    id: "app.messageStatus1",
    defaultMessage: "Inquiry",
    description: "Message Status",
  },
  earnedAmount: {
    id: "app.earnedAmount",
    defaultMessage: "Earned amount",
    description: "Payment details",
  },
  missedEarnings: {
    id: "app.missedEarnings",
    defaultMessage: "Missed earnings",
    description: "Missed Earnings",
  },
  refundAmount: {
    id: "app.refundAmount",
    defaultMessage: "Refund amount",
    description: "Refund Amount",
  },
  cleaningPrice: {
    id: "app.cleaningPrice",
    defaultMessage: "Cleaning fee",
    description: "Payment details",
  },
  tax: {
    id: "app.tax",
    defaultMessage: "Taxes",
    description: "Payment details",
  },
  taxRefundToGuest: {
    id: "app.taxRefundToGuest",
    defaultMessage: "Refunded to guest",
    description: "Payment details",
  },
  taxPaidOutToHost: {
    id: "app.taxPaidOutToHost",
    defaultMessage: "Paid out to host",
    description: "Payment details",
  },
  subTotal: {
    id: "app.subTotal",
    defaultMessage: "Subtotal",
    description: "Payment details",
  },
  youEarn: {
    id: "app.youEarn",
    defaultMessage: "You earn",
    description: "Payment details",
  },
  tripDetails: {
    id: "app.tripDetails",
    defaultMessage: "Trip details",
    description: "Trip details",
  },
  noThreadFound: {
    id: "app.noThreadFound",
    defaultMessage:
      "No thread found! If you think something went wrong, please refresh the page",
    description: "View Message",
  },
  dashBoardHeader: {
    id: "app.dashBoardHeader",
    defaultMessage: "Welcome to",
    description: "Dashboard",
  },
  dashBoardInfo: {
    id: "app.dashBoardInfo",
    defaultMessage:
      "Check your messages, view upcoming trip information, and find travel inspiration all from your dashboard. Before booking your first stay, make sure to:",
    description: "Dashboard",
  },
  dashBoardInfo1: {
    id: "app.dashBoardInfo1",
    defaultMessage:
      "Upload a photo and write a short bio to help hosts get to know you before inviting you into their home.",
    description: "Dashboard",
  },
  allMessages: {
    id: "app.allMessages",
    defaultMessage: "All messages",
    description: "Dashboard",
  },
  hi: {
    id: "app.hi",
    defaultMessage: "Hi",
    description: "Hi",
  },
  letYouGetReady: {
    id: "app.letYouGetReady",
    defaultMessage: "Let’s get you ready to become a host.",
    description: "Let You Get Ready",
  },
  whatKindOfPlaceListing: {
    id: "app.whatKindOfPlaceListing",
    defaultMessage: "What kind of place are you listing?",
    description: "What Kind Of Place Listing",
  },
  whatTypeOfProperty: {
    id: "app.whatTypeOfProperty",
    defaultMessage: "What kind of property will you host?",
    description: "What Type Of Property",
  },
  whatGuestHave: {
    id: "app.whatGuestHave",
    defaultMessage: "What kind of place will guests have?",
    description: "What will guests have",
  },
  howManyRooms: {
    id: "app.howManyRooms",
    defaultMessage: "How many total rooms does your property have?",
    description: "How many total rooms does your property have?",
  },
  isPersonalHome: {
    id: "app.isPersonalHome",
    defaultMessage: "Is this your personal home?",
    description: "Is this your personal home?",
  },
  isPersonalHomeInfo: {
    id: "app.isPersonalHomeInfo",
    defaultMessage:
      "Guests like to know if this is a place you live and keep your personal belongings in.",
    description: "Personal Home Info",
  },
  skip: {
    id: "app.skip",
    defaultMessage: "Skip now",
    description: "Skip Button",
  },
  howManyGuests: {
    id: "app.howManyGuests",
    defaultMessage: "How many guests would you like to welcome?",
    description: "How many guests would you like to welcome?",
  },
  howManyBeds: {
    id: "app.howManyBeds",
    defaultMessage: "How many beds can guests use?",
    description: "How many beds can guests use?",
  },
  howManyBedrooms: {
    id: "app.howManyBedrooms",
    defaultMessage: "How many bedrooms can guests use?",
    description: "How many bedrooms can guests use?",
  },
  howManyBathrooms: {
    id: "app.howManyBathrooms",
    defaultMessage: "How many bathrooms?",
    description: "How many bathrooms?",
  },
  whereLocated: {
    id: "app.whereLocated",
    defaultMessage: "Is the pin in the right spot?",
    description: "Is the pin in the right spot?",
  },
  bathroom: {
    id: "app.bathroom",
    defaultMessage: "bathroom",
    description: "Bathroom",
  },
  street: {
    id: "app.street",
    defaultMessage: "Street address",
    description: "Street Address",
  },
  buildingName: {
    id: "app.buildingName",
    defaultMessage: "Apt, Suite, Bldg.",
    description: "Building Name",
  },
  zipcode: {
    id: "app.zipcode",
    defaultMessage: "ZIP code",
    description: "ZIP Code",
  },
  adminLoggedIn: {
    id: "app.adminLoggedIn",
    defaultMessage:
      "You are logged in as admin, you can't perform this action!",
    description: "adminLoggedIn",
  },
  whatamenities: {
    id: "app.whatamenities",
    defaultMessage: "Do you have any standout amenities?",
    description: "Amenities Headline",
  },
  safetyamenities: {
    id: "app.safetyamenities",
    defaultMessage: "Safety amenities",
    description: "Safety amenities Headline",
  },
  whatSpace: {
    id: "app.whatSpace",
    defaultMessage: "What about these guest favorites?",
    description: "What about these guest favorites?",
  },
  step2SubHeading: {
    id: "app.step2SubHeading",
    defaultMessage: "Set the scene",
    description: "STEP 2 Sub Heading",
  },
  step3SubHeading: {
    id: "app.step3SubHeading",
    defaultMessage: "Get ready for guests",
    description: "STEP 3 Sub Heading",
  },
  step1HeadingContent: {
    id: "app.step1HeadingContent",
    defaultMessage: "Beds, bathrooms, amenities, and more.",
    description: "STEP 1 Heading Content",
  },
  step2HeadingContent: {
    id: "app.step2HeadingContent",
    defaultMessage: "Photos, short description, title.",
    description: "STEP 2 Heading Content",
  },
  step3HeadingContent: {
    id: "app.step3HeadingContent",
    defaultMessage: "Booking settings, calendar, price.",
    description: "STEP 3 Heading Content",
  },
  mapWarning: {
    id: "app.mapWarning",
    defaultMessage: "Drag the map and reposition the pin",
    description: "Map Warning",
  },
  mapSuccess: {
    id: "app.mapSuccess",
    defaultMessage: "Great! Thank you!",
    description: "Map Success",
  },
  photos: {
    id: "app.photos",
    defaultMessage: "Upload your place photos",
    description: "Upload your place photos",
  },
  placeTitle: {
    id: "app.placeTitle",
    defaultMessage: "Create your title",
    description: "Title",
  },
  titleLabel: {
    id: "app.titleLabel",
    defaultMessage: "Listing title",
    description: "Title",
  },
  description: {
    id: "app.description",
    defaultMessage: "Create your description",
    description: "Description",
  },
  guestRequirementsTitle: {
    id: "app.guestRequirementsTitle",
    defaultMessage: "Review guest requirements",
    description: "Guest Requirements Title",
  },
  guestRequirementsDescription: {
    id: "app.guestRequirementsDescription",
    defaultMessage:
      "Require all guests to submit the following before they can book:",
    description: "Guest Requirements Description",
  },
  setHouseRules: {
    id: "app.setHouseRules",
    defaultMessage: "Set house rules for your guests",
    description: "House Rules",
  },
  reviewGuestBookTitle: {
    id: "app.reviewGuestBookTitle",
    defaultMessage: "How guests book: Instantly",
    description: "How guests book: Instantly",
  },
  reviewGuestBookDescription: {
    id: "app.reviewGuestBookDescription",
    defaultMessage: "Guests can book available days instantly as long as they:",
    description: "Guests can book available",
  },
  reviewGuestBookNote: {
    id: "app.reviewGuestBookNote",
    defaultMessage:
      "Guests who don’t meet your requirements must send a reservation request.",
    description: "Review Guests Note",
  },
  advanceNoticeCheckInTitle: {
    id: "app.advanceNoticeCheckInTitle",
    defaultMessage: "When can guests check-in?",
    description: "Advance Notice Check In Title",
  },
  advanceNoticeFrom: {
    id: "app.advanceNoticeFrom",
    defaultMessage: "From:",
    description: "From",
  },
  advanceNoticeTo: {
    id: "app.advanceNoticeTo",
    defaultMessage: "To:",
    description: "To",
  },
  advanceNoticeError: {
    id: "app.advanceNoticeError",
    defaultMessage: "From time should be earlier than To time",
    description: "Error Message",
  },
  advanceNoticeInvalid: {
    id: "app.advanceNoticeInvalid",
    defaultMessage: "Invalid input value",
    description: "Error Message",
  },
  calendar: {
    id: "app.calendar",
    defaultMessage: "Update your calendar",
    description: "calendar title",
  },
  basePrice: {
    id: "app.basePrice",
    defaultMessage: "Base price",
    description: "Base price",
  },
  basePriceLabel: {
    id: "app.basePriceLabel",
    defaultMessage: "Price per night",
    description: "Base price Label",
  },
  basePriceInvalid: {
    id: "app.basePriceInvalid",
    defaultMessage:
      "Oops! Base price is a required field. (Numbers only allowed (ex: 25))",
    description: "Base price Invalid",
  },
  cleaningPriceInvalid: {
    id: "app.cleaningPriceInvalid",
    defaultMessage: "Invalid Cleaning Price, only numbers(eg: 25) are allowed",
    description: "Cleaning price Invalid",
  },
  taxInvalid: {
    id: "app.taxInvalid",
    defaultMessage: "Invalid tax. The tax must be between 0% and 99%.",
    description: "Invalid tax",
  },
  currency: {
    id: "app.currency",
    defaultMessage: "Currency",
    description: "Currency",
  },
  discount: {
    id: "app.discount",
    defaultMessage: "Discounts",
    description: "Discounts",
  },
  discountLabel: {
    id: "app.discountLabel",
    defaultMessage: "% off",
    description: "Discount Label",
  },
  discountWeekly: {
    id: "app.discountWeekly",
    defaultMessage: "Weekly discount",
    description: "Weekly discount",
  },
  discountInvalid: {
    id: "app.discountInvalid",
    defaultMessage:
      "Invalid discount. The discount must be between 0% and 99%.",
    description: "Invalid discount",
  },
  discountMonthly: {
    id: "app.discountMonthly",
    defaultMessage: "Monthly discount",
    description: "Monthly discount",
  },
  localLaws: {
    id: "app.localLaws",
    defaultMessage: "Your local laws and taxes",
    description: "Local Laws",
  },
  photosPlaceholder: {
    id: "app.photosPlaceholder",
    defaultMessage: "Click here to upload photos or drag them in",
    description: "Photos Placeholder",
  },
  descriptionLabel: {
    id: "app.descriptionLabel",
    defaultMessage:
      "You'll always remember your time at this unique place to stay.",
    description: "Description Placeholder",
  },
  instantBookingTitle: {
    id: "app.instantBookingTitle",
    defaultMessage: "Increase your earnings with Instant Book",
    description: "Instant booking",
  },
  instantBookingInfo: {
    id: "app.instantBookingInfo",
    defaultMessage:
      "Instant Book can give your listing an edge.Not only do guests prefer to book instantly, we’re promoting Instant Book listings in search results.",
    description: "Instant booking",
  },
  whoCanBook: {
    id: "app.whoCanBook",
    defaultMessage: "Who can book instantly",
    description: "Instant booking",
  },
  whoCanBookInfo: {
    id: "app.whoCanBookInfo",
    defaultMessage:
      "Choose who can book available days without requesting approval.",
    description: "Instant booking",
  },
  whoCanBookInfo1: {
    id: "app.whoCanBookInfo1",
    defaultMessage:
      "Guests who meet your requirements and agree to your rules.",
    description: "Instant booking",
  },
  whoCanBookInfo2: {
    id: "app.whoCanBookInfo2",
    defaultMessage: "Anyone else must send a reservation request.",
    description: "Instant booking",
  },
  whoCanBookInfo3: {
    id: "app.whoCanBookInfo3",
    defaultMessage: "No one. All guests must send reservation requests.",
    description: "Instant booking",
  },
  maxDaysTitle: {
    id: "app.maxDaysTitle",
    defaultMessage: "Availability window",
    description: "Availability window",
  },
  datesDropDown: {
    id: "app.datesDropDown",
    defaultMessage: "Dates unavailable by default",
    description: "Booking Dropdown",
  },
  datesOption1: {
    id: "app.datesOption1",
    defaultMessage: "3 months into the future",
    description: "Booking Dropdown",
  },
  datesOption2: {
    id: "app.datesOption2",
    defaultMessage: "6 months into the future",
    description: "Booking Dropdown",
  },
  datesOption3: {
    id: "app.datesOption3",
    defaultMessage: "9 months into the future",
    description: "Booking Dropdown",
  },
  datesOption4: {
    id: "app.datesOption4",
    defaultMessage: "12 months into the future",
    description: "Booking Dropdown",
  },
  datesOption5: {
    id: "app.datesOption5",
    defaultMessage: "All future dates",
    description: "Booking Dropdown",
  },
  syncCalendars: {
    id: "app.syncCalendars",
    defaultMessage: "Sync calendars",
    description: "Sync calendars",
  },
  tripLength: {
    id: "app.tripLength",
    defaultMessage: "Trip length",
    description: "Trip length",
  },
  tripLengthError1: {
    id: "app.tripLengthError1",
    defaultMessage: "Minimum nights can't be higher than maximum nights",
    description: "Trip length",
  },
  tabPlaceType: {
    id: "app.tabPlaceType",
    defaultMessage: "Place type",
    description: "Tab bar",
  },
  setCover: {
    id: "app.setCover",
    defaultMessage: "Cover photo",
    description: "Cover Photo",
  },
  tabTitle: {
    id: "app.tabTitle",
    defaultMessage: "Title",
    description: "Tab bar",
  },
  guestRequirements: {
    id: "app.guestRequirements",
    defaultMessage: "Guest requirements",
    description: "Tab bar",
  },
  advanceNotice: {
    id: "app.advanceNotice",
    defaultMessage: "Check in & Cancelation",
    description: "Tab bar",
  },
  tabCalendar: {
    id: "app.tabCalendar",
    defaultMessage: "Calendar",
    description: "Tab bar",
  },
  tabPricing: {
    id: "app.tabPricing",
    defaultMessage: "Pricing",
    description: "Tab bar",
  },
  tabDiscount: {
    id: "app.tabDiscount",
    defaultMessage: "Discount",
    description: "Tab bar",
  },
  tabBooking: {
    id: "app.tabBooking",
    defaultMessage: "Booking",
    description: "Tab bar",
  },
  tabLocalLaws: {
    id: "app.tabLocalLaws",
    defaultMessage: "Local laws",
    description: "Tab bar",
  },
  readyToPublish: {
    id: "app.readyToPublish",
    defaultMessage: "* Your listing is ready to be published!",
    description: "Listing inde",
  },
  publishNow: {
    id: "app.publishNow",
    defaultMessage: "Publish",
    description: "Listing index",
  },
  previewListing: {
    id: "app.previewListing",
    defaultMessage: "Preview",
    description: "Listing index",
  },
  unPublishNow: {
    id: "app.unPublishNow",
    defaultMessage: "Unpublish",
    description: "Listing index",
  },
  panelReservation: {
    id: "app.panelReservation",
    defaultMessage: "Reservations",
    description: "Reservations",
  },
  transactions: {
    id: "app.transactions",
    defaultMessage: "transactions",
    description: "Transactions",
  },
  notFoundTitle: {
    id: "app.notFoundTitle",
    defaultMessage: "Uh-oh!",
    description: "Not Found",
  },
  notFoundSubTitle: {
    id: "app.notFoundSubTitle",
    defaultMessage: "We can't seem to find the page you're looking for!",
    description: "Not Found",
  },
  errorCode: {
    id: "app.errorCode",
    defaultMessage: "Error code: 404",
    description: "Not Found",
  },
  linksTitle: {
    id: "app.linksTitle",
    defaultMessage: "Here are some helpful links instead:",
    description: "Not Found",
  },
  hostingOn: {
    id: "app.hostingOn",
    defaultMessage: "Hosting on",
    description: "Not Found",
  },
  pageError: {
    id: "app.pageError",
    defaultMessage: "Error",
    description: "Page Error",
  },
  pageErrorInfo: {
    id: "app.pageErrorInfo",
    defaultMessage: "Sorry, a critical error occurred on this page.",
    description: "Page Error",
  },
  messageStatus2: {
    id: "app.messageStatus2",
    defaultMessage: "Pre-approved",
    description: "Message Status",
  },
  messageStatus3: {
    id: "app.messageStatus3",
    defaultMessage: "Declined",
    description: "Message Status",
  },
  messageStatus4: {
    id: "app.messageStatus4",
    defaultMessage: "Approved",
    description: "Message Status",
  },
  messageStatus5: {
    id: "app.messageStatus5",
    defaultMessage: "Pending",
    description: "Message Status",
  },
  messageStatus6: {
    id: "app.messageStatus6",
    defaultMessage: "Canceled by host",
    description: "Message Status",
  },
  messageStatus7: {
    id: "app.messageStatus7",
    defaultMessage: "Canceled by guest",
    description: "Message Status",
  },
  messageStatus8: {
    id: "app.messageStatus8",
    defaultMessage: "Approved",
    description: "Message Status",
  },
  messageStatus9: {
    id: "app.messageStatus9",
    defaultMessage: "Expired",
    description: "Message Status",
  },
  messageStatus10: {
    id: "app.messageStatus10",
    defaultMessage: "Request to book",
    description: "Message Status",
  },
  panelHeader1: {
    id: "app.panelHeader1",
    defaultMessage: "In progress",
    description: "Panel Header",
  },
  panelHeader2: {
    id: "app.panelHeader2",
    defaultMessage: "Completed",
    description: "Panel Header",
  },
  messageStatus11: {
    id: "app.messageStatus11",
    defaultMessage: "Canceled",
    description: "Message Status",
  },
  messagesNew: {
    id: "app.messagesNew",
    defaultMessage: "New",
    description: "New",
  },
  cancellationNote: {
    id: "app.cancellationNote",
    defaultMessage: "Tell host to why you need to cancel",
    description: "Cancelation Notes",
  },
  cancellationNoteForHost: {
    id: "app.cancellationNoteForHost",
    defaultMessage: "understand why you can't host them",
    description: "Cancelation Notes",
  },
  saveAndContinue: {
    id: "app.saveAndContinue",
    defaultMessage: "Save & Continue",
    description: "Save & Continue",
  },
  saveAndExit: {
    id: "app.saveAndExit",
    defaultMessage: "Save & Exit",
    description: "Save and Exit",
  },
  reservationCancel: {
    id: "app.reservationCancel",
    defaultMessage: "This reservation will be canceled immediately",
    description: "Cancel your trip",
  },
  nonRefundable: {
    id: "app.nonRefundable",
    defaultMessage: "Non-Refundable",
    description: "Cancel your trip",
  },
  refundable: {
    id: "app.refundable",
    defaultMessage: "Refundable",
    description: "Cancel your trip",
  },
  refundCost: {
    id: "app.refundCost",
    defaultMessage: "You will be refunded the above cost.",
    description: "Cancel your trip",
  },
  keepReservation: {
    id: "app.keepReservation",
    defaultMessage: "Keep your Reservation",
    description: "Keep reservation",
  },
  cancelYourReservation: {
    id: "app.cancelYourReservation",
    defaultMessage: "Cancel your reservation",
    description: "Cancel your trip",
  },
  earnings: {
    id: "app.earnings",
    defaultMessage: "Earnings",
    description: "Earnings",
  },
  willBeRefund: {
    id: "app.willBeRefund",
    defaultMessage: "will be refunded the",
    description: "will be refunded the",
  },
  reservationCost: {
    id: "app.reservationCost",
    defaultMessage: "reservation cost.",
    description: "Reservation cost",
  },
  cancelYourTrip: {
    id: "app.cancelYourTrip",
    defaultMessage: "Cancel your trip",
    description: "Cancel your Trip",
  },
  consider: {
    id: "app.consider",
    defaultMessage: "Consider",
    description: "Consider",
  },
  tripBeforeCanceling: {
    id: "app.tripBeforeCanceling",
    defaultMessage: "trip before canceling",
    description: "Trip before canceling",
  },
  cancellingInfo: {
    id: "app.cancellingInfo",
    defaultMessage:
      "Cancelling a guest's reservation can disrupt their plans and have serious implications on their trip.",
    description: "Trip before canceling",
  },
  started: {
    id: "app.started",
    defaultMessage: "Started",
    description: "Started",
  },
  startIn: {
    id: "app.startIn",
    defaultMessage: "Trip starts in",
    description: "Starts in",
  },
  travelingWith: {
    id: "app.travelingWith",
    defaultMessage: "Travelling with",
    description: "Traveling With",
  },
  needToCancel: {
    id: "app.needToCancel",
    defaultMessage: "Tell me why you need to cancel",
    description: "why you need to cancel",
  },
  stayingFor: {
    id: "app.stayingFor",
    defaultMessage: "Staying for",
    description: "Staying for",
  },
  propertyLocated: {
    id: "app.propertyLocated",
    defaultMessage: "property is located in",
    description: "Property is located in",
  },
  responseFrom: {
    id: "app.responseFrom",
    defaultMessage: "Your response",
    description: "Response from",
  },
  savingButton: {
    id: "app.savingButton",
    defaultMessage: "Saving",
    description: "Saving Button",
  },
  night: {
    id: "app.night",
    defaultMessage: "night",
    description: "Bill Details",
  },
  minimumStay: {
    id: "app.minimumStay",
    defaultMessage: "Minimum stay",
    description: "Minimum stay",
  },
  minimumNightStay: {
    id: "app.minimumNightStay",
    defaultMessage: "Minimum stay is",
    description: "Minimum stay",
  },
  maximumNightStay: {
    id: "app.maximumNightStay",
    defaultMessage: "Maximum stay",
    description: "Maximum stay",
  },
  review: {
    id: "app.review",
    defaultMessage: "Review",
    description: "Review",
  },
  verifications: {
    id: "app.verifications",
    defaultMessage: "Verifications",
    description: "Verifications",
  },
  verification: {
    id: "app.verification",
    defaultMessage: "Verification",
    description: "Verification",
  },
  guestCapcity: {
    id: "app.guestCapcity",
    defaultMessage: "guest",
    description: "Guest",
  },
  guestsCapcity: {
    id: "app.guestsCapcity",
    defaultMessage: "guests",
    description: "Guests",
  },
  monthlyDiscount: {
    id: "app.monthlyDiscount",
    defaultMessage: "monthly price discount",
    description: "Monthly Discount",
  },
  weeklyDiscount: {
    id: "app.weeklyDiscount",
    defaultMessage: "weekly price discount",
    description: "Weekly Discount",
  },
  flexibleCheckIn: {
    id: "app.flexibleCheckIn",
    defaultMessage: "Flexible check in time",
    description: "Flexible check in time",
  },
  cancellationNoteForGuest: {
    id: "app.cancellationNoteForGuest",
    defaultMessage: "understand why you want to cancel your reservation",
    description: "Cancelation notes fot guest",
  },
  howManyday: {
    id: "app.howManyday",
    defaultMessage: "day",
    description: "day",
  },
  howManydays: {
    id: "app.howManydays",
    defaultMessage: "days",
    description: "days",
  },
  howManyGuest: {
    id: "app.howManyGuest",
    defaultMessage: "Guests",
    description: "Guest",
  },
  your: {
    id: "app.your",
    defaultMessage: "Your",
    description: "Your",
  },
  chooseCancellationPolicy: {
    id: "app.chooseCancellationPolicy",
    defaultMessage: "Cancelation policy",
    description: "Cancelation Policy",
  },
  pendingPayouts: {
    id: "app.pendingPayouts",
    defaultMessage: "Pending payouts",
    description: "Pending Payouts",
  },
  allPayoutMethod: {
    id: "app.allPayoutMethod",
    defaultMessage: "All payout methods",
    description: "All payout methods",
  },
  inboxCompleted: {
    id: "app.inboxCompleted",
    defaultMessage: "Completed",
    description: "Message Status - Inbox Item",
  },
  loginConfirmation: {
    id: "app.loginConfirmation",
    defaultMessage:
      "Sorry, you must login to confirm your email. Please login and try clicking the link from your email again!",
    description: "Login confirmation",
  },
  upcomingReservations: {
    id: "app.upcomingReservations",
    defaultMessage: "Upcoming reservations",
    description: "Upcoming reservations",
  },
  previousReservations: {
    id: "app.previousReservations",
    defaultMessage: "Previous reservations",
    description: "Previous reservations",
  },
  upcomingTrips: {
    id: "app.upcomingTrips",
    defaultMessage: "Upcoming trips",
    description: "Upcoming trips",
  },
  previousTrips: {
    id: "app.previousTrips",
    defaultMessage: "Previous trips",
    description: "Previous trips",
  },
  noUpcomingReservation: {
    id: "app.noUpcomingReservation",
    defaultMessage:
      "When you have upcoming reservations, you'll see them here.",
    description: "noUpcomingReservation",
  },
  noPreviousReservation: {
    id: "app.noPreviousReservation",
    defaultMessage:
      "When you have previous reservations, you'll see them here.",
    description: "noPreviousReservation",
  },
  noUpcomingTrips: {
    id: "app.noUpcomingTrips",
    defaultMessage:
      "No upcoming trips found. Start Planning your first adventure and Time to explore the unseen",
    description: "noUpcomingTrips",
  },
  noPreviousTrips: {
    id: "app.noPreviousTrips",
    defaultMessage:
      "No previous trips found. Start Planning your next adventure and Time to explore the unseen",
    description: "noPreviousTrips",
  },
  payNow: {
    id: "app.paynow",
    defaultMessage: "Pay now",
    description: "Pay now",
  },
  creditCard: {
    id: "app.creditCard",
    defaultMessage: "Debit / Credit card",
    description: "Debit / Credit card",
  },
  fullName: {
    id: "app.fullName",
    defaultMessage: "Full name",
    description: "fullName",
  },
  cvv: {
    id: "app.cvv",
    defaultMessage: "CVV",
    description: "cvv",
  },
  cardExpires: {
    id: "app.Expires",
    defaultMessage: "Expires",
    description: "Expires",
  },
  required: {
    id: "app.required",
    defaultMessage: "*Required",
    description: "*Required",
  },
  invalid: {
    id: "app.invalid",
    defaultMessage: "*Invalid",
    description: "*Invalid",
  },
  payoutFirstName: {
    id: "app.payoutFirstName",
    defaultMessage: "Account holder first name",
    description: "payoutFirstName",
  },
  payoutLastName: {
    id: "app.payoutLastName",
    defaultMessage: "Account holder last name",
    description: "payoutLastName",
  },
  payoutRouting: {
    id: "app.payoutRouting",
    defaultMessage: "Routing number",
    description: "payoutRouting",
  },
  accountNumber: {
    id: "app.accountNumber",
    defaultMessage: "Account number",
    description: "accountNumber",
  },
  confirmAccountNumber: {
    id: "app.confirmAccountNumber",
    defaultMessage: "Confirm account number",
    description: "confirmAccountNumber",
  },
  ssn4Digits: {
    id: "app.ssn4Digits",
    defaultMessage: "SSN last 4 digits",
    description: "ssn4Digits",
  },
  payoutRoutingInvalid: {
    id: "app.payoutRoutingInvalid",
    defaultMessage: "Invalid routing number",
    description: "payoutRoutingInvalid",
  },
  accountNumberInvalid: {
    id: "app.accountNumberInvalid",
    defaultMessage: "Invalid account number",
    description: "accountNumberInvalid",
  },
  confirmAccountNumberInvalid: {
    id: "app.confirmAccountNumberInvalid",
    defaultMessage: "Invalid confirm account number",
    description: "confirmAccountNumberInvalid",
  },
  confirmAccountNumberMismatch: {
    id: "app.confirmAccountNumberMismatch",
    defaultMessage: "Account number mismatch",
    description: "confirmAccountNumberMismatch",
  },
  ssn4DigitsInvalid: {
    id: "app.ssn4DigitsInvalid",
    defaultMessage: "Invalid SSN number",
    description: "ssn4DigitsInvalid",
  },
  verifiedBy: {
    id: "app.verifiedBy",
    defaultMessage: "Verified by",
    description: "verifiedBy",
  },
  saved: {
    id: "app.saved",
    defaultMessage: "Saved",
    description: "saved",
  },
  wishList: {
    id: "app.wishList",
    defaultMessage: "Wishlist",
    description: "wishList",
  },
  wishLists: {
    id: "app.wishLists",
    defaultMessage: "Wishlists",
    description: "wishLists",
  },
  createWishList: {
    id: "app.createWishList",
    defaultMessage: "Create new wishlist",
    description: "createWishList",
  },
  lists: {
    id: "app.lists",
    defaultMessage: "Lists",
    description: "lists",
  },
  nameRequired: {
    id: "app.nameRequired",
    defaultMessage: "Name is required",
    description: "Name Required",
  },
  wishListAdded: {
    id: "app.wishListAdded",
    defaultMessage: "Wishlist added successfully.",
    description: "Wishlist added",
  },
  wishListUpdated: {
    id: "app.wishListUpdated",
    defaultMessage: "Wishlist updated successfully.",
    description: "Wishlist updated",
  },
  editWishList: {
    id: "app.editWishList",
    defaultMessage: "Edit wishlist",
    description: "editWishList",
  },
  noWishlists: {
    id: "app.noWishlists",
    defaultMessage: "Create your first wishlist",
    description: "noWishlists",
  },
  goToAllLists: {
    id: "app.goToAllLists",
    defaultMessage: "Go to all lists",
    description: "Go to All Lists",
  },
  noWishlistsHomes: {
    id: "app.noWishlistsHomes",
    defaultMessage: "Sorry, You don't have any wishlist homes!",
    description: "noWishlistsHomes",
  },
  areYouSureDeleteWishList: {
    id: "app.areYouSureDeleteWishList",
    defaultMessage: "Are you sure want to delete this?",
    description: "areYouSureDeleteWishList",
  },
  confirmDelete: {
    id: "app.confirmDelete",
    defaultMessage: "Confirm delete",
    description: "confirmDelete",
  },
  similarListings: {
    id: "app.similarListings",
    defaultMessage: "Similar Listings",
    description: "similarListings",
  },
  overview: {
    id: "app.overview",
    defaultMessage: "Overview",
    description: "Overview",
  },
  theHost: {
    id: "app.theHost",
    defaultMessage: "The Host",
    description: "The Host",
  },

  documentverificaiton: {
    id: "app.documentverificaiton",
    defaultMessage: "Document verification",
    description: "documentverificaiton",
  },
  documentverificaitonDetails: {
    id: "app.documentverificaitonDetails",
    defaultMessage: "Your document have been verified",
    description: "documentverificaitonDetails",
  },
  documentApprove: {
    id: "app.documentApprove",
    defaultMessage: "Approved",
    description: "documentApprove",
  },
  documentReject: {
    id: "app.documentReject",
    defaultMessage: "Rejected",
    description: "documentReject",
  },
  documentUploadPlaceholder: {
    id: "app.documentUploadPlaceholder",
    defaultMessage: "Click here to upload document or drag them in",
    description: "Document Upload Placeholder",
  },
  documentVerificaitonInfo: {
    id: "app.documentVerificaitonInfo",
    defaultMessage:
      "Please upload your valid documents for ID verification. Eg: Passport, Driving License",
    description: "Document Verification Info",
  },
  searchAsIMove: {
    id: "app.searchAsIMove",
    defaultMessage: "Search as I move the map",
    description: "Search as I move the map",
  },
  blankSpace: {
    id: "app.blankSpace",
    defaultMessage: "Input is blank",
    description: "blankSpace",
  },
  ContactMessage: {
    id: "app.ContactMessage",
    defaultMessage: "Message",
    description: "ContactMessage",
  },
  contactForm: {
    id: "app.contactForm",
    defaultMessage: "Contact Us",
    description: "contactForm",
  },
  Nameincontact: {
    id: "app.Nameincontact",
    defaultMessage: "Name",
    description: "Nameincontact",
  },
  sendmail: {
    id: "app.sendmail",
    defaultMessage: "Send",
    description: "Send",
  },
  helpFirstTitle: {
    id: "app.helpFirstTitle",
    defaultMessage: "Create your listing",
    description: "Title",
  },
  helpSecondTitle: {
    id: "app.helpSecondTitle",
    defaultMessage: "Welcome your guests",
    description: "Title",
  },
  helpThirdTitle: {
    id: "app.helpThirdTitle",
    defaultMessage: "Getting paid safely",
    description: "Title",
  },
  helpFirstDesc1: {
    id: "app.helpFirstDesc1",
    defaultMessage:
      "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s.",
    description: "Description",
  },
  helpFirstDesc2: {
    id: "app.helpFirstDesc2",
    defaultMessage:
      "Maecenas non scelerisque purus, congue cursus arcu. Donec vel dapibus mi. Mauris maximus posuere placerat. Sed et libero eu nibh tristique mollis a eget lectus",
    description: "Description",
  },
  helpSecondDesc1: {
    id: "app.helpSecondDesc1",
    defaultMessage:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    description: "Description",
  },
  helpSecondDesc2: {
    id: "app.helpSecondDesc2",
    defaultMessage:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. ",
    description: "Description",
  },

  helpSecondDesc3: {
    id: "app.helpSecondDesc3",
    defaultMessage:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    description: "Description",
  },
  helpThirdDesc1: {
    id: "app.helpThirdDesc1",
    defaultMessage:
      " Morbi pellentesque venenatis felis sed convallis. Nulla varius, nibh vitae placerat tempus, mauris sem elementum ipsum. ",
    description: "Description",
  },
  helpThirdDesc2: {
    id: "app.helpThirdDesc2",
    defaultMessage:
      "Fusce quis varius nulla. Nullam nisi nisi, suscipit ut magna quis, feugiat porta nibh. ",
    description: "Description",
  },
  helpThirdDesc3: {
    id: "app.helpThirdDesc3",
    defaultMessage:
      "Pellentesque sagittis vehicula sem quis luctus. Proin sodales magna in lorem hendrerit aliquam. Integer eu varius orci.",
    description: "Description",
  },
  reportUserInfo: {
    id: "app.reportUserInfo",
    defaultMessage:
      "Do you want to report this User? If so, please choose one of the following reasons.",
    description: "Report User",
  },
  reportContent1: {
    id: "app.reportContent1",
    defaultMessage: "This profile should not be on",
    description: "Report User",
  },
  reportContent2: {
    id: "app.reportContent2",
    defaultMessage: "Attempt to share contact information",
    description: "Report User",
  },
  close: {
    id: "app.close",
    defaultMessage: "Close",
    description: "Report User",
  },
  reportContent3: {
    id: "app.reportContent3",
    defaultMessage: "Inappropriate content of spam",
    description: "Report User",
  },
  thankyouInfo1: {
    id: "app.thankyouInfo1",
    defaultMessage:
      "Thanks for taking the time to report this user. These reports help make better for everyone, so we take them seriously. We will reach out if we have questions about your report.",
    description: "Report User",
  },
  thankyouTitle: {
    id: "app.thankyouTitle",
    defaultMessage: "Thank you",
    description: "Report User",
  },
  messageHistroyLabel: {
    id: "app.messageHistroyLabel",
    defaultMessage: "View",
    description: "Report User",
  },
  topBannerTitle: {
    id: "app.topBannerTitle",
    defaultMessage: "It's simple to become a app host",
    description: "Report User",
  },
  footerTerms: {
    id: "app.footerTerms",
    defaultMessage: "Terms, Privacy & More",
    description: "Footer Button",
  },
  footerClose: {
    id: "app.footerClose",
    defaultMessage: "Close",
    description: "Footer Button",
  },
  myHostSpaces: {
    id: "app.myHostSpaces",
    defaultMessage: "Spaces",
    description: "Menu Item",
  },
  clear: {
    id: "app.clear",
    defaultMessage: "Clear",
    description: "clear",
  },
  apply: {
    id: "app.apply",
    defaultMessage: "Apply",
    description: "apply",
  },
  homeType: {
    id: "app.homeType",
    defaultMessage: "Home type",
    description: "homeType",
  },
  showAmenities: {
    id: "app.showAmenities",
    defaultMessage: "Show more",
    description: "Toggle Button",
  },
  closeAmenities: {
    id: "app.closeAmenities",
    defaultMessage: "Close all",
    description: "Toggle Button",
  },
  showMore: {
    id: "app.showMore",
    defaultMessage: "Show more",
    description: "Toggle Button",
  },
  showLess: {
    id: "app.showLess",
    defaultMessage: "Show Less",
    description: "Toggle Button",
  },
  closeSafetyFeatures: {
    id: "app.closeSafetyFeatures",
    defaultMessage: "Close all",
    description: "Toggle Button",
  },
  bookNow: {
    id: "app.bookNow",
    defaultMessage: "Book Now",
    description: "Booking button",
  },
  shareNow: {
    id: "app.shareNow",
    defaultMessage: "Share Now",
    description: "Sharing button",
  },
  contactAdmin: {
    id: "app.contactAdmin",
    defaultMessage: " contact us.",
    description: "Contact Admin",
  },
  roomsAndBeds: {
    id: "app.roomsAndBeds",
    defaultMessage: "Rooms and Beds",
    description: "Rooms and Beds",
  },
  showAllFacilities: {
    id: "app.showAllFacilities",
    defaultMessage: "Show more",
    description: "Show all facilities text",
  },
  closeFacilities: {
    id: "app.closeFacilities",
    defaultMessage: "Close all",
    description: "Close facilities text",
  },
  showAllHouseRules: {
    id: "app.showAllHouseRules",
    defaultMessage: "Show more",
    description: "Show all house rules text",
  },
  closeHouseRules: {
    id: "app.closeHouseRules",
    defaultMessage: "Close all",
    description: "Close house rules text",
  },
  chooseACountry: {
    id: "app.chooseACountry",
    defaultMessage: "Choose a country",
    description: "Choose a country",
  },
  addPhoneNumber: {
    id: "app.addPhoneNumber",
    defaultMessage: "Add phone number",
    description: "Add phone number",
  },
  addAPhoneNumber: {
    id: "app.addAPhoneNumber",
    defaultMessage: "Add a phone number",
    description: "Add a phone number",
  },
  verifyViaSms: {
    id: "app.verifyViaSms",
    defaultMessage: "Verify via SMS",
    description: "Verify via SMS",
  },
  weHaveSentVerificationCode: {
    id: "app.weHaveSentVerificationCode",
    defaultMessage: "We sent a verification code to",
    description: "We sent a verification code to",
  },
  verificationCodeLabel: {
    id: "app.verificationCodeLabel",
    defaultMessage: "Please enter the 4-digit code",
    description: "Please enter the 4-digit code",
  },
  seeAll: {
    id: "app.seeAll",
    defaultMessage: "See all",
    description: "seeAll",
  },
  listNotFoundTitle: {
    id: "app.listNotFoundTitle",
    defaultMessage: "It looks like this listing may deleted or unpublished.",
    description: "Not Found",
  },
  cancelInfoBooking: {
    id: "app.cancelInfoBooking",
    defaultMessage: "Most hosts respond within 24 hours.",
    description: "View message",
  },
  cookiesDisclaimer: {
    id: "app.cookiesDisclaimer",
    defaultMessage:
      "uses cookies to understand how you use our site and to improve your experience. By using our site, you accept our use of cookies. To learn more, go to our",
    description: "extraGuestIncluded",
  },
  gotIt: {
    id: "app.gotIt",
    defaultMessage: "GOT IT",
    description: " cookies Disclaimer Button",
  },
  Maximumupload: {
    id: "app.Maximumupload",
    defaultMessage: "Maximum upload size",
    description: "Photos Upload",
  },
  cookiePolicy: {
    id: "app.cookiePolicy",
    defaultMessage: "Cookie policy",
    description: " cookies Disclaimer Link",
  },
  blockDates: {
    id: "app.blockedDates",
    defaultMessage: "Block dates",
    description: "Listing Step3",
  },
  deSelect: {
    id: "app.deSelect",
    defaultMessage: "Cancel",
    description: "Cancel deSelect button",
  },
  selectedDatesSuccess: {
    id: "app.selectedDatesSuccess",
    defaultMessage: "Your calendar has been updated successfully",
    description: "Your calendar has been updated successfully.",
  },
  selectedDatesError: {
    id: "app.selectedDatesError",
    defaultMessage: "Something went wrong, please try again.",
    description: "Something went wrong, please try again.",
  },
  sessionPrice: {
    id: "app.sessionPrice",
    defaultMessage: "Special price",
    description: "sessionPrice",
  },
  contactFormInformation: {
    id: "app.contactFormInformation",
    defaultMessage: " Contact & Support",
    description: "contactForm",
  },
  contactFormEmail: {
    id: "app.contactFormEmail",
    defaultMessage: "Email Us",
    description: "contactForm",
  },
  contactFormCall: {
    id: "app.contactFormCall",
    defaultMessage: "Call Us",
    description: "contactForm",
  },
  faqtitle: {
    id: "app.faqtitle",
    defaultMessage: " FAQ",
    description: "Faq  title ",
  },
  contactFormAddress: {
    id: "app.contactFormAddress",
    defaultMessage: "Contact address",
    description: "Contact Address",
  },
  showDescription: {
    id: "app.showDescription",
    defaultMessage: "Show more",
    description: "View Listing",
  },
  showAllSafetyFeatures: {
    id: "app.showAllSafetyFeatures",
    defaultMessage: "Show more",
    description: "Toggle Button",
  },
  showAllSharedSpaces: {
    id: "app.showAllSharedSpaces",
    defaultMessage: "Show more",
    description: "Toggle Button",
  },
  closeAllSharedSpaces: {
    id: "app.closeAllSharedSpaces",
    defaultMessage: "Close all",
    description: "Toggle Button",
  },
  Youhadreviewsfor: {
    id: "app.Youhadreviewsfor",
    defaultMessage: "You reviewed",
    description: "You reviewed",
  },
  sreview: {
    id: "app.sreview",
    defaultMessage: "reviewed the",
    description: "s review",
  },
  exceedLimit: {
    id: "app.exceedLimit",
    defaultMessage: "Limit exceeded",
    description: "exceedLimit",
  },
  enableLabel: {
    id: "app.enableLabel",
    defaultMessage: "Enable",
    description: "Enable",
  },
  disableLabel: {
    id: "app.disableLabel",
    defaultMessage: "Disable",
    description: "Disable",
  },
  removeDateError: {
    id: "app.removeDateError",
    defaultMessage:
      "Please choose the blocked or special pricing dates to make them available.",
    description: "Remove date from special pricing error message",
  },
  unBlockInfo: {
    id: "app.unBlockInfo",
    defaultMessage:
      "Note: To unblock a date, select any blocked or available date and save without giving special price.",
    description: "calendar unblock info",
  },
  localLawsOne: {
    id: "app.localLawsOne",
    defaultMessage:
      "Take a moment to review your local laws. We want to make sure you’ve got everything you need to get off to a great start.",
    description: "Lowcal Law",
  },
  localLawsTwo: {
    id: "app.localLawsTwo",
    defaultMessage:
      "Please educate yourself about the laws in your jurisdiction before listing your space.",
    description: "Lowcal Law",
  },
  localLawsThree: {
    id: "app.localLawsThree",
    defaultMessage:
      "Most cities have rules covering homesharing, and the specific codes and ordinances can appear in many places (such as zoning, building, licensing or tax codes). In most places, you must register, get a permit, or obtain a license before you list your property or accept guests. You may also be responsible for collecting and remitting certain taxes. In some places, short-term rentals could be prohibited altogether.",
    description: "Lowcal Law",
  },
  localLawsFive: {
    id: "app.localLawsFive",
    defaultMessage:
      "Since you are responsible for your own decision to list or book, you should get comfortable with the applicable rules before listing on",
    description: "Lowcal Law",
  },
  localLawsSix: {
    id: "app.localLawsSix",
    defaultMessage:
      "To get you started, we offer some helpful resources under “Your City Laws.”",
    description: "Lowcal Law",
  },
  localLawsSeven: {
    id: "app.localLawsSeven",
    defaultMessage:
      "By accepting our Terms of Service and listing your space, you certify that you will follow applicable laws and regulations.",
    description: "Lowcal Law",
  },
  removeFile: {
    id: "app.removeFile",
    defaultMessage: "Remove file",
    description: "Remove file",
  },
  Sayyourspace: {
    id: "app.Sayyourspace",
    defaultMessage: "Describe your place",
    description: "Sayyourspace",
  },
  aboutDescPayment: {
    id: "app.aboutDescPayment",
    defaultMessage:
      "Tell your expectations and the number of guests you are bringing with you so that the host can make your trip an organized and a perfect trip you ever had!",
    description: "Sayyourspace",
  },
  availableLabel: {
    id: "app.availableLabel",
    defaultMessage: "Available",
    description: "Listing Step3",
  },
  blockLabel: {
    id: "app.blockLabel",
    defaultMessage: "Block",
    description: "Listing Step3",
  },
  warningLabel: {
    id: "app.warningLabel",
    defaultMessage: "Something went wrong!",
    description: "Warning",
  },
  completeRequest: {
    id: "app.completeRequest",
    defaultMessage: "We couldn't complete your request",
    description: "Warning",
  },
  errorCodeWarning: {
    id: "app.errorCodeWarning",
    defaultMessage: "Error code:",
    description: "Warning",
  },
  helpfulLinks: {
    id: "app.helpfulLinks",
    defaultMessage: "Here are some helpful links instead:",
    description: "Warning",
  },
  chooseLanguage: {
    id: "app.chooseLanguage",
    defaultMessage: "Choose language",
    description: "Choose language",
  },
  chooseACurrency: {
    id: "app.chooseACurrency",
    defaultMessage: "Choose currency",
    description: "Choose  currency",
  },
  payoutFailure: {
    id: "app.payoutFailure",
    defaultMessage: "Oops! We are sorry!",
    description: "Uh-oh, the registration has failed.",
  },
  payoutFailureSubtitle: {
    id: "app.payoutFailureSubtitle",
    defaultMessage:
      "Connecting your bank account with the platform verification is failed.",
    description: "Please add a valid SSN.",
  },
  payoutFailureContent: {
    id: "app.payoutFailureContent",
    defaultMessage:
      'Please try again to add your bank account with the platform by clickting the "Retry" option or Add a new payout account by clicking the "Add Payout Method".',
    description: "Please add a valid SSN.",
  },
  paymentCardNumber: {
    id: "app.paymentCardNumber",
    defaultMessage: "Card number",
    description: "cardNumber",
  },
  payoutType: {
    id: "app.payoutType",
    defaultMessage: "Account type",
    description: "payout Account Type",
  },
  payoutIndividual: {
    id: "app.payoutIndividual",
    defaultMessage: "Individual",
    description: "Individual text",
  },
  payoutCompany: {
    id: "app.payoutCompany",
    defaultMessage: "Company",
    description: "Company text",
  },
  payoutCompanyName: {
    id: "app.payoutCompanyName",
    defaultMessage: "Company name",
    description: "Company text",
  },
  payoutStripeInfo: {
    id: "app.payoutStripeInfo",
    defaultMessage:
      'We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click "Finish" to set up your payments on Stripe.',
    description: "Stripe text info",
  },
  notReady: {
    id: "app.notReady",
    defaultMessage: "Not ready",
    description: "Payout Method",
  },
  payoutVerify: {
    id: "app.payoutVerify",
    defaultMessage: "Verify",
    description: "Payout Method",
  },
  payoutRetry: {
    id: "app.payoutRetry",
    defaultMessage: "Retry",
    description: "Payout Method",
  },
  payoutVerifyStripeInfo: {
    id: "app.payoutVerifyStripeInfo",
    defaultMessage:
      "We use Stripe to make sure you get paid on time safe and secure. It requires additional verification to connect your bank account with the platform.",
    description: "Payout Method",
  },
  reservationIsCompleted: {
    id: "app.reservationIsCompleted",
    defaultMessage: "Reservation is completed",
    description: "View message action",
  },
  reservationIsCompletedDescription: {
    id: "app.reservationIsCompletedDescription",
    defaultMessage: "Reservation of your listing is completed.",
    description: "View message action",
  },
  averagePricePerNight: {
    id: "app.averagePricePerNight",
    defaultMessage: "Average rate per night for your trip.",
    description: "Tooltip",
  },
  exportCalendar: {
    id: "app.exportCalendar",
    defaultMessage: "Export calendar",
    description: "exportCalendar",
  },
  exportCalendarDescription: {
    id: "app.exportCalendarDescription",
    defaultMessage: "Copy and paste the link into other ICAL applications",
    description: "exportCalendar",
  },
  importCalendar: {
    id: "app.importCalendar",
    defaultMessage: "Import a new calendar",
    description: "importCalendar",
  },
  importCalendarDesc: {
    id: "app.importCalendarDesc",
    defaultMessage:
      "Import other calendars you use and we’ll automatically keep this listing’s availability up-to-date.",
    description: "importCalendar",
  },
  importCalendarDesc1: {
    id: "app.importCalendarDesc1",
    defaultMessage: "Calendar address (URL)",
    description: "importCalendar",
  },
  importCalendarBtn: {
    id: "app.importCalendarBtn",
    defaultMessage: "Import calendar",
    description: "importCalendar",
  },
  booked: {
    id: "app.booked",
    defaultMessage: "Booked",
    description: "Calendar",
  },
  notAvailable: {
    id: "app.notAvailable",
    defaultMessage: "Not available",
    description: "Calendar",
  },
  yesText: {
    id: "app.yesText",
    defaultMessage: "Yes",
    description: "yes",
  },
  noText: {
    id: "app.noText",
    defaultMessage: "No",
    description: "No",
  },
  verificationDesc: {
    id: "app.verificationDesc",
    defaultMessage: "If it doesn't arrive, click cancel and try again.",
    description: "Verification",
  },
  confirmedLabel: {
    id: "app.confirmedLabel",
    defaultMessage: "Confirmed",
    description: "Confirmed",
  },
  confirmLabel: {
    id: "app.confirmLabel",
    defaultMessage: "Confirm",
    description: "Confirm",
  },
  uploadSizedLabel: {
    id: "app.uploadSizedLabel",
    defaultMessage: "Maximum upload size:",
    description: "uploadSizedLabel",
  },
  showMap: {
    id: "app.showMap",
    defaultMessage: "Show map",
    description: "showMap",
  },
  totalPrice: {
    id: "app.totalPrice",
    defaultMessage: "Display total price",
    description: "totalPrice",
  },
  totalPriceDesc: {
    id: "app.totalPriceDesc",
    defaultMessage: "(includes all fees, before taxes)",
    description: "totalPriceDesc",
  },
  totalBeforeTaxes: {
    id: "app.totalBeforeTaxes",
    defaultMessage: "total before taxes",
    description: "total before taxes",
  },
  totalPriceModal: {
    id: "app.totalPriceModal",
    defaultMessage: "Total before taxes",
    description: "totalPriceModal",
  },
  shareLabel: {
    id: "app.shareLabel",
    defaultMessage: "Share",
    description: "shareLabel",
  },
  emailLabel: {
    id: "app.emailLabel",
    defaultMessage: "Email",
    description: "emailLabel",
  },
  inLabel: {
    id: "app.inLabel",
    defaultMessage: "in",
    description: "inLabel",
  },
  socialShareDesc: {
    id: "app.socialShareDesc",
    defaultMessage: "Share this place with friends and family!",
    description: "socialShareDesc",
  },
  tripCompleted: {
    id: "app.tripCompleted",
    defaultMessage: "Trip is completed",
    description: "tripCompleted",
  },
  taxRateInvalidError: {
    id: "app.taxRateInvalidError",
    defaultMessage:
      "Invalid tax rate. The tax rate must be between 1% and 99%.",
    description: "Invalid discount",
  },
  contentLabel: {
    id: "app.contentLabel",
    defaultMessage: "Content",
    description: "Content",
  },
  ChooseFooterCategory: {
    id: "app.ChooseFooterCategory",
    defaultMessage: "Choose footer category",
    description: "Choose Footer Category",
  },
  metaTitleLabel: {
    id: "app.metaTitleLabel",
    defaultMessage: "Meta title",
    description: "Meta Title",
  },
  metaDescriptionLabel: {
    id: "app.metaDescriptionLabel",
    defaultMessage: "Meta description",
    description: "Meta Description",
  },
  pageTitleLabel: {
    id: "app.pageTitleLabel",
    defaultMessage: "Page title",
    description: "Page Title",
  },
  pageUrl: {
    id: "app.pageUrl",
    defaultMessage: "Page URL",
    description: "Page URL",
  },
  pageUrlExample: {
    id: "app.pageUrlExample",
    defaultMessage: "Page URL  eg.(pageURL)",
    description: "Page URL  eg.(pageURL)",
  },
  footerCategoryLabel: {
    id: "app.footerCategoryLabel",
    defaultMessage: "Footer category",
    description: "Footer Category",
  },
  pageLabel: {
    id: "app.pageLabel",
    defaultMessage: "/page/",
    description: "/page/",
  },
  loadingLabel: {
    id: "app.loadingLabel",
    defaultMessage: "Loading...",
    description: "Loading...",
  },
  imageLabel: {
    id: "app.imageLabel",
    defaultMessage: "Image",
    description: "Image",
  },
  locationAddress: {
    id: "app.locationAddress",
    defaultMessage: "Location address",
    description: "Location Address",
  },
  totalUsers: {
    id: "app.totalUsers",
    defaultMessage: "Total users",
    description: "Total Users",
  },
  last24Hours: {
    id: "app.last24Hours",
    defaultMessage: "Last 24 hours users",
    description: "Last 24 hours Users",
  },
  last30Days: {
    id: "app.last30Days",
    defaultMessage: "Last 30 days users",
    description: "Last 30 days Users",
  },
  last30DaysListing: {
    id: "app.last30DaysListing",
    defaultMessage: "Last 30 days listings",
    description: "Last 30 days Listings",
  },
  last24HoursListing: {
    id: "app.last24HoursListing",
    defaultMessage: "Last 24 hours listings",
    description: "Last 24 hours Listings",
  },
  totalListings: {
    id: "app.totalListings",
    defaultMessage: "Total listings",
    description: "Total Listings",
  },
  totalReservation: {
    id: "app.totalReservation",
    defaultMessage: "Total reservations",
    description: "Total Reservations",
  },
  last24HoursReservation: {
    id: "app.last24HoursReservation",
    defaultMessage: "Last 24 hours reservations",
    description: "Last 24 hours Reservations",
  },
  last30DaysReservation: {
    id: "app.last30DaysReservation",
    defaultMessage: "Last 30 days  reservations",
    description: "Last 30 days  Reservations",
  },
  copyRightLabel: {
    id: "app.copyRightLabel",
    defaultMessage: "Copyright",
    description: "Copyright",
  },
  copyRightLabelDesc: {
    id: "app.copyRightLabelDesc",
    defaultMessage: "All rights reserved.",
    description: "All rights reserved.",
  },
  logInLabel: {
    id: "app.logInLabel",
    defaultMessage: "Login",
    description: "Log in",
  },
  listId: {
    id: "app.listId",
    defaultMessage: "List ID",
    description: "List ID",
  },
  reviewContentLabel: {
    id: "app.reviewContentLabel",
    defaultMessage: "Review content",
    description: "Review Content",
  },
  goToMainSite: {
    id: "app.goToMainSite",
    defaultMessage: "Go to main site",
    description: "Go To Main Site",
  },
  addNewLabel: {
    id: "app.addNewLabel",
    defaultMessage: "Add new",
    description: "Add New",
  },
  editLabel: {
    id: "app.editLabel",
    defaultMessage: "Edit",
    description: "Edit",
  },
  adminRoleLabel: {
    id: "app.adminRoleLabel",
    defaultMessage: "Admin role",
    description: "Admin Role",
  },
  adminUserLabel: {
    id: "app.adminUserLabel",
    defaultMessage: "Admin user",
    description: "Admin User",
  },
  addPageLabel: {
    id: "app.addPageLabel",
    defaultMessage: "Add page",
    description: "Add Page",
  },
  enabledLabel: {
    id: "app.enabledLabel",
    defaultMessage: "Enabled",
    description: "Enabled",
  },
  disabledLabel: {
    id: "app.disabledLabel",
    defaultMessage: "Disabled",
    description: "Disabled",
  },
  addLabel: {
    id: "app.addLabel",
    defaultMessage: "Add",
    description: "Add",
  },
  setBaseCurrency: {
    id: "app.setBaseCurrency",
    defaultMessage: "Set as base currency",
    description: "Set as base currency",
  },
  dateOfBirthLabel: {
    id: "app.dateOfBirthLabel",
    defaultMessage: "Date of birth",
    description: "Date of birth",
  },
  infoLabel: {
    id: "app.infoLabel",
    defaultMessage: "Info",
    description: "Info",
  },
  titleAdminLabel: {
    id: "app.titleAdminLabel",
    defaultMessage: "Title",
    description: "Title",
  },
  bannerImage: {
    id: "app.bannerImage",
    defaultMessage: "Banner image",
    description: "Banner Image",
  },
  descriptionAdminLabel: {
    id: "app.descriptionAdminLabel",
    defaultMessage: "Description",
    description: "Description",
  },
  buttonLabel: {
    id: "app.buttonLabel",
    defaultMessage: "Button label",
    description: "Button Label",
  },
  IconLabel: {
    id: "app.IconLabel",
    defaultMessage: "Icon",
    description: "Icon",
  },
  manageLabel: {
    id: "app.manageLabel",
    defaultMessage: "Manage",
    description: "Manage",
  },
  listSettings: {
    id: "app.listSettings",
    defaultMessage: "List settings",
    description: "List Settings",
  },
  reservationId: {
    id: "app.reservationId",
    defaultMessage: "Reservation ID",
    description: "Reservation ID",
  },
  adminListTitle: {
    id: "app.adminListTitle",
    defaultMessage: "List title",
    description: "List Title",
  },
  codeLabel: {
    id: "app.codeLabel",
    defaultMessage: "Code",
    description: "Code",
  },
  hostNameLabel: {
    id: "app.hostNameLabel",
    defaultMessage: "Host name",
    description: "Host name",
  },
  payoutLabel: {
    id: "app.payoutLabel",
    defaultMessage: "Payout amount",
    description: "Payout Amount",
  },
  reservationStatus: {
    id: "app.reservationStatus",
    defaultMessage: "Reservation status",
    description: "Reservation Status",
  },
  payoutStatus: {
    id: "app.payoutStatus",
    defaultMessage: "Payout status",
    description: "Payout Status",
  },
  holdPayout: {
    id: "app.holdPayout",
    defaultMessage: "Hold payout",
    description: "Hold Payout",
  },
  paymentName: {
    id: "app.paymentName",
    defaultMessage: "Payment name",
    description: "Payment Name",
  },
  addPopularLocation: {
    id: "app.addPopularLocation",
    defaultMessage: "Add a new popular location",
    description: "Add a new popular location",
  },
  profilePicture: {
    id: "app.profilePicture",
    defaultMessage: "Profile photo",
    description: "Profile photo",
  },
  bioInfo: {
    id: "app.bioInfo",
    defaultMessage: "Personal info",
    description: "Personal info",
  },
  userDeletedLabel: {
    id: "app.userDeletedLabel",
    defaultMessage: "User deleted",
    description: "User Deleted",
  },
  payoutRefund: {
    id: "app.payoutRefund",
    defaultMessage: "Payout/Refund",
    description: "Payout/Refund",
  },
  noPayoutMethod: {
    id: "app.noPayoutMethod",
    defaultMessage: "No payout method",
    description: "No Payout method",
  },
  closedLabel: {
    id: "app.closedLabel",
    defaultMessage: "Closed",
    description: "Closed",
  },
  processingLabel: {
    id: "app.processingLabel",
    defaultMessage: "Processing...",
    description: "Processing...",
  },
  completedLabel: {
    id: "app.completedLabel",
    defaultMessage: "Completed",
    description: "Completed",
  },
  transactionNotfound: {
    id: "app.transactionNotfound",
    defaultMessage: "Transaction not found",
    description: "Transaction not found",
  },
  notEligible: {
    id: "app.notEligible",
    defaultMessage: "Not eligible",
    description: "Not Eligible",
  },
  proceedRefund: {
    id: "app.proceedRefund",
    defaultMessage: "Proceed refund",
    description: "Proceed Refund",
  },
  viewLabel: {
    id: "app.viewLabel",
    defaultMessage: "View",
    description: "View",
  },
  exportDataIntoCSV: {
    id: "app.exportDataIntoCSV",
    defaultMessage: "Export data into CSV",
    description: "Export Data into CSV",
  },
  receiverLabel: {
    id: "app.receiverLabel",
    defaultMessage: "Receiver",
    description: "Receiver",
  },
  minimumPrice: {
    id: "app.minimumPrice",
    defaultMessage: "Minimum price",
    description: "Minimum Price",
  },
  maximumPrice: {
    id: "app.maximumPrice",
    defaultMessage: "Maximum price",
    description: "Maximum Price",
  },
  priceRangeCurrency: {
    id: "app.priceRangeCurrency",
    defaultMessage: "Price range currency",
    description: "Price Range Currency",
  },
  guestFeeType: {
    id: "app.guestFeeType",
    defaultMessage: "Guest fee type",
    description: "Guest Fee Type",
  },
  fixedPrice: {
    id: "app.fixedPrice",
    defaultMessage: "Fixed price",
    description: "Fixed Price",
  },
  percentage: {
    id: "app.percentage",
    defaultMessage: "Percentage",
    description: "Percentage",
  },
  guestServiceFee: {
    id: "app.guestServiceFee",
    defaultMessage: "Guest service fee",
    description: "Guest Service Fee",
  },
  guestServiceFeePlacehold: {
    id: "app.guestServiceFeePlacehold",
    defaultMessage: "Amount/Percentage",
    description: "Amount/Percentage",
  },
  hostFeeType: {
    id: "app.hostFeeType",
    defaultMessage: "Host fee type",
    description: "Host Fee Type",
  },
  hostServiceFeeType: {
    id: "app.hostServiceFeeType",
    defaultMessage: "Host service fee",
    description: "Host Service Fee",
  },
  submitLabel: {
    id: "app.submitLabel",
    defaultMessage: "Submit",
    description: "Submit",
  },
  add: {
    id: "app.add",
    defaultMessage: "Add",
    description: "Add Button",
  },
  update: {
    id: "app.update",
    defaultMessage: "Update",
    description: "Update Button",
  },
  addItemNew: {
    id: "app.addItemNew",
    defaultMessage: "Item name",
    description: "Item name",
  },

  addOtherItem: {
    id: "app.addOtherItem",
    defaultMessage: "Other item name",
    description: "Other Item name",
  },
  startValue: {
    id: "app.startValue",
    defaultMessage: "Start value",
    description: "Start value",
  },
  endValue: {
    id: "app.endValue",
    defaultMessage: "End value",
    description: "Add end value here ....",
  },
  enable: {
    id: "app.enable",
    defaultMessage: "Enable",
    description: "Enable",
  },
  disable: {
    id: "app.disable",
    defaultMessage: "Disable",
    description: "Disable",
  },
  startValueIsInvalid: {
    id: "app.startValueIsInvalid",
    defaultMessage: "Invalid start value, provide a valid number",
    description: "startValueIsInvalid",
  },
  endValueIsInvalid: {
    id: "app.endValueIsInvalid",
    defaultMessage: "Invalid end value, provide a valid number",
    description: "endValueIsInvalid",
  },
  endValueGreater: {
    id: "app.endValueGreater",
    defaultMessage: "End value must be greater than start value",
    description: "endValueGreater",
  },
  logoLabel: {
    id: "app.logoLabel",
    defaultMessage: "Logo",
    description: "Logo",
  },
  homeLogoLabel: {
    id: "app.homelogoLabel",
    defaultMessage: "Home page logo",
    description: "Home Page Logo",
  },
  emailLogoLabel: {
    id: "app.emaillogoLabel",
    defaultMessage: "Email logo",
    description: "Email Logo",
  },
  siteName: {
    id: "app.siteName",
    defaultMessage: "Site name",
    description: "Site Name",
  },
  siteTitle: {
    id: "app.siteTitle",
    defaultMessage: "Site title",
    description: "Site Title",
  },
  metaKeywordLabel: {
    id: "app.metaKeywordLabel",
    defaultMessage: "Meta keyword",
    description: "Meta Keyword",
  },
  metaKeywordLabelDesc: {
    id: "app.metaKeywordLabelDesc",
    defaultMessage: "Meta description",
    description: "Meta Description",
  },
  facebookURL: {
    id: "app.facebookURL",
    defaultMessage: "Facebook URL",
    description: "Facebook URL",
  },
  twitterURL: {
    id: "app.twitterURL",
    defaultMessage: "Twitter URL",
    description: "Twitter URL",
  },
  instagramURL: {
    id: "app.instagramURL",
    defaultMessage: "Instagram URL",
    description: "Instagram URL",
  },
  homePageLayout: {
    id: "app.homePageLayout",
    defaultMessage: "Home page layout",
    description: "Home Page Layout",
  },
  homePageLayoutDesc: {
    id: "app.homePageLayoutDesc",
    defaultMessage: "Banner Text with Image Slider Layout",
    description: "Banner Text with Image Slider Layout",
  },
  homePageLayoutDesc1: {
    id: "app.homePageLayoutDesc1",
    defaultMessage: "Banner Text Only Layout",
    description: "Banner Text Only Layout",
  },
  homePageLayoutDesc2: {
    id: "app.homePageLayoutDesc2",
    defaultMessage: "Banner Text Layout with Detailed Search form",
    description: "Banner Text Layout with Detailed Search form",
  },
  homePageLayoutDesc3: {
    id: "app.homePageLayoutDesc3",
    defaultMessage: "Single Banner Image Layout with Detailed Search form",
    description: "Single Banner Image Layout with Detailed Search form",
  },
  phoneNumberFormat: {
    id: "app.phoneNumberFormat",
    defaultMessage: "Phone number format",
    description: "Phone Number Format",
  },
  twilioSMS: {
    id: "app.twilioSMS",
    defaultMessage: "Twilio SMS",
    description: "Twilio SMS",
  },
  normalPhoneNumber: {
    id: "app.normalPhoneNumber",
    defaultMessage: "Normal phone number",
    description: "Normal Phone Number",
  },
  playStoreUrl: {
    id: "app.playStoreUrl",
    defaultMessage: "Play store URL",
    description: "Play store URL",
  },
  appStoreUrl: {
    id: "app.appStoreUrl",
    defaultMessage: "App store URL",
    description: "App store URL",
  },
  emailIdLabel: {
    id: "app.emailIdLabel",
    defaultMessage: "Email address",
    description: "Email address",
  },
  mobileNumberLabel: {
    id: "app.mobileNumberLabel",
    defaultMessage: "Mobile number",
    description: "Mobile Number",
  },
  headerTitle: {
    id: "app.headerTitle",
    defaultMessage: "Header title",
    description: "Header Title",
  },
  headerTitleContent: {
    id: "app.headerTitleContent",
    defaultMessage: "Header content",
    description: "Header Content",
  },
  blockTitleLabel: {
    id: "app.blockTitleLabel",
    defaultMessage: "Block title",
    description: "Block Title",
  },
  blockContentLabel: {
    id: "app.blockContentLabel",
    defaultMessage: "Block content",
    description: "Block Content",
  },
  blockImageLabel: {
    id: "app.blockImageLabel",
    defaultMessage: "Block image",
    description: "Block Image",
  },
  aboutUsLabel: {
    id: "app.aboutUsLabel",
    defaultMessage: "About Us",
    description: "About Us",
  },
  cancelledLabel: {
    id: "app.cancelledLabel",
    defaultMessage: "Canceled by",
    description: "Canceled by",
  },
  bookingType: {
    id: "app.bookingType",
    defaultMessage: "Booking type",
    description: "Booking Type",
  },
  amountPaid: {
    id: "app.amountPaid",
    defaultMessage: "Paid amount",
    description: "Paid amount",
  },
  guestName: {
    id: "app.guestName",
    defaultMessage: "Guest name",
    description: "Guest Name",
  },
  guestPhoneNumber: {
    id: "app.guestPhoneNumber",
    defaultMessage: "Guest phone number",
    description: "Guest Phone Number",
  },
  guestEmail: {
    id: "app.guestEmail",
    defaultMessage: "Guest Email",
    description: "Guest Email",
  },
  hostName: {
    id: "app.hostName",
    defaultMessage: "Host name",
    description: "Host name",
  },
  hostPhoneNumber: {
    id: "app.hostPhoneNumber",
    defaultMessage: "Host phone number",
    description: "Host Phone Number",
  },
  hostEmail: {
    id: "app.hostEmail",
    defaultMessage: "Host Email ID",
    description: "Host Email ID",
  },
  cancelDate: {
    id: "app.cancelDate",
    defaultMessage: "Canceled date",
    description: "Canceled date",
  },
  hostBannerTitle: {
    id: "app.hostBannerTitle",
    defaultMessage: "Why host page banner title",
    description: "Host Banner Title",
  },
  hostBannerLabel: {
    id: "app.hostBannerLabel",
    defaultMessage: "Host banner",
    description: "Host Banner",
  },
  hostingBlockTitleHeading: {
    id: "app.hostingBlockTitleHeading",
    defaultMessage: "Hosting block title heading",
    description: "Hosting Block Title Heading",
  },
  hostingBlockTitleLabel: {
    id: "app.hostingBlockTitleLabel",
    defaultMessage: "Hosting block title",
    description: "Hosting Block Title",
  },
  hostingBlockContentLabel: {
    id: "app.hostingBlockContentLabel",
    defaultMessage: "Hosting block content",
    description: "Hosting Block Content",
  },
  coverSectionTitleLabel: {
    id: "app.coverSectionTitleLabel",
    defaultMessage: "Cover section title",
    description: "Cover Section Title",
  },
  coverSectionContentLabel: {
    id: "app.coverSectionContentLabel",
    defaultMessage: "Cover section content",
    description: "Cover Section Content",
  },
  coverSectionFeatureLabel: {
    id: "app.coverSectionFeatureLabel",
    defaultMessage: "Cover section feature",
    description: "Cover Section Feature",
  },
  coverSectionBannerLabel: {
    id: "app.coverSectionBannerLabel",
    defaultMessage: "Cover section banner",
    description: "Cover Section Banner",
  },
  quoteSectionTitle: {
    id: "app.quoteSectionTitle",
    defaultMessage: "Quote section title",
    description: "Quote Section Title",
  },
  quoteSectionContent: {
    id: "app.quoteSectionContent",
    defaultMessage: "Quote section content",
    description: "Quote Section Content",
  },
  quoteSectionButton: {
    id: "app.quoteSectionButton",
    defaultMessage: "Quote section button",
    description: "Quote section button",
  },
  quoteSectionBanner: {
    id: "app.quoteSectionBanner",
    defaultMessage: "Quote section banner",
    description: "Quote Section Banner",
  },
  faqBlockTitle: {
    id: "app.faqBlockTitle",
    defaultMessage: "FAQ title",
    description: "FAQ title",
  },
  faqBlockContent: {
    id: "app.faqBlockContent",
    defaultMessage: "FAQ content",
    description: "FAQ Content",
  },
  siteSettings: {
    id: "app.siteSettings",
    defaultMessage: "Site settings",
    description: "Site Settings",
  },
  manageAdmin: {
    id: "app.manageAdmin",
    defaultMessage: "Manage admins",
    description: "Manage Admins",
  },
  manageAdminUsers: {
    id: "app.manageAdminUsers",
    defaultMessage: "Manage admin users",
    description: "Manage Admin Users",
  },
  manageAdminRoles: {
    id: "app.manageAdminRoles",
    defaultMessage: "Manage admin roles",
    description: "Manage Admin Roles",
  },
  manageUser: {
    id: "app.manageUser",
    defaultMessage: "Manage users",
    description: "Manage Users",
  },
  manageReservations: {
    id: "app.manageReservations",
    defaultMessage: "Manage reservations",
    description: "Manage Reservations",
  },
  reviewManagement: {
    id: "app.reviewManagement",
    defaultMessage: "Reviews management",
    description: "Reviews Management",
  },
  adminReviews: {
    id: "app.adminReviews",
    defaultMessage: "Admin reviews",
    description: "Admin Reviews",
  },
  welcomeAdmin: {
    id: "app.welcomeAdmin",
    defaultMessage: "Welcome, Admin",
    description: "Welcome, Admin",
  },
  manageReviwes: {
    id: "app.manageReviwes",
    defaultMessage: "Manage reviews",
    description: "Manage Reviews",
  },
  writeReviwes: {
    id: "app.writeReviwes",
    defaultMessage: "Write a review",
    description: "Write Review",
  },
  manageServiceFee: {
    id: "app.manageServiceFee",
    defaultMessage: "Manage service fee",
    description: "Manage Service Fee",
  },
  reportManagement: {
    id: "app.reportManagement",
    defaultMessage: "Report management",
    description: "Report Management",
  },
  managePayout: {
    id: "app.managePayout",
    defaultMessage: "Manage payout",
    description: "Manage Payout",
  },
  manageCurrency: {
    id: "app.manageCurrency",
    defaultMessage: "Manage currency",
    description: "Manage Currency",
  },
  searchSettings: {
    id: "app.searchSettings",
    defaultMessage: "Search settings",
    description: "Search Settings",
  },
  changePasswordLabel: {
    id: "app.changePasswordLabel",
    defaultMessage: "Change password",
    description: "Change Password",
  },
  staticContentManagement: {
    id: "app.staticContentManagement",
    defaultMessage: "Static content management",
    description: "Static Content Management",
  },
  contentManagementLabel: {
    id: "app.contentManagementLabel",
    defaultMessage: "Content management",
    description: "Content Management",
  },
  maximumNightsLabel: {
    id: "app.maximumNightsLabel",
    defaultMessage: "Maximum nights",
    description: "Maximum Nights",
  },
  minimumNightsLabel: {
    id: "app.minimumNightsLabel",
    defaultMessage: "Minimum nights",
    description: "Minimum Nights",
  },
  listSettingStep: {
    id: "app.listSettingStep",
    defaultMessage: "List settings for step",
    description: "List Settings for Step",
  },
  essentialAmenities: {
    id: "app.essentialAmenities",
    defaultMessage: "Essential amenities",
    description: "Essential Amenities",
  },
  bathroomType: {
    id: "app.bathroomType",
    defaultMessage: "Bathroom type",
    description: "Bathroom Type",
  },
  bedTypeLabel: {
    id: "app.bedTypeLabel",
    defaultMessage: "Bed type",
    description: "Bed Type",
  },
  bedRoomsLabel: {
    id: "app.bedRoomsLabel",
    defaultMessage: "Bed rooms",
    description: "Bed Rooms",
  },
  buildingSize: {
    id: "app.buildingSize",
    defaultMessage: "Building size",
    description: "Building Size",
  },
  houseTypeLabel: {
    id: "app.houseTypeLabel",
    defaultMessage: "House type",
    description: "House Type",
  },
  personCapacity: {
    id: "app.personCapacity",
    defaultMessage: "Person capacity",
    description: "Person Capacity",
  },
  whyBecomeHostPage: {
    id: "app.whyBecomeHostPage",
    defaultMessage: "Why Become Host Page",
    description: "Why Become Host Page",
  },
  homeBannerLabel: {
    id: "app.homeBannerLabel",
    defaultMessage: "Home banner",
    description: "Home Banner",
  },
  staticInfoBlock: {
    id: "app.staticInfoBlock",
    defaultMessage: "Static info block",
    description: "Static Info Block",
  },
  managePopularLocations: {
    id: "app.managePopularLocations",
    defaultMessage: "Manage popular locations",
    description: "Manage Popular Locations",
  },
  footerBlockLabel: {
    id: "app.footerBlockLabel",
    defaultMessage: "Footer block",
    description: "Footer Block",
  },
  imageBannerLabel: {
    id: "app.imageBannerLabel",
    defaultMessage: "Image banner",
    description: "Image Banner",
  },
  bannerCaptionLabel: {
    id: "app.bannerCaptionLabel",
    defaultMessage: "Banner caption",
    description: "Banner Caption",
  },
  homePageSettings: {
    id: "app.homePageSettings",
    defaultMessage: "Home page settings",
    description: "Home Page Settings",
  },
  roleLabel: {
    id: "app.roleLabel",
    defaultMessage: "Role",
    description: "Role",
  },
  adminUserDesc: {
    id: "app.adminUserDesc",
    defaultMessage:
      "Provide new password only. Ignore this field to use the existing password for this user.",
    description:
      "Provide new password only. Ignore this field to use the existing password for this user.",
  },
  selectRoleLabel: {
    id: "app.selectRoleLabel",
    defaultMessage: "Select role",
    description: "Select Role",
  },
  roleNameLabel: {
    id: "app.roleNameLabel",
    defaultMessage: "Role name",
    description: "Role Name",
  },
  privilagesLabel: {
    id: "app.privilagesLabel",
    defaultMessage: "Privileges",
    description: "Privileges",
  },
  adminTitleLabel: {
    id: "app.adminTitleLabel",
    defaultMessage: "Title",
    description: "Title",
  },
  invalidPageUrl: {
    id: "app.invalidPageUrl",
    defaultMessage: "Invalid page URL",
    description: "Invalid Page URL",
  },
  userLoggedIn: {
    id: "app.userLoggedIn",
    defaultMessage:
      "You are already logged in as User, please logout in the main site to continue!",
    description: "You are already Logged-in as user",
  },
  provideListId: {
    id: "app.provideListId",
    defaultMessage: "Provide list ID",
    description: "Provide list ID",
  },
  onlyNumericKey: {
    id: "app.onlyNumericKey",
    defaultMessage: "Only numeric values are allowed",
    description: "Only numeric values are allowed",
  },
  noRecordFound: {
    id: "app.noRecordFound",
    defaultMessage: "No matching records found.",
    description: "No matching records found.",
  },
  ratingReviewLabel: {
    id: "app.ratingReviewLabel",
    defaultMessage: "Review rating",
    description: "Review Rating",
  },
  deleteReviewLabel: {
    id: "app.deleteReviewLabel",
    defaultMessage: "Delete review",
    description: "Deleting Review",
  },
  idLabel: {
    id: "app.idLabel",
    defaultMessage: "ID",
    description: "ID",
  },
  emailBlankSpaceAdmin: {
    id: "app.emailBlankSpaceAdmin",
    defaultMessage: "Email should not be blank spaces",
    description: "email address error",
  },
  passwordInvalidAdmin: {
    id: "app.passwordInvalidAdmin",
    defaultMessage: "Password should be minimum 8 characters.",
    description: "Password minimum characters error",
  },
  passwordBlankSpaceAdmin: {
    id: "app.passwordBlankSpaceAdmin",
    defaultMessage: "Password should not be blank space.",
    description: "Password error",
  },
  sNoLabel: {
    id: "app.sNoLabel",
    defaultMessage: "S.No",
    description: "S.No",
  },
  deletingPageDetails: {
    id: "app.deletingPageDetails",
    defaultMessage: "Deleting page details",
    description: "Deleting Page Details",
  },
  changeAdminPasswordDesc: {
    id: "app.changeAdminPasswordDesc",
    defaultMessage:
      "Leave email address field blank if you don't want to change the email",
    description:
      "Leave email address field blank if you don't want to change the email",
  },
  passWordMismatch: {
    id: "app.passWordMismatch",
    defaultMessage: "Password is mismatching with Confirm password",
    description: "Password is mismatching with Confirm password",
  },
  passWordMinimum: {
    id: "app.passWordMinimum",
    defaultMessage: "Password must be minimum 8 characters",
    description: "Password must be minimum 8 characters",
  },
  symbolLabel: {
    id: "app.symbolLabel",
    defaultMessage: "Symbol",
    description: "Symbol",
  },
  baseCurrencyLabel: {
    id: "app.baseCurrencyLabel",
    defaultMessage: "Base currency",
    description: "Base Currency",
  },
  setEnableDisable: {
    id: "app.setEnableDisable",
    defaultMessage: "Action",
    description: "Action",
  },
  setCurrencyLabel: {
    id: "app.setCurrencyLabel",
    defaultMessage: "Set base currency",
    description: "Set Base Currency",
  },
  allowedPaymentCurrency: {
    id: "app.allowedPaymentCurrency",
    defaultMessage: "Allowed payment currency",
    description: "Allowed Payment Currency",
  },
  hostEMailLabel: {
    id: "app.hostEMailLabel",
    defaultMessage: "Host Email",
    description: "Host Email",
  },
  RequestedFiles: {
    id: "app.RequestedFiles",
    defaultMessage: "Requested files",
    description: "Requested Files",
  },
  actionLabel: {
    id: "app.actionLabel",
    defaultMessage: "Action",
    description: "Action",
  },
  createdDate: {
    id: "app.createdDate",
    defaultMessage: "Created date",
    description: "Created Date",
  },
  stateLabel: {
    id: "app.stateLabel",
    defaultMessage: "State",
    description: "State",
  },
  recommendLabel: {
    id: "app.recommendLabel",
    defaultMessage: "Recommend",
    description: "Recommend",
  },
  setLabel: {
    id: "app.setLabel",
    defaultMessage: "Set",
    description: "Set",
  },
  publishedLabel: {
    id: "app.publishedLabel",
    defaultMessage: "Published",
    description: "Published",
  },
  deletingListingTitle: {
    id: "app.deletingListingTitle",
    defaultMessage: "Deleting listing",
    description: "Deleting Listing",
  },
  settingsIDLabel: {
    id: "app.settingsIDLabel",
    defaultMessage: "Settings ID",
    description: "Settings ID",
  },
  operationLabel: {
    id: "app.operationLabel",
    defaultMessage: "Operation",
    description: "Operation",
  },
  payoutsLabel: {
    id: "app.payoutsLabel",
    defaultMessage: "Payouts",
    description: "Payouts",
  },
  dataMissing: {
    id: "app.dataMissing",
    defaultMessage: "List is missing",
    description: "List is missing",
  },
  hostEmailIdLabel: {
    id: "app.hostEmailIdLabel",
    defaultMessage: "Host Email ID",
    description: "Host Email ID",
  },
  guestEmailIdLabel: {
    id: "app.guestEmailIdLabel",
    defaultMessage: "Guest Email ID",
    description: "Guest Email ID",
  },
  messagesLabel: {
    id: "app.messagesLabel",
    defaultMessage: "Messages",
    description: "Messages",
  },
  deletePopularLocationLabel: {
    id: "app.deletePopularLocationLabel",
    defaultMessage: "Deleting popular location",
    description: "Deleting Popular Location",
  },
  reporterName: {
    id: "app.reporterName",
    defaultMessage: "Reporter name",
    description: "Reporter Name",
  },
  reporterEmail: {
    id: "app.reporterEmail",
    defaultMessage: "Reporter email",
    description: "Reporter Email",
  },
  userNameLabel: {
    id: "app.userNameLabel",
    defaultMessage: "User name",
    description: "User Name",
  },
  userEmailLabel: {
    id: "app.userEmailLabel",
    defaultMessage: "User email ID",
    description: "User Email ID",
  },
  reportType: {
    id: "app.reportType",
    defaultMessage: "Report type",
    description: "Report Type",
  },
  refundToGuest: {
    id: "app.refundToGuest",
    defaultMessage: "Refund to guest",
    description: "Refund to Guest",
  },
  subTotalLabel: {
    id: "app.subTotalLabel",
    defaultMessage: "Sub total",
    description: "Sub Total",
  },
  payoutLabelAdmin: {
    id: "app.payoutLabelAdmin",
    defaultMessage: "Payout",
    description: "Payout",
  },
  pleaseChooseCurreny: {
    id: "app.pleaseChooseCurreny",
    defaultMessage: "Please choose a currency",
    description: "Please choose a currency",
  },
  currencyRequiredLabel: {
    id: "app.currencyRequiredLabel",
    defaultMessage: "Currency is required when you use fixed price",
    description: "Currency is required when you use fixed price",
  },
  choosePresentageValue: {
    id: "app.choosePresentageValue",
    defaultMessage: "Choose percentage value between 1 to 99.99",
    description: "Choose percentage value between 1 to 99",
  },
  reason: {
    id: "app.reason",
    defaultMessage: "Reason",
    description: "Reason",
  },
  allLabel: {
    id: "app.allLabel",
    defaultMessage: "All",
    description: "All",
  },
  profileID: {
    id: "app.profileID",
    defaultMessage: "Profile ID",
    description: "Profile ID",
  },
  selectLabel: {
    id: "app.selectLabel",
    defaultMessage: "Select",
    description: "Select",
  },
  banLabel: {
    id: "app.banLabel",
    defaultMessage: "Prohibit",
    description: "Prohibit",
  },
  unBanLabel: {
    id: "app.unBanLabel",
    defaultMessage: "Permit",
    description: "Permit",
  },
  deletingUser: {
    id: "app.deletingUser",
    defaultMessage: "Deleting user",
    description: "Deleting User",
  },
  usersLabel: {
    id: "app.usersLabel",
    defaultMessage: "Users",
    description: "Users",
  },
  pageName: {
    id: "app.pageName",
    defaultMessage: "Page name",
    description: "Page Name",
  },
  reservationConfirmCode: {
    id: "app.reservationConfirmCode",
    defaultMessage: "Reservation confirmation code",
    description: "Reservation Confirmation Code",
  },
  CheckInDate: {
    id: "app.CheckInDate",
    defaultMessage: "Check-in",
    description: "Check-in",
  },
  CheckOutDate: {
    id: "app.CheckOutDate",
    defaultMessage: "Checkout",
    description: "Checkout",
  },
  SenderLabel: {
    id: "app.SenderLabel",
    defaultMessage: "Sender",
    description: "Sender",
  },
  reviewStatusLabel: {
    id: "app.reviewStatusLabel",
    defaultMessage: "Review status",
    description: "Review Status",
  },
  writeSomethigs: {
    id: "app.writeSomethigs",
    defaultMessage: "write something.......",
    description: "write something.......",
  },
  cookieStaticPolicy: {
    id: "app.cookieStaticPolicy",
    defaultMessage: "Cookie Policy",
    description: "Cookie Policy",
  },
  wishListSave: {
    id: "app.wishListSave",
    defaultMessage: "Save",
    description: "Save Button",
  },
  noPhoneNumberEntered: {
    id: "app.noPhoneNumberEntered",
    defaultMessage: "No phone number entered.",
    description: "No phone number entered.",
  },
  welcomeAdminLabel: {
    id: "app.welcomeAdminLabel",
    defaultMessage: "Welcome Admin !!!",
    description: "Welcome Admin !!!",
  },
  listDeleted: {
    id: "app.listDeleted",
    defaultMessage: "User deleted",
    description: "User deleted",
  },
  verifiedLabel: {
    id: "app.verifiedLabel",
    defaultMessage: "Verified",
    description: "verifiedBy",
  },
  linkCopiedLabel: {
    id: "app.linkCopiedLabel",
    defaultMessage: "Link Copied",
    description: "Link Copied",
  },
  copyLinkLabel: {
    id: "app.copyLinkLabel",
    defaultMessage: "Copy Link",
    description: "Copy Link",
  },
  todayLabel: {
    id: "app.todayLabel",
    defaultMessage: "Today",
    description: "todayLabel",
  },
  ibanNumber: {
    id: "app.ibanNumber",
    defaultMessage: "IBAN number",
    description: "ibanNumber",
  },
  confirmIbanNumber: {
    id: "app.confirmIbanNumber",
    defaultMessage: "Confirm IBAN number",
    description: "confirmIbanNumber",
  },
  ibanNumberInvalid: {
    id: "app.ibanNumberInvalid",
    defaultMessage: "Invalid IBAN number",
    description: "ibanNumberInvalid",
  },
  confirmIbanNumberInvalid: {
    id: "app.confirmIbanNumberInvalid",
    defaultMessage: "Invalid confirm IBAN number",
    description: "confirmIbanNumberInvalid",
  },
  confirmIbanNumberMismatch: {
    id: "app.confirmIbanNumberMismatch",
    defaultMessage: "IBAN number mismatch",
    description: "confirmIbanNumberMismatch",
  },
  stripeTokenInfo1: {
    id: "app.stripeTokenInfo1",
    defaultMessage: "By clicking, you agree to our terms and the ",
    description: "stripeTokenInfo1",
  },
  stripeTokenInfo2: {
    id: "app.stripeTokenInfo2",
    defaultMessage: "Stripe Connected Account Agreement",
    description: "stripeTokenInfo2",
  },
  ofLabel: {
    id: "app.PaginationOfContent",
    defaultMessage: "of",
    description: "of",
  },
  adminDashboard: {
    id: "app.adminDashboard",
    defaultMessage: "Dashboard",
    description: "of",
  },
  clickHeretoUploadLogo: {
    id: "app.clickHeretoUploadLogo",
    defaultMessage: "Click here to upload the logo",
    description: "clickHeretoUploadLogo",
  },
  userManagement: {
    id: "app.userManagement",
    defaultMessage: "Manage users",
    description: "userManagement",
  },
  profileVerifiedView: {
    id: "app.profileVerifiedView",
    defaultMessage: "View verified profile",
    description: "View Verified Profile",
  },
  listingsManagement: {
    id: "app.listingsManagement",
    defaultMessage: "Manage listings",
    description: "listingsManagement",
  },
  readyTopay: {
    id: "app.readyTopay",
    defaultMessage: "Ready to pay",
    description: "readyTopay",
  },
  usersReviews: {
    id: "app.usersReviews",
    defaultMessage: "Reviews management",
    description: "usersReviews",
  },
  servicefeesSettings: {
    id: "app.servicefeesSettings",
    defaultMessage: "Manage service fee",
    description: "Manage Service Fee",
  },
  documentVerificationManagement: {
    id: "app.documentVerificationManagement",
    defaultMessage: "Manage document verification ",
    description: "Manage Document Verification ",
  },
  pdf: {
    id: "app.pdf",
    defaultMessage: "PDF",
    description: "pdf",
  },
  reportUserMessage: {
    id: "app.reportUserMessage",
    defaultMessage: "Report user",
    description: "reportUser",
  },
  payoutManagement: {
    id: "app.payoutManagement",
    defaultMessage: "Manage payout",
    description: "payoutManagement",
  },
  currencyManagement: {
    id: "app.currencyManagement",
    defaultMessage: "Manage currency",
    description: "Manage Currency",
  },
  active: {
    id: "app.active",
    defaultMessage: "Active",
    description: "Active",
  },
  changeAdminEmailPassword: {
    id: "app.changeAdminEmailPassword",
    defaultMessage: "Change admin Email/Password",
    description: "changeAdminEmailPassword",
  },
  bannerSettings: {
    id: "app.bannerSettings",
    defaultMessage: "Banner caption",
    description: "Banner Caption",
  },
  homepageBannererSettings: {
    id: "app.homepageBannererSettings",
    defaultMessage: "Image banner",
    description: "banHome page BannernerSettings",
  },
  clickHeretoUploadImage: {
    id: "app.clickHeretoUploadImage",
    defaultMessage: "Click here to upload image",
    description: "Click Here to Upload Image",
  },
  whyBecomeHostBlock1: {
    id: "app.whyBecomeHostBlock1",
    defaultMessage: "Why host page banner",
    description: "Why Become Host Block 1",
  },
  whyBecomeHostBlock2: {
    id: "app.whyBecomeHostBlock2",
    defaultMessage: "Why Become Host Block 2",
    description: "whyBecomeHostBlock2",
  },
  whyBecomeHostBlock3: {
    id: "app.whyBecomeHostBlock3",
    defaultMessage: "Why Become Host Block 3",
    description: "whyBecomeHostBlock3",
  },
  whyBecomeHostBlock4: {
    id: "app.whyBecomeHostBlock4",
    defaultMessage: "Why Become Host Block 4",
    description: "whyBecomeHostBlock4",
  },
  whyBecomeHostBlock5: {
    id: "app.whyBecomeHostBlock5",
    defaultMessage: "Why Become Host Block 5",
    description: "whyBecomeHostBlock5",
  },
  whyBecomeHostBlock6: {
    id: "app.whyBecomeHostBlock6",
    defaultMessage: "Why host  - Quote section",
    description: "whyBecomeHostBlock6",
  },
  whyBecomeHostBlock7: {
    id: "app.whyBecomeHostBlock7",
    defaultMessage: "Why host - FAQ",
    description: "whyBecomeHostBlock7",
  },
  contentManagementSystem: {
    id: "app.contentManagementSystem",
    defaultMessage: "Content management",
    description: "contentManagementSystem",
  },
  editPageDetails: {
    id: "app.editPageDetails",
    defaultMessage: "Edit page details",
    description: "editPageDetails",
  },
  editCancelPageDetails: {
    id: "app.editCancelPageDetails",
    defaultMessage: "Edit cancelation  details",
    description: "editCancelPageDetails",
  },
  staticPageManagement: {
    id: "app.staticPageManagement",
    defaultMessage: "Static content page management",
    description: "staticPageManagement",
  },
  failed: {
    id: "app.failed",
    defaultMessage: "Failed",
    description: "Failed Payout status",
  },
  managementReviews: {
    id: "app.managementReviews",
    defaultMessage: "Management reviews",
    description: "managementReviews",
  },
  payoutDetails: {
    id: "app.payoutDetails",
    defaultMessage: "Payout details",
    description: "payoutDetails",
  },
  UploadImage: {
    id: "app.UploadImage",
    defaultMessage: "Upload image",
    description: "Upload Image",
  },
  editPopularLocation: {
    id: "app.editPopularLocation",
    defaultMessage: "Edit popular location",
    description: "Edit Popular Location",
  },
  reservationDetails: {
    id: "app.reservationDetails",
    defaultMessage: "Reservation details",
    description: "Reservation Details Header",
  },
  urlRequired: {
    id: "app.urlRequired",
    defaultMessage: "URL is required",
    description: "Reservation Details Header",
  },
  calendarAddress: {
    id: "app.calendarAddress",
    defaultMessage: "Paste calendar address (URL) here",
    description: "calendarAddress",
  },
  maxPriceGreater: {
    id: "app.maxPriceGreater",
    defaultMessage: "Maximum Price must be greater than Minimum Price",
    description: "maxPriceGreater",
  },
  payoutSortNumber: {
    id: "app.payoutSortNumber",
    defaultMessage: "Sort number",
    description: "Sort Number for United Kingdom(GB)",
  },
  payoutInvalidSortNumber: {
    id: "app.payoutInvalidSortNumber",
    defaultMessage: "Invalid sort number",
    description: "Invalid sort number",
  },
  stripePublishableKey: {
    id: "app.stripePublishableKey",
    defaultMessage: "Stripe publishable key",
    description: "Stripe Publishable Key",
  },
  submitForVerification: {
    id: "app.submitForVerification",
    defaultMessage: "Submit for verification",
    description: "Submit Verification button",
  },
  submitForAppeal: {
    id: "app.submitForAppeal",
    defaultMessage: "Submit for appeal",
    description: "Submit Appeal button",
  },
  waitingForAdmin: {
    id: "app.waitingForAdmin",
    defaultMessage: "Waiting for the admin approval.",
    description: "Waiting For Admin",
  },
  require: {
    id: "app.require",
    defaultMessage: "Required",
    description: "Required",
  },
  optional: {
    id: "app.optional",
    defaultMessage: "Optional",
    description: "Optional",
  },
  listingApproval: {
    id: "app.listingApproval",
    defaultMessage: "Listing request",
    description: "Listing Request",
  },
  readyForApproval: {
    id: "app.readyForApproval",
    defaultMessage: "*Your listing is submitted for verification",
    description: "Ready For Approval",
  },
  readyForVerification: {
    id: "app.readyForVerification",
    defaultMessage: "*Your listing is ready for verification",
    description: "Ready For Verification",
  },
  submittedOn: {
    id: "app.submittedOn",
    defaultMessage: "Submitted On",
    description: "submittedOn",
  },
  action: {
    id: "app.action",
    defaultMessage: "Action",
    description: "Action",
  },
  favIconLogoLabel: {
    id: "app.favIconlogoLabel",
    defaultMessage: "Fav Icon",
    description: "Fav Icon",
  },
  ogImageLabel: {
    id: "app.ogImageLabel",
    defaultMessage: "OG Image",
    description: "OG Image",
  },
  ogToolTip: {
    id: "app.ogToolTip",
    defaultMessage:
      "It's the image that represents your webpage when shared on platforms like Facebook, X, or LinkedIn.(Recommended size: at least 1,200 x 630 pixels with a file size under 8MB)",
    description: "OG Tool Tip",
  },
  approved: {
    id: "app.approved",
    defaultMessage: "Approved",
    description: "approved",
  },
  declined: {
    id: "app.declined",
    defaultMessage: "Declined",
    description: "declined",
  },
  declinedReason: {
    id: "app.declinedReason",
    defaultMessage: "Declined reason",
    description: "declinedReason",
  },
  comment: {
    id: "app.comment",
    defaultMessage: "Comment",
    description: "Comment",
  },
  pngOnlyLabel: {
    id: "app.pngOnlyLabel",
    defaultMessage: "* png images only",
    description: "* png images only",
  },
  bookingConfirmed: {
    id: "app.bookingConfirmed",
    defaultMessage: "Booking Confirmed",
    description: "Booking Confirmed",
  },
  declineAdmin: {
    id: "app.declineAdmin",
    defaultMessage: "This listing is declined",
    description: "declineAdmin",
  },
  weeklyPriceDiscount: {
    id: "app.weeklyPriceDiscount",
    defaultMessage: "% weekly price discount",
    description: "weeklyPriceDiscount",
  },
  monthlyPriceDiscount: {
    id: "app.monthlyPriceDiscount",
    defaultMessage: "% monthly price discount",
    description: "monthlyPriceDiscount",
  },
  history: {
    id: "app.history",
    defaultMessage: "History",
    description: "history",
  },
  listingRejected: {
    id: "app.listingRejected",
    defaultMessage: "Listing rejected",
    description: "listingRejected",
  },
  requests: {
    id: "app.requests",
    defaultMessage: "Requests",
    description: "Listing Requests",
  },
  clickHeretoUploadFavIcon: {
    id: "app.clickHeretoUploadFavIcon",
    defaultMessage: "Click here to upload the favicon",
    description: "Click here to upload the favicon",
  },
  clickHeretoUploadOgImage: {
    id: "app.clickHeretoUploadOgImage",
    defaultMessage: "Click here to upload the OG Image",
    description: "Click here to upload the og OG Image",
  },
  personalHome: {
    id: "app.personalHome",
    defaultMessage: "Personal home",
    description: "Personal home",
  },
  homePageLayoutDesc5: {
    id: "rentall.homePageLayoutDesc5",
    defaultMessage: "Single Banner Image Layout with  Search Form",
    description: "homePageLayoutDesc5",
  },
  managePaymentGateWay: {
    id: "app.managePaymentGateWay",
    defaultMessage: "Manage payment gateway",
    description: "Manage Payment Gateway",
  },
  paymentGateway: {
    id: "app.paymentGateway",
    defaultMessage: "Payment gateway",
    description: "Payment Gateway",
  },
  paymentGatewaySection: {
    id: "app.paymentGatewaySection",
    defaultMessage: "Manage payment gateway",
    description: "Manage Payment Gateway",
  },
  activeLabel: {
    id: "app.activeLabel",
    defaultMessage: "Active",
    description: "Active",
  },
  inActiveLabel: {
    id: "app.inActiveLabel",
    defaultMessage: "Inactive",
    description: "Inactive",
  },
  placesToStay: {
    id: "app.placesToStay",
    defaultMessage: "Places to stay",
    description: "Places to stay",
  },
  languageCurrency: {
    id: "app.languageCurrency",
    defaultMessage: "Language / Currency",
    description: "languageCurrency",
  },
  updateProfile: {
    id: "app.updateProfile",
    defaultMessage: "Update profile",
    description: "UpdateProfile",
  },
  whyHostPage: {
    id: "app.whyHostPage",
    defaultMessage: "Why Host Page",
    description: "Why Host Page",
  },
  cancellationPolicyManagement: {
    id: "app.cancellationPolicyManagement",
    defaultMessage: "Cancelation policy management",
    description: "cancelationPolicyManagement",
  },
  policyName: {
    id: "app.policyName",
    defaultMessage: "Policy name",
    description: "policyName",
  },
  policyContent: {
    id: "app.policyContent",
    defaultMessage: "Policy content",
    description: "policyContent",
  },
  priorDays: {
    id: "app.priorDays",
    defaultMessage: "Days prior to the check-in",
    description: "Days Prior to the check-in",
  },
  accommodationPriorCheckIn: {
    id: "app.accommodationPriorCheckIn",
    defaultMessage: "Guest's refundable amount - prior check-in",
    description: "accommodationPriorCheckIn",
  },
  accommodationBeforeCheckIn: {
    id: "app.accommodationBeforeCheckIn",
    defaultMessage: "Guest's refundable amount - before check-in",
    description: "accommodationBeforeCheckIn",
  },
  accommodationDuringCheckIn: {
    id: "app.accommodationDuringCheckIn",
    defaultMessage: "Guest's refundable amount - during check-in",
    description: "accommodationDuringCheckIn",
  },
  guestFeePriorCheckIn: {
    id: "app.guestFeePriorCheckIn",
    defaultMessage: "Refundable guestfee - prior check-in",
    description: "guestFeePriorCheckIn",
  },
  guestFeeBeforeCheckIn: {
    id: "app.guestFeeBeforeCheckIn",
    defaultMessage: "Refundable guestfee - before check-in",
    description: "guestFeeBeforeCheckIn",
  },
  guestFeeDuringCheckIn: {
    id: "app.guestFeeDuringCheckIn",
    defaultMessage: "Refundable guestfee - during check-in",
    description: "guestFeeDuringCheckIn",
  },
  hostFeePriorCheckIn: {
    id: "app.hostFeePriorCheckIn",
    defaultMessage: "Payable hostfee - prior check-in",
    description: "hostFeePriorCheckIn",
  },
  hostFeeBeforeCheckIn: {
    id: "app.hostFeeBeforeCheckIn",
    defaultMessage: "Payable hostfee - before check-in",
    description: "hostFeeBeforeCheckIn",
  },
  hostFeeDuringCheckIn: {
    id: "app.hostFeeDuringCheckIn",
    defaultMessage: "Payable hostfee - during check-in",
    description: "hostFeeDuringCheckIn",
  },
  nonRefundableNightsPriorCheckIn: {
    id: "app.nonRefundableNightsPriorCheckIn",
    defaultMessage: "NonRefundable nights - prior check-in",
    description: "nonRefundableNightsPriorCheckIn",
  },
  nonRefundableNightsBeforeCheckIn: {
    id: "app.nonRefundableNightsBeforeCheckIn",
    defaultMessage: "Non refundable nights - before check-in",
    description: "nonRefundableNightsBeforeCheckIn",
  },
  nonRefundableNightsDuringCheckIn: {
    id: "app.nonRefundableNightsDuringCheckIn",
    defaultMessage: "NonRefundable nights - during check-in",
    description: "nonRefundableNightsDuringCheckIn",
  },
  manageSiteConfig: {
    id: "app.manageSiteConfig",
    defaultMessage: "Manage site configurations",
    description: "manageSiteConfig",
  },
  maxUploadLabel: {
    id: "app.maxUploadLabel",
    defaultMessage: "Maximum image upload size [MB]",
    description: "maxUploadLabel",
  },
  fcmPushNotificationKey: {
    id: "app.fcmPushNotificationKey",
    defaultMessage: "FCM push notification key",
    description: "fcmPushNotificationKey",
  },
  deepLinkBundleId: {
    id: "app.deepLinkBundleId",
    defaultMessage: "Deep link bundle ID",
    description: "deepLinkBundleId",
  },
  smtpHost: {
    id: "app.smtpHost",
    defaultMessage: "SMTP host",
    description: "smtpHost",
  },
  smtpPort: {
    id: "app.smtpPort",
    defaultMessage: "SMTP port",
    description: "smtpPort",
  },
  smptEmail: {
    id: "app.smptEmail",
    defaultMessage: "SMTP email",
    description: "smptEmail",
  },
  smtpSender: {
    id: "app.smtpSender",
    defaultMessage: "SMTP sender",
    description: "smtpSender",
  },
  smtpSenderEmail: {
    id: "app.smtpSenderEmail",
    defaultMessage: "SMTP sender email",
    description: "smtpSenderEmail",
  },
  smtpPassWord: {
    id: "app.smtpPassWord",
    defaultMessage: "SMTP password",
    description: "smtpPassWord",
  },
  twillioAccountSid: {
    id: "app.twillioAccountSid",
    defaultMessage: "Twilio account SID",
    description: "twillioAccountSid",
  },
  twillioAuthToken: {
    id: "app.twillioAuthToken",
    defaultMessage: "Twilio auth token",
    description: "twillioAuthToken",
  },
  twillioPhone: {
    id: "app.twillioPhone",
    defaultMessage: "Twilio phone number",
    description: "twillioPhone",
  },
  paypalClientId: {
    id: "app.paypalClientId",
    defaultMessage: "PayPal client ID",
    description: "paypalClientId",
  },
  paypalSecret: {
    id: "app.paypalSecret",
    defaultMessage: "PayPal secret key",
    description: "paypalSecret",
  },
  paypalHost: {
    id: "app.paypalHost",
    defaultMessage: "PayPal host",
    description: "paypalHost",
  },
  minPrice: {
    id: "app.minPrice",
    defaultMessage: "Min price",
    description: "minPrice",
  },
  showAllReviews: {
    id: "app.showAllReviews",
    defaultMessage: "Show all reviews",
    description: "Show all reviews",
  },
  reviewAndPay: {
    id: "app.reviewAndPay",
    defaultMessage: "Review and pay",
    description: "Review and pay",
  },
  mapListing: {
    id: "app.mapListing",
    defaultMessage: "Listings",
    description: "Listings",
  },
  priceDetails: {
    id: "app.priceDetails",
    defaultMessage: "Price details",
    description: "Price details",
  },
  priceBreakdown: {
    id: "app.priceBreakdown",
    defaultMessage: "Price breakdown",
    description: "Price breakdown",
  },
  stepOneCommonHeading: {
    id: "app.stepOneCommonHeading",
    defaultMessage: "Step #1",
    description: "Step #1",
  },
  stepTwoCommonHeading: {
    id: "app.stepTwoCommonHeading",
    defaultMessage: "Step #2",
    description: "Step #2",
  },
  stepThreeCommonHeading: {
    id: "app.stepThreeCommonHeading",
    defaultMessage: "Step #3",
    description: "Step #3",
  },
  totalGuests: {
    id: "app.totalGuests",
    defaultMessage: "Guests",
    description: "Guests",
  },
  yourLocationLabel: {
    id: "app.yourLocationLabel",
    defaultMessage: "Your location",
    description: "Your location",
  },
  yourLocationLabelDesc: {
    id: "app.yourLocationLabelDesc",
    defaultMessage: "Search your location ex: USA or other countries",
    description: "Search your location ex: USA or other countries",
  },
  setAsCoverPhoto: {
    id: "app.SetAsCoverPhoto",
    defaultMessage: "Set as cover photo",
    description: "Set as cover photo",
  },
  basePriceTip: {
    id: "app.basePriceTip",
    defaultMessage: "Your base price is your default nightly rate.",
    description: "Your base price is your default nightly rate.",
  },
  discountTip: {
    id: "app.discountTip",
    defaultMessage: "Discount for longer stay",
    description: "Discount for longer stay",
  },
  setHouseRulesPanel: {
    id: "app.setHouseRulesPanel",
    defaultMessage: "What are your house rules?",
    description: "What are your house rules?",
  },
  whatamenitiesPanel: {
    id: "app.whatamenitiesPanel",
    defaultMessage: "Check in and cancelation policy",
    description: "Check in and Cancelation policy",
  },
  minMaxPanel: {
    id: "app.minMaxPanel",
    defaultMessage: "Availability window & Trip length",
    description: "Availability window & Trip length",
  },
  instantPanelText: {
    id: "app.instantPanelText",
    defaultMessage: "What is your preferred booking type?",
    description: "What is your preferred booking type?",
  },
  placeTitlePanel: {
    id: "app.placeTitlePanel",
    defaultMessage: "Name and describe your place",
    description: "Name and describe your place",
  },
  toLabel: {
    id: "app.toLabel",
    defaultMessage: "to",
    description: "to",
  },
  printText: {
    id: "app.printText",
    defaultMessage: "Print",
    description: "Print",
  },
  bookedBy: {
    id: "app.bookedBy",
    defaultMessage: "Booked by",
    description: "bookedBy",
  },
  letsStart: {
    id: "app.letsStart",
    defaultMessage: "Let's go!",
    description: "Let's go!",
  },
  maxPrice: {
    id: "app.maxPrice",
    defaultMessage: "Max price",
    description: "maxPrice",
  },
  hostingBlockImageLabel: {
    id: "app.hostingBlockImageLabel",
    defaultMessage: "Hosting Block Image",
    description: "Hosting Block Image",
  },
  searchYourLocation: {
    id: "app.searchYourLocation",
    defaultMessage: "Enter your address",
    description: "Enter your address",
  },
  editProfilePhoto: {
    id: "app.editProfilePhoto",
    defaultMessage: "Update photo",
    description: "Update photo",
  },
  logInSignUp: {
    id: "app.logInSignUp",
    defaultMessage: "Login",
    description: "Log in",
  },
  signUpHere: {
    id: "app.signUpHere",
    defaultMessage: "Signup",
    description: "Signup",
  },
  logInHere: {
    id: "app.logInHere",
    defaultMessage: "Login",
    description: "Login",
  },
  startExplore: {
    id: "app.StartExplore",
    defaultMessage: "Start explore!",
    description: "Start explore!",
  },
  upcomingTab: {
    id: "app.upcomingTab",
    defaultMessage: "Upcoming",
    description: "Upcoming Reservations",
  },
  previousTab: {
    id: "app.previousTab",
    defaultMessage: "Previous",
    description: "Previous Reservations",
  },
  searchYourListing: {
    id: "app.searchYourListing",
    defaultMessage: "Search your listings",
    description: "Search your listing",
  },
  filterByDate: {
    id: "app.filterByDate",
    defaultMessage: "Filter by date",
    description: "Filter by date",
  },
  filterByListingsDate: {
    id: "app.filterByListingsDate",
    defaultMessage: "Filter by listings",
    description: "Filter by listings",
  },
  StartDate: {
    id: "app.StartDate",
    defaultMessage: "Start date",
    description: "Start date",
  },
  EndDate: {
    id: "app.EndDate",
    defaultMessage: "End date",
    description: "End date",
  },
  ascending: {
    id: "app.ascending",
    defaultMessage: "Sort by oldest",
    description: "ascending",
  },
  descending: {
    id: "app.descending",
    defaultMessage: "Sort by newest",
    description: "descending",
  },
  sortByListings: {
    id: "app.sortByListings",
    defaultMessage: "Sort by order",
    description: "Sort by listings",
  },
  searchReservations: {
    id: "app.searchReservations",
    defaultMessage: "Search reservations",
    description: "Search reservations",
  },
  reviewsAboutYou: {
    id: "app.reviewsAboutYou",
    defaultMessage: "Reviews about you",
    description: "Reviews about you",
  },
  aboutMe: {
    id: "app.aboutMe",
    defaultMessage: "About me",
    description: "aboutMe",
  },
  showAllPhotos: {
    id: "app.showAllPhotos",
    defaultMessage: "Show all photos",
    description: "showAllPhotos",
  },
  bedsCapital: {
    id: "app.bedsCapital",
    defaultMessage: "Beds",
    description: "Beds",
  },
  filterByPayout: {
    id: "app.filterByPayout",
    defaultMessage: "Filter by payout methods",
    description: "Filter by payout methods",
  },
  cancelTrip: {
    id: "app.cancelTrip",
    defaultMessage: "Cancel trip",
    description: "Cancel trip",
  },
  lightMode: {
    id: "app.lightMode",
    defaultMessage: "Light mode",
    description: "Light mode",
  },
  darkMode: {
    id: "app.darkMode",
    defaultMessage: "Dark mode",
    description: "Dark mode",
  },
  chatText: {
    id: "app.chatText",
    defaultMessage: "Chat",
    description: "chatText",
  },
  toolTipContent1: {
    id: "app.toolTipContent1",
    defaultMessage:
      "Guests will receive a full refund if they cancel the reservation prior to the days specified here.",
    description: "Cancel tooltip",
  },
  toolTipContent2: {
    id: "app.toolTipContent2",
    defaultMessage:
      "The accomodation amount of certain percentage (based on the value provided here) will be refunded to the guest if they cancels the reservation after check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent3: {
    id: "app.toolTipContent3",
    defaultMessage:
      "The accomodation amount of certain percentage (based on the value provided here) will be refunded to the guest if they cancels the reservation before the check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent4: {
    id: "app.toolTipContent4",
    defaultMessage:
      'The accomodation amount of certain percentage (based on the value provided here) will be refunded to the guest if they cancels the reservation prior to the days provided in the "Days prior to check-in" field',
    description: "Cancel tooltip",
  },
  toolTipContent5: {
    id: "app.toolTipContent5",
    defaultMessage:
      "A certain percentage (based on the value provided here) of the Service fee will be refunded to the guest if they cancels the reservation after check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent6: {
    id: "app.toolTipContent6",
    defaultMessage:
      "A certain percentage (based on the value provided here) of the Service fee will be refunded to the guest if they cancels the reservation before the check-in date",
    description: "Cancel tooltip",
  },
  toolTipContent7: {
    id: "app.toolTipContent7",
    defaultMessage:
      'A certain percentage (based on the value provided here) of the Service fee will be refunded to the guest if they cancels the reservation prior to the days provided in the "Days prior to check-in" field',
    description: "Cancel tooltip",
  },
  toolTipContent8: {
    id: "app.toolTipContent8",
    defaultMessage:
      "The host will pay a certain percentage (based on the value provided here) of the service fee  if the guest cancels the reservation after check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent9: {
    id: "app.toolTipContent9",
    defaultMessage:
      "The host will pay a certain percentage (based on the value provided here) of the service fee  if the guest cancels the reservation before the check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent10: {
    id: "app.toolTipContent10",
    defaultMessage:
      'The host will pay a certain percentage (based on the value provided here) of the service fee  if the guest cancels the reservation prior to the days provided in the "Days prior to check-in" field',
    description: "Cancel tooltip",
  },
  toolTipContent11: {
    id: "app.toolTipContent11",
    defaultMessage:
      "The accommodation amount  for certain nights (based on the value provided here) will not be refunded to the guest if they cancel the reservation after check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent12: {
    id: "app.toolTipContent12",
    defaultMessage:
      "The accommodation amount  for certain nights (based on the value provided here) will not be refunded to the guest if they cancel the reservation  before the check-in date.",
    description: "Cancel tooltip",
  },
  toolTipContent13: {
    id: "app.toolTipContent13",
    defaultMessage:
      'The accommodation amount for certain nights (based on the value provided here) will not be refunded to the guest if they cancel the reservation prior to the days provided in the "Days prior to check-in" field.',
    description: "Cancel tooltip",
  },
  reviewsToWrite: {
    id: "app.reviewsToWrite",
    defaultMessage: "Reviews to write",
    description: "reviewsToWrite",
  },
  writtenReviews: {
    id: "app.writtenReviews",
    defaultMessage: "Written reviews",
    description: "writtenReviews",
  },
  verifyLabel: {
    id: "app.verifyLabel",
    defaultMessage: "Verify",
    description: "Verify",
  },
  responded: {
    id: "app.responded",
    defaultMessage: "Responded",
    description: "responded",
  },
  notResponded: {
    id: "app.notResponded",
    defaultMessage: "Not responded",
    description: "notResponded",
  },
  reply: {
    id: "app.reply",
    defaultMessage: "Reply",
    description: "reply",
  },
  sayHelloTo: {
    id: "app.sayHelloTo",
    defaultMessage: "Hello",
    description: "Hello",
  },
  SearchTransactions: {
    id: "app.SearchTransactions",
    defaultMessage: "Search transactions",
    description: "SearchTransactions",
  },
  subTitle: {
    id: "app.subTitle",
    defaultMessage: "Sub title",
    description: "subTitle",
  },
  subContent: {
    id: "app.subContent",
    defaultMessage: "Description",
    description: "subContent",
  },
  content1: {
    id: "app.content1",
    defaultMessage: "Before day:",
    description: "content1",
  },
  content2: {
    id: "app.content2",
    defaultMessage: "Check-in",
    description: "content2",
  },
  content3: {
    id: "app.content3",
    defaultMessage: "Checkout",
    description: "content3",
  },
  before: {
    id: "app.before",
    defaultMessage: "Before",
    description: "before",
  },
  updateCancellationInfo: {
    id: "app.updateCancellationInfo",
    defaultMessage: "Update cancelation info",
    description: "Update Cancelation Info",
  },
  searchReviews: {
    id: "app.searchReviews",
    defaultMessage: "Search reviews",
    description: "Search reviews",
  },
  manageForceUpdate: {
    id: "app.manageForceUpdate",
    defaultMessage: "Force update",
    description: "manageForceUpdate",
  },
  invalidVersionNumber: {
    id: "app.invalidVersionNumber",
    defaultMessage: "Invalid Version Number",
    description: "invalidVersionNumber",
  },
  noListHeading: {
    id: "app.noListHeading",
    defaultMessage: "You don't have any listings!",
    description: "noListHeading",
  },
  noListSmall: {
    id: "app.noListSmall",
    defaultMessage: "List your unused space to make money.",
    description: "noListHeading",
  },
  addNewlisting: {
    id: "app.addNewlisting",
    defaultMessage: "Add a new listing",
    description: "noListHeading",
  },
  noWishListText: {
    id: "app.noWishListText",
    defaultMessage:
      "As you search, tap the Heart icon to save your favorite listings to say or things to do to a wishlist",
    description: "noWishListText",
  },
  onLabel: {
    id: "app.onLabel",
    defaultMessage: "on",
    description: "onLabel",
  },
  adminManageReservation: {
    id: "app.adminManageReservation",
    defaultMessage: "Manage reservations",
    description: "Manage Reservations",
  },
  grant: {
    id: "app.grant",
    defaultMessage: "Grant",
    description: "Grant",
  },
  deny: {
    id: "app.deny",
    defaultMessage: "Deny",
    description: "Deny",
  },
  writeAReview: {
    id: "app.writeAReview",
    defaultMessage: "Write a review",
    description: "Write a review",
  },
  changeAdminPassword: {
    id: "app.changeAdminPassword",
    defaultMessage: "New password",
    description: "New password",
  },
  bannerTitle: {
    id: "app.bannerTitle",
    defaultMessage: "Banner title",
    description: "Banner title",
  },
  bannerDescription: {
    id: "app.bannerDescription",
    defaultMessage: "Banner description",
    description: "Banner description",
  },
  managePopularLocation: {
    id: "app.managePopularLocation",
    defaultMessage: "Manage popular locations",
    description: "Manage Popular Locations",
  },
  homePageBanner: {
    id: "app.homePageBanner",
    defaultMessage: "Home page banner ",
    description: "Home Page Banner ",
  },
  document: {
    id: "app.document",
    defaultMessage: "Documents",
    description: "Documents ",
  },
  documentConfirmed: {
    id: "app.documentConfirmed",
    defaultMessage: "Documents confirmed",
    description: "Documents confirmed ",
  },
  listingNotAvailable: {
    id: "app.listingNotAvailable",
    defaultMessage: "Listing not available",
    description: "Listing not available",
  },
  noReviewHeading: {
    id: "app.noReviewHeading",
    defaultMessage: "There are no reviews about you yet.",
    description: "There are no reviews about you yet.",
  },
  noReviewSubHeding: {
    id: "app.noReviewSubHeding",
    defaultMessage:
      "Reviews are written at the end of a reservation. Reviews you’ve received will be visible both here and on your public profile.",
    description:
      "Reviews are written at the end of a reservation. Reviews you’ve received will be visible both here and on your public profile.",
  },
  noTransactionText: {
    id: "app.noTransactionText",
    defaultMessage: "No transactions",
    description: "No transactions",
  },
  totalRooms: {
    id: "app.totalRooms",
    defaultMessage: "Total Rooms",
    description: "totalRooms",
  },
  closeAll: {
    id: "app.closeAll",
    defaultMessage: "Close all",
    description: "Close all",
  },
  createListing: {
    id: "app.createListing",
    defaultMessage: "Create your listings",
    description: "Create your listings",
  },
  whereLocatedContent: {
    id: "app.whereLocatedContent",
    defaultMessage: "Where’s your place located?",
    description: "Where’s your place located?",
  },
  admin: {
    id: "app.admin",
    defaultMessage: "Admin",
    description: "Admin",
  },
  moreText: {
    id: "app.moreText",
    defaultMessage: "More",
    description: "moreText",
  },
  singularListingLabel: {
    id: "app.singularListingLabel",
    defaultMessage: "Listing",
    description: "Listing",
  },
  searchTrips: {
    id: "app.searchTrips",
    defaultMessage: "Search trips",
    description: "Search trips",
  },
  noTransactionComplete: {
    id: "app.noTransactionComplete",
    defaultMessage:
      "You do not have any completed transactions for the selected listings, and payout method.",
    description: "noTransactionComplete",
  },
  noTransactionFuture: {
    id: "app.noTransactionFuture",
    defaultMessage:
      "You do not have any upcoming transactions for the selected listings, and payout method.",
    description: "noTransactionFuture",
  },
  noTransactionGross: {
    id: "app.noTransactionGross",
    defaultMessage:
      "You do not have any transactions for the selected listings, and payout method.",
    description: "noTransactionGross",
  },
  keepTrip: {
    id: "app.keepTrip",
    defaultMessage: "Keep your trip",
    description: "Keep reservation",
  },
  refreshToolTip: {
    id: "app.refreshToolTip",
    defaultMessage:
      "Please click this icon to sync your email verification on the same browser.",
    description: "refreshToolTip",
  },
  googleClientId: {
    id: "app.googleClientId",
    defaultMessage: "Google client ID",
    description: "googleClientId",
  },
  googleSecretId: {
    id: "app.googleSecretId",
    defaultMessage: "Google secret ID",
    description: "googleSecretId",
  },
  deepLinkContent: {
    id: "app.deepLinkContent",
    defaultMessage: "Deep link JSON content",
    description: "deepLinkContent",
  },
  logIn: {
    id: "app.loginTabTitle",
    defaultMessage: "Log In",
    description: "tabTitle",
  },
  registerUser: {
    id: "app.registerTabTitle",
    defaultMessage: "New User Registration",
    description: "tabTitle",
  },
  adminPage: {
    id: "app.adminTabTitle",
    defaultMessage: "Admin Page",
    description: "tabTitle",
  },
  userProfile: {
    id: "app.userProfileTabTitle",
    defaultMessage: "User Profile",
    description: "tabTitle",
  },
  staticTab: {
    id: "app.staticTabTitle",
    defaultMessage: "Static Page",
    description: "tabTitle",
  },
  documentVerificationTab: {
    id: "app.documentVerificationTabTitle",
    defaultMessage: "DocumentVerification",
    description: "tabTitle",
  },
  payoutFailureTab: {
    id: "app.payoutFailureTabTitle",
    defaultMessage: "Payout Failure",
    description: "tabTitle",
  },
  userBanTab: {
    id: "app.userBanTabTitle",
    defaultMessage: "User Banned",
    description: "tabTitle",
  },
  writeReviewTab: {
    id: "app.writeReviewTabTitle",
    defaultMessage: "Write Review",
    description: "tabTitle",
  },
  manageListingTab: {
    id: "app.manageListingTabTitle",
    defaultMessage: "Manage Listing",
    description: "tabTitle",
  },
  viewMessageTab: {
    id: "app.viewMessageTabTitle",
    defaultMessage: "ViewMessage",
    description: "tabTitle",
  },
  payoutPreferencesTab: {
    id: "app.payoutPreferencesTabTitle",
    defaultMessage: "Payout Preferences",
    description: "tabTitle",
  },
  addPayoutPreferencesTab: {
    id: "app.addPayoutPreferencesTabTitle",
    defaultMessage: "Add Payout Preferences",
    description: "tabTitle",
  },
  itineraryTab: {
    id: "app.itineraryTabTitle",
    defaultMessage: "Itinerary",
    description: "tabTitle",
  },
  pageNotFoundTab: {
    id: "app.pageNotFoundTabTitle",
    defaultMessage: "Page Not Found",
    description: "tabTitle",
  },
  addNewNageDetailsTab: {
    id: "app.addNewNageDetailsTabTitle",
    defaultMessage: "Add a new page details",
    description: "tabTitle",
  },
  whyhostTab: {
    id: "app.whyhostTabTitle",
    defaultMessage: "whyhost",
    description: "tabTitle",
  },
  adminDashboardTab: {
    id: "app.adminDashboardTabTitle",
    defaultMessage: "Admin Dashboard",
    description: "tabTitle",
  },
  editUserTab: {
    id: "app.editUserTabTitle",
    defaultMessage: "Edit User",
    description: "tabTitle",
  },
  userManagementTab: {
    id: "app.userManagementTabTitle",
    defaultMessage: "User Management",
    description: "tabTitle",
  },
  siteConfigurationSettingsTab: {
    id: "app.siteConfigurationSettingsTabTitle",
    defaultMessage: "Site Configuration Settings",
    description: "tabTitle",
  },
  listingsManagementTab: {
    id: "app.listingsManagementTabTitle",
    defaultMessage: "Listings Management",
    description: "tabTitle",
  },
  currencyManagementTab: {
    id: "app.currencyManagementTabTitle",
    defaultMessage: "Currency Management",
    description: "tabTitle",
  },
  bannerSettingsTab: {
    id: "app.bannerSettingsTabTitle",
    defaultMessage: "Banner Settings",
    description: "tabTitle",
  },
  homePageBannerTab: {
    id: "app.homePageBannerTabTitle",
    defaultMessage: "Home page Banner",
    description: "tabTitle",
  },
  serviceFeesSettingsTab: {
    id: "app.serviceFeesSettingsTabTitle",
    defaultMessage: "Service Fees Settings",
    description: "tabTitle",
  },
  addPopularLocationTab: {
    id: "app.addPopularLocationTabTitle",
    defaultMessage: "Add popular location",
    description: "tabTitle",
  },
  profileVerifiedViewTab: {
    id: "app.profileVerifiedViewTabTitle",
    defaultMessage: "Profile Verified View",
    description: "tabTitle",
  },
  documentVerificationManagementTab: {
    id: "app.documentVerificationManagementTabTitle",
    defaultMessage: "Document Verification Management",
    description: "tabTitle",
  },
  usersReviewsTab: {
    id: "app.usersReviewsTabTitle",
    defaultMessage: "Users Reviews",
    description: "tabTitle",
  },
  editWhyBecomeHostBlock2Tab: {
    id: "app.editWhyBecomeHostBlock2TabTitle",
    defaultMessage: "Edit Why Become Host Block 2",
    description: "tabTitle",
  },
  addNewReviewTab: {
    id: "app.addNewReviewTabTitle",
    defaultMessage: "Add a new review",
    description: "tabTitle",
  },
  whyBecomeHostBlock6Tab: {
    id: "app.whyBecomeHostBlock6TabTitle",
    defaultMessage: "Why Become Host Block 6",
    description: "tabTitle",
  },
  whyBecomeHostBlock7Tab: {
    id: "app.whyBecomeHostBlock7TabTitle",
    defaultMessage: "Why Become Host Block 7",
    description: "tabTitle",
  },
  payoutManagementTab: {
    id: "app.payoutManagementTabTitle",
    defaultMessage: "Payout Management",
    description: "tabTitle",
  },
  failedPayoutTab: {
    id: "app.failedPayoutTabTitle",
    defaultMessage: "Failed Payout",
    description: "tabTitle",
  },
  paymentGatewaySectionTab: {
    id: "app.paymentGatewaySectionTabTitle",
    defaultMessage: "Payment Gateway Section",
    description: "tabTitle",
  },
  cancellationPoliciesManagementTab: {
    id: "app.cancellationPoliciesManagementTabTitle",
    defaultMessage: "Cancelation Policies Management",
    description: "tabTitle",
  },
  adminLogInTab: {
    id: "app.adminLogInTabTitle",
    defaultMessage: "Admin Log In",
    description: "tabTitle",
  },
  editCancelPolicyTab: {
    id: "app.editCancelPolicyTabTitle",
    defaultMessage: "Edit Cancel Policy",
    description: "tabTitle",
  },
  panelInquiry: {
    id: "app.panelInquiry",
    defaultMessage: "Inquiry",
    description: "tabTitle",
  },
  manageInquiry: {
    id: "app.manageInquiry",
    defaultMessage: "Manage Inquiry",
    description: "Manage Inquiry",
  },
  id: {
    id: "app.id",
    defaultMessage: "ID",
    description: "ID",
  },
  checkInAndCheckOut: {
    id: "app.checkInAndCheckOut",
    defaultMessage: "Check-in & CheckOut",
    description: "checkInAndCheckOut",
  },
  bookingStatus: {
    id: "app.bookingStatus",
    defaultMessage: "Booking Status",
    description: "Booking Status",
  },
  removePhotos: {
    id: "app.removePhotos",
    defaultMessage: "Remove photo",
    description: "removePhotos",
  },
  adminCancellation: {
    id: "app.adminCancellation",
    defaultMessage: "Cancel reservation",
    description: "adminCancellation",
  },
  cancellationReason: {
    id: "app.cancellationReason",
    defaultMessage: "Enter your message here",
    description: "cancellationReason",
  },
  hostReasonForCancellation: {
    id: "app.hostReasonForCancellation",
    defaultMessage: "Tell host to why you need to cancel?",
    description: "reasonForCancellation",
  },
  guestReasonForCancellation: {
    id: "app.guestReasonForCancellation",
    defaultMessage: "Tell guest to why you need to cancel?",
    description: "guestReasonForCancellation",
  },
  hostMissedEarnings: {
    id: "app.hostMissedEarnings",
    defaultMessage: "Host missed earnings",
    description: "hostMissedEarnings",
  },
  hostTotalEarnings: {
    id: "app.hostTotalEarnings",
    defaultMessage: "Host total earnings",
    description: "hostTotalEarnings",
  },
  adminGuestRefund: {
    id: "app.adminGuestRefund",
    defaultMessage: "Guest will be refunded the above cost",
    description: "adminGuestRefund",
  },
  adminHostRefund: {
    id: "app.adminHostRefund",
    defaultMessage: "will be refunded the reservation cost",
    description: "adminHostRefund",
  },
  inquiryDetails: {
    id: "app.inquiryDetails",
    defaultMessage: "Inquiry details",
    description: "inquiryDetails",
  },
  displayApp: {
    id: "app.displayApp",
    defaultMessage: "Would you like to display the apps? (If available)",
    description: "displayApp",
  },
  bookingAction: {
    id: "app.bookingAction",
    defaultMessage: "Booking action",
    description: "bookingAction",
  },
  androidLabel: {
    id: "app.androidLabel",
    defaultMessage: "Android",
    description: "Android",
  },
  iOSLabel: {
    id: "app.iOSLabel",
    defaultMessage: "iOS",
    description: "iOS",
  },
  currentPassword: {
    id: "app.currentPassword",
    defaultMessage: "Current password",
    description: "Current password",
  },
  addGuestText: {
    id: "app.addGuestText",
    defaultMessage: "Add guest",
    description: "Add guest",
  },
  addGuests: {
    id: "app.addGuests",
    defaultMessage: "Add guests",
    description: "Add guests",
  },
  icalPlaceHolderTextTwo: {
    id: "app.icalPlaceHolderTextTwo",
    defaultMessage: "Custom name for this calendar",
    description: "Custom name for this calendar",
  },
  bookingIsExpired1: {
    id: "app.bookingIsExpired1",
    defaultMessage: "booking request is expired.",
    description: "booking request is expired.",
  },
  inputFieldRestriction: {
    id: "app.inputFieldRestriction",
    defaultMessage: "Limit exceeded! You can add up to 250 characters.",
    description: "Limit exceeded! You can add up to 250 characters.",
  },
  descriptionFieldRestriction: {
    id: "app.descriptionFieldRestriction",
    defaultMessage: "Limit exceeded! You can add up to 10,000 characters",
    description: "Limit exceeded! You can add up to 10,000 characters",
  },
  discountExceedLimit: {
    id: "app.discountExceedLimit",
    defaultMessage: "Limit exceeded! The discount must be between 0% and 99%.",
    description: "discountExceedLimit",
  },
  editProfileFieldRestriction: {
    id: "app.editProfileFieldRestriction",
    defaultMessage: "Limit exceeded! You can add up to 25 characters.",
    description: "Limit exceeded! You can add up to 25 characters.",
  },
  roles: {
    id: "app.roles",
    defaultMessage: "Roles",
    description: "roles",
  },
  currencies: {
    id: "app.currencies",
    defaultMessage: "Currencies",
    description: "currencies",
  },
  cancellationTitleOne: {
    id: "app.cancellationTitleOne",
    defaultMessage:
      "Cancellation prior to the days mentioned in 'Days prior to the check-in' field:",
    description: "Cancellation Title",
  },
  cancellationTitleTwo: {
    id: "app.cancellationTitleTwo",
    defaultMessage:
      "Cancellation before to the check-in date (i.e within range from Prior date to Check-in date):",
    description: "Cancellation Title",
  },
  cancellationTitleThree: {
    id: "app.cancellationTitleThree",
    defaultMessage: "Cancellation after the check-in date:",
    description: "Cancellation Title",
  },
  cancellationPriorTitleOne: {
    id: "app.cancellationPriorTitleOne",
    defaultMessage: "Refundable guest accomodation amount",
    description: "Cancellation Prior",
  },
  cancellationPriorTitleTwo: {
    id: "app.cancellationPriorTitleTwo",
    defaultMessage: "Refundable guest service fee",
    description: "Cancellation Prior",
  },
  cancellationPriorTitleThree: {
    id: "app.cancellationPriorTitleThree",
    defaultMessage: "Payable host service fee",
    description: "Cancellation Prior",
  },
  cancellationPriorTitleFour: {
    id: "app.cancellationPriorTitleFour",
    defaultMessage: " Non-Refundable nights",
    description: "Cancellation Prior",
  },
  phoneNumberVerificationInfo1: {
    id: "app.phoneNumberVerificationInfo1",
    defaultMessage: "I agree to receive a message from",
    description: "phoneNumberVerificationInfo",
  },
  phoneNumberVerificationInfo2: {
    id: "app.phoneNumberVerificationInfo2",
    defaultMessage:
      "at the phone number provided above. I understand I will receive messages for the verification purposes. Click here to see the",
    description: "phoneNumberVerificationInfo",
  },
  termsAndPrivacy: {
    id: "app.termsAndPrivacy",
    defaultMessage: "Terms and Privacy",
    description: "phoneNumberVerificationInfo",
  },
  twilioAuthErr: {
    id: "app.twilioAuthErr",
    defaultMessage: "Authentication Error - Invalid Key",
    description: "Authentication Error",
  },
  // TODO: New Translations For Share Feature
  share: {
    id: "app.share",
    defaultMessage: "Share",
    description: "Share",
  },
  createShareListing: {
    id: "app.createShareListing",
    defaultMessage: "Create a share listing",
    description: "Create a share listing",
  },
  nights: { id: "listingDetails.nights", defaultMessage: "Nights" },
  night: { id: "listingDetails.night", defaultMessage: "Night" },
  reservationId: {
    id: "listingDetails.reservationId",
    defaultMessage: "Reservation ID",
  },
  expiresAt: { id: "listingDetails.expiresAt", defaultMessage: "Expires At" },
  calculating: {
    id: "listingDetails.calculating",
    defaultMessage: "Calculating...",
  },
  completed: { id: "listingDetails.completed", defaultMessage: "Completed" },
  selected: { id: "listingDetails.selected", defaultMessage: "Selected" },
  user: { id: "listingDetails.user", defaultMessage: "User" },
  roomNumber: {
    id: "listingDetails.roomNumber",
    defaultMessage: "Room Number",
  },
  bedsNumber: {
    id: "listingDetails.bedsNumber",
    defaultMessage: "Beds Number",
  },
  bedsKinds: { id: "listingDetails.bedsKinds", defaultMessage: "Bed Kinds" },
  beds: { id: "listingDetails.beds", defaultMessage: "Beds" },
  bed: { id: "listingDetails.bed", defaultMessage: "Bed" },
  bedImages: { id: "listingDetails.bedImages", defaultMessage: "Bed Images" },
  room: { id: "listingDetails.room", defaultMessage: "Room" },
  expired: { id: "listingDetails.expired", defaultMessage: "Expired" },
  sharedUnits: {
    id: "listingDetails.sharedUnits",
    defaultMessage: "Shared Units",
  },
  sharedUnitsDescription: {
    id: "listingDetails.sharedUnitsDescription",
    defaultMessage: "You can share your units with others",
  },
});

export default messages;
