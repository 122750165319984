exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReceiptContainer-root-3sAWz {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.ReceiptContainer-container-PZmxi {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}", ""]);

// exports
exports.locals = {
	"root": "ReceiptContainer-root-3sAWz",
	"container": "ReceiptContainer-container-PZmxi"
};