import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import { change } from "redux-form";

import Row from "react-bootstrap/lib/Row";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Col from "react-bootstrap/lib/Col";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import renderTooltip from "../../../components/siteadmin/SiteSettingsForm/toolTipHelper.js";

// Component
import CurrencyConverter from "../../CurrencyConverter";
// Helper
import { convert } from "../../../helpers/currencyConvertion";
// Locale
import messages from "../../../locale/messages";
//Images
import Faq from "/public/SiteIcons/question.svg";

import s from "./Calendar.css";
class BillDetails extends Component {
  static propTypes = {
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    tax: PropTypes.number,
    currency: PropTypes.string.isRequired,
    monthlyDiscount: PropTypes.number,
    weeklyDiscount: PropTypes.number,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    formatMessage: PropTypes.any,
    specialPricing: PropTypes.array,
  };

  static defaultProps = {
    basePrice: 0,
    cleaningPrice: 0,
    tax: 0,
    monthlyDiscount: 0,
    weeklyDiscount: 0,
    startDate: null,
    endDate: null,
    specialPricing: [],
  };

  render() {
    const {
      basePrice,
      cleaningPrice,
      tax,
      currency,
      monthlyDiscount,
      weeklyDiscount,
      endDate,
    } = this.props;
    const { serviceFees, base, rates, specialPricing, taxRate, change } =
      this.props;
    const { formatMessage } = this.props.intl;
    let { startDate } = this.props;

    let serviceFee = 0,
      taxes = 0,
      totalWithoutTax = 0,
      currentDay,
      bookingSpecialPricing = [],
      isSpecialPriceAssigned = false,
      totalWithoutServiceFee = 0;
    let priceForDays = 0,
      isAverage = 0,
      isDayTotal = 0,
      momentStartDate,
      momentEndDate,
      dayDifference,
      discount,
      discountType,
      total,
      discountLessBasePrice = 0;

    if (startDate && endDate) {
      startDate = moment(startDate).format("YYYY-MM-DD");
      momentStartDate = moment(startDate);
      momentEndDate = moment(endDate);
      dayDifference = momentEndDate.diff(momentStartDate, "days");

      if (dayDifference > 0) {
        let stayedNights = [];
        // Find stayed nights
        for (let i = 0; i < dayDifference; i++) {
          let currentDate = moment(startDate).add(i, "day");
          stayedNights.push(currentDate);
        }

        if (stayedNights?.length > 0) {
          stayedNights.map((item, key) => {
            let isSpecialPricing;
            if (item) {
              let pricingRow, currentPrice;
              currentDay = moment(item).format("dddd").toLowerCase();
              isSpecialPricing =
                specialPricing?.length > 0 &&
                specialPricing?.find(
                  (o) =>
                    moment(item).format("MM/DD/YYYY") ==
                    moment(o.blockedDates).format("MM/DD/YYYY")
                );
              if (
                isSpecialPricing?.isSpecialPrice &&
                (isSpecialPricing?.dayStatus == "secondHalf" ||
                  isSpecialPricing?.dayStatus == "full")
              ) {
                isSpecialPriceAssigned = true;
                currentPrice = Number(isSpecialPricing?.isSpecialPrice);
              } else {
                currentPrice = Number(basePrice);
              }
              // Price object
              pricingRow = {
                blockedDates: item,
                isSpecialPrice: currentPrice,
              };
              bookingSpecialPricing.push(pricingRow);
            }
          });
        }
      }

      priceForDays = bookingSpecialPricing.reduce(
        (total, item) => total + Number(item.isSpecialPrice),
        0
      );
      discount = 0;
      discountType = null;
      total = 0;
    }

    isAverage = Number(priceForDays) / Number(dayDifference);
    isDayTotal = isAverage.toFixed(2) * dayDifference;
    priceForDays = isDayTotal;

    if (dayDifference >= 7) {
      if (monthlyDiscount > 0 && dayDifference >= 28) {
        discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        discountType =
          monthlyDiscount + "% " + formatMessage(messages.monthlyDiscount);
      } else {
        discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
        discountType =
          weeklyDiscount + "% " + formatMessage(messages.weeklyDiscount);
      }
    }
    discountLessBasePrice = isDayTotal - discount;
    totalWithoutServiceFee = isDayTotal + cleaningPrice - discount;
    totalWithoutTax = isDayTotal - discount;

    if (serviceFees) {
      serviceFee =
        serviceFees.guest.type === "percentage"
          ? totalWithoutServiceFee * (Number(serviceFees.guest.value) / 100)
          : convert(
              base,
              rates,
              serviceFees.guest.value,
              serviceFees.guest.currency,
              currency
            );
    }

    if (tax) taxes = totalWithoutTax * (Number(tax) / 100);

    total = priceForDays + serviceFee + cleaningPrice + taxes - discount;

    // function LinkWithTooltip({ id, children, href, tooltip }) {
    //   return (
    //     <OverlayTrigger
    //       overlay={
    //         <Tooltip className={s.tooltip} id={id}>
    //           {tooltip}
    //         </Tooltip>
    //       }
    //       placement="top"
    //       delayShow={300}
    //       delayHide={150}
    //     >
    //       {children}
    //     </OverlayTrigger>
    //   );
    // }

    if (isAverage) {
      change("BookingForm", "averageBasePrice", isAverage);
    }

    return (
      <FormGroup className={s.noMargin}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={"viewListingCalender"}
          >
            <div className={cx("paymentDetailsFlex", s.billdetail)}>
              <div className={cx(s.noBorder, "paymentDetailsFlexLeft")}>
                {isSpecialPriceAssigned && (
                  <div className="tooltipFlex">
                    <>
                      {renderTooltip(
                        formatMessage(messages.averagePricePerNight),
                        "commonTooltipIcon"
                      )}
                    </>
                    <div className={cx(s.specialPriceText, "directionLtr")}>
                      <CurrencyConverter amount={isAverage} from={currency} />
                      {" x"} {dayDifference}{" "}
                      {dayDifference > 1
                        ? formatMessage(messages.nights)
                        : formatMessage(messages.night)}
                    </div>
                  </div>
                )}
                {!isSpecialPriceAssigned && (
                  <div className={cx(s.specialPriceText, "directionLtr")}>
                    <CurrencyConverter amount={isAverage} from={currency} />
                    {" x"} {dayDifference}{" "}
                    {dayDifference > 1
                      ? formatMessage(messages.nights)
                      : formatMessage(messages.night)}
                  </div>
                )}
              </div>
              <div className={"paymentDetailsFlexRight"}>
                <CurrencyConverter amount={isDayTotal} from={currency} />
              </div>
            </div>
            {discount > 0 && (
              <div className={cx("paymentDetailsFlex", s.billdetail)}>
                <div className={"paymentDetailsFlexLeft"}>{discountType}</div>
                <div className={cx("paymentDetailsFlexRight", s.discountText)}>
                  <span>- </span>
                  <span className={cx(s.displayCell, s.priceCss)}>
                    <CurrencyConverter amount={discount} from={currency} />
                  </span>
                </div>
              </div>
            )}
            {cleaningPrice > 0 && (
              <div className={cx("paymentDetailsFlex", s.billdetail)}>
                <div className={"paymentDetailsFlexLeft"}>
                  <FormattedMessage {...messages.cleaningFee} />
                </div>
                <div className={cx("paymentDetailsFlexRight")}>
                  <CurrencyConverter amount={cleaningPrice} from={currency} />
                </div>
              </div>
            )}
            {serviceFee > 0 && (
              <div className={cx("paymentDetailsFlex", s.billdetail)}>
                <div className={"paymentDetailsFlexLeft"}>
                  <FormattedMessage {...messages.serviceFee} />
                </div>
                <div className={"paymentDetailsFlexRight"}>
                  <CurrencyConverter amount={serviceFee} from={currency} />
                </div>
              </div>
            )}
            {taxes > 0 && (
              <div className={cx("paymentDetailsFlex", s.billdetail)}>
                <div className={"paymentDetailsFlexLeft"}>
                  <FormattedMessage {...messages.tax} />
                </div>
                <div className={"paymentDetailsFlexRight"}>
                  <CurrencyConverter amount={taxes} from={currency} />
                </div>
              </div>
            )}
            <div
              className={cx(
                "paymentDetailsFlex",
                s.billdetail,
                s.totalBorderTop
              )}
            >
              <div className={cx(s.totalText, "paymentDetailsFlexLeft")}>
                <FormattedMessage {...messages.total} />
              </div>
              <div className={cx(s.totalText, "paymentDetailsFlexRight")}>
                <CurrencyConverter amount={total} from={currency} />
              </div>
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

const mapState = (state) => ({
  specialPricing: state.viewListing.specialPricing,
});

const mapDispatch = {
  change,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(BillDetails))
);
