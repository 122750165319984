exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HomeBanner-homeBannerForm-2FAOI {\n    position: relative;\n}\n\n.HomeBanner-formWrap-1saKp {\n    color: #767676;\n    position: relative;\n    background: var(--white-color);\n    background: var(--white-color);\n    padding: 30px;\n    border-radius: 15px;\n    max-width: 450px;\n    -webkit-box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);\n            box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);\n    z-index: 3;\n}\n\n.HomeBanner-sliderImage-1mc1U {\n    position: absolute;\n    inset: 0;\n    width: 100%;\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n}\n\n@media screen and (min-width:1200px) {\n    .HomeBanner-sliderImage-1mc1U {\n        height: 120%;\n    }\n}\n\n@media screen and (max-width:767px) {\n    .HomeBanner-formWrap-1saKp {\n        padding: 20px;\n    }\n\n}", ""]);

// exports
exports.locals = {
	"homeBannerForm": "HomeBanner-homeBannerForm-2FAOI",
	"formWrap": "HomeBanner-formWrap-1saKp",
	"sliderImage": "HomeBanner-sliderImage-1mc1U"
};