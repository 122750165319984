exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AdminNavigation-root-14ux0 {\n  margin: 0;\n}\n\n.AdminNavigation-navDropDown-vHI9o {\n  border: 0px;\n}\n\n.AdminNavigation-languageIcon-1O-pF {\n  width: 100%;\n  max-width: 13px;\n  margin-right: 3px;\n  position: relative;\n  top: -1px;\n}\n\n.AdminNavigation-mozCss-178Bt {\n  min-width: 110px;\n}\n\n.AdminNavigation-darkSwith-1wLke {\n  float: left;\n  position: relative;\n  top: 13px;\n  margin-right: 15px;\n}\n\n.AdminNavigation-themeIcon-f4HXy {\n  margin-right: 8px;\n}\n\n.AdminNavigation-themeIconSec-1L3XN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-size: 16px;\n}\n\n.AdminNavigation-themeIconAlignment-1Uexu {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.AdminNavigation-selectedThemeColor-4ZhP_ {\n  color: var(--btn-secondary-bg);\n  color: var(--btn-secondary-bg);\n}", ""]);

// exports
exports.locals = {
	"root": "AdminNavigation-root-14ux0",
	"navDropDown": "AdminNavigation-navDropDown-vHI9o",
	"languageIcon": "AdminNavigation-languageIcon-1O-pF",
	"mozCss": "AdminNavigation-mozCss-178Bt",
	"darkSwith": "AdminNavigation-darkSwith-1wLke",
	"themeIcon": "AdminNavigation-themeIcon-f4HXy",
	"themeIconSec": "AdminNavigation-themeIconSec-1L3XN",
	"themeIconAlignment": "AdminNavigation-themeIconAlignment-1Uexu",
	"selectedThemeColor": "AdminNavigation-selectedThemeColor-4ZhP_"
};