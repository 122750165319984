exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Inbox-container-jlaY- {\n  margin: 0 auto;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n  padding-top: 30px;\n}", ""]);

// exports
exports.locals = {
	"container": "Inbox-container-jlaY-"
};