exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialLogin-displayFlexIcon-18ThY {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 10px;\n    color: var(--common-text-color) !important;\n    color: var(--common-text-color) !important;\n    font-weight: 500;\n}\n\n.SocialLogin-bgColor-3zun8 {\n    background-color: #F8F7FD;\n    border-radius: 24px;\n    padding: 7px 22px 7px 7px;\n    min-width: 123px;\n    max-width: 123px;\n    margin: 0 auto;\n}", ""]);

// exports
exports.locals = {
	"displayFlexIcon": "SocialLogin-displayFlexIcon-18ThY",
	"bgColor": "SocialLogin-bgColor-3zun8"
};