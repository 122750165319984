import React, { Component } from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FormattedMessage, injectIntl } from "react-intl";
import cx from "classnames";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import CustomCheckbox from "../../CustomCheckbox";
import messages from "../../../locale/messages";
import s from "./RedoSearch.css";

class RedoSearch extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchByMap, change, isMapDrag } = this.props;
  }

  render() {
    const { searchByMap, change } = this.props;

    return (
      <></>
      // <div className={cx(s.redoContainer, "redoContainerRTL")}>
      //   <div className={cx(s.redoContent, "bgBlack")}>
      //     <CustomCheckbox
      //       className={"icheckbox_square-green"}
      //       value={true}
      //       checked={searchByMap}
      //       onChange={(event) => {
      //         console.log("event", event); // --> event is [true] or [false]
      //         change("SearchForm", "searchByMap", event);
      //         change("SearchForm", "initialLoad", false);
      //       }}
      //     />
      //     <small className={cx(s.redoText, "textWhite")}>
      //       <FormattedMessage {...messages.searchAsIMove} />
      //     </small>
      //   </div>
      // </div>
    );
  }
}

const selector = formValueSelector("SearchForm");

const mapState = (state) => ({
  searchByMap: selector(state, "searchByMap"),
});

const mapDispatch = {
  change,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(RedoSearch))
);
