import React from "react";
import PropTypes from "prop-types";
import { Table, TBody, TD, TR, Img } from "oy-vey";
import EmptySpace from "./EmptySpace";
import { mainUrl } from "../../../config";

const Header = (props) => {
  const style = {
    color: "#33bacf",
    fontWeight: "bold",
    backgroundColor: "#F7F7F7",
    width: "100%",
  };

  return (
    <Table width="100%" style={style} color={"#33bacf"}>
      <TBody>
        <TR>
          <TD>
            <Table width="100%">
              <TBody>
                <TR>
                  <TD
                    style={{
                      color: "#33bacf",
                      fontFamily: "Arial",
                      fontSize: "28px",
                      textAlign: "center",
                    }}
                  >
                    <EmptySpace height={20} />
                    <Img src= {mainUrl + "/images/logo/2.png"} style={{
                      width: '100%',
                      display: 'block',
                      margin: '0 auto'
                    }} alt={props.siteName} />
                  </TD>
                </TR>
              </TBody>
            </Table>
          </TD>
        </TR>
      </TBody>
    </Table>
  );
};

Header.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Header;
