exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ViewMessage-root-2563g {\n\tpadding-left: 20px;\n\tpadding-right: 20px;\n}\n\n.ViewMessage-container-1A_HT {\n\tmargin: 0px auto;\n\tpadding-top: 30px;\n\tmax-width: var(--max-content-width);\n\tmax-width: var(--max-content-width);\n}\n\na,\na:hover {\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n}\n\n.ViewMessage-bgColor-2cwg3 {\n\twidth: 100%;\n\tbackground: #F7FAFF;\n}\n\n@media (max-width: 1023px) and (min-width: 768px) {\n\t.ViewMessage-bgColor-2cwg3 {\n\t\tpadding: 0 10px;\n\t}\n}\n\n@media (max-width: 767px) {\n\t.ViewMessage-bgColor-2cwg3 {\n\t\tpadding: 0 10px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"root": "ViewMessage-root-2563g",
	"container": "ViewMessage-container-1A_HT",
	"bgColor": "ViewMessage-bgColor-2cwg3"
};