exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Policies-root-1ZHDC {\n  padding-left: 0px;\n  padding-right: 0px;\n}\n\n.Policies-container-37e3f {\n  margin: 0px auto;\n  padding: 0 0 40px;\n  margin-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}", ""]);

// exports
exports.locals = {
	"root": "Policies-root-1ZHDC",
	"container": "Policies-container-37e3f"
};