exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListingSwiperPhotos-listPhotoContainer-3s2ty {\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.ListingSwiperPhotos-parent-iwQ_u {\n\tpadding-top: 66.6667%;\n\tposition: relative;\n\theight: auto;\n}\n\n.ListingSwiperPhotos-imageContent-37l1- {\n\tposition: absolute;\n\tinset: 0;\n\theight: 100%;\n\twidth: 100%;\n\tbackground-position: 50% 50%;\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tborder-radius: 15px;\n\tbackground-color: var(--background-gray-secondary-color);\n\tbackground-color: var(--background-gray-secondary-color);\n}\n\n.ListingSwiperPhotos-sliderItem-2V851 {\n\tpadding: 1px !important;\n}", ""]);

// exports
exports.locals = {
	"listPhotoContainer": "ListingSwiperPhotos-listPhotoContainer-3s2ty",
	"parent": "ListingSwiperPhotos-parent-iwQ_u",
	"imageContent": "ListingSwiperPhotos-imageContent-37l1-",
	"sliderItem": "ListingSwiperPhotos-sliderItem-2V851"
};