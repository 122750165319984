import React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { mainUrl } from "../../../config";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class ShareReservationRequestSubGuest extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
      reservationId: PropTypes.number,
      slotId: PropTypes.number.isRequired,
      listId: PropTypes.number,
      guests: PropTypes.number,
      checkIn: PropTypes.any,
      checkOut: PropTypes.any,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "normal",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };
    const {
      content: {
        email,
        name,
        logo,
        siteName,
        reservationId,
        slotId,
        listId,
        guests,
        checkIn,
        checkOut,
      },
    } = this.props;

    const action = {
      action: "booking",
      reservationId: reservationId,
      slotId: slotId,
      listId: listId,
      guests: guests,
      checkIn: checkIn,
      checkOut: checkOut,
    };

    const actionString = `${btoa("acavno")}.${btoa(
      btoa(JSON.stringify(action))
    )}.${btoa("onvaca")}`;

    let bookUrl = mainUrl + `/start-process/${actionString}`;

    let firstName = email.split("@")[0];

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <Body textStyle={textStyle}>
          <div>
            Hi {firstName},You have an invitation to join their shared
            accommodation booking on Onvaca! To secure your spot, click below to
            complete your payment:
            <EmptySpace height={20} />
            <div>
              <a
                href={bookUrl}
                style={buttonStyle}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pay Now
              </a>
            </div>
          </div>
          <EmptySpace height={20} />
          <div>
            Welcome to {siteName}! In order to get started, you need to register
            your email address {email}. we have shared a link with you to
            register to our website.
          </div>
          <EmptySpace height={40} />
          <div>
            <a style={buttonStyle} href={bookUrl}>
              Register to our website
            </a>
          </div>
          <EmptySpace height={30} />
          <EmailSignature siteName={siteName} />
        </Body>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ShareReservationRequestSubGuest;
