exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoginModal-root-2fzcd {\n\tpadding: 32px;\n}\n\n.LoginModal-modalCaptionLink-UqmeK,\n.LoginModal-modalCaptionLink-UqmeK:hover,\n.LoginModal-modalCaptionLink-UqmeK:focus,\n.LoginModal-modalCaptionLink-UqmeK:active {\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n\tfont-size: 14px;\n\ttext-decoration: underline;\n\tcursor: pointer;\n}\n\n.LoginModal-iconPosition-1bFRI {\n\tposition: absolute;\n\ttop: 15px;\n\tright: 10px;\n}\n\n.LoginModal-linkText-26oVG {\n\tfont-size: 14px;\n\tline-height: 18px;\n\tfont-weight: 500;\n\tdisplay: grid;\n\tgrid-template-columns: auto auto;\n\tgrid-gap: 6px;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n\t-webkit-box-pack: center;\n\t    -ms-flex-pack: center;\n\t        justify-content: center;\n\tmargin-bottom: 0px;\n\tmargin-top: 24px;\n}\n\n.LoginModal-lineThrough-5nHc7 {\n\tfont-size: 14px;\n\tline-height: 18px;\n\tfont-weight: 500;\n\ttext-align: center;\n\tposition: relative;\n\tmargin: 0px 0px 24px;\n}\n\n.LoginModal-linetext-2OZK8 {\n\tposition: relative;\n\tdisplay: inline-block;\n}\n\n.LoginModal-linetext-2OZK8::before {\n\tcontent: '';\n\tposition: absolute;\n\ttop: 9px;\n\tleft: -30px;\n\twidth: 26px;\n\theight: 1px;\n\tbackground-color: var(--common-text-color);\n\tbackground-color: var(--common-text-color);\n}\n\n.LoginModal-linetext-2OZK8::after {\n\tcontent: '';\n\tposition: absolute;\n\ttop: 9px;\n\tright: -30px;\n\twidth: 26px;\n\theight: 1px;\n\tbackground-color: var(--common-text-color);\n\tbackground-color: var(--common-text-color);\n}", ""]);

// exports
exports.locals = {
	"root": "LoginModal-root-2fzcd",
	"modalCaptionLink": "LoginModal-modalCaptionLink-UqmeK",
	"iconPosition": "LoginModal-iconPosition-1bFRI",
	"linkText": "LoginModal-linkText-26oVG",
	"lineThrough": "LoginModal-lineThrough-5nHc7",
	"linetext": "LoginModal-linetext-2OZK8"
};