import history from "../core/history";

const searchLocation = async (setPersonalizedValues, startDate, endDate, change, personCapacity, uri, searchLocationStatus) => {
    try {
        let updatedURI;

        if (startDate && endDate) {
            setPersonalizedValues({ name: 'isOneTotalToggle', value: true });
            change('SearchForm', 'isOneTotalToggle', true);
            uri = uri + '&startdate=' + startDate + '&enddate=' + endDate;
        }

        if (searchLocationStatus) {
            if (personCapacity && !isNaN(personCapacity) && searchLocationStatus) {
                uri = uri + '&guests=' + personCapacity;
            }
        } else {
            if (personCapacity && !isNaN(personCapacity)) {
                uri = uri + '&guests=' + personCapacity;
            }
        }

        updatedURI = encodeURI(uri);
        if (searchLocationStatus) {
            setPersonalizedValues({ name: 'searchLocationStatus', value: false });
        }
        history.push(updatedURI);
    } catch (error) {
        return false;
    }
}

export default searchLocation;