exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HomeSlider-navigationIcon-3lyy7 {\n\tdisplay: block;\n\tfill: rgb(118, 118, 118);\n\theight: 36px;\n\twidth: 36px;\n\tfont-size: 36px;\n}\n\n.HomeSlider-noMargin-1KCTn {\n\tmargin: 0 auto;\n}\n\n.HomeSlider-padding-22vQl {\n\tpadding: 0 10px;\n}\n\n.HomeSlider-containerTitle-3UxmE {\n\tcolor: var(--common-text-color);\n\tcolor: var(--common-text-color);\n\tline-height: 24px;\n\tmargin: 0 0 45px;\n\tpadding: 0px 15px 0 6px;\n\tfont-weight: bold;\n\tfont-size: 32px;\n}\n\n.HomeSlider-floatLeft-1ppNr {\n\tfloat: left;\n}\n\n.HomeSlider-floatRight-1cJ4a {\n\tfloat: right;\n}\n\n@media screen and (max-width: 1170px) {\n\t.HomeSlider-containerTitle-3UxmE {\n\t\ttext-align: center;\n\t}\n}\n\n@media screen and (max-width: 767px) {\n\t.HomeSlider-containerTitle-3UxmE {\n\t\tmargin: 0 0 40px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"navigationIcon": "HomeSlider-navigationIcon-3lyy7",
	"noMargin": "HomeSlider-noMargin-1KCTn",
	"padding": "HomeSlider-padding-22vQl",
	"containerTitle": "HomeSlider-containerTitle-3UxmE",
	"floatLeft": "HomeSlider-floatLeft-1ppNr",
	"floatRight": "HomeSlider-floatRight-1cJ4a"
};