import { cancel } from '../../actions/Reservation/cancelReservation';

async function submit(values, dispatch) {
  dispatch(cancel({
    reservationId: values.reservationId,
    cancellationPolicy: values.cancellationPolicy,
    refundToGuest: values.refundToGuest,
    payoutToHost: values.payoutToHost,
    guestServiceFee: values.guestServiceFee,
    hostServiceFee: values.hostServiceFee,
    total: values.total,
    currency: values.currency,
    threadId: values.threadId,
    cancelledBy: values.cancelledBy,
    message: values.message,
    checkIn: values.checkIn,
    checkOut: values.checkOut,
    guests: values.guests,
    userType: values.userType,
    isTaxRefunded: values.isTaxRefunded
  }))
}

export default submit;