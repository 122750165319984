import fx from "money";
import { locales } from "../config";
import moment from "moment";
import cookie from "react-cookies";

export function convert(base, rates, amount, from, to) {
  if (!rates || !base || typeof rates !== "object") {
    throw new Error("Invalid rates or base currency provided.");
  }
  var toCurrency;
  fx.base = base;
  fx.rates = rates;
  if (to) {
    toCurrency = to;
  } else {
    toCurrency = base;
  }

  try {
    return fx.convert(amount, { from, to: toCurrency });
  } catch (error) {
    console.error("Error during currency conversion:", error);
    return 0; // Handle conversion failure gracefully
  }
}

export function showCurrencySymbol(currency, locale) {
  let defaultValue = 0;
  let convertCurrency = currency ? currency : "USD";

  let symbol =
    defaultValue
      .toLocaleString(locales[0], {
        style: "currency",
        currency: convertCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(/\d+([,.]\d+)?/g, "") || null;

  symbol = symbol && symbol.toString().trim() == currency ? "" : symbol + " ";

  return symbol;
}

export const calculateTotalAmount = ({
  basePrice = 0,
  cleaningPrice = 0,
  tax = 0,
  currency,
  monthlyDiscount = 0,
  weeklyDiscount = 0,
  startDate,
  endDate,
  serviceFees = {},
  base,
  rates,
  specialPricing = [],
}) => {
  if (!startDate || !endDate || !currency || !base || !rates) {
    throw new Error("Missing required parameters");
  }

  let total = 0;
  let toCurrency = cookie.load("currency") ?? "USD";

  // -------------------------------
  // 1) Calculate nights difference
  if (startDate && endDate) {
    const momentStartDate = moment(startDate).format("YYYY-MM-DD");
    const momentEndDate = moment(endDate).format("YYYY-MM-DD");
    const dayDifference = moment(momentEndDate).diff(
      moment(momentStartDate),
      "days"
    );

    if (dayDifference > 0) {
      // 2) Build a nightly price array (check for special prices)
      let bookingSpecialPricing = [];
      for (let i = 0; i < dayDifference; i++) {
        const currentDate = moment(startDate).add(i, "days");
        const isSpecialPricing = specialPricing?.find(
          (o) =>
            moment(currentDate).format("MM/DD/YYYY") ===
            moment(o.blockedDates).format("MM/DD/YYYY")
        );

        // If a special price applies, use it; otherwise use the base price
        const currentPrice =
          isSpecialPricing?.isSpecialPrice &&
          (isSpecialPricing?.dayStatus === "secondHalf" ||
            isSpecialPricing?.dayStatus === "full")
            ? Number(isSpecialPricing.isSpecialPrice)
            : Number(basePrice);

        bookingSpecialPricing.push({
          blockedDates: currentDate,
          isSpecialPrice: currentPrice,
        });
      }

      // Sum up nightly prices
      let priceForDays = bookingSpecialPricing.reduce(
        (acc, item) => acc + Number(item.isSpecialPrice),
        0
      );

      // 3) Calculate per-night average, discount, taxes, etc.
      const isAverage = priceForDays / dayDifference;
      // Weekly/Monthly discount logic
      let discount = 0;

      if (dayDifference >= 7) {
        if (monthlyDiscount > 0 && dayDifference >= 28) {
          discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        } else {
          discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
        }
      }

      // Subtotal (nights + cleaning fee - discount)
      const totalWithoutServiceFee = priceForDays + cleaningPrice - discount;

      // Guest service fee calculation (percentage or fixed)
      let serviceFee = 0;
      if (serviceFees?.guest?.type === "percentage") {
        serviceFee =
          totalWithoutServiceFee * (Number(serviceFees.guest.value) / 100);
      } else {
        serviceFee = convert(
          base,
          rates,
          serviceFees.guest.value,
          serviceFees.guest.currency,
          toCurrency
        );
      }

      // Tax
      let taxes = 0;
      if (tax) {
        // tax is a percentage of (nights - discount)
        const totalWithoutTax = priceForDays - discount;
        taxes = totalWithoutTax * (Number(tax) / 100);
      }

      // Final total
      total = priceForDays + serviceFee + cleaningPrice + taxes - discount;
    }
  }

  return {
    amount: Number(convert(base, rates, total, currency, toCurrency)).toFixed(
      2
    ),
    currency: toCurrency,
  };
};
