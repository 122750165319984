import React from "react";
import Layout from "../../components/Layout";
import NotFound from "../notFound/NotFound";
import messages from "../../locale/messages";
import Cookies from "js-cookie";
import { setRuntimeVariable } from "../../actions/runtime";
import { loadAccount } from "../../actions/account";
import CreateShare from "./CreateShare";
import { getCurrencyRates } from "../../actions/getCurrencyRates";
import ManageShare from "./ManageShare";

export default async function action({ store, params, intl, query }) {
  const title = intl?.formatMessage(messages.tabBooking);
  let isAuthenticated = store.getState().runtime.isAuthenticated;

  const token = query?.token;
  if (token) {
    Cookies.set("id_token", token);
    try {
      const verifyResponse = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          cookie: `id_token=${token};`,
        },
        body: JSON.stringify({
          query: `query userAccount { userAccount { userId } }`,
        }),
      });
      const verifyResult = await verifyResponse.json();
      if (verifyResult.data?.userAccount) {
        store.dispatch(
          setRuntimeVariable({ name: "isAuthenticated", value: true })
        );
        store.dispatch(loadAccount());
        isAuthenticated = true;
      } else {
        Cookies.remove("id_token");
      }
    } catch (error) {
      Cookies.remove("id_token");
    }
  }

  const action = params.action;
  const listId = query?.listId;
  const reservationId = query?.reservationId;

  if (!isAuthenticated) {
    return {
      title,
      component: (
        <Layout>
          <NotFound title={title} />
        </Layout>
      ),
      status: 404,
    };
  }

  if (action === "create_share_reservation") {
    let bookingData = {};
    let bookDetails = {
      checkIn: query?.checkIn,
      checkOut: query?.checkOut,
      guests: query?.guests || 1,
    };

    if (listId) {
      try {
        const response = await fetch("/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token || ""}`,
          },
          body: JSON.stringify({
            query: `query UserListing($listId: String!) {
              UserListing(listId: $listId) {
                id
                title
                personCapacity
                coverPhoto
                city
                state
                country
                serviceFees {
                    currency
                    guestType
                    guestValue
                    hostType
                    hostValue
                }
                listingData {
                  basePrice
                  cleaningPrice
                  tax
                  currency
                  weeklyDiscount
                  monthlyDiscount
                }
              }
            }`,
            variables: { listId },
          }),
        });
        const result = await response.json();
        if (result.data?.UserListing) {
          bookingData = { ...result.data.UserListing };
        }
      } catch (error) {}
    }

    store.dispatch({
      type: "BOOKING_PROCESS_SUCCESS",
      payload: { data: bookingData, bookDetails, bookingLoading: false },
    });

    const {
      listingData: {
        basePrice,
        cleaningPrice,
        tax,
        currency,
        weeklyDiscount,
        monthlyDiscount,
      } = {},
      serviceFees,
    } = bookingData;

    return {
      title,
      component: (
        <CreateShare
          listId={listId}
          isHost={false}
          totalAmount={basePrice}
          basePrice={basePrice}
          cleaningPrice={cleaningPrice}
          tax={tax}
          serviceFees={{
            guest: {
              currency: "USD",
              type: "fixed",
              value: 0,
            },
          }}
          currency={currency || "USD"}
          monthlyDiscount={monthlyDiscount}
          weeklyDiscount={weeklyDiscount}
          startDate={query?.checkIn}
          endDate={query?.checkOut}
        />
      ),
    };
  }

  if (action === "manage_share_reservation") {
    return {
      title,
      component: <ManageShare reservationId={reservationId} />,
    };
  }
}
