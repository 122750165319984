exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PasswordVerification-root-3N6tx {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n.PasswordVerification-container-3cE59 {\n  margin: 0 auto;\n  padding: 60px 0px;\n  max-width: 500px;\n}\n\n@media screen and (max-width: 1200px) {\n  .PasswordVerification-root-3N6tx {\n    padding-top: 80px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "PasswordVerification-root-3N6tx",
	"container": "PasswordVerification-container-3cE59"
};