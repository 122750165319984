
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { reduxForm } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import cx from 'classnames';

import PlaceGeoSuggest from '../PlaceGeoSuggest';
import detectMobileBrowsers from '../../../helpers/detectMobileBrowsers';
import messages from '../../../locale/messages';
import history from '../../../core/history';
import { googleMapLoader } from '../../../helpers/googleMapLoader';

import searchIcon from '/public/SiteIcons/searchIconHome.svg';

import s from './LocationSearchForm.css';
import bt from '../../commonStyle.css';
class LocationSearchForm extends React.Component {
  static propTypes = {
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      chosen: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      formatMessage: PropTypes.any,
    }),
    settingsData: PropTypes.shape({
      listSettings: PropTypes.array.isRequired
    }).isRequired
  };

  static defaultProps = {
    listingFields: []
  };

  static defaultProps = {
    personalized: {
      location: null,
      lat: null,
      lng: null,
      startDate: null,
      endDate: null,
      chosen: null
    },
    settingsData: {
      listSettings: []
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileDevice: false,
      googleMapsApiLoaded: false,
    }
  }

  async componentDidMount() {
    const autocompleteService = await googleMapLoader();
    this.setState({ googleMapsApiLoaded: true });
    this.autocompleteService = autocompleteService;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    if (detectMobileBrowsers.isMobile() === true) {
      this.setState({ mobileDevice: true });
    }
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType
      });
    }
  }

  handleClick = () => {
    const { personalized } = this.props;
    let updatedURI, uri = '/s?';

    if (personalized.chosen != null) {
      uri = uri + '&address=' + personalized.location + '&chosen=' + personalized.chosen;
    } else {
      if (personalized.location != null) {
        uri = uri + '&address=' + personalized.location;
      }
    }
    updatedURI = encodeURI(uri);
    history.push(updatedURI);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { googleMapsApiLoaded } = this.state;

    return (
      <form>
        <div className={cx(s.searchFormContainer, 'homeSearchForm', 'homeLocationSearchForm', 'homeLocationSearchFormAR', 'searchFormContainerDark')}>
          <div>
            {
              googleMapsApiLoaded ?
                <PlaceGeoSuggest
                  label={formatMessage(messages.homeWhere)}
                  className={cx(s.formControlInput, s.input)}
                  containerClassName={s.geoSuggestContainer}
                />
                : <></>
            }
          </div>
          <div className={cx(s.searchBtnContainer, 'searchBtnContainerAr')}>
            <Button className={cx(bt.btnPrimary, s.btnBlock, s.btnLarge)} onClick={this.handleClick}>
              <img src={searchIcon} className={cx(s.searchIconCommon, 'searchIconCommonTwoRTL')} />
              <span className={'hidden-xs'}>
                <FormattedMessage {...messages.search} />
              </span>
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

LocationSearchForm = reduxForm({
  form: 'SearchForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(LocationSearchForm);

const mapState = (state) => ({
  personalized: state?.personalized,
  settingsData: state?.viewListing?.settingsData,
  listingFields: state?.listingFields?.data,
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(LocationSearchForm)));
