exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListNavigation-root-1Za15 {\n  margin: 0;\n}\n\n.ListNavigation-exitText-3l14C {\n  color: var(--btn-secondary-bg) !important;\n  color: var(--btn-secondary-bg) !important;\n}\n\n.ListNavigation-headerPosition-YQlXg {\n  width: 100%;\n  position: absolute;\n  top: 12px;\n  left: 0;\n  right: 0;\n  line-height: normal;\n}\n\n@supports (-webkit-touch-callout: none) {\n  .ListNavigation-headerPosition-YQlXg {\n    top: 10px;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  @supports (-webkit-touch-callout: none) {\n    .ListNavigation-headerPosition-YQlXg {\n      top: 13px;\n      padding: 0 12px;\n    }\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .ListNavigation-hideSm-170r4 {\n    display: none !important;\n  }\n\n}", ""]);

// exports
exports.locals = {
	"root": "ListNavigation-root-1Za15",
	"exitText": "ListNavigation-exitText-3l14C",
	"headerPosition": "ListNavigation-headerPosition-YQlXg",
	"hideSm": "ListNavigation-hideSm-170r4"
};