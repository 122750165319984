exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PopularLocationGrid-GridCollapse-39OYL {\n\tposition: relative;\n}\n\n.PopularLocationGrid-containerTitle-3Sduk {\n\tcolor: var(--common-text-color);\n\tcolor: var(--common-text-color);\n\tline-height: normal;\n\tmargin: 0 0 45px;\n\tpadding: 0px 15px;\n\tfont-weight: bold;\n\tfont-size: 32px;\n\t-webkit-transition: all .4s ease-out;\n\t-o-transition: all .4s ease-out;\n\ttransition: all .4s ease-out;\n}\n\n.PopularLocationGrid-noLeftPadding-2Gect {\n\tpadding: 0;\n}\n\n@media screen and (max-width: 767px) {\n\t.PopularLocationGrid-marginLeft-3gvoA {\n\t\tmargin: 0 0 24px 34px;\n\t}\n\n\t.PopularLocationGrid-containerTitle-3Sduk {\n\t\tmargin: 0 0 40px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"GridCollapse": "PopularLocationGrid-GridCollapse-39OYL",
	"containerTitle": "PopularLocationGrid-containerTitle-3Sduk",
	"noLeftPadding": "PopularLocationGrid-noLeftPadding-2Gect",
	"marginLeft": "PopularLocationGrid-marginLeft-3gvoA"
};