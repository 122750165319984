exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IncrementButton-incrementDecrementButton-3RBvR {\n\twidth: 100%;\n\tborder: 1px solid var(--border-color);\n\tborder: 1px solid var(--border-color);\n\tborder-radius: 2px;\n\toverflow: hidden;\n\theight: 58px;\n\tdisplay: grid;\n\tgrid-template-columns: 3fr 0fr 0fr;\n}\n\n.IncrementButton-incrementDecrementText-2J4of {\n\twhite-space: nowrap;\n\toverflow: hidden;\n\t-o-text-overflow: ellipsis;\n\t   text-overflow: ellipsis;\n\tpadding: 20px 20px 17px;\n\tmargin: 0px;\n\tfont-size: 16px;\n\tline-height: 1;\n\tvertical-align: middle;\n\tfont-weight: normal;\n\tbackground: var(--background-color);\n\tbackground: var(--background-color);\n}\n\n.IncrementButton-iconButton-2hpqc {\n\tborder-color: var(--border-color);\n\tborder-color: var(--border-color);\n\tborder-width: 0px 0px 0px 1px;\n\tborder-radius: 0px;\n\tpadding: 12px 21px;\n\theight: 58px;\n\tmargin: 0;\n\tfont-size: 20px;\n\tline-height: 1px;\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n\tbackground: var(--background-color);\n\tbackground: var(--background-color);\n\t-webkit-box-shadow: none;\n\t        box-shadow: none;\n}\n\n.IncrementButton-iconButton-2hpqc:hover,\n.IncrementButton-iconButton-2hpqc:focus,\n.IncrementButton-iconButton-2hpqc:active {\n\tbackground: var(--background-color) !important;\n\tbackground: var(--background-color) !important;\n\tborder-color: var(--border-color) !important;\n\tborder-color: var(--border-color) !important;\n\tborder-width: 0px 0px 0px 1px;\n\toutline: 0 !important;\n\t-webkit-box-shadow: none;\n\t        box-shadow: none;\n}", ""]);

// exports
exports.locals = {
	"incrementDecrementButton": "IncrementButton-incrementDecrementButton-3RBvR",
	"incrementDecrementText": "IncrementButton-incrementDecrementText-2J4of",
	"iconButton": "IncrementButton-iconButton-2hpqc"
};