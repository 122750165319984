exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LanguageSwitcher-formControlSelect-3qQKi {\n  height: 53px;\n  border-radius: 2px;\n  margin-bottom: 8px;\n  font-size: 19px;\n  display: block;\n  overflow: hidden;\n  border: 1px solid var(--border-color);\n  border: 1px solid var(--border-color);\n  background-color: var(--white-color);\n  background-color: var(--white-color);\n  color: var(--common-text-color);\n  color: var(--common-text-color);\n  line-height: 24px;\n  /*Drop Down Caret Icon*/\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  background-image:\n    -webkit-linear-gradient(45deg, transparent 50%, gray 50%),\n    -webkit-linear-gradient(315deg, gray 50%, transparent 50%);\n  background-image:\n    -o-linear-gradient(45deg, transparent 50%, gray 50%),\n    -o-linear-gradient(315deg, gray 50%, transparent 50%);\n  background-image:\n    linear-gradient(45deg, transparent 50%, gray 50%),\n    linear-gradient(135deg, gray 50%, transparent 50%);\n  background-position:\n    calc(100% - 15px) calc(50% + 2px),\n    calc(100% - 15px) calc(1em + 2px),\n    calc(100% - 2.5em) 0.5em;\n  background-size:\n    5px 6px,\n    5px 6px,\n    1px 1.5em;\n  background-repeat: no-repeat;\n  /*Drop Down Caret Icon*/\n}", ""]);

// exports
exports.locals = {
	"formControlSelect": "LanguageSwitcher-formControlSelect-3qQKi"
};