exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AddPayoutContainer-container-1D3m8 {\n  margin: 5px auto;\n  padding: 0 0 40px 0px;\n  margin-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}\n\n.AddPayoutContainer-landingContainer-1tda9 {\n  max-width: 1080px;\n  width: 100%;\n  display: block;\n  margin: 0 auto;\n}", ""]);

// exports
exports.locals = {
	"container": "AddPayoutContainer-container-1D3m8",
	"landingContainer": "AddPayoutContainer-landingContainer-1tda9"
};