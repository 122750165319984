exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout4-bannerLayoutContainer-202L6 {\n  min-height: 508px;\n  height: auto;\n  position: relative;\n}\n\n.Layout4-bannerBackgroundImage-1blUe {\n  background-size: cover;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  -o-object-fit: cover;\n     object-fit: cover;\n  background-repeat: no-repeat;\n  background-position: top center;\n  -webkit-transition: all 0.1s ease;\n  -o-transition: all 0.1s ease;\n  transition: all 0.1s ease;\n  -webkit-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);\n          box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);\n}\n\n.Layout4-searchFormContainer-28s-y {\n  position: relative;\n  height: 100%;\n  margin: 0 auto;\n  padding: 125px 15px;\n}\n\n.Layout4-searchFormWrap-19M6v {\n  color: #767676;\n  background: #fff;\n  padding: 30px;\n  border-radius: 15px;\n  width: 100%;\n  max-width: 450px;\n  -webkit-box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);\n          box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);\n}\n\n.Layout4-searchFormWrap-19M6v h1 span {\n  margin: 0 0 10px;\n}\n\n.Layout4-searchFormWrap-19M6v h1 {\n  margin: 0;\n  font-weight: 600;\n  font-size: 26px;\n  color: var(--common-text-color);\n  color: var(--common-text-color);\n}\n\n@media screen and (max-width: 767px) {\n  .Layout4-searchFormWrap-19M6v {\n    padding: 20px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"bannerLayoutContainer": "Layout4-bannerLayoutContainer-202L6",
	"bannerBackgroundImage": "Layout4-bannerBackgroundImage-1blUe",
	"searchFormContainer": "Layout4-searchFormContainer-28s-y",
	"searchFormWrap": "Layout4-searchFormWrap-19M6v"
};