exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotFound-container-3FKMx {\n    margin: 0 auto;\n    padding: 0 0 40px;\n    max-width: var(--max-content-width);\n    max-width: var(--max-content-width);\n}\n\n.NotFound-textJumbo-2zh7U {\n    font-size: 145px;\n    font-weight: bold;\n    margin: 0;\n}\n\n.NotFound-textCenter-n32mo {\n    text-align: center;\n}\n\n.NotFound-listStyled-3qEcm {\n    padding-left: 0;\n    list-style: none;\n}\n\n.NotFound-subTitle-322WM {\n    font-size: 15px;\n    color: #db2424;\n}\n\n.NotFound-textColor-3dOKI {\n    color: yellow;\n}\n\n@media (max-width: 1200px) {\n    .NotFound-container-3FKMx {\n        padding: 60px 0 40px;\n    }\n}\n\n@media screen and (max-width: 1199px) {\n    .NotFound-textJumbo-2zh7U {\n        font-size: 85px;\n    }\n}", ""]);

// exports
exports.locals = {
	"container": "NotFound-container-3FKMx",
	"textJumbo": "NotFound-textJumbo-2zh7U",
	"textCenter": "NotFound-textCenter-n32mo",
	"listStyled": "NotFound-listStyled-3qEcm",
	"subTitle": "NotFound-subTitle-322WM",
	"textColor": "NotFound-textColor-3dOKI"
};