exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WriteReview-root-1mt5y {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.WriteReview-container-3QFkp {\n  margin: 5px auto;\n  padding: 0 0 40px;\n  margin-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}", ""]);

// exports
exports.locals = {
	"root": "WriteReview-root-1mt5y",
	"container": "WriteReview-container-3QFkp"
};