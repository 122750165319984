exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Message-notification-3sAhe {\n  fill: var(--btn-primary-bg);\n  fill: var(--btn-primary-bg);\n  height: 7px;\n  width: 7px;\n  position: absolute;\n  top: 12px;\n  -webkit-transform: translate3d(4px, -8px, 0);\n          transform: translate3d(4px, -8px, 0);\n}\n\n@media screen and (max-width: 1199px) {\n  .Message-notification-3sAhe {\n    top: 24px;\n    -webkit-transform: translate3d(1px, -8px, 0);\n    transform: translate3d(1px, -8px, 0);\n  }\n}", ""]);

// exports
exports.locals = {
	"notification": "Message-notification-3sAhe"
};