exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentVerification-root-2Vq-R {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.DocumentVerification-container-3d-DR {\n  margin: 0 auto;\n  padding-top: 30px;\n  max-width: var(--max-content-width);\n  max-width: var(--max-content-width);\n}\n\n.DocumentVerification-infoTitle-2dD7E {\n  color: var(--common-text-color);\n  color: var(--common-text-color);\n  font-size: 20px;\n  margin-bottom: 20px;\n  margin-top: 0px;\n}", ""]);

// exports
exports.locals = {
	"root": "DocumentVerification-root-2Vq-R",
	"container": "DocumentVerification-container-3d-DR",
	"infoTitle": "DocumentVerification-infoTitle-2dD7E"
};