import React, { Component } from "react";
import { injectIntl } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import s from "./CreateShareList.module.css";
import cx from "classnames";

import BillDetails from "../ViewListing/Calendar/BillDetails";
import { calculateTotalAmount } from "../../helpers/currencyConvertion";
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";
import showToaster from "../../helpers/showToaster";

class CreateShareList extends Component {
  static propTypes = {
    bookingProcess: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formGroups: [
        { id: Date.now() + 1, email: "@", amount: 0.0, bedroomId: null },
        { id: Date.now() + 2, email: null, amount: 0.0, bedroomId: null },
      ],
      splitEqually: false,
      /** NEW: Checkbox for allowing public */
      allowPublic: true,
      totalAddedAmount: 0,
      totalAmountToPay: 0,
      currentCurrency: "",
      maxSlots: props.maxSlots || 3,
      minSlots: 2,
      errorMessage: "",
      loading: false,
      userData: null,
      listingData: null,
    };
  }

  componentDidMount() {
    this.updateTotalAmountAndCurrency();
    this.fetchUserData();
    this.fetchListData(this.props.listId);
  }

  async fetchUserData() {
    const query = `
      query Me {
        me {
          id
          email
        }
      }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
        credentials: "include",
      });

      const { data } = await response.json();

      if (data && data.me) {
        const { email } = data.me;
        this.setState((prevState) => ({
          userData: data.me,
          formGroups: prevState.formGroups.map((group, index) =>
            index === 0 ? { ...group, email } : group
          ),
          loading: false,
        }));
      } else {
        this.setState({
          errorMessage: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      this.setState({
        errorMessage: "Error fetching user data",
        loading: false,
      });
    }
  }

  async fetchListData(id) {
    const query = `
      query UserListing($listId: String!) {
        UserListing(listId: $listId) {
          id
          userId
          bedrooms {
            id
            bedTypes {
                id
                type
            }
          }
          title
          serviceFees {
            id
            guestType
            guestValue
            hostType
            hostValue
            currency
            status
          }
          listingData {
            checkInStart
            checkInEnd
          }
        }
      }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables: { listId: String(id) },
        }),
        credentials: "include",
      });

      const { data, errors } = await response.json();

      if (errors) {
        throw new Error(errors.map((error) => error.message).join(", "));
      }

      if (data && data.UserListing) {
        this.setState({
          listingData: data.UserListing,
          maxSlots: data?.UserListing?.bedrooms?.length ?? 3,
          loading: false,
          errorMessage: null,
        });
      } else {
        this.setState({
          errorMessage: "No data found for the given listing ID.",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching listing data:", error);
      this.setState({
        errorMessage: error.message || "Error fetching listing data.",
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If form groups changed, update the total added amount
    if (prevState.formGroups !== this.state.formGroups) {
      this.updateTotalAddedAmount();
    }

    // Handle splitting equally if the checkbox changes
    if (prevState.splitEqually !== this.state.splitEqually) {
      this.handleSplitEqually();
    }

    // If props related to total calculation change, recalculate
    const propsAffectingCalculation = [
      "basePrice",
      "cleaningPrice",
      "tax",
      "currency",
      "monthlyDiscount",
      "weeklyDiscount",
      "startDate",
      "endDate",
      "serviceFees",
      "base",
      "rates",
      "taxRate",
    ];

    const propsChanged = propsAffectingCalculation.some(
      (key) => prevProps[key] !== this.props[key]
    );

    if (propsChanged) {
      this.updateTotalAmountAndCurrency();
    }
  }

  updateTotalAmountAndCurrency = () => {
    const {
      basePrice,
      cleaningPrice,
      tax,
      currency,
      monthlyDiscount,
      weeklyDiscount,
      startDate,
      endDate,
      serviceFees,
      base,
      rates,
      taxRate,
    } = this.props;

    const totalAmountCalculated = calculateTotalAmount({
      basePrice,
      cleaningPrice,
      tax,
      currency,
      monthlyDiscount,
      weeklyDiscount,
      startDate,
      endDate,
      serviceFees,
      base,
      rates,
      // taxRate,
    });

    this.setState({
      totalAmountToPay: totalAmountCalculated.amount,
      currentCurrency: totalAmountCalculated.currency,
    });
  };

  updateTotalAddedAmount = () => {
    const total = this.state.formGroups.reduce(
      (acc, group) => acc + parseFloat(group.amount || 0),
      0
    );
    this.setState({ totalAddedAmount: total });
  };

  handleAddFormGroup = () => {
    const { formGroups, maxSlots } = this.state;

    if (formGroups.length >= maxSlots) {
      showToaster({ messageId: "maxSlotsReached", toasterType: "error" });
      return;
    }

    if (formGroups.length < maxSlots) {
      this.setState((prevState) => ({
        formGroups: [
          ...prevState.formGroups,
          { id: Date.now(), email: null, amount: 0, bedroomId: null },
        ],
      }));
    }
  };

  handleDeleteFormGroup = (id) => {
    const { formGroups, minSlots } = this.state;
    if (formGroups.length > minSlots) {
      this.setState((prevState) => ({
        formGroups: prevState.formGroups.filter((group) => group.id !== id),
      }));
    }
  };

  handleInputChange = (e, id, field) => {
    const { totalAmountToPay, totalAddedAmount, formGroups } = this.state;
    let value = e.target.value;

    if (field === "amount") {
      let newAmount = parseFloat(value) || 0;
      // No negative values
      if (newAmount < 0) {
        newAmount = 0;
      }
      // Current group's amount
      const currentGroup = formGroups.find((g) => g.id === id);
      const currentGroupAmount = parseFloat(currentGroup.amount || 0);

      // Total without this group's existing amount
      const totalWithoutCurrent = totalAddedAmount - currentGroupAmount;
      // Allowed leftover
      let leftover = totalAmountToPay - totalWithoutCurrent;

      if (leftover < 0) {
        leftover = 0;
      }
      // If new amount is greater than leftover, clamp it
      if (newAmount > leftover) {
        newAmount = leftover;
      }
      // Round to two decimals
      newAmount = parseFloat(newAmount.toFixed(2));
      value = newAmount;
    }

    // Handle bedroomId separately
    if (field === "bedroomId") {
      value = value === "" ? null : parseInt(value, 10); // Convert to integer or null if empty
    }

    this.setState((prevState) => ({
      formGroups: prevState.formGroups.map((group) =>
        group.id === id ? { ...group, [field]: value } : group
      ),
    }));

  };

  handleSplitEqually = () => {
    const { splitEqually, formGroups, totalAmountToPay } = this.state;
    if (splitEqually) {
      const numGroups = formGroups.length;
      const splitValue = parseFloat((totalAmountToPay / numGroups).toFixed(2));
      let newFormGroups = formGroups.map((group) => ({
        ...group,
        amount: splitValue,
      }));
      // Check leftover
      let sumOfSplits = newFormGroups.reduce(
        (acc, group) => acc + group.amount,
        0
      );
      sumOfSplits = parseFloat(sumOfSplits.toFixed(2));

      let leftover = parseFloat((totalAmountToPay - sumOfSplits).toFixed(2));
      if (Math.abs(leftover) > 0.000001) {
        newFormGroups[0].amount = parseFloat(
          (newFormGroups[0].amount + leftover).toFixed(2)
        );
      }

      this.setState({ formGroups: newFormGroups });
    }
  };

  handleSubmit = async () => {
    const { bookingProcess } = this.props;

    this.setState({ loading: true, errorMessage: "" });

    try {
      const {
        basePrice,
        cleaningPrice,
        tax,
        monthlyDiscount,
        weeklyDiscount,
        startDate,
        endDate,
      } = this.props;

      const {
        formGroups,
        userData,
        listingData,
        totalAmountToPay,
        currentCurrency,
        allowPublic, // Including allowPublic
      } = this.state;

      const guestDetailsFromState = formGroups.map((group) => ({
        amount: group.amount,
        email: group.email,
        bedroomId: group.bedroomId,
      }));

      const mutation = `
        mutation CreateReservation(
          $listId: Int!,
          $hostId: String!,
          $guestId: String!,
          $checkIn: String!,
          $checkOut: String!,
          $guests: Int!,
          $message: String!,
          $basePrice: Float!,
          $cleaningPrice: Float!,
          $taxPrice: Float!,
          $currency: String!,
          $discount: Float!,
          $discountType: String!,
          $total: Float!,
          $checkInStart: String!,
          $checkInEnd: String!,
          $hostServiceFeeType: String!,
          $hostServiceFeeValue: Float!,
          $guestDetails: [GuestDetailsInput!]!,
          $isSplitAndShare: Boolean!,
          $allowPublic: Boolean
        ) {
          createReservation(
            listId: $listId
            hostId: $hostId
            guestId: $guestId
            checkIn: $checkIn
            checkOut: $checkOut
            guests: $guests
            message: $message
            basePrice: $basePrice
            cleaningPrice: $cleaningPrice
            taxPrice: $taxPrice
            currency: $currency
            discount: $discount
            discountType: $discountType
            total: $total
            checkInStart: $checkInStart
            checkInEnd: $checkInEnd
            hostServiceFeeType: $hostServiceFeeType
            hostServiceFeeValue: $hostServiceFeeValue
            guestDetails: $guestDetails
            isSplitAndShare: $isSplitAndShare
            allowPublic: $allowPublic
          ) {
            id
            listId
            hostId
            listTitle
            guestId
            checkIn
            checkOut
            guests
            message
            basePrice
            cleaningPrice
            taxPrice
            currency
            discount
            discountType
            guestServiceFee
            hostServiceFee
            total
            confirmationCode
            reservationState
            paymentState
            payoutId
            isHold
            paymentAttempt
            createdAt
            updatedAt
            count
            status
            errorMessage
            paymentMethodId
            cancellationPolicy
            isSpecialPriceAverage
            dayDifference
            taxRate
            checkInStart
            checkInEnd
            hostServiceFeeType
            hostServiceFeeValue
            bookingType
            status
            slots {
              id
            }
          }
        }
      `;

      const variables = {
        listId: listingData?.id || 0,
        hostId: listingData?.userId || "",
        guestId: userData?.id || "",
        checkIn: startDate,
        checkOut: endDate,
        guests: formGroups.length,
        message: `Reservation created via Split & Share by ${
          userData?.email || "Unknown User"
        }`,
        basePrice: basePrice || 0,
        cleaningPrice: cleaningPrice || 0,
        taxPrice: tax || 0,
        currency: currentCurrency,
        discount: monthlyDiscount || weeklyDiscount || 0,
        discountType: "AUTO",
        total: parseFloat(totalAmountToPay || 0),
        checkInStart: listingData?.listingData?.checkInStart,
        checkInEnd: listingData?.listingData?.checkInEnd,
        hostServiceFeeType: listingData?.serviceFees?.hostType ?? "percentage",
        hostServiceFeeValue: listingData?.serviceFees?.hostValue ?? 0,
        guestDetails: guestDetailsFromState,
        isSplitAndShare: true,
        allowPublic, // Make sure we send this to the API
      };

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          query: mutation,
          variables,
        }),
      });

      const result = await response.json();
      if (result.errors) {
        throw new Error(result.errors.map((err) => err.message).join(", "));
      }

      const reservationData = result?.data?.createReservation;
      if (!reservationData) {
        throw new Error("No data returned from createReservation");
      }

      this.setState({ loading: false, errorMessage: "" });
      console.log("Reservation created successfully:", reservationData);

      if (
        reservationData?.slots &&
        reservationData.slots.length > 0 &&
        reservationData.slots[0].id
      ) {
        bookingProcess(
          listingData?.id,
          reservationData.guests,
          reservationData.checkIn,
          reservationData.checkOut,
          null,
          null,
          null,
          reservationData.id,
          reservationData.slots[0].id
        );
      } else {
        console.warn("No slots found in reservationData, cannot redirect.");
      }
    } catch (error) {
      console.error("Error creating reservation:", error);
      this.setState({
        loading: false,
        errorMessage: error.message || "Failed to create reservation",
      });
    }
  };

  toggleSplitEqually = () => {
    this.setState((prevState) => ({ splitEqually: !prevState.splitEqually }));
  };

  /** NEW: Toggle for allowPublic */
  toggleAllowPublic = () => {
    this.setState((prevState) => ({ allowPublic: !prevState.allowPublic }));
  };

  render() {
    const {
      basePrice,
      cleaningPrice,
      tax,
      currency,
      monthlyDiscount,
      weeklyDiscount,
      startDate,
      endDate,
      serviceFees,
      base,
      rates,
      taxRate,
      bookingProcess,
    } = this.props;

    const {
      formGroups,
      splitEqually,
      totalAddedAmount,
      totalAmountToPay,
      currentCurrency,
      listingData,
      errorMessage,
      loading,
      maxSlots,
      allowPublic, // local state
    } = this.state;

    const hasFewerThanTwoBedrooms = listingData?.bedrooms < 2;
    if (hasFewerThanTwoBedrooms) {
      showToaster({
        messageId: "hasFewerThanTwoBedrooms",
        toasterType: "error",
      });
    }

    const selectedBedrooms = formGroups.reduce((acc, group) => {
      if (group.bedroomId) {
        acc[group.bedroomId] = group.id;
      }
      return acc;
    }, {});

    const isAllSlotsFilled = formGroups.every(
      (group) => parseFloat(group.amount) > 0
    );
    const isRemainingZero =
      Math.abs(totalAmountToPay - totalAddedAmount) < 0.000001;

    const isSubmitDisabled =
      !isAllSlotsFilled || !isRemainingZero || hasFewerThanTwoBedrooms;

    return (
      <div className={cx(s.container)}>
        {loading && (
          <div className={cx(s.loaderOverlay)}>
            <div className={cx(s.loaderSpinner)} />
          </div>
        )}

        {errorMessage && (
          <div style={{ color: "red", marginBottom: "1em" }}>
            {errorMessage}
          </div>
        )}

        <div className={s.checkboxesRow}>
          {/* Split Equally Checkbox */}
          <label className={cx(s.splitEqually)}>
            <input
              type="checkbox"
              checked={splitEqually}
              onChange={this.toggleSplitEqually}
              disabled={hasFewerThanTwoBedrooms}
            />
            Split Equally
          </label>

          {/* NEW: allowPublic Checkbox */}
          <label className={cx(s.splitEqually)}>
            <input
              type="checkbox"
              checked={allowPublic}
              onChange={this.toggleAllowPublic}
              disabled={hasFewerThanTwoBedrooms}
            />
            Allow Public
          </label>
        </div>

        {formGroups.map((group, index) => (
          <div key={group.id} className={cx(s.formGroup)}>
            <input
              type="email"
              placeholder="Email"
              className={cx(s.input)}
              value={group.email}
              onChange={(e) => this.handleInputChange(e, group.id, "email")}
              readOnly={index === 0 || hasFewerThanTwoBedrooms}
              style={
                index === 0
                  ? { backgroundColor: "#eee" }
                  : hasFewerThanTwoBedrooms
                  ? { backgroundColor: "#eee" }
                  : {}
              }
            />

            <div className={cx(s.amountContainer)}>
              <input
                type="number"
                placeholder="Amount"
                className={cx(s.amountInput)}
                value={group.amount}
                onChange={(e) => this.handleInputChange(e, group.id, "amount")}
                disabled={hasFewerThanTwoBedrooms}
              />
            </div>

            <select
              className={cx(s.select)}
              value={group.bedroomId || ""} // Ensure value is not null
              onChange={(e) => this.handleInputChange(e, group.id, "bedroomId")}
              disabled={hasFewerThanTwoBedrooms}
            >
              <option value="">Choose Bedroom</option>
              {listingData?.bedrooms?.map((bedroom, index) => {
                const bedTypes = bedroom?.bedTypes
                  ?.map((type) => type.type)
                  .join(", ");
                const isDisabled =
                  selectedBedrooms[bedroom.id] &&
                  selectedBedrooms[bedroom.id] !== group.id;
                return (
                  <option
                    key={bedroom.id}
                    value={bedroom.id}
                    disabled={isDisabled}
                    style={isDisabled ? { color: "gray" } : {}}
                  >
                    Bedroom {index + 1}
                    {bedTypes ? ` - (${bedTypes})` : ""}
                  </option>
                );
              })}
            </select>

            {formGroups.length > 2 && index >= 2 && !hasFewerThanTwoBedrooms ? (
              <button
                type="button"
                className={cx(s.deleteButton)}
                onClick={() => this.handleDeleteFormGroup(group.id)}
              >
                <img
                  style={{ width: "15px" }}
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyNSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2ZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj4KICAgIDxnPgogICAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik06Ljg3LjYzczAsMCwuMDEtLjAxQzcuMjguMjIsNy44MiwwLDguMzgsMGMxLjA4LDAsMi4xNywwLDMuMjUsMCwuNTYsMCwxLjEuMjIsMS40OS42MiwwLDAsMCwwLC4wMS4wMS40LjQuOTMuNjIsMS40OS42Mmg0LjFjLjMxLDAsLjY0LjA5Ljg3LjMuNi41NC41MSwxLjY5LS4yMywyLjA2LS4yMy4xMi0uNS4xNS0uNzYuMTUtNS43OCwwLTExLjU3LDAtMTcuMzUsMC0uMzYsMC0uNzQtLjEyLS45OC0uNDEtLjMzLS40LS4zNy0xLS4xNS0xLjQ2LjE5LS40LjY0LS42NCwxLjA3LS42MywxLjM5LDAsMi43OCwwLDQuMTcsMCwuNTYsMCwxLjEtLjIyLDEuNDktLjYyWiIvPgogICAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xLjI1LDYuMjVjNS44MywwLDExLjY2LDAsMTcuNSwwLDAsNS40MiwwLDEwLjg0LDAsMTYuMjYsMCwxLjA1LS43MiwyLjA0LTEuNzIsMi4zNS0uMjguMDktLjU4LjEzLS44OC4xMy00LjE0LDAtOC4yOCwwLTEyLjQyLDAtLjkyLDAtMS44LS41NS0yLjIxLTEuMzctLjE5LS4zNi0uMjctLjc2LS4yNy0xLjE3LDAtNS40LDAtMTAuOCwwLTE2LjIxWk02LjA4LDguNzZjLS42LjAzLTEuMDkuNTctMS4wOCwxLjE3LDAsMy43NywwLDcuNTMsMCwxMS4zLS4wMS40LjE1LjgyLjUsMS4wNS40Mi4yOCwxLC4yOSwxLjQzLjA1LjM5LS4yMS41OC0uNjcuNTctMS4xLDAtMy43MywwLTcuNDYsMC0xMS4xOSwwLS4zNS0uMS0uNzEtLjM1LS45NS0uMjgtLjI3LS42OC0uMzctMS4wNi0uMzNaTTEzLjU4LDguNzZjLS4zOS4wMy0uNzcuMjYtLjk1LjYyLS4xNC4yNy0uMTQuNTktLjE0Ljg5LDAsMy42NSwwLDcuMywwLDEwLjk1LDAsLjQuMTUuODMuNSwxLjA2LjQzLjI4LDEuMDIuMjksMS40Ni4wMy4zNy0uMjIuNTUtLjY2LjU0LTEuMDgsMC0zLjczLDAtNy40NiwwLTExLjE5LDAtLjM1LS4xLS43MS0uMzUtLjk2LS4yOC0uMjctLjY5LS4zNy0xLjA2LS4zM1oiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPg=="
                />
              </button>
            ) : (
              <button type="button" className={cx(s.disabledDelete)}>
                <img
                  style={{ width: "15px" }}
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyNSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2ZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj4KICAgIDxnPgogICAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik06Ljg3LjYzczAsMCwuMDEtLjAxQzcuMjguMjIsNy44MiwwLDguMzgsMGMxLjA4LDAsMi4xNywwLDMuMjUsMCwuNTYsMCwxLjEuMjIsMS40OS42MiwwLDAsMCwwLC4wMS4wMS40LjQuOTMuNjIsMS40OS42Mmg0LjFjLjMxLDAsLjY0LjA5Ljg3LjMuNi41NC41MSwxLjY5LS4yMywyLjA2LS4yMy4xMi0uNS4xNS0uNzYuMTUtNS43OCwwLTExLjU3LDAtMTcuMzUsMC0uMzYsMC0uNzQtLjEyLS45OC0uNDEtLjMzLS40LS4zNy0xLS4xNS0xLjQ2LjE5LS40LjY0LS42NCwxLjA3LS42MywxLjM5LDAsMi43OCwwLDQuMTcsMCwuNTYsMCwxLjEtLjIyLDEuNDktLjYyWiIvPgogICAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xLjI1LDYuMjVjNS44MywwLDExLjY2LDAsMTcuNSwwLDAsNS40MiwwLDEwLjg0LDAsMTYuMjYsMCwxLjA1LS43MiwyLjA0LTEuNzIsMi4zNS0uMjguMDktLjU4LjEzLS44OC4xMy00LjE0LDAtOC4yOCwwLTEyLjQyLDAtLjkyLDAtMS44LS41NS0yLjIxLTEuMzctLjE5LS4zNi0uMjctLjc2LS4yNy0xLjE3LDAtNS40LDAtMTAuOCwwLTE2LjIxWk02LjA4LDguNzZjLS42LjAzLTEuMDkuNTctMS4wOCwxLjE3LDAsMy43NywwLDcuNTMsMCwxMS4zLS4wMS40LjE1LjgyLjUsMS4wNS40Mi4yOCwxLC4yOSwxLjQzLjA1LjM5LS4yMS41OC0uNjcuNTctMS4xLDAtMy43MywwLTcuNDYsMC0xMS4xOSwwLS4zNS0uMS0uNzEtLjM1LS45NS0uMjgtLjI3LS42OC0uMzctMS4wNi0uMzNaTTEzLjU4LDguNzZjLS4zOS4wMy0uNzcuMjYtLjk1LjYyLS4xNC4yNy0uMTQuNTktLjE0Ljg5LDAsMy42NSwwLDcuMywwLDEwLjk1LDAsLjQuMTUuODMuNSwxLjA2LjQzLjI4LDEuMDIuMjksMS40Ni4wMy4zNy0uMjIuNTUtLjY2LjU0LTEuMDgsMC0zLjczLDAtNy40NiwwLTExLjE5LDAtLjM1LS4xLS43MS0uMzUtLjk2LS4yOC0uMjctLjY5LS4zNy0xLjA2LS4zM1oiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPg=="
                />
              </button>
            )}
          </div>
        ))}

        <div className={cx(s.summary)}>
          <div>
            Remaining Amount to Pay:{" "}
            <span className={cx(s.amount)}>
              {Math.abs(totalAmountToPay - totalAddedAmount).toFixed(2)}{" "}
              {currentCurrency}
            </span>
          </div>
          {!hasFewerThanTwoBedrooms && formGroups.length < maxSlots && (
            <button
              type="button"
              onClick={this.handleAddFormGroup}
              className={cx(s.addButton)}
            >
              +
            </button>
          )}
        </div>

        <BillDetails
          basePrice={basePrice}
          cleaningPrice={cleaningPrice}
          tax={tax}
          currency={currency}
          monthlyDiscount={monthlyDiscount}
          weeklyDiscount={weeklyDiscount}
          startDate={startDate}
          endDate={endDate}
          serviceFees={serviceFees}
          base={base}
          rates={rates}
          taxRate={taxRate}
        />

        <div className={cx(s.actions)}>
          <Loader
            type={"button"}
            className={cx(s.submitButton, "arButtonLoader")}
            handleClick={this.handleSubmit}
            show={loading}
            disabled={isSubmitDisabled}
            label={"Create shared room"}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(CreateShareList));
