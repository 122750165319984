import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../locale/messages";
import moment from "moment";
import CurrencyConverter from "../CurrencyConverter/CurrencyConverter";
import { mainUrl } from "../../config";
import ImageSlider from "../ViewListing/ImageSlider/ImageSlider";

import {
  openImageLightBox,
  closeImageLightBox,
} from "../../actions/ImageLightBox";
import { connect } from "react-redux";

class ShareTable extends Component {
  static propTypes = {
    listId: PropTypes.number,
    bookingProcess: PropTypes.any.isRequired,
  };

  state = {
    loading: false,
    error: null,
    listingData: null,
    currentImageGroup: [],
    imageLightboxIsOpen: false,
  };

  async UNSAFE_componentWillMount() {
    const { listId } = this.props;
    await this.fetchListData(listId);
  }

  fetchListData = async (id) => {
    const query = `
      query GetReservationsByListId($listId: Int!) {
        getReservationsByListId(listId: $listId, isSplitAndShare: true) {
            status
            message
            reservations {
                id
                listId
                checkIn
                checkOut
                currency
                guests
                slots {
                    id
                    paymentStatus
                    price
                    bedroom {
                      bedTypes {
                        id
                        type
                      }
                      images {
                        id
                        name
                        title
                        description
                      }
                    }
                    userData {
                      firstName
                      id
                      lastName
                      picture
                    }
                }
            }
            count
        }
    }
    `;

    try {
      this.setState({ loading: true });

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables: { listId: id },
        }),
        credentials: "include",
      });

      const { data, errors } = await response.json();

      if (errors) {
        throw new Error(errors.map((error) => error.message).join(", "));
      }

      this.setState({
        listingData: data.getReservationsByListId,
        loading: false,
        // currentImageGroup: [],
        // imageLightboxIsOpen: false,
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
      });
      console.error("Error fetching listing data:", error);
    }
  };

  handleClick(listId, guests, startDate, endDate, reservationId, slotId) {
    const { bookingProcess } = this.props;
    bookingProcess(
      listId,
      guests,
      startDate,
      endDate,
      null,
      null,
      null,
      reservationId,
      slotId
    );
  }

  handleOpenImageLightBox(images) {
    if (!images || images.length === 0) return;

    const formattedImages = images.map((image) => ({
      src: `${mainUrl}/images/upload/x_medium_${image.name}`,
      title: image.title || "",
      description: image.description || "",
    }));

    this.setState({
      currentImageGroup: formattedImages,
      imageLightboxIsOpen: true,
    });
    openImageLightBox();
  }

  // openImageLightbox = (images) => {
  //   if (!images || images.length === 0) return;

  //   const formattedImages = images.map((image) => ({
  //     src: `${mainUrl}/images/upload/x_medium_${image.name}`,
  //     title: image.title || "",
  //     description: image.description || "",
  //   }));

  //   this.setState({
  //     currentImageGroup: formattedImages,
  //     imageLightboxIsOpen: true,
  //   });
  // };

  // Update the closeImageLightbox method:
  // closeImageLightbox = () => {
  //   this.setState({
  //     imageLightboxIsOpen: false,
  //     currentImageGroup: [],
  //   });
  // };

  render() {
    const { listId, openImageLightBox, closeImageLightBox } = this.props;
    const {
      loading,
      error,
      listingData,
      imageLightboxIsOpen,
      currentImageGroup,
    } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    const listing = listingData ?? null;

    // Inline styles
    const styles = {
      container: {},
      combinedCell: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
      },
      title: {
        margin: 0,
        fontSize: "24px !important",
        color: "#333",
      },
      description: {
        margin: "20px 0",
        fontSize: "16px",
        color: "#555",
      },
      avatar: {
        borderRadius: "50%",
        width: "50px",
        height: "50px",
      },
      nameEmailContainer: {
        display: "flex",
        flexDirection: "column",
      },
      name: {
        fontSize: "16px",
        fontWeight: "bold",
        margin: "0",
      },
      email: {
        fontSize: "14px",
        color: "#666",
        margin: "0",
      },
      reservationCard: {
        marginBottom: "20px",
        borderRadius: "8px",
        background: "#fff",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      },
      reservationHeader: {
        padding: "16px",
        background: "#f9f9f9",
        borderBottom: "1px solid #ddd",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      reservationInfo: {
        margin: 0,
        fontSize: "14px",
        color: "#555",
      },
      table: {
        width: "100%",
        borderCollapse: "collapse",
      },
      tableHeader: {
        background: "#48CDDC",
        color: "white",
        textAlign: "center",
      },
      tableHeaderCell: {
        padding: "10px",
      },
      tableRow: {
        transition: "background 0.3s",
      },
      tableRowHover: {
        background: "#f1f1f1",
      },
      tableCell: {
        padding: "10px",
        textAlign: "center",
      },
      selectButton: {
        background: "white",
        color: "#48CDDC",
        border: "1px solid #48CDDC",
        padding: "5px 10px",
        cursor: "pointer",
        borderRadius: "4px",
      },
      selectedButton: {
        background: "#48CDDC",
        color: "white",
        padding: "5px 10px",
        cursor: "default",
      },
      imageContainer: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
      imagePreview: {
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        objectFit: "cover",
        cursor: "pointer",
      },
      plusIcon: {
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        background: "rgba(0,0,0,0.5)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
    };

    return (
      <div style={styles.container}>
        <p
          className={styles.title}
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "10px",
            color: "#333",
          }}
        >
          <FormattedMessage {...messages.sharedUnits} />
        </p>
        <p className={styles.description}>
          <FormattedMessage {...messages.sharedUnitsDescription} />
        </p>

        {imageLightboxIsOpen && currentImageGroup.length > 0 && (
          <ImageSlider
            sources={currentImageGroup}
            imageLightBox={imageLightboxIsOpen}
            closeImageLightBox={closeImageLightBox}
          />
        )}

        {listing.reservations.map((reservation) => (
          <div key={reservation.id} style={styles.reservationCard}>
            <div style={styles.reservationHeader}>
              <p style={styles.reservationInfo}>
                <strong>
                  <FormattedMessage {...messages.nights} />:{" "}
                </strong>
                {Math.ceil(
                  (new Date(reservation.checkOut) -
                    new Date(reservation.checkIn)) /
                    (1000 * 60 * 60 * 24)
                )}
                <FormattedMessage {...messages.night} />{" "}
              </p>
              <p>
                <strong>
                  {moment(reservation.checkIn).format("L")} -{" "}
                  {moment(reservation.checkOut).format("L")}
                </strong>
              </p>
              <p style={styles.reservationInfo}>
                <strong>
                  <FormattedMessage {...messages.reservationId} />:{" "}
                </strong>
                {reservation.id}
              </p>
            </div>

            <table style={styles.table}>
              <thead>
                <tr style={styles.tableHeader}>
                  <th style={styles.tableHeaderCell}>
                    <FormattedMessage {...messages.user} />
                  </th>
                  <th style={styles.tableHeaderCell}>
                    <FormattedMessage {...messages.bedsNumber} />
                  </th>
                  <th style={styles.tableHeaderCell}>
                    <FormattedMessage {...messages.bedsKinds} />
                  </th>
                  <th style={styles.tableHeaderCell}>
                    <FormattedMessage {...messages.bedImages} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reservation.slots.map((slot, index) => (
                  <tr
                    key={slot.id}
                    style={{
                      ...styles.tableRow,
                      ...(slot.userData ? {} : styles.tableRowHover),
                    }}
                  >
                    <td style={styles.tableCell}>
                      {slot.userData ? (
                        <div style={styles.combinedCell}>
                          <img
                            src={`/images/avatar/small_${slot.userData?.picture}`}
                            alt="avatar"
                            style={styles.avatar}
                          />
                          <div style={styles.nameEmailContainer}>
                            <p style={styles.name}>
                              {slot.userData?.firstName}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <button
                          style={
                            slot.status === "selected"
                              ? styles.selectedButton
                              : styles.selectButton
                          }
                          onClick={() =>
                            this.handleClick(
                              listId,
                              reservation.guests,
                              reservation.checkIn,
                              reservation.checkOut,
                              reservation.id,
                              slot.id
                            )
                          }
                        >
                          <CurrencyConverter
                            amount={slot.price}
                            from={reservation.currency}
                          />{" "}
                          /
                          <FormattedMessage {...messages.night} />
                        </button>
                      )}
                    </td>
                    <td style={styles.tableCell}>
                      {slot?.bedroom?.bedTypes?.length ?? 1}{" "}
                      {slot?.bedroom?.bedTypes?.length > 1 ? (
                        <FormattedMessage {...messages.beds} />
                      ) : (
                        <FormattedMessage {...messages.bed} />
                      )}
                    </td>
                    <td style={styles.tableCell}>
                      {slot?.bedroom?.bedTypes
                        ? slot?.bedroom?.bedTypes?.map((e) => e.type).toString()
                        : "N/A"}
                    </td>
                    <td style={styles.tableCell}>
                      {slot?.bedroom?.images &&
                      slot.bedroom.images.length > 0 ? (
                        <div style={styles.imageContainer}>
                          {slot.bedroom.images
                            .slice(0, 2)
                            .map((image, imgIndex) => (
                              <img
                                key={image.id}
                                src={`${mainUrl}/images/upload/x_small_${image.name}`}
                                alt={image.title || `Bed image ${imgIndex + 1}`}
                                style={styles.imagePreview}
                                onClick={() => this.handleOpenImageLightBox(slot.bedroom.images)}
                              />
                            ))}
                          {slot.bedroom.images.length > 2 && (
                            <div
                              style={styles.plusIcon}
                              onClick={this.handleOpenImageLightBox}
                            >
                              +{slot.bedroom.images.length - 2}
                            </div>
                          )}
                        </div>
                      ) : (
                        "No Images"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <hr style={{ margin: "20px 0" }} />
      </div>
    );
  }
}

const mapState = (state) => {};

const mapDispatch = {
  openImageLightBox,
  closeImageLightBox,
};

export default injectIntl(connect(mapState, mapDispatch)(ShareTable));
