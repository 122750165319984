// const SNAZZY_MAP_STYLES = [
//   {
//     featureType: "landscape",
//     elementType: "all",
//     stylers: [
//       {
//         saturation: 43.400000000000006,
//       },
//       {
//         lightness: 37.599999999999994,
//       },
//       {
//         gamma: 1,
//       },
//       {
//         hue: "#00ddff",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry.fill",
//     stylers: [
//       {
//         hue: "#00abff",
//       },
//       {
//         saturation: "-1",
//       },
//       {
//         lightness: "11",
//       },
//       {
//         gamma: "1",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         hue: "#00abff",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#006a9e",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         hue: "#00abff",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.icon",
//     stylers: [
//       {
//         color: "#006a9e",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "all",
//     stylers: [
//       {
//         hue: "#FFC200",
//       },
//       {
//         saturation: -61.8,
//       },
//       {
//         lightness: 45.599999999999994,
//       },
//       {
//         gamma: 1,
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "all",
//     stylers: [
//       {
//         hue: "#FF0300",
//       },
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 51.19999999999999,
//       },
//       {
//         gamma: 1,
//       },
//     ],
//   },
//   {
//     featureType: "road.local",
//     elementType: "all",
//     stylers: [
//       {
//         hue: "#ff0000",
//       },
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 52,
//       },
//       {
//         gamma: 1,
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "all",
//     stylers: [
//       {
//         hue: "#0078FF",
//       },
//       {
//         saturation: -13.200000000000003,
//       },
//       {
//         lightness: 2.4000000000000057,
//       },
//       {
//         gamma: 1,
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry.fill",
//     stylers: [
//       {
//         color: "#33bacf",
//       },
//     ],
//   },
// ];

function mapThemeStyle(theme) {
  let mapTheme;
  if (theme == "dark") {
    mapTheme = [
      {
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [
          {
            weight: "2.00",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#9c9c9c",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#f2f2f2",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#7b7b7b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#46bcec",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#c8d7d4",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#070707",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
    ];
  } else {
    mapTheme = [
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: 43.400000000000006,
          },
          {
            lightness: 37.599999999999994,
          },
          {
            gamma: 1,
          },
          {
            hue: "#00ddff",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            hue: "#00abff",
          },
          {
            saturation: "-1",
          },
          {
            lightness: "11",
          },
          {
            gamma: "1",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.stroke",
        stylers: [
          {
            hue: "#00abff",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#006a9e",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.stroke",
        stylers: [
          {
            hue: "#00abff",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#006a9e",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            hue: "#FFC200",
          },
          {
            saturation: -61.8,
          },
          {
            lightness: 45.599999999999994,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            hue: "#FF0300",
          },
          {
            saturation: -100,
          },
          {
            lightness: 51.19999999999999,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            hue: "#ff0000",
          },
          {
            saturation: -100,
          },
          {
            lightness: 52,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            hue: "#0078FF",
          },
          {
            saturation: -13.200000000000003,
          },
          {
            lightness: 2.4000000000000057,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#CAE6F4",
          },
        ],
      },
    ];
  }
  return mapTheme;
}

export { mapThemeStyle };
