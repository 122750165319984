exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ChangePasswordForm-btnBig-2r_nc {\n\tpadding: 13px 21px !important;\n\twidth: 100%;\n}\n\n.ChangePasswordForm-pwdImage-2Kwof {\n\tposition: absolute;\n\ttop: 49px;\n\tright: 20px;\n\tcursor: pointer;\n}\n\n.ChangePasswordForm-root-22v3Q {\n\tborder: 1px solid var(--border-color) !important;\n\tborder: 1px solid var(--border-color) !important;\n\tpadding: 30px;\n\tborder-radius: 14px;\n}\n\n.ChangePasswordForm-titleText-1ZdV5 {\n\tfont-size: 22px;\n\tfont-weight: 600;\n\tmargin: 0px 0px 22px;\n}", ""]);

// exports
exports.locals = {
	"btnBig": "ChangePasswordForm-btnBig-2r_nc",
	"pwdImage": "ChangePasswordForm-pwdImage-2Kwof",
	"root": "ChangePasswordForm-root-22v3Q",
	"titleText": "ChangePasswordForm-titleText-1ZdV5"
};