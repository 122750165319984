import { Loader } from '@googlemaps/js-api-loader';
import { googleMapAPI } from '../config';

export const googleMapLoader = async () => {
    const loader = new Loader({
        apiKey: googleMapAPI,
        libraries: ['places'],
    });
    try {
        await loader.importLibrary('places');
        return new google.maps.places.AutocompleteService();
    } catch (error) {
        console.error(error);
    }
}