import React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class ContactSupport extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      ContactMessage: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      listId: PropTypes.number.isRequired,
      confirmationCode: PropTypes.number.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };
    let textBold = {
      fontWeight: "bold",
    };
    const {
      content: {
        ContactMessage,
        email,
        name,
        confirmationCode,
        userType,
        listId,
        logo,
        siteName,
      },
    } = this.props;

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            A {userType} has reached out regarding reservation #
            {confirmationCode} for property ID {listId}.
          </div>
          <EmptySpace height={20} />
          <div>
            <span style={textBold}>Contact Details:</span>
            <br />
            <span style={textBold}>Name:</span> {name}
            <br />
            <span style={textBold}>Email:</span> {email}
            <br />
            <span style={textBold}>Message:</span>
            {ContactMessage &&
              ContactMessage.trim()
                .split("\n")
                .map(function (item, index) {
                  return (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            <br />
          </div>
          <EmptySpace height={20} />
          <div>Please review and respond as soon as possible.</div>
          <EmptySpace height={30} />
          <EmailSignature siteName={siteName} />
        </Body>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default ContactSupport;
