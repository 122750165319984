import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Grid, Row, Col, Panel } from "react-bootstrap";
import cx from "classnames";

import s from "./StartProcessContainer.css";
import bt from "../../components/commonStyle.css";

import Loader from "../../components/Loader";
import NotFound from "../notFound/NotFound";
import { bookingProcess } from "../../actions/booking/bookingProcess";

// Example decode helper
function doubleBase64Decode(encoded) {
  try {
    return atob(atob(encoded));
  } catch (err) {
    console.error("Decoding error:", err);
    return null;
  }
}

class StartProcessContainer extends React.Component {
  static propTypes = {
    // Provided via server or parent
    actionData: PropTypes.shape({
      listId: PropTypes.number,
      guests: PropTypes.number,
      checkIn: PropTypes.string,
      checkOut: PropTypes.string,
      reservationId: PropTypes.number,
      slotId: PropTypes.number,
    }),
    actionString: PropTypes.string,

    // From Redux
    isAuthenticated: PropTypes.bool.isRequired,
    bookingProcess: PropTypes.func.isRequired,
    intl: PropTypes.object,
  };

  state = {
    loading: true,
    invalidLink: false,
    processCompleted: false,
  };

  componentDidMount() {
    this.processAction();
  }

  async processAction() {
    const { actionString, actionData, isAuthenticated, bookingProcess } =
      this.props;

    // 1. If the server already decoded actionData, just use it
    let payload = actionData;
    if (!payload) {
      // If for some reason we did not decode on the server
      if (!actionString) {
        this.setState({ loading: false, invalidLink: true });
        return;
      }

      const parts = actionString.split(".");
      if (parts.length !== 3) {
        this.setState({ loading: false, invalidLink: true });
        return;
      }

      const [prefix, encodedAction, suffix] = parts;
      const decoded = doubleBase64Decode(encodedAction);
      if (!decoded) {
        this.setState({ loading: false, invalidLink: true });
        return;
      }

      try {
        payload = JSON.parse(decoded);
      } catch (err) {
        console.error("JSON parse error:", err);
        this.setState({ loading: false, invalidLink: true });
        return;
      }
    }

    // 2. If user is not authenticated, we might still be on the client in a no-SSR scenario:
    if (!isAuthenticated) {
      // You can do a client-side redirect, but better if done on the server
      window.location.href = `/login?refer=${encodeURIComponent(
        `/start-process/${actionString}`
      )}`;
      return;
    }

    // 3. Now call bookingProcess
    const { listId, guests, checkIn, checkOut, reservationId, slotId } =
      payload;
    try {
      await bookingProcess(
        listId,
        guests,
        checkIn,
        checkOut,
        null, // preApprove
        null, // restrictEdit
        null, // threadId
        reservationId,
        slotId
      );
      this.setState({ loading: false, processCompleted: true });
    } catch (err) {
      console.error("Booking process error:", err);
      this.setState({ loading: false, invalidLink: true });
    }
  }

  render() {
    const { loading, invalidLink, processCompleted } = this.state;
    const { intl } = this.props;
    const title = intl?.formatMessage
      ? intl.formatMessage({
          id: "startProcessTitle",
          defaultMessage: "Start Process",
        })
      : "Start Process";

    if (loading) {
      return (
        <div>
          <Loader type={"text"} show={loading} loaderClass={"textLoader"} />
        </div>
      );
    }
    return (
      <Grid fluid className="dashBoardContainer">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Panel className={cx("bgLight")} style={{ border: "none" }}>
              <div className={cx("actionsContainer")}>
                {!loading && invalidLink && (
                  <NotFound
                    title={
                      <FormattedMessage
                        id="invalidLink"
                        defaultMessage="Invalid Link"
                      />
                    }
                  />
                )}

                {!loading && !invalidLink && !processCompleted && (
                  <div>
                    <FormattedMessage
                      id="processing"
                      defaultMessage="Processing your request..."
                    />
                  </div>
                )}

                {!loading && processCompleted && (
                  <div>
                    <FormattedMessage
                      id="processCompleted"
                      defaultMessage="Booking process completed!"
                    />
                  </div>
                )}
              </div>
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.runtime.isAuthenticated,
});

const mapDispatchToProps = {
  bookingProcess,
};

export default injectIntl(
  withStyles(
    s,
    bt
  )(connect(mapStateToProps, mapDispatchToProps)(StartProcessContainer))
);
