import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { mainUrl } from "../../../config";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class CompletedReservationHost extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      reservationId: PropTypes.number.isRequired,
      guestName: PropTypes.string.isRequired,
      guestLastName: PropTypes.string.isRequired,
      guestProfilePic: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "10px",
      textAlign: "center",
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "bold",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const bookingTitle = {
      paddingBottom: "20px",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "25px",
      margin: "0",
      padding: "0",
      textAlign: "center",
    };

    const profilePic = {
      borderRadius: "999px",
      margin: "0",
      padding: "0",
      lineHeight: "150%",
      borderSpacing: "0",
      width: "125px",
    };

    const userName = {
      color: "#565a5c",
      fontSize: "26px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const subTitle = {
      color: "#565a5c",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const linkText = {
      color: PRIMARYCOLOR,
      fontSize: "18px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const space = {
      paddingBottom: "20px",
    };
    const {
      content: { reservationId, logo, siteName },
    } = this.props;
    const {
      content: { guestName, guestLastName, guestProfilePic },
    } = this.props;
    let messageURL = mainUrl + "/review/write/" + reservationId;
    let imageURL;
    if (guestProfilePic) {
      imageURL = mainUrl + "/images/avatar/medium_" + guestProfilePic;
    }

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>
                    {guestProfilePic && (
                      <img style={profilePic} src={imageURL} height={125} />
                    )}
                  </div>
                  <EmptySpace height={20} />
                  <h1 style={bookingTitle}>
                    ⭐ How Was Your Guest {guestName}?
                  </h1>
                  <EmptySpace height={20} />
                  <div>Hi {hostName},</div>
                  <EmptySpace height={20} />
                  <div>
                    {guestName} has just checked out! Now’s the perfect time to
                    leave a review and let them know what you appreciated about
                    their stay.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Reviews help build trust in the {siteName} community, and
                    your feedback is valuable. It only takes a few minutes!
                  </div>
                  <EmptySpace height={50} />
                  <div>
                    <a href={messageURL} style={buttonStyle}>
                      Write a Review
                    </a>
                  </div>
                  <EmptySpace height={40} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={50} />
        </div>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CompletedReservationHost;
