exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PlaceMap-alertContainer-2WkLs {\n\tpadding: 10px 15px;\n\tposition: relative;\n\ttext-align: left;\n\tpadding-left: 50px;\n\tfont-size: 18px;\n\tcolor: #ffffff;\n}\n\n.PlaceMap-alertDanger-1WS4w {\n\tbackground: var(--btn-secondary-bg);\n\tbackground: var(--btn-secondary-bg);\n}\n\n.PlaceMap-dangerIcon-30mdG {\n\tposition: absolute;\n\tleft: 18px;\n\ttop: 11px;\n\tfont-size: 20px;\n\t-webkit-transform: none;\n\t    -ms-transform: none;\n\t        transform: none;\n}\n\n.PlaceMap-alertSuccess-3cZ3s {\n\tbackground: var(--map-success-color);\n\tbackground: var(--map-success-color);\n\tcolor: var(--common-text-color);\n\tcolor: var(--common-text-color);\n}\n\n.PlaceMap-successIcon-3VgDF {\n\tcolor: #8bd275;\n\tposition: absolute;\n\tleft: 18px;\n\ttop: 15px;\n\tfont-size: 20px;\n\t-webkit-transform: none;\n\t    -ms-transform: none;\n\t        transform: none;\n}\n\n.PlaceMap-positionRelative-a0Ctp {\n\tposition: relative;\n}\n\n.PlaceMap-positionAbsolute-3hPte {\n\tposition: absolute;\n\ttop: 0px;\n\tleft: 0;\n\tz-index: 1;\n\twidth: 100%;\n}\n\n@media screen and (max-width: 991px) {\n\t.PlaceMap-tabViewMap-2z-4h {\n\t\theight: 75vh !important;\n\t}\n}", ""]);

// exports
exports.locals = {
	"alertContainer": "PlaceMap-alertContainer-2WkLs",
	"alertDanger": "PlaceMap-alertDanger-1WS4w",
	"dangerIcon": "PlaceMap-dangerIcon-30mdG",
	"alertSuccess": "PlaceMap-alertSuccess-3cZ3s",
	"successIcon": "PlaceMap-successIcon-3VgDF",
	"positionRelative": "PlaceMap-positionRelative-a0Ctp",
	"positionAbsolute": "PlaceMap-positionAbsolute-3hPte",
	"tabViewMap": "PlaceMap-tabViewMap-2z-4h"
};