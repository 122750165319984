exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n\n.MoreFiltersModal-logInModalContainer-2DcHS {\n  max-width: 680px;\n  width: 100%;\n}\n\n.MoreFiltersModal-logInModalBody-2KS-y {\n  padding: 0px;\n}\n\n.MoreFiltersModal-panelHeader-iqJ0z {\n  font-size: 16px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  border-bottom: 1px solid var(--border-secondary-color);\n  border-bottom: 1px solid var(--border-secondary-color);\n  background: transparent;\n  text-align: center;\n}\n\n@media (max-width:991px) and (min-width:768px) {\n  .MoreFiltersModal-logInModalContainer-2DcHS {\n    width: auto;\n    display: block;\n    margin: 30px 15px;\n    max-width: 100%;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .MoreFiltersModal-logInModalContainer-2DcHS {\n    display: block;\n    width: auto;\n    max-width: 100%;\n  }\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "MoreFiltersModal-logInModalContainer-2DcHS",
	"logInModalBody": "MoreFiltersModal-logInModalBody-2KS-y",
	"panelHeader": "MoreFiltersModal-panelHeader-iqJ0z"
};