import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import CurrencyView from "../modules/CurrencyView";
import EmailSignature from "./EmailSignature";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";
//Helper
import { getDateUsingTimeZone } from "../../../helpers/dateRange";
class CancelledByGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      payoutToHost: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const {
      content: {
        guestName,
        hostName,
        confirmationCode,
        checkIn,
        listTitle,
        payoutToHost,
        currency,
        logo,
        country,
        siteName,
      },
    } = this.props;
    let checkInDate =
        checkIn != null ? moment(checkIn).format("ddd, Do MMM, YYYY") : "",
      momentStartDate = moment(checkIn).startOf("day");
    let today = getDateUsingTimeZone(country, false),
      interval = momentStartDate.diff(today, "days"),
      isPastDay = false;
    if (interval < 0) {
      isPastDay = true;
    }

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <h3>❌ Reservation Canceled</h3> <br />
                  <EmptySpace height={20} />
                  <div>Hi {hostName},</div>
                  <EmptySpace height={20} />
                  <div>
                    We’re sorry to inform you that {guestName} has canceled
                    their reservation {confirmationCode} at {listTitle},{" "}
                    {isPastDay
                      ? "which had already started"
                      : "which was set to begin"}{" "}
                    on {checkInDate}.
                    <br /> If you're eligible for a payout:
                    {payoutToHost > 0 && (
                      <span>
                        {" "}
                        According to the cancellation policy, your payout has
                        been updated to{" "}
                        <CurrencyView
                          amount={payoutToHost}
                          currency={currency}
                        />
                        .
                      </span>
                    )}
                    <br /> If no payout is due:
                    {payoutToHost === 0 && (
                      <span>
                        {" "}
                        Unfortunately, as per the policy, this cancellation does
                        not qualify for a payout.
                      </span>
                    )}
                    <EmptySpace height={10} />
                    <p>
                      Your calendar has been updated, and the previously booked
                      dates are now available again.
                    </p>
                  </div>
                  <EmptySpace height={20} />
                  <EmailSignature siteName={siteName} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CancelledByGuest;
