exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Page-root-2VuHt {\n  padding-left: 20px;\n  padding-right: 20px;\n  min-height: 50vh\n}\n\n.Page-container-QOUG7 {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-container-width);\n  max-width: var(--max-container-width);\n}\n\n@media screen and (max-width:1200px) {\n  .Page-container-QOUG7 {\n    padding: 85px 0 40px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "Page-root-2VuHt",
	"container": "Page-container-QOUG7"
};