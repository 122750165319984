exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IncrementBtn-incrementDecrementButton-68O2n {\n\twidth: 100%;\n\tborder: 1px solid var(--border-secondary-color);\n\tborder: 1px solid var(--border-secondary-color);\n\tborder-radius: 2px;\n\toverflow: hidden;\n\theight: 58px;\n\tdisplay: grid;\n\tgrid-template-columns: 3fr 0fr 0fr;\n}\n\n.IncrementBtn-incrementDecrementText-16yxa {\n\twhite-space: nowrap;\n\toverflow: hidden;\n\t-o-text-overflow: ellipsis;\n\t   text-overflow: ellipsis;\n\tpadding: 18px 20px;\n\tmargin: 0px;\n\tfont-size: 16px;\n\tline-height: 1;\n\tvertical-align: middle;\n\tfont-weight: normal;\n\tbackground: #fff;\n}\n\n.IncrementBtn-iconButton-23Ppf {\n\tborder-color: var(--border-secondary-color);\n\tborder-color: var(--border-secondary-color);\n\tborder-width: 0px 0px 0px 1px;\n\tborder-radius: 0px;\n\tpadding: 12px 21px;\n\theight: 58px;\n\tmargin: 0px 0px;\n\tfont-size: 15px;\n\tline-height: 1px;\n\tcolor: var(--btn-secondary-bg);\n\tcolor: var(--btn-secondary-bg);\n\tbackground: #fff;\n}\n\n.IncrementBtn-iconButton-23Ppf:hover,\n.IncrementBtn-iconButton-23Ppf:focus,\n.IncrementBtn-iconButton-23Ppf:active {\n\tbackground: #fff;\n\tborder-color: var(--border-secondary-color);\n\tborder-color: var(--border-secondary-color);\n\tborder-width: 0px 0px 0px 1px;\n\tmargin-left: 0px;\n\toutline: 0 !important;\n\t-webkit-box-shadow: none;\n\t        box-shadow: none;\n}", ""]);

// exports
exports.locals = {
	"incrementDecrementButton": "IncrementBtn-incrementDecrementButton-68O2n",
	"incrementDecrementText": "IncrementBtn-incrementDecrementText-16yxa",
	"iconButton": "IncrementBtn-iconButton-23Ppf"
};