exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Book-container-1KgN7 {\n  max-width: var(--max-container-width);\n  max-width: var(--max-container-width);\n  margin: 40px auto 0 auto;\n}\n\n.Book-landingContainer-cFYDc {\n  width: 100%;\n  display: block;\n  margin: 0 auto;\n}\n\n@media screen and (max-width:767px) {\n  .Book-overFlowHiddenMobile-3XVJa {\n    overflow-x: hidden;\n  }\n}", ""]);

// exports
exports.locals = {
	"container": "Book-container-1KgN7",
	"landingContainer": "Book-landingContainer-cFYDc",
	"overFlowHiddenMobile": "Book-overFlowHiddenMobile-3XVJa"
};