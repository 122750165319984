exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchResults-loadingOverlay-2ctPS {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  z-index: 2;\n  background: rgba(255, 255, 255, 0.5);\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n}\n\n.SearchResults-searchResults-1-W6C {\n  position: relative;\n  padding: 75px 16px 33px;\n  margin-bottom: 50px;\n}\n\n.SearchResults-resultsFooter-YP632 {\n  padding: 20px;\n  position: relative;\n}\n\n.SearchResults-listItem-1DzFT {\n  width: 33%;\n  padding: 0px 10px 33px;\n}\n\n.SearchResults-resultsPagination-11ra- {\n  margin-bottom: 48px;\n  position: relative;\n}\n\n@media screen and (min-width: 768px) and (max-width: 1199px) {\n  .SearchResults-listItem-1DzFT {\n    width: 50%;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .SearchResults-listItem-1DzFT {\n    width: 100%;\n  }\n\n  .SearchResults-searchResults-1-W6C {\n    padding: 24px 7px 33px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"loadingOverlay": "SearchResults-loadingOverlay-2ctPS",
	"searchResults": "SearchResults-searchResults-1-W6C",
	"resultsFooter": "SearchResults-resultsFooter-YP632",
	"listItem": "SearchResults-listItem-1DzFT",
	"resultsPagination": "SearchResults-resultsPagination-11ra-"
};