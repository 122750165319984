import React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { mainUrl } from "../../../config";
import { PRIMARYCOLOR, COMMON_TEXT_COLOR } from "../../../constants";

class AdminListApprove extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      userMail: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "normal",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const linkText = {
      color: PRIMARYCOLOR,
      fontSize: "18px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const {
      content: { hostName, listId, listTitle, logo, siteName },
    } = this.props;
    const URL = mainUrl + `/become-a-host/${listId}/home`;
    const listURL = mainUrl + `/rooms/${listId}/preview`;

    return (
      <Layout>
        <Header
          color={PRIMARYCOLOR}
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <Body textStyle={textStyle}>
          <div>Hi {hostName},</div>
          <EmptySpace height={20} />
          <div>
            Great news! Your listing{" "}
            <a href={listURL} style={linkText}>
              {" "}
              {listTitle}{" "}
            </a>{" "}
            has been verified by the admin. Just publish it now to start
            receiving new reservations!
          </div>
          <EmptySpace height={40} />
          <div>
            <a style={buttonStyle} href={URL}>
              Publish
            </a>
          </div>
          <EmptySpace height={40} />
          <EmailSignature siteName={siteName} />
        </Body>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default AdminListApprove;
