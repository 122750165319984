import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import moment from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import renderTooltip from "../../components/siteadmin/SiteSettingsForm/toolTipHelper.js";

// Component
import CurrencyConverter from "../CurrencyConverter";
import Link from "../Link";
import NotFound from "../../routes/notFound/NotFound";

// Helper
import { generateTime } from "./helper";

// Locale
import messages from "../../locale/messages";
import ListNotFound from "../../routes/listNotFound/ListNotFound";

// Images
import priceIcon from "/public/SiteIcons/printIcon.svg";
import dateIcon from "/public/SiteIcons/viewReceiptArrow.svg";

import bt from "../../components/commonStyle.css";
import s from "./Receipt.css";

class PaymentReceipt extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    siteName: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number,
      taxPrice: PropTypes.number,
      total: PropTypes.number.isRequired,
      discount: PropTypes.number,
      discountType: PropTypes.string,
      guestServiceFee: PropTypes.number,
      currency: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      paymentState: PropTypes.string,
      listTitle: PropTypes.string,
      listData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        listingData: PropTypes.shape({
          checkInStart: PropTypes.string,
          checkInEnd: PropTypes.string,
        }),
        settingsData: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            listsettings: PropTypes.shape({
              itemName: PropTypes.string.isRequired,
            }),
          })
        ),
      }),
      hostData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
      }),
      guestData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        displayName: PropTypes.string,
      }),
      bookingSpecialPricing: PropTypes.array,
      slots: PropTypes.array,
      isSplitAndShare: PropTypes.bool,
    }),
    userId: PropTypes.string, // from Redux
  };

  static defaultProps = {
    data: null,
  };

  print() {
    window.print();
  }

  render() {
    const { data, siteName, userId } = this.props;
    const { formatMessage } = this.props.intl;

    // 1. Read the slotId from window.location.search (no react-router-dom)
    let slotId = null;
    if (typeof window !== "undefined" && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      slotId = params.get("slotId");
    }

    // If slotId is present, find the matching slot from data.slots
    let slotData = null;
    let slotIndex = -1;
    if (slotId && data && data.slots && data.slots.length > 0) {
      slotIndex = data.slots.findIndex(
        (slot) => String(slot.id) === String(slotId)
      );
      if (slotIndex > -1) {
        slotData = data.slots[slotIndex];
      }
    }

    // Basic checks
    if (!data) {
      return <NotFound />;
    } else if (!data.listData) {
      return <ListNotFound />;
    } else if (data.paymentState !== "completed") {
      return <NotFound />;
    }

    const {
      id,
      listId,
      checkIn,
      checkOut,
      confirmationCode,
      listTitle,
      createdAt,
      updatedAt,
      hostId,
      guestId,
    } = data;

    const {
      basePrice,
      cleaningPrice,
      total,
      discount,
      discountType,
      guestServiceFee,
      taxPrice,
      currency,
      hostServiceFee,
	  isSplitAndShare
    } = data;

    const { hostData, guestData } = data;
    const {
      title,
      street,
      city,
      state,
      country,
      zipcode,
      beds,
      settingsData,
      listingData,
    } = data.listData;

    let { checkInStart, checkInEnd } = data;

    // 2. Prepare variables that may get overridden by slotData
    let bookedByName = guestData && guestData.firstName;
    let bookedByEmail = guestData && guestData.displayName;
    let invoiceTotal = total;
    let bedroomInfo = null;

    // If valid slotData, override name, email, and total
    if (slotData) {
      const { userData, price, bedroom } = slotData;
      if (userData) {
        bookedByName = `${userData.firstName} ${userData.lastName}`;
        bookedByEmail = userData.email;
      }
      // For simplicity, assume slotData.price is the invoice total
      invoiceTotal = price;

      // If there's a bedroom object, gather info
      if (bedroom) {
        // Convert bedTypes array to comma-separated string
        const bedTypesArray = bedroom.bedTypes || [];
        const bedTypesString = bedTypesArray.map((bt) => bt.type).join(", ");
        bedroomInfo = {
          bedroomNumber: slotIndex + 1, // index + 1
          bedTypes: bedTypesString,
        };
      }
    }

    // Format dates
    let createdDate = createdAt
      ? moment(createdAt).format("ddd, MMM Do, YYYY ")
      : "";
    let updatedDate = updatedAt ? moment(updatedAt).format("MM/DD/YYYY") : "";
    let checkInDate = checkIn ? moment(checkIn).format("MM/DD/YYYY") : "";
    let checkOutDate = checkOut ? moment(checkOut).format("MM/DD/YYYY") : "";

    // Fallback for checkInStart & checkInEnd
    checkInStart = checkInStart || (listingData && listingData.checkInStart);
    checkInEnd = checkInEnd || (listingData && listingData.checkInEnd);

    // Build a check-in time display
    let checkInTime =
      checkInStart !== "Flexible" ? generateTime(checkInStart) : null;
    let checkOutTime =
      checkInEnd !== "Flexible" ? generateTime(checkInEnd) : null;
    let checkInTimeFormat = formatMessage(messages.flexibleCheckIn);

    if (checkInStart === "Flexible" && checkInEnd === "Flexible") {
      checkInTimeFormat = formatMessage(messages.flexibleCheckIn);
    } else if (checkInStart !== "Flexible" && checkInEnd === "Flexible") {
      checkInTimeFormat = `From ${checkInTime}`;
    } else if (checkInStart === "Flexible" && checkInEnd !== "Flexible") {
      checkInTimeFormat = `Upto ${checkOutTime}`;
    } else if (checkInStart !== "Flexible" && checkInEnd !== "Flexible") {
      checkInTimeFormat = `${checkInTime} - ${checkOutTime}`;
    }

    // Calculate total nights
    let dayDifference = 0;
    if (checkIn && checkOut) {
      const momentStartDate = moment(checkIn);
      const momentEndDate = moment(checkOut);
      dayDifference = momentEndDate.diff(momentStartDate, "days");
    }

    // Determine userType
    let userType;
    let subTotal;
    if (userId === hostId) {
      userType = "host";
      // For the host, we might do total - hostServiceFee
      // If slotData is found, override with slotData’s price
      subTotal = slotData ? invoiceTotal : total - (hostServiceFee || 0);
    } else {
      userType = "guest";
      // For the guest, we might do total + guestServiceFee
      // If slotData is found, override with slotData’s price
      subTotal = slotData ? invoiceTotal : total + (guestServiceFee || 0);
    }

    // Identify property type from settingsData
    let roomType = "";
    if (settingsData && settingsData[0] && settingsData[0].listsettings) {
      roomType = settingsData[0].listsettings.itemName;
    }

    function LinkWithTooltip({ id, children, href, tooltip }) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip className={s.tooltip} id={id}>
              {tooltip}
            </Tooltip>
          }
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {children}
        </OverlayTrigger>
      );
    }

    return (
      <div
        className={cx(s.containerResponsive, s.spaceTop4, "commonWordBreak")}
      >
        <div className={cx(s.space4, s.rowTable)}>
          <div>
            <h2 className={s.titleText}>
              <FormattedMessage {...messages.customerReceipt} />
            </h2>
            <div>
              <FormattedMessage {...messages.receipt} />: # {id}
            </div>
          </div>
          <button
            type="button"
            className={cx(s.button, "hidden-print", s.printBtn, "printBtnRTL")}
            onClick={() => this.print()}
          >
            <img src={priceIcon} alt="print icon" />
            <FormattedMessage {...messages.printText} />
          </button>
        </div>

        <div className={s.grid}>
          {/* LEFT PANEL */}
          <div className={s.leftPanel}>
            <h3 className={s.innnerTitleText}>
              <FormattedMessage {...messages.bookedBy} />: {bookedByName}
            </h3>

            {bookedByEmail && (
              <div style={{ marginBottom: "10px" }}>
                <span>Email: {bookedByEmail}</span>
              </div>
            )}

            <div className={s.createDateCss}>{createdDate}</div>
            <div className={s.createDateCss}>
              <span>
                <FormattedMessage {...messages.confirmationCode} />
              </span>
              : <span>#{confirmationCode}</span>
            </div>
            <div className={s.borderLine}></div>

            <div className={s.dateGrid}>
              <div>
                <div className={s.text}>
                  <FormattedMessage {...messages.checkIn} />
                </div>
                <div className={s.subText}>{checkInDate}</div>
                <div className={cx(s.dateText, s.subText)}>
                  {checkInTimeFormat}
                </div>
              </div>
              <img src={dateIcon} className={"commonIconRTL"} alt="date icon" />
              <div>
                <div className={s.text}>
                  <FormattedMessage {...messages.checkOut} />
                </div>
                <div className={s.subText}>{checkOutDate}</div>
              </div>
            </div>
            <div className={s.borderLine}></div>

            <div className={s.accommoGrid}>
              <div className={s.gridBottom}>
                <div className={s.text}>
                  <FormattedMessage {...messages.accommodationType} />
                </div>
                <div className={s.subText}>
                  {isSplitAndShare && bedroomInfo && (
                    <>
                      {/* <div> */}
                      <div className={s.text}>
                        <strong>Bedroom #{bedroomInfo.bedroomNumber}</strong>
                      </div>
                      {bedroomInfo.bedTypes && (
                        <div className={s.subText}>
                          Bed Types: {bedroomInfo.bedTypes}
                        </div>
                      )}
                      {/* </div> */}
                    </>
                  )}

                  {!isSplitAndShare && beds > 0 && (
                    <span>
                      {roomType}
                      <span>-</span>
                      {beds}{" "}
                      {beds > 1
                        ? formatMessage(messages.beds)
                        : formatMessage(messages.bed)}
                    </span>
                  )}
                </div>
              </div>
              <div className={s.gridBottom}>
                <div className={s.text}>
                  <FormattedMessage {...messages.hostedBy} />
                </div>
                <div className={s.subText}>
                  {hostData && hostData.firstName}
                </div>
              </div>
              <div>
                <div className={s.text}>
                  <FormattedMessage {...messages.howManyGuest} />
                </div>
                <div className={s.subText}>
                  {data.guests}{" "}
                  {data.guests > 1 ? (
                    <FormattedMessage {...messages.guests} />
                  ) : (
                    <FormattedMessage {...messages.guest} />
                  )}
                </div>
              </div>
              <div>
                <div className={s.text}>
                  <FormattedMessage {...messages.duration} />
                </div>
                <div className={s.subText}>
                  {dayDifference}{" "}
                  {dayDifference > 1
                    ? formatMessage(messages.nights)
                    : formatMessage(messages.night)}
                </div>
              </div>
            </div>

            <div className={s.borderLine}></div>
            <div>
              <div className={s.text}>
                <FormattedMessage {...messages.accommodationAddress} />
              </div>
              <div className={cx(s.titleColor, "hidden-print")}>
                <Link to={"/rooms/" + listId} className={s.titleColor}>
                  {listTitle ? listTitle : title}
                </Link>
              </div>
              <div className={cx(s.titleColor, "printOnly")}>
                {listTitle ? listTitle : title}
              </div>
              <div className={s.subText}>
                {street} {city}, {state} {zipcode} {country}
              </div>
            </div>
          </div>

          {/* RIGHT PANEL */}
          <div>
            <div className={s.leftPanel}>
              <h3 className={s.innnerTitleText}>
                <FormattedMessage {...messages.reservationCharges} />
              </h3>

              {/* If slotData is found, we show only the slot's total. 
                  Otherwise, display the original breakdown */}
              {slotData ? (
                <div className={cx(s.tableFlex, s.textBold)}>
                  <div>
                    <FormattedMessage {...messages.total} />
                  </div>
                  <div>
                    <CurrencyConverter amount={subTotal} from={currency} />
                  </div>
                </div>
              ) : (
                <>
                  <div className={s.tableFlex}>
                    <div>
                      {basePrice} x {dayDifference}{" "}
                      {dayDifference > 1
                        ? formatMessage(messages.nights)
                        : formatMessage(messages.night)}
                    </div>
                    <div>
                      <CurrencyConverter
                        amount={basePrice * dayDifference}
                        from={currency}
                      />
                    </div>
                  </div>

                  {cleaningPrice > 0 && (
                    <div className={s.tableFlex}>
                      <div>
                        <FormattedMessage {...messages.cleaningPrice} />
                      </div>
                      <div>
                        <CurrencyConverter
                          amount={cleaningPrice}
                          from={currency}
                        />
                      </div>
                    </div>
                  )}

                  {discount > 0 && (
                    <div className={s.tableFlex}>
                      <div>{discountType}</div>
                      <div className={s.minusFlex}>
                        <span>- </span>
                        <CurrencyConverter amount={discount} from={currency} />
                      </div>
                    </div>
                  )}

                  {userType === "guest" && guestServiceFee > 0 && (
                    <div className={s.tableFlex}>
                      <div>
                        <FormattedMessage {...messages.serviceFee} />
                      </div>
                      <div>
                        <CurrencyConverter
                          amount={guestServiceFee + guestServiceFee}
                          from={currency}
                        />
                      </div>
                    </div>
                  )}

                  {userType === "host" && hostServiceFee > 0 && (
                    <div className={s.tableFlex}>
                      <div>
                        <FormattedMessage {...messages.serviceFee} />
                      </div>
                      <div>
                        - &nbsp;
                        <CurrencyConverter
                          amount={hostServiceFee + guestServiceFee}
                          from={currency}
                        />
                      </div>
                    </div>
                  )}

                  {taxPrice > 0 && (
                    <div className={s.tableFlex}>
                      <div>
                        <FormattedMessage {...messages.tax} />
                      </div>
                      <div>
                        <CurrencyConverter amount={taxPrice} from={currency} />
                      </div>
                    </div>
                  )}

                  <div className={s.totalBorderLine}></div>
                  <div className={cx(s.tableFlex, s.textBold)}>
                    <div>
                      <FormattedMessage {...messages.total} />
                    </div>
                    <div>
                      <CurrencyConverter amount={subTotal} from={currency} />
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Payment info for guests */}
            {userType === "guest" && (
              <div className={cx(s.leftPanel, s.paymentTop)}>
                <h3 className={s.innnerTitleText}>
                  <FormattedMessage {...messages.payment} />
                </h3>
                <div className={cx(s.tableFlex)}>
                  <div className={s.paymentWidth}>
                    <FormattedMessage {...messages.paymentReceived} />{" "}
                    {updatedDate}
                  </div>
                  <div>
                    <CurrencyConverter amount={subTotal} from={currency} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={s.receiptFooter}>
          {siteName} <FormattedMessage {...messages.receiptInfo1} />{" "}
          <FormattedMessage {...messages.receiptInfo2} /> {siteName}.{" "}
          <FormattedMessage {...messages.receiptInfo3} /> {siteName}.
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  userId: state.account.data.userId,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(PaymentReceipt))
);
