exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Home-root-1avl7 {\n  padding-left: 0px;\n  padding-right: 0px;\n  overflow-x: hidden;\n}\n\n.Home-container-3YPN- {\n  max-width: 1460px;\n  margin: 0 auto;\n}\n\n.Home-pageContainer-iwLwU {\n  padding-top: 70px;\n}\n\n.Home-popularBg-13UbT {\n  background: #f8f7fd;\n  padding: 80px 0;\n  margin-top: 100px;\n}\n\n.Home-displayGrid-VHp7Q {\n  display: grid;\n  grid-template-columns: 24% 24% 24% 24%;\n  grid-gap: 17px;\n  margin: 0px 6px;\n}\n\n.Home-feedBackBg-1FpN8 {\n  background-color: #fcfcf0;\n  padding: 64px 0px;\n}\n\n.Home-popularGridWidth-4zWJq {\n  width: 100%;\n  max-width: 1172px;\n  margin: 70px auto 0 auto;\n  background-color: #ffffff;\n  padding: 41px 58px;\n  border-radius: 20px;\n  -webkit-box-shadow: 0px 2px 18px #00000014;\n          box-shadow: 0px 2px 18px #00000014;\n  position: relative;\n  z-index: 2;\n}\n\n.Home-displayGridTwo-X2Snv {\n  display: grid;\n  grid-template-columns: 25% 25% 25% 25%;\n}\n\n.Home-layout2Bottom-18aPN {\n  padding-bottom: 80px;\n}\n\n@media screen and (max-width: 1199px) {\n  .Home-popularGridWidthPadding-3xqs2 {\n    padding: 0px 15px;\n  }\n}\n\n@media screen and (max-width: 1170px) {\n  .Home-containerTitle-24piu {\n    text-align: center;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .Home-layout2Bottom-18aPN {\n    padding: 0 15px 80px;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .Home-marginLeft-uVnGJ {\n    margin: 0 0 24px 34px;\n  }\n\n  .Home-displayGrid-VHp7Q {\n    grid-template-columns: 100%;\n  }\n\n  .Home-displayGridTwo-X2Snv {\n    grid-template-columns: 100%;\n  }\n\n  .Home-popularGridWidth-4zWJq {\n    padding: 50px 20px 20px;\n  }\n\n  .Home-containerTitle-24piu {\n    margin: 0 0 40px;\n  }\n\n  .Home-displayGridTwo-X2Snv {\n    grid-row-gap: 25px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "Home-root-1avl7",
	"container": "Home-container-3YPN-",
	"pageContainer": "Home-pageContainer-iwLwU",
	"popularBg": "Home-popularBg-13UbT",
	"displayGrid": "Home-displayGrid-VHp7Q",
	"feedBackBg": "Home-feedBackBg-1FpN8",
	"popularGridWidth": "Home-popularGridWidth-4zWJq",
	"displayGridTwo": "Home-displayGridTwo-X2Snv",
	"layout2Bottom": "Home-layout2Bottom-18aPN",
	"popularGridWidthPadding": "Home-popularGridWidthPadding-3xqs2",
	"containerTitle": "Home-containerTitle-24piu",
	"marginLeft": "Home-marginLeft-uVnGJ"
};