exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NoResults-root-2jD5u {\n    margin: 60px 20px 0;\n    text-align: center;\n}\n\n.NoResults-container-9RPw5 {\n    margin: 64px 40px 96px;\n}\n\n.NoResults-noHeading-3AnJb {\n    font-size: 20px;\n    margin-top: 20px;\n}\n\n.NoResults-noSubHeading-1E-uA {\n    font-size: 18px;\n    margin: 10px 0 0;\n    color: #707070;\n}", ""]);

// exports
exports.locals = {
	"root": "NoResults-root-2jD5u",
	"container": "NoResults-container-9RPw5",
	"noHeading": "NoResults-noHeading-3AnJb",
	"noSubHeading": "NoResults-noSubHeading-1E-uA"
};