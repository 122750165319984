var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReservationContainer-panelHeader-2zTXD {\n  border-radius: 0;\n  width: 100%;\n  display: block;\n  font-size: 16px;\n  border-bottom: 1px solid var(--border-secondary-color);\n  border-bottom: 1px solid var(--border-secondary-color);\n  background: #f5f5f5;\n  margin-bottom: 0px;\n  padding-bottom: 0px;\n}\n\n.ReservationContainer-listFlex-2GW3f {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  margin: 30px 0;\n}\n\n.ReservationContainer-formControlInput-34BIv {\n  width: 382px;\n  height: 45px;\n  border: 1px solid var(--border-color);\n  border: 1px solid var(--border-color);\n  border-radius: 23px;\n}\n\n.ReservationContainer-locationBgIcon-tmbdB {\n  background: url(" + escape(require("../../../public/SiteIcons/listSeaechIcon.svg")) + ");\n  background-repeat: no-repeat;\n  background-position: 4% 51%;\n  padding-left: 35px;\n  background-size: 15px;\n}\n\n.ReservationContainer-linkCss-2MdYa {\n  padding: 12px 20px !important;\n  border-radius: 23px !important;\n}\n\n.ReservationContainer-plusIcon-2Kn45 {\n  margin-right: 6px;\n}\n\n.ReservationContainer-noMargin-3JRTR {\n  margin: 0;\n}\n\n@media screen and (max-width: 767px) {\n  .ReservationContainer-formControlInput-34BIv {\n    width: 100%;\n    margin-bottom: 15px;\n  }\n\n  .ReservationContainer-listFlex-2GW3f {\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    margin-top: 15px;\n    margin-bottom: 15px;\n    row-gap: 15px;\n  }\n\n  .ReservationContainer-mobileSearchWidth-1rNmP {\n    width: 100%;\n  }\n\n  .ReservationContainer-linkCss-2MdYa {\n    display: block;\n    text-align: center;\n  }\n\n  .ReservationContainer-locationBgIcon-tmbdB {\n    padding-left: 45px;\n    margin-bottom: 0px;\n  }\n}", ""]);

// exports
exports.locals = {
	"panelHeader": "ReservationContainer-panelHeader-2zTXD",
	"listFlex": "ReservationContainer-listFlex-2GW3f",
	"formControlInput": "ReservationContainer-formControlInput-34BIv",
	"locationBgIcon": "ReservationContainer-locationBgIcon-tmbdB",
	"linkCss": "ReservationContainer-linkCss-2MdYa",
	"plusIcon": "ReservationContainer-plusIcon-2Kn45",
	"noMargin": "ReservationContainer-noMargin-3JRTR",
	"mobileSearchWidth": "ReservationContainer-mobileSearchWidth-1rNmP"
};